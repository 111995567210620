import './Table.scss';

import React from 'react';

export function TableSortDownSvg(props: { onSort?: () => void, className?: string }) {
    return (
        <svg onClick={props.onSort} className={'clickable down'} width="20" height="20" viewBox="0 0 20 20" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path d="M5.8335 8.33301L10.0002 12.4997L14.1668 8.33301H5.8335Z" fill="#EEAD4C"/>
        </svg>
    )
}

export function TableSortUpSvg(props: { onSort?: () => void }) {
    return (
        <svg onClick={props.onSort} className={'clickable up'} width="20" height="20" viewBox="0 0 20 20" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path d="M5.8335 8.33301L10.0002 12.4997L14.1668 8.33301H5.8335Z" fill="#EEAD4C"/>
        </svg>
    )
}


