import {useDispatch, useSelector} from "react-redux";
import {fetchSubMgmtData, getIsLoading, getUsers} from "../../billing/billingSlice";
import React, {useEffect, useState} from "react";
import {OWNER_ROLE} from "../../constants";
import {OwnerUserOptions} from "./OwnerUserOptions";
import {Loader} from "../Loader/Loader";
import {PrimaryButton} from "../Button/Button";
import AddNewUser from "./AddNewUser";

export function OwnerTeamManagement(props: any) {
    const dispatch = useDispatch();
    const isLoading = useSelector(getIsLoading)
    const users = useSelector(getUsers);

    const [showAddNewUserForm, setShowAddNewUserForm] = useState(false);
    const [buttonIsEnabled, setButtonIsEnabled] = useState(true)

    const ACTIVE_MANAGER = users.length > 0 ? users.filter(user => user.role === OWNER_ROLE)[0].active : false
    const ADD_MEMBER_ENABLED = true

    useEffect(() => {
        dispatch(fetchSubMgmtData());
    }, []);

    function toggleShowAddNewUserForm() {
        setShowAddNewUserForm(!showAddNewUserForm);
    }


    const userList = users.map(user => {
        return (
            <tr key={user.id}>
                <td>{user.name}</td>
                <td>{user.email}</td>
                <td>{user.role}</td>
                <td>{user.renew_date}</td>
                <td>{user.expires_date}</td>
                <OwnerUserOptions id={user.id}
                                  role={user.role}
                                  active={user.active}
                                  expires_date={user.expires_date}
                                  setShowInvoicePreview={props.setShowInvoicePreview}
                                  setInvoiceAction={props.setInvoiceAction}
                                  active_manager={ACTIVE_MANAGER}
                                  buttonIsEnabled={buttonIsEnabled}
                                  setButtonIsEnabled={setButtonIsEnabled}
                />
            </tr>
        );
    })

    return (
        <>
            <div className="team-management">
                {isLoading ? <Loader isLoading={true}></Loader> :
                    <div>
                        <p className="admin-text">{'Add new user'}</p>
                        <PrimaryButton text="Add New User"
                                       onClick={toggleShowAddNewUserForm}
                                       isEnabled={ADD_MEMBER_ENABLED}
                        />
                    </div>
                }
                <h3 className={'underlined'}>Users</h3>
                <table>
                    <thead>
                    <tr>
                        <th align="left">Name</th>
                        <th align="left">Email</th>
                        <th align="left">Role</th>
                        <th align="left">Renew Date</th>
                        <th align="left">Expires Date</th>
                        <th align="left">Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    {isLoading ? "" : userList}
                    </tbody>
                </table>
                {showAddNewUserForm ? <AddNewUser handleInviteUser={props.handleInviteUser}
                                                  setShowAddNewUserForm={setShowAddNewUserForm}
                /> : ''}
                <p className={'update-error'}>{props.teamMgmtError ? props.teamMgmtError : ''}</p>
            </div>
        </>
    );
}
