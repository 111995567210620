import {useDispatch} from "react-redux";
import {TypicalMonthly} from "../../api/requestsTypes";
import {setInvoicePreview} from "../../billing/billingSlice";
import {PrimaryButton} from "../Button/Button";
import React from "react";
import {USER_ROLE} from "../../constants";

interface OwnerTeamMemberButtonProps {
    id: number
    setInvoiceAction: (action: string) => void
    setShowInvoicePreview: (show: boolean) => void
    buttonIsEnabled: boolean
    setButtonIsEnabled: (state: boolean) => void
}

function OwnerTeamMemberButton(props: OwnerTeamMemberButtonProps) {

    const dispatch = useDispatch()

    async function delete_user() {
        let next_invoice = {
            invoiced_now: false,
            next_invoice: {
                discount_description: '',
                discounts: '',
                line_items: [],
                next_invoice_date: '',
                subtotal: '',
                total: ''
            },
            proration_date: -1,
            typical_monthly: {} as TypicalMonthly,
            invoice_preview_params: {
                subscription_id: '',
                price_id: '',
                type: '',
                user_id: props.id
            }
        }
        props.setButtonIsEnabled(false)
        await await dispatch(setInvoicePreview(next_invoice))
        props.setInvoiceAction('delete_user')
        props.setShowInvoicePreview(true)
        props.setButtonIsEnabled(true)
    }

    return (
        <td>
            <PrimaryButton className={'small-inline-button-right'}
                           text={'Delete'}
                           onClick={delete_user}
                           isEnabled={props.buttonIsEnabled}
                           isLoading={!props.buttonIsEnabled}
            />
        </td>
    )
}

interface OwnerUserOptionsProps {
    id: number
    role: string
    active: boolean
    expires_date: string
    setShowInvoicePreview: (state: boolean) => void
    setInvoiceAction: (action: string) => void
    active_manager: boolean
    buttonIsEnabled: boolean
    setButtonIsEnabled: (state: boolean) => void
}

export function OwnerUserOptions(props: OwnerUserOptionsProps) {
    const owner_user = props.role === USER_ROLE

    function user_actions() {
        if (owner_user) {
            return <OwnerTeamMemberButton
                id={props.id}
                setInvoiceAction={props.setInvoiceAction}
                setShowInvoicePreview={props.setShowInvoicePreview}
                buttonIsEnabled={props.buttonIsEnabled}
                setButtonIsEnabled={props.setButtonIsEnabled}
            />
        } else {
            return <td>No Actions</td>
        }
    }

    return (
        user_actions()
    )
}
