import React from 'react';
import './TermsConditions.scss';
import '../../App.scss'
import {Container} from 'reactstrap';

function Terms() {
    return (
        <div>
            <h1 className='tc-h1'>TERMS & CONDITIONS</h1>
            <h1 className='modified'>Last modified 5/27/2022</h1>
            <h1 className='p-header'>PLEASE READ THESE TERMS CAREFULLY.</h1>
            <p>These Terms and Conditions of Use (the “Terms”) govern your use of and access to ValueAnalytics.org and
                its sub-domains and affiliated sites, as well as Value Analytics, LLC’s (“Value Analytics”) pages and
                accounts on Facebook®, Twitter®, LinkedIn®, Google Plus®, YouTube®, and any other social media website
                (collectively the “Sites), and its Services (as hereinafter defined). Please read both these Terms and
                Value Analytics’ <a className='blue-link' target='_blank' href='/privacy'>Privacy Policy</a> carefully,
                which is incorporated into these Terms. You may not use the Sites or the Services until you have read
                the Terms. By using any or all of the Sites or the Services, you accept and agree to be bound by these
                Terms. If you do not want to agree to be bound by these Terms, do not use the Sites or the Services.
                Value Analytics may modify these Terms at any time, and any modifications will be effective immediately
                unless otherwise stated. You are responsible for reviewing any modified terms. All modifications will be
                posted at ValueAnalytics.org/terms. Your continued use of a Site or the Services following any changes
                means you accept and agree to any changes. For your convenience and future reference, the date of the
                most recent revision of these Terms is listed above so that you may compare different versions to
                determine what, if any, changes have been made. As used in these Terms, the terms "you", "your" or
                "user" all refer to both the person using the Sites or the Services in any way, including those
                registered as, for, or on behalf of a corporate entity, such as a company, business, affiliated
                entities, corporation, government agency, or other business entity and its employees, subcontractors,
                affiliates, and all other persons or entities permitted to access and use the Sites or the Services.</p>
            <h1 className='p-header'>SITE CONTENT.</h1>
            <p>Value Analytics exclusively owns and controls the Sites, which, among other things, provides information
                about products and services and access to a variety of materials and content, as well as the Value
                Analytics Excel Add-In (the “Services”). You agree that use or access to any or all of the Sites or the
                Services does not create any sort of representation or future promise. Value Analytics does not warrant
                or make any representation of accuracy regarding the information or data contained, described in, or
                related to the Services. Value Analytics shall own any feedback, recommendations, or suggestions that
                you may provide to Value Analytics and may use the results of such feedback for any purpose, including
                but not limited to in its marketing and promotional materials. The unauthorized reproduction,
                sublicensing, sharing, use of, or theft of any content, written, photographic, video or otherwise, is
                expressly prohibited. Any use of the Sites or Services in violation of these terms will constitute
                infringement of Value Analytics’ intellectual property rights.</p>
            <h1 className='p-header'>FEES.</h1>
            <p>You agree to pay all fees for the Services you select. Fees shall be due and payable to Value Analytics
                by credit card, via a third-party payment processor, prior to Services being rendered by Value
                Analytics. Fees for monthly or annual subscriptions will be automatically initiated on the respective
                monthly or annual anniversary date. Services can be cancelled by you at any time and will be effective
                at the end of the paid period in which Value Analytics receives notice of cancellation. In the event a
                payment is not received for any reason, Value Analytics may suspend your access to the applicable
                Services or terminate your Services. Refunds shall not be given in the event any or all of the Sites
                become non-operational, in the event information in the Services is incorrect or inaccurate, or for any
                other reason. All payments are non-refundable. Value Analytics reserves the right to adjust fees at any
                time at its discretion.</p>
            <h1 className='p-header'>INTELLECTUAL PROPERTY.</h1>
            <p>Unless explicitly stated otherwise, as between you and Value Analytics, Value Analytics owns and retains
                all right, title, and interest in and to (a) the Services; (b) its Sites; and (c) all associated
                content, materials, graphics, logos, slogans, product and service names, information, and other
                intellectual property. All other names, logos, product and service names, designs and slogans on the
                Sites are the trademarks of their respective owners and should not be used without those respective
                owners’ permission. You are granted a limited, non-exclusive, non-transferable, revocable license to
                access and use the Sites and the Services therein, including the resources available for download or
                streaming strictly in accordance with these Terms and solely for your personal purposes.</p>
            <h1 className='p-header'>THIRD PARTY RIGHTS.</h1>
            <p>Content and materials posted to the Sites may be the copyrighted content of others (“Third Party
                Content”) that is used by Value Analytics either by permission or under Section 107 of the Copyright Act
                as “fair use” for purposes such as education and research. Users must obtain permission from the owners
                of any Third-Party Content before copying, distributing or otherwise using those materials. Except as
                otherwise expressly permitted under copyright law, no copying, redistribution, retransmission,
                publication, or commercial exploitation of downloaded material will be permitted without the express
                permission of the copyright owner.</p>
            <p>If you believe that your work has been copied on one or more of the Sites in a way that constitutes
                copyright infringement or otherwise violates your intellectual property rights, please contact us via
                email at the contact information listed below and provide the following: (i) identification of what is
                claimed to have been infringed; (ii) identification of what is claimed to be infringing; (iii) your
                contact information (or the contact information of the person we need to contact about the
                infringement); (iv) a statement that the person submitting the complaint is doing so with a good faith
                belief that use of the material in the manner complained of is not authorized by the owner, its agent,
                or the law; (v) a statement that the information provided is accurate, and under penalty of perjury;
                (vi) a physical or electronic signature of the person submitting the complaint; and (vii) if that person
                is not the owner of the content at issue, a statement that the person submitting the complaint is
                authorized to act on the owner’s behalf.</p>
            <h1 className='p-header'>LINKING TO OUR SITES.</h1>
            <p>Anyone linking to the Sites must comply with all applicable laws and must not: (i) misrepresent its
                relationship with Value Analytics; (ii) present false or misleading information about Value Analytics;
                or (iii) contain content that is reasonably considered profanity, offensive, defamatory, vulgar, or
                unlawful.</p>
            <h1 className='p-header'>ADVERTISEMENTS AND LINKS.</h1>
            <p>Value Analytics may at times include advertisements on the Sites. Your correspondence or business
                dealings with, or participation in promotions of, advertisers found on or through the Sites are solely
                between you and such advertiser. Any opinions, advice, statements, services, offers, or other
                information or content expressed or made available by advertisers, including information providers, or
                any other end users are those of the respective author(s) and not our own. You agree that Value
                Analytics shall not be responsible or liable for any loss or damage of any sort incurred as a result of
                any such dealings or as the result of the presence of such advertisers. Any affiliate links on the Sites
                will be clearly marked; however, you may reach out to Value Analytics using the contact information
                listed below with any questions you may have regarding affiliate links.</p>
            <p>You may find links to other websites on a Site. These links are provided solely as a convenience to you
                and not as an endorsement by Value Analytics of the contents on such third-party sites, and Value
                Analytics expressly disclaims any representations regarding the content or accuracy of materials on such
                third-party websites. You acknowledge and agree that Value Analytics shall not be responsible or liable,
                directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with
                use of or reliance on any such content, goods or products available on or through any such linked site.
                You agree that it is your responsibility to evaluate the accuracy, completeness, or usefulness of any
                information, opinion, advice, etc., or other content available through such third-party sites.</p>
            <h1 className='p-header'>DISCLAIMER AND LIMITATION OF LIABILITY.</h1>
            <p>THE SITES AND THE SERVICES ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS, WITHOUT WARRANTY OF ANY
                KIND. VALUE ANALYTICS, TOGETHER WITH ITS AFFILIATES, LICENSORS, SERVICE PROVIDERS, EMPLOYEES, AGENTS,
                OFFICERS OR DIRECTORS (THE “RELEASED PARTIES”), SPECIFICALLY DISCLAIM ALL WARRANTIES, EXPRESS OR
                IMPLIED, INCLUDING, BUT NOT LIMITED TO, WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE,
                AND NON-INFRINGEMENT AND WARRANTIES THAT MAY ARISE OUT OF COURSE OF DEALING, COURSE OF PERFORMANCE,
                USAGE OR TRADE PRACTICE. THE RELEASED PARTIES DO NOT GUARANTEE THE RELIABILITY, ACCURACY, COMPLETENESS,
                SAFETY, TIMELINESS, LEGALITY, USEFULNESS, ADEQUACY OR SUITABILITY OF ANY OF THE INFORMATION OR CONTENT
                ON THE SITES. VALUE ANALYTICS AND THE RELEASED PARTIES DO NOT WARRANT THAT THE SERVICE OR SITES WILL BE
                ERROR FREE, FREE FROM ANY VIRUS, MALWARE, OR HARMFUL CODE, FREE FROM INTERRUPTIONS, WILL NOT INFRINGE
                THE INTELLECTUAL PROPERTY RIGHTS OF ANY THIRD PARTY, OR ACCOMPLISH ANY PARTICULAR RESULT. ACCORDINGLY,
                YOU AGREE TO EXERCISE CAUTION, DISCRETION AND COMMON SENSE WHEN USING THE SITES. THE ENTIRE RISK FOR USE
                OF THE SITE AND/OR SERVICES IS BORNE BY YOU. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO
                EVENT SHALL THE RELEASED PARTIES BE LIABLE FOR ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL
                OR PUNITIVE DAMAGES ARISING OUT OF THE USE OF OR INABILITY TO ACCESS THE SITES, INCLUDING, WITHOUT
                LIMITATION, DAMAGES FOR LOSS OF GOODWILL, WORK DISRUPTIONS, COMPUTER FAILURE OR MALFUNCTION, OR ANY AND
                ALL OTHER PERSONAL OR COMMERCIAL DAMAGES OR LOSSES, EVEN IF ADVISED OF THE POSSIBILITY THEREOF, AND
                REGARDLESS OF THE LEGAL OR EQUITABLE THEORY (CONTRACT, TORT, BREACH OF WARRANTY OR OTHERWISE) UPON WHICH
                THE CLAIM IS BASED. THE RELEASED PARTIES ARE NOT RESPONSIBLE FOR ANY LIABILITY ARISING OUT OF THE
                POSTINGS OR ANY MATERIAL LINKED THROUGH THE SITES. YOUR SOLE REMEDY WITH RESPECT TO ANY CLAIM ARISING
                OUT OF YOUR USE OF THE SITES AND SERVICES IS TO CEASE USING THE SITES AND SERVICES. IN NO EVENT SHALL
                VALUE ANALYTICS’ TOTAL LIABILITY EXCEED AN AMOUNT EQUAL TO THE LESSOR OF ACTUAL DAMAGES INCURRED OR THE
                FEES PAID BY YOU IN THE TWELVE (12) MONTH PERIOD PROCEEDING THE EVENT GIVING RISE TO SUCH LIABILITY FOR
                THE SERVICES GIVING RISE TO SUCH LIABILITY. </p>
            <h1 className='p-header'>INDEMNIFICATION.</h1>
            <p>YOU AGREE TO INDEMNIFY, DEFEND, AND HOLD VALUE ANALYTICS HARMLESS FROM AND AGAINST ANY DAMAGES, CLAIMS OR
                LOSSES ARISING FROM YOUR: (A) BREACH OF THESE TERMS; (B) INFRINGEMENT OF ANY THIRD-PARTY INTELLECTUAL
                PROPERTY; AND (C) NEGLIGENCE, WILLFUL MISCONDUCT, OR VIOLATION OF LAW. VALUE ANALYTICS SHALL NOT BE
                LIABLE FOR ANY SPECIAL, INCIDENTAL, CONSEQUENTIAL, PUNITIVE OR EXEMPLARY DAMAGES, ARISING OUT OF OR
                RELATED TO THIS AGREEMENT, WHETHER OR NOT A PARTY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES,
                AND WHETHER BASED UPON BREACH OF CONTRACT, WARRANTY, OR STATUTORY DUTY, NEGLIGENCE, STRICT LIABILITY OR
                OTHER TORT, PRINCIPLES OF INDEMNITY OR CONTRIBUTION, OR OTHERWISE. YOU AGREE THAT VALUE ANALYTICS WILL
                NOT MAKE ANY COURT APPEARANCES ON YOUR BEHALF WITHOUT A SUBPOENA AND IF VALUE ANALYTICS IS REQUIRED TO
                MAKE A COURT APPEARANCE DUE TO A SUBPOENA BY YOU, THEN YOU SHALL INDEMNIFY AND REIMBURSE VALUE ANALYTICS
                FOR ALL RELATED LOSSES AND EXPENSES. </p>
            <h1 className='p-header'>CHOICE OF LAW AND VENUE.</h1>
            <p>These Terms are governed by the laws of the Commonwealth of Pennsylvania without regard to any conflict
                of laws. For any dispute regarding these Terms, the Sites, or the Services, you agree to submit to the
                personal and exclusive jurisdiction and venue of the federal and state courts located in Butler County,
                PA.</p>
            <h1 className='p-header'>MISCELLANEOUS.</h1>
            <p>Should any provision of these Terms be found unenforceable, the remainder of these Terms shall remain in
                full force and effect. These Terms (and any policies linked or attached to it, including but not limited
                to the <a className='blue-link' target='_blank' href='/privacy'>Privacy Policy</a> ) constitute the
                entire agreement between you and Value Analytics and supersedes any oral or written communications
                between us. In the event of conflict between these Terms and any other agreement or understanding
                between the parties, these Terms shall govern.</p>
            <h1 className='p-header'>YOUR COMMENTS AND CONCERNS.</h1>
            <p>All feedback, comments, requests for technical support and other communications relating to the Sites or
                the Services should be directed to: Derek@ValueAnalytics.org or Adam@ValueAnalytics.org or to Value
                Analytics, PO Box 993, Mars, PA 16046. </p>
        </div>
    )
}

function TermsPage() {
    return (
        <div className='informational-page' role='main'>
            <Container>
                <main>
                    <div className='card-style top-bottom-margin'>
                        <Terms/>
                    </div>
                </main>
            </Container>
        </div>
    )
}

export {
    Terms,
    TermsPage
}

