import React, {useState} from 'react';
import {useSelector} from "react-redux";
import {selectClaims} from '../../auth/authSlice';
import {config} from '../../config';
import {loadStripe} from '@stripe/stripe-js';
import {Elements} from '@stripe/react-stripe-js';
import {
    selectPlan,
    selectPlanDetails
} from "../../signup/signUpSlice";
import OrderSummary from './OrderSummary';
import {Terms} from '../TermsConditions/Terms';
import './SignupForm.scss';
import {CheckoutForm} from "./CheckoutForm";
import {CSSTransition} from "react-transition-group";
import {Col, Container, Row} from "reactstrap";

const stripePromise = loadStripe(config.stripe_pk);

interface SignupFormProps {
    onUpdatingSubscription: (isUpdatingSubscription: boolean) => void;
    isUpgrading: boolean
}

export function SignupForm(props: SignupFormProps) {
    const [acceptedTerms, setAcceptedTerms] = useState(false);
    const [referredBy, setReferredBy] = useState('')
    const [error, setError] = useState('');
    const plan = useSelector(selectPlan);
    const claims = useSelector(selectClaims);
    const planDetails = useSelector(selectPlanDetails);
    return (
        <CSSTransition appear
                       in
                       classNames={"login-transition"}
                       timeout={1000}
        >
            <div id="form">

                <Container>
                    <Row className={'pb-5'}>
                        <Col lg={6} md={12} className={'order-lg-2 mt-5'}>
                            <div className="summary">
                                <h3>Order Summary</h3>
                                <OrderSummary
                                />
                            </div>
                        </Col>
                        <Col lg={6} md={12} className={'order-lg-1'}>
                            <Row>
                                {props.isUpgrading ? "" :
                                    <Col md={12} className={'order-lg-4 pt-5 pt-lg-0'}>
                                        <div className="referral">
                                            <h4>Referral</h4>
                                            <input type="text" id="referred"
                                                   onChange={(e: { target: HTMLInputElement }) => setReferredBy(e.target.value)}
                                                   placeholder='Referred By'/>
                                            <label htmlFor="referred">
                                                Please let us know who referred you to our application. <br></br>
                                            </label>
                                        </div>
                                    </Col>
                                }
                                <Col md={12} className={'order-lg-1 mt-lg-5'}>
                                    <h3>User Agreement</h3>
                                </Col>
                                <Col md={12} className={'order-lg-2'}>
                                    <div className="privacy-policy">
                                        <Terms/>
                                    </div>
                                </Col>
                                <Col md={12} className={'order-lg-3'}>
                                    <div className="agree">
                                        <input type="checkbox" id="agree" name="agree"
                                               checked={acceptedTerms}
                                               onChange={(e: { target: HTMLInputElement }) => setAcceptedTerms(e.target.checked)}/>
                                        <label htmlFor="agree">
                                            I have read and agree to the <a href="/terms" target="_blank">
                                            terms and conditions</a> and <a href="/privacy" target="_blank">privacy
                                            policy</a>.
                                        </label>
                                    </div>
                                </Col>
                                <Col md={12} className={'order-lg-5'}>
                                    <div className="payment-info">
                                        {
                                            error && <div className="error-details"> {error} </div>
                                        }
                                        <Elements stripe={stripePromise}>
                                            <CheckoutForm
                                                planDetails={planDetails}
                                                onUpdatingSubscription={props.onUpdatingSubscription}
                                                onError={setError}
                                                planId={plan.planType}
                                                customerId={claims.customer_id}
                                                acceptedTerms={acceptedTerms}
                                                referredBy={referredBy}
                                                isUpgrading={props.isUpgrading}
                                            />
                                        </Elements>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>

        </CSSTransition>
    );
}
