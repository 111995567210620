import React, {ReactNode, useState} from "react";
import {PrimaryButton} from "../Button/Button";
import { Auth } from 'aws-amplify';
import {EyeOpen, EyeClosed} from '../Svg/Svg';
import {Loader} from '../Loader/Loader';
import '../Button/Button.scss'

export function ShowPassword(props:any){

    return (
        props.eyeOpen ? <div  className='eye-button' onClick={props.onClick}>
                            <EyeClosed></EyeClosed>
                        </div>:
                        <div className='eye-button' onClick={props.onClick}>
                            <EyeOpen></EyeOpen>

                        </div >
    )

}


export function ResetPassword(props: any){
    const [curPassword, setCurPassword] = useState('')
    const [newPassword, setNewPassword] = useState('')

    const [showCurPassword, setShowCurPassword] = useState(false)
    const [showNewPassword, setShowNewPassword] = useState(false)

    const [newPasswordError, setNewPasswordError] = useState('')
    const [curPasswordError, setCurPasswordError] = useState('')

    const [completionMessage, setCompletionMessage] = useState('')
    const [isLoading, setIsLoading] = useState(false)


    function updatePassword(curPassword: string, newPassword: string){

        setIsLoading(true)
        Auth.currentAuthenticatedUser()
            .then(user => {
                return Auth.changePassword(user, curPassword, newPassword);
            })
            .then((data) => {
                setIsLoading(false)
                if (data=='SUCCESS') {
                    setNewPasswordError('')
                    setCurPasswordError('')
                    setCompletionMessage('Password updated!')
                }
            })
            .catch((err) => {
                setIsLoading(false)
                if (err.code == 'NotAuthorizedException') {
                    setCurPasswordError('Incorrect Password')
                } else if (err.code == 'LimitExceededException') {
                    setNewPasswordError('')
                    setCurPasswordError('')
                    setCompletionMessage('Password change attempt limit exceeded. Try again in a few minutes.')
                }
            });

    }

    function toggleShowCurPassword(e:any){
        e.preventDefault()
        setShowCurPassword(!showCurPassword)

    }

    function toggleShowNewPassword(e:any){
        e.preventDefault()
        setShowNewPassword(!showNewPassword)
    }


    function handleCurPassword(e: any) {
        setCurPassword(e.target.value)
    }

    function handleNewPassword(e: any) {
        setNewPassword(e.target.value)
    }

    function submitNewPassword(e: any) {
        e.preventDefault()
        if (newPassword.length >= 8 && curPassword.length >= 8) {
            updatePassword(curPassword, newPassword)
        } else if (newPassword.length < 8) {
            setNewPasswordError('New password must be 8 characters or greater')
        } else if (curPassword.length < 8) {
            setCurPasswordError('Please provide current password')
        }
    }

    return (

        <div>
        <h3>Change Password</h3>
            <div className="form-input">
                <label> {curPasswordError? curPasswordError : 'Current Password'}
                    <input
                            type={showCurPassword? "text" : "password"}
                            className={props.className}
                            placeholder={'Enter Current Password'}
                            onChange={handleCurPassword}
                        />
                </label>
                < ShowPassword eyeOpen={showCurPassword} onClick={toggleShowCurPassword}></ShowPassword>
            </div>
            <div className="form-input">
                <label> {newPasswordError? newPasswordError : 'New Password'}
                    <input
                            type={showNewPassword? "text" : "password"}
                            className={props.className}
                            placeholder={'Enter New Password'}
                            onChange={handleNewPassword}
                        />
                </label>
                < ShowPassword eyeOpen={showNewPassword} onClick={toggleShowNewPassword}></ShowPassword>
            </div>
            <div>
                <button className={'primary button'} onClick={submitNewPassword}>
                    <span>Update Password</span>
                    <Loader isLoading={isLoading} className={'inline'}></Loader>
                </button>
                <p className={'completion-message'}>{completionMessage? completionMessage:''}</p>
            </div>

        </div>


    )

}




