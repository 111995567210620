import React from 'react-dom';
import './SearchCriteriaContainer.scss'
import CriteriaCategoryContainer from "../CriteriaCategoryContainer/CriteriaCategoryContainer";
import CriteriaSearchBar from "../CriteriaSearchBar/CriteriaSearchBar";
import FilterFlap from "../FilterFlap/FilterFlap";
import PinnedCriteriaContainer from "../PinnedCriteriaContainer/PinnedCriteriaContainer";
import {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {selectPinnedFilters} from "../../filtering/pinnedFilterSlice";
import {selectFilterList} from "../../filtering/filterSlice";
import {
    getExpandableFilterCategoriesFrom,
    getFiltersWithName,
    getNonExpandableFilterCategoriesFrom
} from "../../filtering/filter";

function SearchCriteriaContainer() {
    const filterList = useSelector(selectFilterList);
    const pinnedFilters = useSelector(selectPinnedFilters);
    const [isFiltersOpened, setIsFiltersOpened] = useState(false);
    const [expandableCategories, setExpandableCategories] = useState(getExpandableFilterCategoriesFrom(filterList));
    const [nonExpandableCategories, setNonExpandableCategories] = useState(getNonExpandableFilterCategoriesFrom(filterList));
    const [isSearchingForText, setIsSearchingForText] = useState(false);
    const [searchText, setSearchText] = useState("");
    const hasPinnedFilters = Object.keys(pinnedFilters).length > 0;
    const backgroundCssClass = hasPinnedFilters || isFiltersOpened ? 'with-background' : '';

    useEffect(() => {
        updateSearchFilters(searchText);
    }, [filterList])

    function updateSearchFilters(text: string) {
        setSearchText(text);
        const filteredFilterList = getFiltersWithName(filterList, text);
        setExpandableCategories(getExpandableFilterCategoriesFrom(filteredFilterList));
        setNonExpandableCategories(getNonExpandableFilterCategoriesFrom(filteredFilterList));
    }

    function toggleFilterFlap() {
        let isOpened = !isFiltersOpened;
        if (!isOpened) {
            setIsSearchingForText(false);
        }
        setIsFiltersOpened(isOpened);
    }

    function handleSearchForFilter(text: string) {
        if (text !== "") {
            setIsFiltersOpened(true);
            setIsSearchingForText(true);
        } else {
            setIsSearchingForText(false);
            setIsFiltersOpened(false);
        }

        updateSearchFilters(text);
    }

    return (
        <div className={'search-criteria-container'}>

                <div className={`search-criteria-wrap`}>
                        <FilterFlap
                            isFilterFlapOpen={isFiltersOpened}
                            hasShadow={!hasPinnedFilters && !isFiltersOpened}
                            handleFlapClicked={toggleFilterFlap}
                        />
                        {/*<PinnedCriteriaContainer/>*/}
                </div>
                    <CriteriaCategoryContainer
                        show={isFiltersOpened}
                        expandableCategories={expandableCategories}
                        nonExpandableCategories={nonExpandableCategories}
                        isSearchingForText={isSearchingForText}
                    />

                <CriteriaSearchBar handleOnSearchForText={handleSearchForFilter}/>
        </div>
    )
}

export default SearchCriteriaContainer;
