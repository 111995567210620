import React from 'react';
import './Card.scss';
import ConditionalWrapper from '../Utility/ConditionalWrapper';

import Document from '../Document/Document';
import WithActiveSubscription from "../PadLock/WithActiveSubscription";

export function Card(props: any) {
    const docs = [];
    for (let i = 0; i < props.docs.length; i++) {
        docs.push(<Document key={i.toString()}
                            name={props.docs[i].name}
                            desc={props.docs[i].desc}
                            url={props.docs[i].url}
                            new_tab={props.docs[i].new_tab}
                            video={props.docs[i].video}
        />)
    }

    return (
        <div className='card'>
            <ConditionalWrapper
                condition={props.protected}
                wrapper={(children) =>
                    <WithActiveSubscription fullscreen={false}>{children}</WithActiveSubscription>}>
                <>
                    <h3 className={'informational-h1'}>{props.title}</h3>
                    <div className='document-list'>
                        {docs}
                    </div>
                </>
            </ConditionalWrapper>
        </div>
    )
}
