import {
    FilterState, IKeywordSearchFilter,
    INumericFilter,
    IPickerFilter,
    ISicCodePicker, KeywordSearchFilters,
    RegularFilterPickers
} from "../filtering/filterStateTypes";
import {normalizeNumber} from "../utility/numberFormatter";

export interface IFilterCountsRequest {
    filters: FilterCount[]
}

type FilterCount
    = INumericFilterCount
    | ISicCodeCount
    | IRegularPickerCount
    | IKeywordSearchFilterCount

interface INumericFilterCount {
    filter_type: 'numeric_filters';
    column: string;
    value: [number, number]
}

interface ISicCodeCount {
    filter_type: 'sic_codes';
    sector_id: string[];
    industry_group_id: string[];
    sic_code: string[]
}

interface IRegularPickerCount {
    filter_type: RegularFilterPickers,
    value: string[]
}

interface IKeywordSearchFilterCount {
    filter_type: KeywordSearchFilters;
    value: string
}

function toSicCodeCount(filterState: ISicCodePicker) : ISicCodeCount {
    return {
        filter_type: 'sic_codes',
        sector_id: filterState.sector_id,
        industry_group_id: filterState.industry_group_id,
        sic_code: filterState.sic_code,
    }
}

function toNumericFilterCount(filterState: INumericFilter) : INumericFilterCount {
    const [min, max] = filterState.range;
    const normalizeMin = normalizeNumber(min, filterState.displayType);
    const normalizeMax = normalizeNumber(max === null ? Infinity : max, filterState.displayType);

    return {
        filter_type: 'numeric_filters',
        column: filterState.filterType,
        value: [normalizeMin, normalizeMax]
    };
}

function toPickerFilterCount(filterState: IPickerFilter) : IRegularPickerCount {
    return {
        filter_type: filterState.filterType,
        value: filterState.value
    }
}

function toKeywordSearchFilterCount(filterState: IKeywordSearchFilter) : IKeywordSearchFilterCount {
    return {
        filter_type: filterState.filterType,
        value: filterState.value
    }
}

function toFilterCount(filterState: FilterState) : FilterCount {
    switch(filterState.type) {
        case 'sicCodePicker':
            return toSicCodeCount(filterState);
        case 'numeric':
            return toNumericFilterCount(filterState);
        case 'picker':
            return toPickerFilterCount(filterState);
        case 'keywordsearch':
            return toKeywordSearchFilterCount(filterState);
    }
}

export function getFilterCountsRequest(selectedFilters: FilterState[]) : IFilterCountsRequest {
    return {
        filters: selectedFilters.map(toFilterCount)
    }
}
