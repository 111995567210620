import React, {useEffect, useState} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { PrimaryButton } from '../Button/Button';
import AddNewUser from './AddNewUser';
import {WEEKLY_NAME, MANAGER_ROLE, MONTHLY_NAME} from "../../constants";
import "./AccountPage.scss"
import { selectAttributes, selectClaims } from '../../auth/authSlice';
import {fetchSubMgmtData, getNextPayment, getPlanType, getUsers, getIsLoading} from '../../billing/billingSlice';
import UserOptions from './UserOptions'
import {Loader} from "../Loader/Loader";


function TeamManagement(props: any) {
  const dispatch = useDispatch();
  const isLoading = useSelector(getIsLoading)
  const userAttributes = useSelector(selectAttributes);
  const userClaims = useSelector(selectClaims);
  const planType = useSelector(getPlanType);
  const users = useSelector(getUsers);
  const nextPayment = useSelector(getNextPayment);

  const [showAddNewUserForm, setShowAddNewUserForm] = useState(false);
  const [nextBillingDate, setNextBillingDate] = useState('Annual Plan $699.98 - renews May 9, 2022');
  const [buttonIsEnabled, setButtonIsEnabled] = useState(true)
  const MONTHLY_USER_MESSAGE = 'As an Account Manager with a monthly subscription, ' +
                               'you are able to add Team Members to your account. Press "Add New User" to get started. '

  const TRIALING_USER_MESSAGE = 'Please upgrade your trial account to a paid monthly subscription to add ' +
                                'Team Members.'

  const WEEKLY_USER_MESSAGE = 'As an Account Manager with a weekly subscription, you cannot add Team Members to your' +
                              ' account. Please upgrade to a monthly subscription to add Team Members.'

  const INACTIVE_MONTHLY_MESSAGE = 'Please renew your Account Manager subscription to add Team Members to your account.'




  const ACTIVE_MANAGER =  users.length > 0 ? users.filter(user => user.role==MANAGER_ROLE)[0].active : false

  let USER_MESSAGE = ''
  if (planType.sub_name == WEEKLY_NAME) {
    USER_MESSAGE = WEEKLY_USER_MESSAGE
  } else if (planType.trialing) { // catches trialing accounts, weather active or not
    USER_MESSAGE = TRIALING_USER_MESSAGE
  } else if (planType.sub_name == MONTHLY_NAME && ACTIVE_MANAGER == true && !planType.trialing) {
    USER_MESSAGE = MONTHLY_USER_MESSAGE
  } else if (planType.sub_name == MONTHLY_NAME && ACTIVE_MANAGER == false && !planType.trialing){
    USER_MESSAGE = INACTIVE_MONTHLY_MESSAGE
  }

  let ADD_MEMBER_ENABLED = false
  if (planType.sub_name == MONTHLY_NAME && ACTIVE_MANAGER == true && !planType.trialing) {
    ADD_MEMBER_ENABLED = true
  }

  useEffect(() => {
      dispatch(fetchSubMgmtData());
  }, []);

  function toggleShowAddNewUserForm() {
    setShowAddNewUserForm(!showAddNewUserForm);
  }


  const userList = users.map(user => {
    return (
      <tr key={user.id}>
        <td>{user.name}</td>
        <td>{user.email}</td>
        <td>{user.role}</td>
        <td>{planType.trialing ? 'Trialing': user.sub_name}</td>
        <td>{user.renew_date}</td>
        <td>{user.expires_date}</td>
          <UserOptions id={user.id}
                       role={user.role}
                       active={user.active}
                       trialing={planType.trialing}
                       expires_date={user.expires_date}
                       sub_name={user.sub_name}
                       setShowInvoicePreview={props.setShowInvoicePreview}
                       setInvoiceAction={props.setInvoiceAction}
                       active_manager={ACTIVE_MANAGER}
                       buttonIsEnabled={buttonIsEnabled}
                       setButtonIsEnabled={setButtonIsEnabled}
          />

      </tr>
    );
  })

  return (
    <>
      <div className="team-management">
        { isLoading ? <Loader isLoading={true}></Loader> :
            <div>
                <p className="admin-text">{USER_MESSAGE}</p>
                <PrimaryButton text="Add New User"
                onClick={toggleShowAddNewUserForm}
                isEnabled={ADD_MEMBER_ENABLED}
                />
            </div>
        }
        <h3 className={'underlined'}>Users</h3>
        <table>
          <thead>
            <tr>
              <th align="left">Name</th>
              <th align="left">Email</th>
              <th align="left">Role</th>
              <th align="left">Plan Type</th>
              <th align="left">Renew Date</th>
              <th align="left">Expires Date</th>
              <th align="left">Actions</th>
            </tr>
          </thead>
          <tbody>
            {isLoading ? "" : userList}

          </tbody>
        </table>
        {showAddNewUserForm ? <AddNewUser handleInviteUser={props.handleInviteUser}
                                          setShowAddNewUserForm={setShowAddNewUserForm}
        /> : ''}
        <p className={'update-error'} >{props.teamMgmtError ? props.teamMgmtError : ''}</p>
      </div>
    </>
  );
}

export default TeamManagement;