import React from 'react-dom'
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {FilterType, IFilter} from "../../filtering/filter";
import FilterPin from "../CriteriaPin/FilterPin";
import FilterLabel from "../CriteriaLabel/FilterLabel";
import './Criterion.scss';
import {addPinnedFilter, removePinnedFilter, selectIsFilterPinned} from '../../filtering/pinnedFilterSlice';
import { addSelectedFilterState } from '../../filtering/selectedFilterSlice';

function Criterion(props: IFilter) {
    const isSelected = useSelector(selectIsFilterPinned(props.value));
    const [showPin, setShowPin] = useState(isSelected);
    const dispatch = useDispatch();

    useEffect(() => {
        setPinState(isSelected);
    }, [isSelected])

    function setPinState(value: boolean) {
        if (isSelected) {
            setShowPin(true);
        } else {
            setShowPin(value);
        }
    }

    function toggleCriterion(value: string) {
        if (isSelected) {
            dispatch(removePinnedFilter(props));
        } else {
            dispatch(addPinnedFilter(props));
        }
    }

    function handleOnClick() {
        dispatch(addSelectedFilterState(props));
    }

    return (
        <>
            {/*<div*/}
            {/*    className={'criterion-pin'}*/}
            {/*    onMouseEnter={() => setPinState(true)}*/}
            {/*    onMouseLeave={() => setPinState(false)}*/}
            {/*    onClick={() => {*/}
            {/*        toggleCriterion(props.value);*/}
            {/*    }}*/}
            {/*>*/}
            {/*    {showPin ? <FilterPin/> : <>&nbsp;</>}*/}
            {/*</div>*/}
            <div
                className={'criterion-label'}
                // onMouseEnter={() => setPinState(true)}
                // onMouseLeave={() => setPinState(false)}
                onClick={handleOnClick}
            >
                <FilterLabel bold={props.bold} label={props.name}></FilterLabel>
            </div>
        </>
    )
}

export default Criterion;
