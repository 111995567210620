import React from 'react';
import './DocumentationPage.scss';
import {ValueAnalyticsDocuments} from '../../data/va-documents';
import '../../App.scss'
import {Col, Container, Row} from 'reactstrap'
import {Card} from '../Card/Card';
import WithActiveSubscription from "../PadLock/WithActiveSubscription";

interface Documents {
    name: string,
    desc: string,
    url: string
}

interface Documentation {
    title: string,
    subtitle: string,
    documents: Documents[]
}

function DocumentationCol(props: { children: React.ReactNode }) {
    return (
        <Col md={12}>{props.children}</Col>
    )
}

function DocumentationCard(props: { documentation: Documentation, protected: boolean }) {
    return (
        <DocumentationCol>
            <Card
                protected={props.protected}
                title={props.documentation.title}
                docs={props.documentation.documents}
                subtitle={props.documentation.subtitle}/>
        </DocumentationCol>
    )
}


function DocumentationPage() {
    let docs = {
        terms: {
            title: 'Terms',
            subtitle: 'Brief description of the category',
            documents: [
                {
                    name: ValueAnalyticsDocuments.privacyPolicy.name,
                    desc: ValueAnalyticsDocuments.privacyPolicy.desc,
                    url: ValueAnalyticsDocuments.privacyPolicy.url
                },
                {
                    name: ValueAnalyticsDocuments.termsAndConditions.name,
                    desc: ValueAnalyticsDocuments.termsAndConditions.desc,
                    url: ValueAnalyticsDocuments.termsAndConditions.url
                }
            ]
        },
        data: {
            title: 'Data Methodology',
            subtitle: 'Brief description of the category',
            documents: [
                {
                    name: 'Data Sourcing and Quality Assurance',
                    desc: '',
                    url: 'https://va-frontend-assets.s3.us-east-2.amazonaws.com/dev/documentation/data_methods/Data-Sourcing-and-Quality-Assurance.docx'
                },
                {
                    name: 'Definitions and Pro-Forma Adjustments',
                    desc: '',
                    url: 'https://va-frontend-assets.s3.us-east-2.amazonaws.com/dev/documentation/data_methods/Definitions-and-Pro-Forma-Adjustments.docx'
                }
            ]
        },
        gpc_method: {
            title: 'Guideline Public Company Method',
            subtitle: 'Brief description of the category',
            documents: [
                {
                    name: '1) Introduction',
                    desc: '',
                    url: 'https://va-frontend-assets.s3.us-east-2.amazonaws.com/dev/documentation/gpc_method/1GPCM-Introduction.docx'
                },
                {
                    name: '2) Qualitative Assessment',
                    desc: '',
                    url: 'https://va-frontend-assets.s3.us-east-2.amazonaws.com/dev/documentation/gpc_method/2GPCM-Qualitative-Assessment.docx'
                },
                {
                    name: '3) Quantitative Assessment',
                    desc: '',
                    url: 'https://va-frontend-assets.s3.us-east-2.amazonaws.com/dev/documentation/gpc_method/3GPCM-Quantitative-Assessment.docx'
                },
                {
                    name: '4) Selection of Multiples',
                    desc: '',
                    url: 'https://va-frontend-assets.s3.us-east-2.amazonaws.com/dev/documentation/gpc_method/4GPCM-Selection-of-Multiples.docx'
                },
                {
                    name: '5) Size Adjustment',
                    desc: '',
                    url: 'https://va-frontend-assets.s3.us-east-2.amazonaws.com/dev/documentation/gpc_method/5GPCM-Size-Adjustment.docx'
                },
                {
                    name: '6) Capitalization Adjustment',
                    desc: '',
                    url: 'https://va-frontend-assets.s3.us-east-2.amazonaws.com/dev/documentation/gpc_method/6GPCM-Capitalization-Adjustment.docx'
                },
                {
                    name: '1-6) Guideline Public Company Combined',
                    desc: '',
                    url: 'https://va-frontend-assets.s3.us-east-2.amazonaws.com/dev/documentation/gpc_method/Guideline-Public-Company-Method-(Combined).docx'
                }
            ]
        },
        tutorials: {
            title: 'Tutorials',
            subtitle: 'Brief description of the category',
            documents: [
                {
                    name: '1) Functionality Overview',
                    desc: '',
                    url: 'https://www.youtube.com/watch?v=PXGrwGQhXns',
                    new_tab: true,
                    video: true
                },
                {
                    name: '2) Excel Add-In',
                    desc: '',
                    url: 'https://www.youtube.com/watch?v=Nl2jL0O--JE&t=1s',
                    new_tab: true,
                    video: true
                }
            ]
        },
        excel_templates: {
            title: 'Excel Templates',
            subtitle: 'Brief description of the category',
            documents: [
                {
                    name: '1) Valuation Template',
                    desc: '',
                    url: 'https://va-frontend-assets.s3.us-east-2.amazonaws.com/dev/documentation/excel/VA-Excel-Add-In-Valuation-Template.xlsx'
                },
                {
                    name: '2) Financial Statement Template',
                    desc: '',
                    url: 'https://va-frontend-assets.s3.us-east-2.amazonaws.com/dev/documentation/excel/VA-Financial-Statement-Template.xlsx'
                },
                {
                    name: '3) Beta Template',
                    desc: '',
                    url: 'https://va-frontend-assets.s3.us-east-2.amazonaws.com/dev/documentation/excel/VA-Beta-Template.xlsx'
                },
                {
                    name: '4) Enterprise Value Calculation',
                    desc: '',
                    url: 'https://va-frontend-assets.s3.us-east-2.amazonaws.com/dev/documentation/excel/VA-EV-Calculation.xlsx'
                },
                {
                    name: '5) Excel Add-In Field List',
                    desc: '',
                    url: 'https://va-frontend-assets.s3.us-east-2.amazonaws.com/dev/documentation/excel/VA-AddIn-Field-List.pdf'
                },
                {
                    name: '6) Option-Pricing',
                    desc: '',
                    url: 'https://va-frontend-assets.s3.us-east-2.amazonaws.com/dev/documentation/excel/VA-Option-Pricing.xlsx'
                }
            ]
        }
    }
    return (
        <div className='informational-page documentation-page' role='main'>
            <Container>
                <Row>
                    <header className={'informational-header-in-container'}>
                        <h1 className={'informational-h1 doc-page-header'}>Documentation</h1>
                        <p className={'informational-p'}>
                            For further support, contact us at <a className="blue-link"
                                                                  href='#'>support@valueanalytics.org</a>
                        </p>
                    </header>
                </Row>
                <Row>
                    <DocumentationCard documentation={docs.gpc_method} protected={true}/>
                    <DocumentationCard documentation={docs.excel_templates} protected={false}/>
                    <DocumentationCard documentation={docs.tutorials} protected={false}/>
                    <DocumentationCard documentation={docs.data} protected={false}/>
                    <DocumentationCard documentation={docs.terms} protected={false}/>
                </Row>
            </Container>
        </div>
    )
}

export default DocumentationPage;
