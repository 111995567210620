import xlsx, {WorkBook} from 'xlsx';
import {
    cellAlphaMap,
    cellDecimalFormat,
    cellNumberFormat,
    cellPercentageFormat,
    dateFormat,
    getAlphaFromCellRef,
    getNumericFromCellRef,
    insertColumns,
    insertRows,
    moveDown,
    moveRight,
    setCellValue
} from './exportUtilities'
import {fhCompsRowMap, multiplesColumnMap, ranksSheetMap, valuationDateMap} from './exporterMappings'
import {RowDictionary} from '../comps/compsTableSlice'
import {ICompSetResponse} from "../api/requestsTypes";


const wbTemplateUrl = 'https://va-frontend-assets.s3.us-east-2.amazonaws.com/template_v6.xlsx';

const get_format = (type: string): string => {
    let format: string;

    if (type == 'percentage') {
        format = cellPercentageFormat
    } else if (type == 'decimal') {
        format = cellDecimalFormat
    } else {
        format = cellNumberFormat
    }

    return format
}

function fetchWorkbookTemplateBuffer(): Promise<ArrayBuffer> {
    return fetch(wbTemplateUrl)
        .then(function (res) {
            if (!res.ok) {
                throw new Error("Could not get the export template");
            }
            return res.arrayBuffer();
        });
}


function convertBufferToWorkbook(ab: ArrayBuffer): WorkBook{
    let data = new Uint8Array(ab);
    return xlsx.read(data, {
        type: "array",
        cellStyles: true,
        // This property is not in the type, but required for formulas to survive round-tripping
        // @ts-ignore
        xlfn: true
    });
}

function writeWorkbook(workBook: WorkBook): any {
    // Write file (the xlsx write file method does
    // not work with the development server that runs in the local env)
    return xlsx.write(workBook, {
        type: 'base64'
    });
}

export function populateWorkBook(
    workBook: WorkBook,
    exportData: any,
    companyCount: number,
    valuationDate: string,
    companyNetDebt: string,
    userInputDictionary: RowDictionary
): void {
    if(companyCount === 0) {
        return;
    }

    // Insert the fh comps data
    fillFhComps(workBook, companyCount, exportData.fh_comps);
    fixFormulasFHComps(workBook, companyCount);
    // Insert the multiples data
    let companies = exportData.descriptions.map((c: any) => c.company);
    fillMultiples(workBook, companies, exportData.multiples);
    fixFormulasMultiples(workBook, companyCount);
    // Insert ranks data
    fillRanks(workBook, companyCount, exportData.ranks);
    // Fix Conc formulas
    fixFormulasConc(workBook, companyCount);
    // Insert the descriptions data
    fillDescriptions(workBook, exportData.descriptions);
    // Insert valuation date
    fillValuationDate(workBook, valuationDate);
    // Populate comps tab
    insertUserData(workBook, companyNetDebt, userInputDictionary)
    // Clean out the extra columns that the library adds to lower file size
    cleanExtraColumns(workBook);
}

function exportWorkbook(wbData: any) {
    const linkSource = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${wbData}`;
    const downloadLink = document.createElement("a");
    const d = new Date();
    const fileName = `export-${d.toLocaleDateString()}.xlsx`;
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
}

export const exportFromCompSet = (
    companyCount: number,
    exportData: ICompSetResponse,
    valuationDate: string,
    companyNetDebt: string,
    userInputDictionary: RowDictionary) => {

    return fetchWorkbookTemplateBuffer()
        .then((buffer) => {
            const workBook = convertBufferToWorkbook(buffer);
            populateWorkBook(
                workBook,
                exportData,
                companyCount,
                valuationDate,
                companyNetDebt,
                userInputDictionary
            );
            const wbData = writeWorkbook(workBook);
            exportWorkbook(wbData);
        })
        .catch(error => {
            console.log(error);
        });
}

const insertUserData = (wb: any, companyNetDebt: string, userInputDictionary: RowDictionary) => {

    const conclusion = wb.Sheets['Conc']
    let results_column = "D"
    let starting_row = 9
    let row_indices = Object.keys(userInputDictionary)
    for (let i = starting_row; i < starting_row + row_indices.length; i++) {
        let cell = results_column + String(i)
        setCellValue(conclusion, cell, Number(userInputDictionary[i - starting_row].companyResults), cellNumberFormat)

    }
    setCellValue(conclusion, "J5", Number(companyNetDebt), cellNumberFormat);


}

const fillFhComps = (workbook: any, companyCount: number, fhComps: any) => {
    var sheet = workbook.Sheets["FHcomps"];
    var sourceCell = 'F6';

    insertColumns(sheet, companyCount - 1, getAlphaFromCellRef(sourceCell));

    for (let i = 0; i < companyCount; i++) {
        // Get cell for edit
        let currentCell = moveRight(sourceCell, i);
        // Set company title
        setCellValue(sheet, currentCell, fhComps.columns[i]);
        // Set the values
        let currentDataCellRef = currentCell;
        for (let v = 0; v < fhCompsRowMap.length; v++) {
            // Update the reference to one below where we are now
            currentDataCellRef = moveDown(currentDataCellRef, 1);
            const valueName = fhCompsRowMap[v];
            if (valueName == null) {
                continue;
            }
            // Find the value from the source data
            let dataFrame = fhComps.values.filter((item: any) => item.data.header == valueName);
            // Get value from that data in array
            if (dataFrame.length === 0) {
            } else {
                // Set the value in the spreadsheet at this reference.
                let data = dataFrame[0].data.values[i];
                let dataType = dataFrame[0].type;
                let format = get_format(dataType)
                let val = dataType == 'percentage' ? (isNaN(data) ? "N/A" : data / 100) : data;
                setCellValue(sheet, currentDataCellRef, val, format);
            }
        }
    }
}

const fixFormulasFHComps = (workbook: any, companyCount: number) => {
    var sheet = workbook.Sheets["FHcomps"];
    // Fix formulas on fhcomps page
    var cells = Object.keys(sheet).filter(ref => !ref.startsWith('!'))
    for (let i = 0; i < cells.length; i++) {
        const cell = sheet[cells[i]];
        // If cell contains formula
        if (cell.f != undefined) {
            // Find range and update it
            var formula = cell.f.match(/[A-Z]{1,3}[0-9]{1,7}:[A-Z]{1,3}[0-9]{1,7}/);
            if (formula != null && formula.length > 0) {
                let range = formula[0];
                let front = range.split(':')[0];
                let back = range.split(':')[1];
                // For fhcomps, adjust back reference by column
                back = moveRight(back, companyCount - 1);
                var newRange = `${front}:${back}`;

                cell.f = cell.f.replace(range, newRange);
            }
        }
    }
}

const fillMultiples = (workbook: any, companyList: Array<string>, multiples: any) => {
    var sheet = workbook.Sheets["Multiples"];
    var sourceCell = 'B10';

    insertRows(sheet, companyList.length - 1, Number(getNumericFromCellRef(sourceCell)));

    let currentCell = sourceCell.toString();
    for (let i = 0; i < companyList.length; i++) {
        // Use current cell for edit
        currentCell = moveDown(sourceCell, i);
        // Set company title
        setCellValue(sheet, currentCell, companyList[i]);
        // Set the values
        let currentDataCellRef = currentCell;
        // Decopule cell counter from iteration because data does not contain empty values for LTM
        let cellCounter = 0;
        for (let v = 0; v < multiplesColumnMap.length; v++) {
            // Update the reference to one right of where we are now
            currentDataCellRef = moveRight(currentDataCellRef, 1);
            const valueName = multiplesColumnMap[v];
            if (valueName == null) {
                continue;
            }
            // Find the value from the source data
            // i = company, v = index in array
            let dataCompany = multiples.values[i];
            let dataFrame = dataCompany[Object.keys(dataCompany)[0]][cellCounter];
            // Increment cell counter to keep pace with data/heading mismatch
            cellCounter++;
            // Get value from that data in array
            if (dataFrame == undefined) {
            } else {
                // Set the value in the spreadsheet at this reference.
                setCellValue(sheet, currentDataCellRef, dataFrame, cellDecimalFormat);
            }
        }
    }
}

const fixFormulasMultiples = (workbook: any, companyCount: number) => {
    var sheet = workbook.Sheets["Multiples"];
    // Fix formulas on fhcomps page
    var cells = Object.keys(sheet).filter(ref => !ref.startsWith('!'))
    for (let i = 0; i < cells.length; i++) {
        const cell = sheet[cells[i]];
        // If cell contains formula
        if (cell.f != undefined) {
            // Complex formula or simple formula?
            // If we have more than 2 references we have a complex formula
            var references = cell.f.match(/[A-Z]{1,3}[0-9]{1,7}/g);
            if (references != null) {
                // Complex formula
                if (references.length > 2) {
                    // Do we have a range?
                    var rangeFormula = cell.f.match(/[A-Z]{1,3}[0-9]{1,7}:[A-Z]{1,3}[0-9]{1,7}/);
                    if (rangeFormula != null) {
                        // Update range
                        let range = rangeFormula[0];
                        let front = range.split(':')[0];
                        let back = range.split(':')[1];
                        // For multiples, adjust back reference by row
                        back = moveDown(back, companyCount - 1);
                        var newRange = `${front}:${back}`;
                        // Second cell reference
                        let reference = references[references.length - 1];
                        // For multiples, adjust reference by row
                        reference = moveDown(reference, companyCount - 1);
                        // Replace the second reference first to prevent overwriting
                        cell.f = cell.f.replace(references[references.length - 1], reference);
                        // Now replace the range
                        cell.f = cell.f.replace(range, newRange);
                    }
                } else {
                    // Find range and update it
                    var rangeFormula = cell.f.match(/[A-Z]{1,3}[0-9]{1,7}:[A-Z]{1,3}[0-9]{1,7}/);
                    if (rangeFormula != null && rangeFormula.length > 0) {
                        let range = rangeFormula[0];
                        let front = range.split(':')[0];
                        let back = range.split(':')[1];
                        // For multiples, adjust back reference by row
                        back = moveDown(back, companyCount - 1);
                        var newRange = `${front}:${back}`;

                        cell.f = cell.f.replace(range, newRange);
                    } else {
                        // Non range formula
                        var formula = cell.f.match(/[A-Z]{1,3}[0-9]{1,7}/g);
                        if (formula != null && formula.length > 0) {
                            let reference = formula[0];
                            // For multiples, adjust reference by row
                            reference = moveDown(reference, companyCount - 1);
                            cell.f = cell.f.replace(formula[0], reference);
                        }
                    }
                }
            }
        }
    }
}

const fillRanks = (workbook: any, companyCount: number, ranks: any) => {

    const ranksSheet = workbook.Sheets['Ranks'];
    const ranksSourceCell = 'B7';
    let rowInsertPosition = 7;
    // 5 is the number of rows between row insert positions in the template
    let rowInsertPostionIncrement = companyCount + 3;

    let ranksCurrentCell = ranksSourceCell.toString();
    let columnRef = ranksSourceCell.toString();
    let rowRef = columnRef;
    // Remove merges formatting
    for (let i = 0; i < ranksSheetMap.length; i++) {
        // Run first level insert on the whole row
        insertRows(ranksSheet, companyCount, rowInsertPosition);
        // Insert data
        for (let j = 0; j < ranksSheetMap[i].length; j++) {
            // Run insert on the actual data values in each box
            const ranksData = ranks[ranksSheetMap[i][j]];
            for (let k = 0; k < ranksData.values.length; k++) {
                ranksCurrentCell = moveDown(rowRef, k);
                setCellValue(ranksSheet, ranksCurrentCell, ranksData.values[k][0]);
                ranksCurrentCell = moveRight(ranksCurrentCell, 1);
                let format = get_format(ranksData.type)
                let dataValue = ranksData.values[k][1];
                let val = ranksData.type == 'percentage' ? (isNaN(dataValue) ? "N/A" : dataValue / 100) : dataValue;
                setCellValue(ranksSheet, ranksCurrentCell, val, format);
            }
            // Move to next box
            columnRef = moveRight(columnRef, 3);
            rowRef = columnRef;
        }
        // Move to next row
        rowInsertPosition += rowInsertPostionIncrement;
        columnRef = 'B' + rowInsertPosition.toString();
        rowRef = columnRef;
        ranksCurrentCell = columnRef;
    }
    ;
}


const fixFormulasConc = (workbook: any, companyCount: number) => {
    var sheet = workbook.Sheets["Conc"];
    // Fix formulas on fhcomps page
    var cells = Object.keys(sheet).filter(ref => !ref.startsWith('!'))
    for (let i = 0; i < cells.length; i++) {
        const cell = sheet[cells[i]];
        // If cell contains formula
        if (cell.f != undefined) {
            // Find formula and update it
            // Only formulas with another sheet reference
            var formula = cell.f.match(/[A-Z]{1,3}[0-9]{1,7}/);
            if (formula != null && formula.length > 0 && cell.f.indexOf('!') != -1) {
                let reference = formula[0];
                // For fhcomps, adjust back reference by column
                reference = moveDown(reference, companyCount - 1);
                cell.f = cell.f.replace(formula[0], reference);
            }
        }
    }
}

const fillDescriptions = (workbook: any, descriptions: any) => {
    var sheet = workbook.Sheets["Desc"];
    var sourceCell = 'B5';

    insertRows(sheet, descriptions.length * 2, 5);

    for (let i = 0; i < descriptions.length; i++) {
        const company = descriptions[i];
        // Company Name is source cell
        setCellValue(sheet, sourceCell, company.company)

        // Exchange ticker is 1 right from source
        var tickerCell = moveRight(sourceCell, 1);
        setCellValue(sheet, tickerCell, `${company.exchange}:${company.ticker}`)

        // Description is two right from source
        var descCell = moveRight(sourceCell, 2);
        setCellValue(sheet, descCell, company.description)

        // Set new sourceCell
        sourceCell = moveDown(sourceCell, 2);
    }
}

const fillValuationDate = (workbook: any, valuationDate: string) => {
    var keys = ["FHcomps", "Multiples", "Ranks", "Conc"];

    for (let i = 0; i < keys.length; i++) {
        const key: string = keys[i];
        let sheet = workbook.Sheets[key];
        let d = new Date(valuationDate)
        let dformat = [d.getMonth() + 1,
            d.getDate(),
            d.getFullYear()].join('/')
        setCellValue(sheet, valuationDateMap[key], dformat, dateFormat);
    }
}

const cleanExtraColumns = (workbook: any) => {
    for (let s = 0; s < Object.keys(workbook.Sheets).length; s++) {
        const sheet = workbook.Sheets[Object.keys(workbook.Sheets)[s]];
        if (sheet['!cols'] != undefined) {
            // Get the last cell in the column list that has data
            let lastCol = getAlphaFromCellRef(sheet['!ref'].split(':')[1]);
            let lastIndex = cellAlphaMap.indexOf(lastCol);
            // remove all items from !cols that are after that. xlsx defaults to 16k columns.
            sheet['!cols'] = sheet['!cols'].slice(0, lastIndex - 1);
        }
    }
}
