import React from "react"

interface figure_props {
    figure_idx: number;
    image: string;
    caption: string;
    height?: string;
    width?: string;
    floatRight?: boolean;
    floatLeft?: boolean
    paddingLeft?:string;
    margin?:string;
}


interface img_cont_style {
    width: string;
    height?: string;
    float?: "-moz-initial" | "inherit" | "initial" | "revert" | "unset" | "inline-end" | "inline-start" | "left" | "none" | "right" | undefined;
    paddingLeft?: string;
    margin?: string;
}

export function Figure(props:figure_props) {

    let fig_cont_style: img_cont_style = {
        width: '100%'
    }
    if (props.paddingLeft) {
        fig_cont_style['paddingLeft'] = props.paddingLeft
    }
    if (props.height) {
        fig_cont_style['height'] = props.height
    }
    if (props.width) {
        // fig_img_style['width'] = props.width
        fig_cont_style['width'] = props.width
    }
    if (props.floatRight) {
        fig_cont_style['float'] = "right"
    }
    if (props.margin) {
        fig_cont_style['margin'] = props.margin
    }
    return (
        <div style={fig_cont_style}>
            <img className='figure-image'
                 src={props.image}/>
            <p className='figure-caption'>
                <span className={'figure-number'}>
                    Figure {String(props.figure_idx)}:
                </span> {props.caption}</p>
        </div>
    )
}