import {useDispatch, useSelector} from "react-redux";
import {addCompsToGroup, selectCompSet} from "../../comps/compsGroupSlice";
import React, {useEffect} from "react";
import {TickerPicker} from "../TickerPicker/TickerPicker";
import {fetchCompSet} from "../../comps/compsTableSlice";
import {TickerItem} from "../../types";
import {fetchTickers, selectIsFetchingTickers, selectTickers} from "../../comps/compsTickerPickerSlice";

export function CompsTickerPicker() {
    const compset = useSelector(selectCompSet);
    const dispatch = useDispatch();
    const tickers = useSelector(selectTickers);
    const isLoading = useSelector(selectIsFetchingTickers);

    useEffect(() => {
        dispatch(fetchTickers({bypassCache: false}));

        return handleOnTickerPickerClosed;
    }, []);
    function handleOnTickerPicked (ticker: TickerItem) {
        dispatch(addCompsToGroup(ticker));
    }

    function handleOnTickerPickerClosed() {
        dispatch(fetchCompSet());
    }


    return (
        <TickerPicker
            onTickerPicked={handleOnTickerPicked}
            excludeTickers={compset.compset}
            isLoading={isLoading}
            tickers={tickers}
        />
    );
}
