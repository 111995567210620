import React from 'react';
import './AboutUsPage.scss';
import '../../App.scss'
import TeamMember from './TeamMember';
import avatar from '../../img/ADbiopic.svg';
import avatar1 from '../../img/DZbiopic.svg';
import avatar2 from '../../img/JCbiopic.svg';
import avatar3 from '../../img/HPBioPic.svg';
import {Container} from 'reactstrap';

function AboutUsPage() {
    return (
        <div className='informational-page' role='main'>
            <Container>

                <header className='informational-header-in-container'>
                    <h1 className='informational-h1'>
                        About Value Analytics
                    </h1>
                    <p className='informational-p card-style'>
                        Value Analytics is a web-based data provider servicing the business valuation community.
                        We provide pre-processed public company financial statement and equity data, as well as
                        customizable data
                        analysis tools, all under a subscription-based model. Value Analytics' low cost and highly
                        flexible platform
                        serves small business valuation and accounting firms. Value Analytics is based in Pittsburgh,
                        Pennsylvania and was founded in 2021.
                    </p>
                </header>
                <main className='informational-main-about-us'>
                    <h1 className='informational-h1'>Our Leadership Team</h1>
                    <TeamMember
                        avatar={avatar1}
                        teamMember="Derek Zweig - CEO"
                        bio="Derek is the Chief Executive Officer and co-founder of Value Analytics.
          Prior to founding Value Analytics in 2021, he worked for Huntington Capital Markets in market risk and Citizens Capital Markets in business valuation.
          Derek is an expert in asset valuation and has performed or contributed to over 1,000 valuation engagements involving businesses, business interests,
          and intangible assets of privately held and publicly traded companies.  Valuation objectives included estate and gift taxation,
          business succession planning, employee stock ownership plans, fairness opinions, purchase price allocations, mergers and acquisitions, buy-sell agreements,
          stock option grants, shareholder disputes, carried interest, and other tax, litigation, and corporate-related matters.
          He is an active member of the CFA Society of Pittsburgh and the Global Association of Risk Professionals.
          Derek earned an M.S. in Applied Economics with a specialization in Quantitative Methods from Krieger School of Arts and Sciences at Johns Hopkins
          University and a B.S. in Business Administration with a specialization in Finance from the Fisher College of Business at Ohio State University."
                    />
                    <TeamMember
                        avatar={avatar2}
                        teamMember="Jonathan Carpenter - Software Engineering Lead"
                        bio="Jonathan is our Software Engineering lead building great services at Value Analytics.
                He has more than seven years of experience working as a web developer in the domain of student
                management systems and scholarly publishing. Jonathan has also worked in the area of robotic process automation as a contractor for the U.S. Air Force.
                Jonathan holds a B.S in Computer Science from Wright State University."
                    />
                    <TeamMember
                        avatar={avatar3}
                        teamMember="Hannah Park - UX Design Lead"
                        bio="Hannah leads our user experience and user interface design team.  She has extensive experience designing digital
          healthcare products in the biotechnology industry.  Hannah excels at transforming product concepts into intuitive and usable interfaces.
          Hannah completed the User Interface and User Experience program at University of California, Irvine and received
          her bachelor's degree from University of California, Santa Barbara. "
                    />
                </main>

            </Container>
        </div>
    )
}

export default AboutUsPage;
