import React from "react";
import {FilterPickerIndustry} from "./FilterPickerIndustry";
import {industrySearchFilter} from "../../filterPicker/filterStatePickerLogic";
import {FilterPickerIndustryGroupProps} from "../../filterPicker/filterStatePickerTypes";
import {FilterPickerParent} from "../FilterPicker/FilterPickerParent";
import {getCheckboxState} from "../../filterPicker/filterPickerUtility";

export function FilterPickerIndustryGroup(props: FilterPickerIndustryGroupProps) {
    const checkboxState = getCheckboxState(props.industryGroup)

    function handleOnIndustryGroupPicked() {
        props.onIndustryGroupPicked(props.industryGroup);
    }

    function getFilterPickerIndustries() {
        return props.industryGroup.industries
            .filter(industrySearchFilter(props))
            .map((industry, index) =>
                <FilterPickerIndustry
                    key={industry.sic_code}
                    industry={industry}
                    onIndustryPicked={props.onIndustryPicked}
                />
            );
    }

    return (
        <FilterPickerParent
            className={"picker-level-2"}
            onCheckBoxClicked={handleOnIndustryGroupPicked}
            searchText={props.searchText}
            checkboxState={checkboxState}
            label={props.industryGroup.industry_group_name}
            children={getFilterPickerIndustries()}
        />
    );
}
