import {Accordion} from "react-bootstrap";
import {Container} from 'reactstrap';
import './ExcelPluginDocumentationPage.scss';
import {Figure} from "./Figure";
import {Bc} from "./boldColor";
import "../Footer/Footer.scss";
import '../../App.scss'
import React, {Fragment, useEffect, useRef, useState} from "react";

import figure_1 from '../../img/instructions_figures/Figure_1_login.png'
import figure_2 from '../../img/instructions_figures/Figure_2_login.png'
import figure_1_fn from '../../img/instructions_figures/Figure_1_fn.png'
import figure_2_fn from '../../img/instructions_figures/Figure_2_fn.png'
import figure_3_fn from '../../img/instructions_figures/Figure_3_fn.png'
import figure_4_fn from '../../img/instructions_figures/Figure_4_fn.png'
import figure_1_desc from '../../img/instructions_figures/Figure_1_desc.png'
import figure_2_desc from '../../img/instructions_figures/Figure_2_desc.png'
import figure_1_rf from '../../img/instructions_figures/Figure_1_rf.png'
import figure_2_rf from '../../img/instructions_figures/Figure_2_rf.png'
import figure_1_ts from '../../img/instructions_figures/Figure_1_ts.png'
import figure_1_ins from '../../img/instructions_figures/Figure_1_ins.png'
import figure_2_ins from '../../img/instructions_figures/Figure_2_ins.png'
import figure_3_ins from '../../img/instructions_figures/Figure_3_ins.png'
import figure_4_ins from '../../img/instructions_figures/Figure_4_ins.png'
import figure_2_reins from '../../img/instructions_figures/Figure_2_reinstall.png'


import login_icon from '../../img/excel_icons/login80x80.png'
import retrieve_icon from '../../img/excel_icons/build80x80.png'
import refresh_icon from '../../img/excel_icons/refresh80x80.png'
import desc_icon from '../../img/excel_icons/description80x80.png'
import ts_icon from '../../img/excel_icons/ts_icon.png'
import install_icon from '../../img/excel_icons/install_icon.png'

export function ExcelPluginDocumentationPage() {

    let keys = {
        install: "install",
        login: "login",
        builder: "builder",
        fields: "fields",
        refresh: "refresh",
        troubleshoot: "troubleshoot",
        reInstall: "reInstall"
    }

    const get_default = function (keys: any){

        const anchor = window.location.hash.split('#')[1];
        let default_open = undefined
        if (Object.values(keys).indexOf(anchor) > -1) {
            default_open = anchor
        }
        return default_open

    }

    useEffect(()=>{

        let anchor = get_default(keys)
        if (anchor) {
            let current = document.getElementById(anchor)
            if (current){
                current.scrollIntoView()
            }
        }

    })



    return (
        <div className='informational-page'>
            <Container>


                <header className='informational-header-in-container'>
                    <h1 className='informational-h1'>Value Analytics Excel Add-In</h1>


                    <p className='informational-p card-style'>
                        The Value Analytics Excel Add-In provides direct access to financial, equity, and
                        company profile data for all U.S. exchange-traded companies available in
                        the Value Analytics database. The purpose of the Add-In is to support custom
                        financial data analysis and modeling via direct access to Value Analytics' data in
                        the context of a Microsoft Excel spreadsheet.
                        <span className='inline-bold'> The Excel Add-In is available to all subscribing Value Analytics users!
                       </span>

                    </p>
                </header>

                <div>
                    <h1 className='informational-h1'>Basic Tasks</h1>
                    <Accordion className='va-accordion card-style'
                               defaultActiveKey={get_default(keys)}>
                        <Accordion.Item eventKey={keys.install} id={keys.install}>
                            <Accordion.Header><img className='docs-icon' src={install_icon}/> Installing the
                                Add-In</Accordion.Header>
                            <Accordion.Body>
                                <p className={'informational-p accordion-p'}>
                                    The Value Analytics Excel Add-In is supported for the following versions of Excel:

                                    <ul>
                                        <li>Excel on Mac with Microsoft 365 Subscription</li>
                                        <li>Excel on Windows with Microsoft 365 Subscription</li>
                                        <li>Excel 2019 or later on Mac</li>
                                        <li>Excel on the web</li>
                                    </ul>

                                    Please note that for Windows users, you must have a Microsoft 365 Subscription to
                                    install the Add-In.
                                    Once you have confirmed that your version of Excel is one of the supported versions
                                    listed above,
                                    you can install the Add-In by first navigating to the Insert tab in the Excel
                                    ribbon, and then
                                    clicking My Add-ins (<span className={'figure-number'}>Figure 1</span>).
                                </p>
                                <Figure figure_idx={1}
                                        caption={'Excel Ribbon with Insert tab and My Add-ins highlighted.'}

                                        image={figure_1_ins}
                                        width={'95%'}
                                        margin={'auto'}
                                ></Figure>
                                <p className={'informational-p accordion-p'}>
                                    After the Office Add-Ins dialogue box opens, select Store and enter "Value
                                    Analytics" in
                                    the search field. Find the Value Analytics Add-In within the search results and
                                    select
                                    Add (<span className={'figure-number'}>Figure 2</span>).
                                </p>
                                <Figure figure_idx={2}
                                        caption={'Add-In window with Store tab with search field highlighted.'}

                                        image={figure_2_ins}
                                        width={'95%'}
                                        margin={'auto'}
                                ></Figure>
                                <p className={'informational-p accordion-p'}>
                                    Next, the Office Add-In store will inform you that you agree to our terms and
                                    conditions
                                    by continuing with the installation. Press Continue to finish installing the Add-In.
                                    If the installation was successful, the Value Analytics tab will be added to the
                                    Excel ribbon
                                    and you will see the following alert (<span
                                    className={'figure-number'}>Figure 3</span>).
                                </p>
                                <Figure figure_idx={3}
                                        caption={'Excel Ribbon with Value Analytics tab highlighted after successful installation.'}

                                        image={figure_3_ins}
                                        width={'95%'}
                                        margin={'auto'}
                                ></Figure>

                                <p className={'informational-p accordion-p'}>
                                    You can test if the installation was successful by navigating to the Value Analytics tab
                                    and clicking Login (<span
                                    className={'figure-number'}>Figure 4</span>).
                                </p>

                                <Figure figure_idx={4}
                                        caption={'Value Analytics tab and menu options. ' +
                                            'The Value Analytics tab and Login buttons are highlighted.'}

                                        image={figure_1}
                                        width={'95%'}
                                        margin={'auto'}
                                ></Figure>

                                <p className={'informational-p accordion-p'}>
                                    After clicking the Login button, you should see the Value Analytics Login page load in
                                    the Excel task pane. <b>However, for some combinations of Windows and Excel builds, the task pane
                                    may appear blank initially.</b> If the task pane appears blank, you can resolve the issue
                                    by re-sizing the task pane (<span className={'figure-number'}>Figure 5</span>).

                                </p>

                                <Figure figure_idx={5}
                                        caption={'Blank task pane (left) compared to correctly rendered task pane (right). ' +
                                        'Resize the task pane (shown in left) to correctly render the task pane.'}

                                        image={figure_4_ins}
                                        width={'95%'}
                                        margin={'auto'}
                                ></Figure>

                                <p className={'informational-p accordion-p'}>
                                    If the task pane appears blank after resizing the window, please contact <a className="blue-link" href="#">support@valueanalytics.org</a>
                                </p>

                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey={keys.login} id={keys.login}>
                            <Accordion.Header><img className='docs-icon' src={login_icon}/>Logging In</Accordion.Header>
                            <Accordion.Body>

                                <p className={'informational-p accordion-p'}>
                                    Once the Add-In has been successfully installed, you can view the Add-In menu
                                    by clicking the Value Analytics tab located on the Excel Ribbon.
                                    <span className={'figure-number'}> Figure 1</span> shows
                                    the location of the Value Analytics tab and the menu options.
                                </p>

                                <Figure figure_idx={1}
                                        caption={'Value Analytics tab and menu options. ' +
                                            'The Value Analytics tab and Login buttons are highlighted.'}

                                        image={figure_1}
                                        width={'95%'}
                                        margin={'auto'}
                                ></Figure>

                                <p className={'informational-p accordion-p'}>
                                    After clicking the Value Analytics tab, click the Login button to access the login
                                    form.
                                    Please provide the same email address and password that you used to create an
                                    account
                                    on the web application to log in to the Excel Add-in. If you have not created a
                                    Value Analytics account, please create
                                    one <a className={'blue-link'} href={'/create-account'} target={'_blank'}>here</a> to use
                                    the Excel Add-In.
                                    <span className={'figure-number'}> Figure 2</span> shows the login form that appears
                                    after pressing Login.
                                </p>

                                <Figure figure_idx={2}
                                        caption={'Value Analytics login form. ' +
                                            'Enter your credentials to login.'}

                                        image={figure_2}
                                        width={'35%'}
                                        margin={'auto'}

                                ></Figure>


                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey={keys.builder} id={keys.builder}>
                            <Accordion.Header><img className='docs-icon' src={retrieve_icon}/>Retrieving
                                Data</Accordion.Header>
                            <Accordion.Body>


                                <p className={'informational-p accordion-p'}>
                                    After logging in, you can start retrieving data from the Value Analytics server.
                                    Clicking the Formula Builder button will open a form that creates the functions
                                    for retrieving general, financial and equity data from Value Analytics.
                                    <span className={'figure-number'}> Figure 1</span> highlights the Formula Builder
                                    button
                                    on the Value Analytics menu.
                                </p>

                                <Figure figure_idx={1}
                                        caption={'Value Analytics menu with Formula Builder button highlighted.'}

                                        image={figure_1_fn}
                                        width={'95%'}
                                        margin={'auto'}
                                ></Figure>

                                <p className={'informational-p accordion-p'}>
                                    Within the Formula Builder interface, data are separated by category.
                                    There are
                                    a total of seven data categories: <i>General Information</i>, <i>Balance Sheet</i>, <i>Cashflow Statement</i>, <i>Income
                                    Statement</i>, <i>Performance Statistics</i>, <i>Equity</i>, and <i>Pricing Multiples</i> (<span className={'figure-number'}>Figure 2</span>).
                                    Select a category by clicking one of the available tabs along the left side of the Formula Builder. Selecting different categories will
                                    change the field options available in the dropdown menu on the form.
                                </p>
                                <h2>Retrieving Financial Data</h2>
                                <p className={'informational-p accordion-p'}>
                                    Financial data are retrieved using the <i>Balance Sheet</i>, <i>Cashflow Statement</i>, <i>Income
                                    Statement</i>, <i>Performance Statistics</i>, <i>Equity</i>, and <i>Pricing Multiples</i> categories.
                                    Once one of the financial categories are selected, complete the form to retrieve data.
                                    The <Bc color={"#00B050"} text={'Output Cell'}/> input specifies the cell in
                                    the Excel sheet that will be populated with financial data.  Note that the <Bc
                                    color={"#00B050"} text={'Output Cell'}/> defaults to the cell that was selected
                                    prior to opening the Formula Builder Page.
                                    To specify a different <Bc color={"#00B050"} text={'Output Cell'}/>:
                                    <div className={'list-container'}>
                                        <ol className={'step-by-step-list'}>
                                            <li>Click within the <Bc color={"#00B050"} text={'Output Cell'}/> input field</li>
                                            <li>Click on the cell in the Excel sheet that you want to populate with financial data</li>
                                        </ol>
                                    </div>
                                    Once you have clicked the desired cell,
                                    pressing "enter" or "tab" will move the <Bc color={"#00B050"} text={'Output Cell'}/> one
                                    row down or one column to the right, respectively.
                                    You can also specify the <Bc color={"#00B050"} text={'Output Cell'}/> by typing the cell address
                                    within the <Bc color={"#00B050"} text={'Output Cell'}/> field.
                                    To change the <Bc color={"#00B050"} text={'Output Cell'}/> by typing:

                                    <div className={'list-container'}>
                                        <ol className={'step-by-step-list'}>
                                            <li>Click within the <Bc color={"#00B050"} text={'Output Cell'}/> input field</li>
                                            <li>Type the cell address in the form</li>
                                            <li>Press "enter"</li>
                                        </ol>
                                    </div>

                                    Pressing "escape" while the cursor is within the input form
                                    will deactivate the cell selection process for the <Bc
                                    color={"#00B050"} text={'Output Cell'}/> input.
                                    <span className={'figure-number'}> Figure 2</span> shows the <Bc color={"#00B050"}
                                                                                                     text={'Output Cell'}/> set
                                    to G3.
                                </p>

                                <Figure figure_idx={2}
                                        caption={'Formula Builder form with Output Cell set to G3.'}

                                        image={figure_2_fn}
                                        width={'54%'}
                                        margin={'auto'}
                                ></Figure>

                                <p className={'informational-p accordion-p'}>
                                    After selecting the Output Cell, select the desired field from the Field dropdown menu.
                                    The fields available within the dropdown menu represent the available fields for the selected category.
                                    <span className={'figure-number'}> Figure 3</span> shows the first several fields
                                    available for <i>Balance Sheet</i> category.
                                     You can search for specific fields within the dropdown menu search bar. Click the
                                    desired field to select it for retrieval. Changing the selected category will update the
                                    available fields in the dropdown menu.
                                </p>


                                <Figure figure_idx={3}
                                        caption={'Formula Builder form with Field drop down menu expanded.'}

                                        image={figure_3_fn}
                                        width={'54%'}
                                        margin={'auto'}
                                ></Figure>



                                <p className={'informational-p accordion-p'}>
                                    Once the desired field is selected, you must provide two more required parameters
                                    for the <i>Balance Sheet</i>, <i>Cashflow Statement</i>, <i>Income Statement</i>, <i>Performance Statistics</i>, <i>Equity</i>, and <i>Pricing Multiples</i> categories:
                                    <div className={'list-container'}>
                                        <ol className={'step-by-step-list'}>
                                            <li><Bc color={"#0070C0"} text={'Ticker'}/> (required): ticker associated with
                                                public company of interest

                                            </li>
                                            <li><Bc color={"#C00000"} text={'Date'}/> (required): reference date for retrieving
                                            financial data. Date should be set to the valuation date</li>

                                        </ol>
                                    </div>

                                    You can also provide two optional parameters:
                                    <div className={'list-container'}>
                                        <ol className={'step-by-step-list'}>
                                            <li><Bc color={"#000000"} text={'Period'}/> (optional): The financial period
                                                of annualized data. Options include Fiscal Year (FY) or Trailing Twelve Month (TTM),
                                                and by default Period is set to "FY".
                                                Period is only applicable for annualized data, i.e. Period is ignored for
                                                Equity data

                                            </li>
                                            <li><Bc color={"#7030A0"} text={'Fiscal Year Offset'}/> (optional):
                                                Offsets the fiscal year relative to provided valuation date.
                                                For example, enter "0" for last fiscal year (LFY) data relative to Date,
                                                "1" for data one fiscal year prior to LFY, etc. Fiscal Year Offset defaults to "0",
                                                and is ignored when Period is set to "TTM"
                                            </li>

                                        </ol>
                                    </div>

                                    Notice that by default, <Bc color={"#000000"} text={'Period'}/> is set to "FY",
                                    and <Bc color={"#7030A0"} text={'Fiscal Year Offset'}/> is set to "0", meaning the Formula Builder
                                    will retrieve last fiscal year (LFY) data relative to <Bc color={"#C00000"} text={'Date'}/> if
                                    you do not select values for <Bc color={"#000000"} text={'Period'}/> and <Bc color={"#7030A0"} text={'Fiscal Year Offset'}/>.

                                </p>
                                <p className={'informational-p accordion-p'}>
                                    To specify <Bc color={"#0070C0"} text={'Ticker'}/>, <Bc color={"#C00000"}
                                                                                            text={'Date'}/>, and <Bc
                                    color={"#7030A0"} text={'Fiscal Year Offset'}/>:

                                    <div className={'list-container'}>
                                        <ol className={'step-by-step-list'}>
                                            <li>Click within the input field of the respective parameter</li>
                                            <li>Click on the cell in the Excel sheet that stores the parameter's value</li>
                                        </ol>
                                    </div>

                                    The Formula Builder will
                                    prompt you if a parameter's value is incorrectly formatted. You can also specify <Bc color={"#0070C0"} text={'Ticker'}/>, <Bc color={"#C00000"}
                                                                                            text={'Date'}/>, and <Bc
                                    color={"#7030A0"} text={'Fiscal Year Offset'}/> by typing the cell address
                                    within the Formula Builder.
                                    To change these parameters by typing:

                                    <div className={'list-container'}>
                                        <ol className={'step-by-step-list'}>
                                            <li>Click within the respective input fields</li>
                                            <li>Type the cell address in the form that contains the parameter's value</li>
                                            <li>Press "enter"</li>
                                        </ol>
                                    </div>
                                    Pressing "esc" on your keyboard will deactivate the cell selection process for the
                                    selected input.
                                    Please note that the input fields on the Formula Builder accept cell address
                                    only, not the literal values of the parameter.
                                </p>
                                <p className={'informational-p accordion-p'}>
                                    <span className={'figure-number'}> Figure 4</span> shows an example of a properly
                                    populated
                                    Formula Builder form. In this example, the user has a valuation Date of April 19th,
                                    2022 and
                                    is interested in Apple Inc.'s Total Revenue for the Last Fiscal Year (LFY) prior to
                                    the
                                    valuation date.
                                    Thus, <Bc color={"#0070C0"} text={'Ticker'}/>=AAPL, <Bc color={"#C00000"}
                                                                                            text={'Date'}/>=04/19/22, <Bc
                                    color={"#7030A0"} text={'Fiscal Year Offset'}/>=0 by default, <Bc color={"#000000"} text={'Period'}/>=FY by
                                    default, and Total Revenue is
                                    selected from the dropdown menu. These inputs will retrieve the Total Revenue for Apple Inc.'s fiscal
                                    year ended September 25, 2021. If the <Bc color={"#7030A0"} text={'Fiscal Year Offset'}/>=1,
                                    the function will retrieve Apple Inc.'s Total Revenue for the fiscal year ended
                                    September 25,
                                    2020. The <Bc color={"#7030A0"} text={'Fiscal Year Offset'}/> allows you to retrieve
                                    data for sequential fiscal years prior to an arbitrary valuation date without
                                    knowing
                                    when the specified firm's fiscal year ended.
                                </p>
                                <Figure figure_idx={4}
                                        caption={'Properly populated Formula Builder example. The ' +
                                            'VA.GETVALUE formula is highlighted.'}

                                        image={figure_4_fn}
                                        width={'54%'}
                                        margin={'auto'}
                                ></Figure>
                                <p className={'informational-p accordion-p'}>
                                    Pressing the Get Data button on the Formula Builder form will populate the <Bc
                                    color={"#00B050"} text={'Output Cell'}/> with
                                    data specified by the input values.
                                    The Formula Builder
                                    also creates the VA.GETVALUE formula within the formula bar of
                                    the <Bc color={"#00B050"} text={'Output Cell'}/> (<span className={'figure-number'}>Figure 4</span>).
                                    VA.GETVALUE has the following syntax:
                                    <br/>
                                    <br/>
                                    <span style={{display: 'block', textAlign: 'center'}}>
                                VA.GETVALUE(Field,  <Bc color={"#0070C0"} text={'Ticker'}/>, <Bc color={"#C00000"}
                                                                                                 text={'Date'}/>, <Bc
                                        color={"#000000"} text={'Period'}/>, <Bc
                                        color={"#7030A0"} text={'Fiscal Year Offset'}/>)
                            </span>
                                    <br/>

                                    Once you become familiar with the VA.GETVALUE syntax, you can call the function
                                    directly without using
                                    the Formula Builder. VA.GETVALUE has the same functionality as standard Excel functions,
                                    meaning you
                                    can drag the formula over multiple cells to vary the function inputs.

                                </p>
                                <h2>Retrieving General Information</h2>
                                <p className={'informational-p accordion-p'}>
                                    The  <i>General Information</i> tab is unique relative to the financial data categories because this category
                                    retrieves data that provides a general description of a target company rather than specific data
                                    from a financial statement. For example, the <i>General Information</i> category includes data like
                                    a company's industry, business description, SIC code, exchange, etc.
                                    The <i>General Information</i> tab builds the VA.GETINFO formula:

                                    <br/>
                                    <br/>
                                    <span style={{display: 'block', textAlign: 'center'}}>
                                    VA.GETINFO(Field,  <Bc color={"#0070C0"} text={'Ticker'}/>)
                                    </span>
                                    <br/>

                                    The VA.GETINFO formula only requires two input parameters: Field and <Bc color={"#0070C0"} text={'Ticker'}/>.
                                    Use the <i>General Information</i> tab to build the VA.GETINFO formula in the same manner described in the previous section.
                                    Once you become familiar with the VA.GETINFO syntax, you can call the function
                                    directly without using the builder in the <i>General Information</i> tab.

                                </p>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey={keys.fields} id={keys.fields}>
                            <Accordion.Header><img className='docs-icon' src={desc_icon}/> Field
                                Definitions</Accordion.Header>
                            <Accordion.Body>


                                <p className={'informational-p accordion-p'}>
                                    You can find a list of all available fields by clicking the Field Definitions
                                    button in the Add-In menu (<span className={'figure-number'}>Figure 1</span>).
                                    The Field Definitions window provides all available fields, their definitions,
                                    and the corresponding Function Value that is input to the VA.GETVALUE function.
                                </p>

                                <Figure figure_idx={1}
                                        caption={'Value Analytics menu options with the Field Definitions ' +
                                            'Button highlighted.'}

                                        image={figure_1_desc}
                                        width={'95%'}
                                        margin={'auto'}
                                ></Figure>

                                <p className={'informational-p accordion-p'}>
                                    The Field Definitions window organizes the available Fields into six categories:
                                    Balance Sheet, Cashflow Statement, Equity, Income Statement, Performance
                                    Statistics, and Pricing Multiples (<span className={'figure-number'}>Figure 2</span>).
                                    Select a different category to change the Fields displayed in the menu.
                                </p>

                                <Figure figure_idx={2}
                                        caption={'Value Analytics Field Descriptions page. The mouse pointer is ' +
                                            'hovering over the Field Description column in the Enterprise Value row, ' +
                                            'which expands the text in the table cell. Also, the "e_enterprise_value" ' +
                                            'text has been copied to the clipboard. The text is highlighted in gold after ' +
                                            'a value has been copied to the clipboard.  '

                                        }

                                        image={figure_2_desc}
                                        width={'92%'}
                                        margin={'auto'}
                                ></Figure>
                                <p className={'informational-p accordion-p'}>
                                    Hover over descriptions to reveal the full Description text, and click a Function
                                    Value to copy
                                    that value to your clipboard. Once the Function Value is copied to your clipboard,
                                    you can paste
                                    the Function Value directly into the VA.GETVALUE function to retrieve data
                                    corresponding to
                                    that field.
                                </p>

                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey={keys.refresh} id={keys.refresh}>
                            <Accordion.Header><img className='docs-icon' src={refresh_icon}/>Refreshing
                                Data</Accordion.Header>
                            <Accordion.Body>


                                <p className={'informational-p accordion-p'}>
                                    Once data has been retrieved from the Value Analytics server, the data is stored
                                    within the
                                    Excel sheet. We store the data within the Excel Sheet so that you will not have to
                                    wait
                                    for the data to be retrieved from the server when the file is opened.
                                    You can refresh the data within the Excel Sheet by pressing the Refresh button in
                                    the
                                    menu (<span className={'figure-number'}>Figure 1</span>), and then the Refresh
                                    button in the
                                    window that opens (<span className={'figure-number'}>Figure 2</span>).
                                </p>

                                <Figure figure_idx={1}
                                        caption={'Value Analytics menu options with the Refresh button highlighted.'}

                                        image={figure_1_rf}
                                        width={'95%'}
                                        margin={'auto'}
                                ></Figure>

                                <p className={'informational-p accordion-p'}>
                                    Most of the time, the refreshed data will be identical to the data stored within the
                                    Excel Sheet.
                                    However, financial data can change when companies issue corrections.
                                    We recommend that you refresh data prior to using data from Value Analytics in
                                    official reports,
                                    so that the reported information is up to date.
                                    You will see a "BUSY" prompt within the cells while the Add-In is retrieving data
                                    from the server.
                                    If you are retrieving a large number of values during the refresh (i.e. thousands of
                                    values),
                                    it may take a moment for the refresh to complete.
                                </p>

                                <Figure figure_idx={2}
                                        caption={'Value Analytics Refresh page. Press the highlighted button to ' +
                                            'refresh data.'}

                                        image={figure_2_rf}
                                        width={'34%'}
                                        margin={'auto'}
                                ></Figure>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey={keys.troubleshoot} id={keys.troubleshoot}>
                            <Accordion.Header><img className='docs-icon'
                                                   src={ts_icon}/> Troubleshooting</Accordion.Header>
                            <Accordion.Body>

                                <p className={'informational-p accordion-p'}>
                                    If the Add-In is not behaving as expecting, you can reload the Add-In by:

                                    <div className={'list-container'}>
                                        <ol className={'step-by-step-list'}>
                                            <li>Clicking any button in the Value Analytics tab to open the task pane</li>
                                            <li>Clicking the information icon in the top-right</li>
                                            <li>Selecting "Reload" from the drop down menu</li>
                                        </ol>
                                    </div>
                                    <span
                                    className={'figure-number'}>Figure 1</span> shows the "Reload" option for Mac
                                    versions of excel.
                                    Reloading the Add-In will install the latest version of the software from our
                                    servers and ensure that your installed
                                    version is up-to-date. If you are still experiencing unexpected behavior, please
                                    contact us
                                    at <a className="blue-link" href="#">support@valueanalytics.org</a>.
                                </p>

                                <Figure figure_idx={1}
                                        caption={'Value Analytics task pane with information button and ' +
                                            'reload options highlighted. Press "reload" to install the latest ' +
                                            'version of the Add-In.'}

                                        image={figure_1_ts}
                                        width={'34%'}
                                        margin={'auto'}
                                ></Figure>
                            </Accordion.Body>
                        </Accordion.Item >
                        <Accordion.Item eventKey={keys.reInstall} id={keys.reInstall}>
                            <Accordion.Header><img className='docs-icon'
                                                   src={install_icon}/> Re-Installing</Accordion.Header>
                            <Accordion.Body>
                                <p className={'informational-p accordion-p'}>
                                    For some Add-In updates, it may be necessary to re-install the Add-In to load
                                    new functionality. You can reinstall the Add-In by first navigating to the Insert tab
                                    in the Excel ribbon, and then clicking My Add-ins
                                    (<span className={'figure-number'}>Figure 1</span>).
                                </p>
                                <Figure figure_idx={1}
                                        caption={'Excel Ribbon with Insert tab and My Add-ins highlighted.'}

                                        image={figure_1_ins}
                                        width={'95%'}
                                        margin={'auto'}
                                ></Figure>
                                <p className={'informational-p accordion-p'}>
                                    Next, navigate to the My Add-ins tab of the Office Add-In page, and select the three dots
                                    on the right side of the Value Analytics Add-In
                                    (<span className={'figure-number'}>Figure 2</span>).
                                </p>
                                <Figure figure_idx={2}
                                        caption={'Office Add-In page with My-Addins tab highlighted.'}

                                        image={figure_2_reins}
                                        width={'95%'}
                                        margin={'auto'}
                                ></Figure>
                                <p className={'informational-p accordion-p'}>
                                    From the menu that opens after selecting the three dots, select "Remove" and confirm
                                    in the following dialogue box. After the Add-In was successfully removed (the Value Analytics
                                    tab will no longer be visible on the ribbon), close the
                                    Excel Application.
                                </p>
                                <p className={'informational-p accordion-p'}>
                                    Next, re-open Excel and navigate back to the Office Add-Ins page
                                    (<span className={'figure-number'}>Figure 1</span>).
                                    From the Office Add-Ins page, select the Store tab and type "Value Analytics" in the search field.
                                    Find the Value Analytics Add-In within the search results and select Add (<span className={'figure-number'}>Figure 3</span>).
                                </p>
                                <Figure figure_idx={3}
                                        caption={'Add-In window with Store tab with search field highlighted.'}

                                        image={figure_2_ins}
                                        width={'95%'}
                                        margin={'auto'}
                                ></Figure>
                                <p className={'informational-p accordion-p'}>
                                    The Office Add-In store will inform you that you agree to our terms and
                                    conditions
                                    by continuing with the installation. Press Continue to finish installing the Add-In.
                                    If the installation was successful, the Value Analytics tab will be added to the
                                    Excel ribbon
                                    and you will see the following alert (<span
                                    className={'figure-number'}>Figure 4</span>).
                                </p>
                                <Figure figure_idx={4}
                                        caption={'Excel Ribbon with Value Analytics tab highlighted after successful installation.'}

                                        image={figure_3_ins}
                                        width={'95%'}
                                        margin={'auto'}
                                ></Figure>
                                <p className={'informational-p accordion-p'}>
                                    If the Add-In is not behaving as expected following the re-installation, please
                                    contact <a className="blue-link" href="#">support@valueanalytics.org</a>
                                </p>


                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </div>
            </Container>
        </div>
    );
}
