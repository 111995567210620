import React, {useState} from 'react'
import {MANAGER_ROLE, USER_ROLE, WEEKLY_NAME, EXPIRED_TERM, MONTHLY_NAME, TRIAL_NAME} from "../../constants";
import { PrimaryButton } from '../Button/Button';
import {getPlanType, getPlanOptions, getUsers, setInvoicePreview} from "../../billing/billingSlice";
import { useSelector, useDispatch} from 'react-redux';
import {fetchInvoicePreview} from "../../billing/billingSlice";
import {Routes} from "../../routes/routes";
import {
    CurrentSubscription,
    IGetInvoicePreview,
    lineItem,
    TypicalMonthly,
    IUser,
    NextInvoice
} from "../../api/requestsTypes";
import {IPlanOption} from "../../api/requests";
import {useHistory} from "react-router-dom";

interface UserOptionsProps{
    id: number
    role: string
    active: boolean
    trialing: boolean
    expires_date: string
    sub_name: string
    setShowInvoicePreview: (state: boolean) => void
    setInvoiceAction: (action: string) => void
    active_manager: boolean
    buttonIsEnabled: boolean
    setButtonIsEnabled: (state: boolean) => void
}

interface TrialingManagerButtonProps {
    planOptions: IPlanOption[]
    planType: CurrentSubscription
    buttonIsEnabled: boolean
    setButtonIsEnabled: (state: boolean) => void
}

function TrialingManagerButton(props: TrialingManagerButtonProps){
    const history = useHistory();

    function handleOnClick() {
        history.push(Routes.upgradeAccount)
    }

    const click_text = 'Extend Access'
    return (
            <td>
                    <PrimaryButton className={'inline-button'}
                                   text={click_text}
                                   onClick={handleOnClick}
                                   isEnabled={props.buttonIsEnabled}
                                   isLoading={!props.buttonIsEnabled}
                    />
            </td>
    )

}


interface ActiveWeeklyButtonProps {
    planOptions: IPlanOption[]
    planType: CurrentSubscription
    id: number
    setInvoiceAction: (action:string) => void
    setShowInvoicePreview: (show:boolean) => void
    buttonIsEnabled: boolean
    setButtonIsEnabled: (state: boolean) => void
}


function ActiveWeeklyManagerButton(props: ActiveWeeklyButtonProps) {
    const dispatch = useDispatch()

    async function upgrade_to_monthly_preview(){
        let monthly_option = props.planOptions.filter(option=>{return option.title == MONTHLY_NAME})[0]
        let invoice_preview_payload = {
            subscription_id: props.planType.sub_id,
            price_id: monthly_option.price_id,
            type: 'update_manager',
            user_id: props.id
        }

        props.setButtonIsEnabled(false)
        await dispatch(fetchInvoicePreview(invoice_preview_payload))
        props.setInvoiceAction('monthly_upgrade')
        props.setShowInvoicePreview(true)
        props.setButtonIsEnabled(true)
    }

    const click_text = 'Upgrade to Monthly'

    return (
        <td>
                    <PrimaryButton className={'inline-button'}
                                   text={click_text}
                                   onClick={upgrade_to_monthly_preview}
                                   isEnabled={props.buttonIsEnabled}
                                   isLoading={!props.buttonIsEnabled}
                    />
        </td>
    )

}

interface ActiveMonthlyButtonProps {
    users: any[]
    setInvoiceAction: (action:string) => void
    setShowInvoicePreview: (show:boolean) => void
    buttonIsEnabled: boolean
    setButtonIsEnabled: (state: boolean) => void
}

function ActiveMonthlyManagerButton(props: ActiveMonthlyButtonProps) {

    const dispatch = useDispatch()
    async function cancel_monthly(){

        let number_of_active_users = props.users.filter(user=>{return user.active}).length

        let invoice_action;
        if (number_of_active_users > 1) {
            invoice_action = 'cancel_with_active_users'
        } else {
            invoice_action = 'cancel_sole_manager'
        }

        let next_invoice = {
            invoiced_now: false,
            next_invoice: {
                discount_description: '',
                discounts: '',
                line_items: [{
                    amount: '$0.00',
                    description: '0 × VA Monthly'
                }],
                next_invoice_date: '',
                subtotal: '$0.00',
                total: '$0.00'
            },
            proration_date: -1,
            typical_monthly: {} as TypicalMonthly,
            invoice_preview_params: {} as IGetInvoicePreview
        }

        props.setButtonIsEnabled(false)
        await dispatch(setInvoicePreview(next_invoice))
        props.setInvoiceAction(invoice_action)
        props.setShowInvoicePreview(true)
        props.setButtonIsEnabled(true)
    }

    const click_text = 'Cancel Subscription'
    return (
        <td>
                    <PrimaryButton className={'inline-button'}
                                   text={click_text}
                                   onClick={cancel_monthly}
                                   isEnabled={props.buttonIsEnabled}
                                   isLoading={!props.buttonIsEnabled}
                    />
        </td>
    )
}

interface CancelledMonthlyButtonProps {
    planOptions: IPlanOption[]
    planType: CurrentSubscription
    id: number
    setInvoiceAction: (action:string) => void
    setShowInvoicePreview: (show:boolean) => void
    buttonIsEnabled: boolean
    setButtonIsEnabled: (state: boolean) => void
}

function CancelledMonthlyManagerButton(props: CancelledMonthlyButtonProps) {
    const dispatch = useDispatch()

    async function resubscribe_to_monthly(){

        let monthly_option = props.planOptions.filter(option=>{return option.title == MONTHLY_NAME})[0]
        let invoice_preview_payload = {
            subscription_id: props.planType.sub_id,
            price_id: monthly_option.price_id,
            type: 'subscribe_manager',
            user_id: props.id
        }

        props.setButtonIsEnabled(false)
        await dispatch(fetchInvoicePreview(invoice_preview_payload))
        props.setInvoiceAction('re_subscribe_manager_monthly')
        props.setShowInvoicePreview(true)
        props.setButtonIsEnabled(true)
    }

    const click_text = 'Renew Subscription'
    return (
        <td>
                    <PrimaryButton className={'inline-button'}
                                   text={click_text}
                                   onClick={resubscribe_to_monthly}
                                   isEnabled={props.buttonIsEnabled}
                                   isLoading={!props.buttonIsEnabled}
                    />
        </td>
    )
}

function ExpiredMonthlyManagerButton(props: CancelledMonthlyButtonProps) {
    const dispatch = useDispatch()
    const [category, setCategory] = useState('default');

    async function resubscribe_to_choice(e: string) {
        let selected = props.planOptions.filter(option=>{return option.price_id == e})[0]
        let next_invoice = {
            invoiced_now: true,
            next_invoice: {
                discount_description: '',
                discounts: '',
                line_items: [{
                    amount: selected.display_price,
                    description: '1 × VA ' + selected.title
                }],
                next_invoice_date: '',
                subtotal: selected.display_price,
                total: selected.display_price
            },
            proration_date: -1,
            typical_monthly: {
                 amount: selected.title == WEEKLY_NAME ? 'N/A' : selected.display_price,
                 description: selected.title,
                 discount_description: '',
                 discounts: '',
                 next_invoice_date: selected.billing_frequency
            },
            invoice_preview_params: {
                subscription_id: props.planType.sub_id,
                price_id: e,
                type: 'subscribe_manager',
                user_id: props.id
            }
        }
        props.setButtonIsEnabled(false)
        await dispatch(setInvoicePreview(next_invoice))
        props.setInvoiceAction('re_subscribe_manager')
        props.setShowInvoicePreview(true)
        props.setButtonIsEnabled(true)
        setCategory('default')


    }
    return (
        <td>
                    <select className={'button primary inline-button'} defaultValue={category} value={category}
                            onChange={event => resubscribe_to_choice(event.target.value)}
                    >

                            <option value="default" disabled hidden>
                            Resubscribe
                            </option>
                        {
                            props.planOptions.map((option, i) => {
                                return <option key={i} value={option.price_id}>{option.title}</option>
                            })
                        }
                    </select>
        </td>
    )
}


interface CancelledTeamMemberButtonProps {
    planOptions: IPlanOption[]
    planType: CurrentSubscription
    id: number
    setInvoiceAction: (action:string) => void
    setShowInvoicePreview: (show:boolean) => void
    active_manager: boolean
    buttonIsEnabled: boolean
    setButtonIsEnabled: (state: boolean) => void
}

function CancelledTeamMemberButton(props: CancelledTeamMemberButtonProps) {

    const dispatch = useDispatch()

    async function resubscribe_to_monthly(){

        let monthly_option = props.planOptions.filter(option=>{return option.title == MONTHLY_NAME})[0]
        let invoice_preview_payload = {
            subscription_id: props.planType.sub_id,
            price_id: monthly_option.price_id,
            type: 'subscribe_user',
            user_id: props.id
        }

        props.setButtonIsEnabled(false)
        await dispatch(fetchInvoicePreview(invoice_preview_payload))
        props.setInvoiceAction('re_subscribe_user')
        props.setShowInvoicePreview(true)
        props.setButtonIsEnabled(true)
    }

    async function delete_user(){
        let next_invoice = {
            invoiced_now: false,
            next_invoice: {
                discount_description: '',
                discounts: '',
                line_items: [],
                next_invoice_date: '',
                subtotal: '',
                total: ''
            },
            proration_date: -1,
            typical_monthly: {} as TypicalMonthly,
            invoice_preview_params: {
                subscription_id: '',
                price_id: '',
                type: '',
                user_id: props.id
            }
        }
        props.setButtonIsEnabled(false)
        await await dispatch(setInvoicePreview(next_invoice))
        props.setInvoiceAction('delete_user')
        props.setShowInvoicePreview(true)
        props.setButtonIsEnabled(true)

    }

    const renew_text = 'Renew'
    const delete_text = 'Delete'
    return (
        <td>
                    <PrimaryButton className={'small-inline-button-left'}
                                   text={renew_text}
                                   onClick={resubscribe_to_monthly}
                                   isEnabled={props.active_manager && props.buttonIsEnabled}
                                   isLoading={!props.buttonIsEnabled}
                    />

                    <PrimaryButton className={'small-inline-button-right'}
                                   text={delete_text}
                                   onClick={delete_user}
                                   isEnabled={props.buttonIsEnabled}
                                   isLoading={!props.buttonIsEnabled}
                    />
        </td>
    )
}


interface ActiveTeamMemberButtonProps {
    planOptions: IPlanOption[]
    planType: CurrentSubscription
    id: number
    setInvoiceAction: (action:string) => void
    setShowInvoicePreview: (show:boolean) => void
    buttonIsEnabled: boolean
    setButtonIsEnabled: (state: boolean) => void
}

function ActiveTeamMemberButton(props: ActiveTeamMemberButtonProps) {


    const dispatch = useDispatch()

    async function cancel_monthly(){

        let monthly_option = props.planOptions.filter(option=>{return option.title == MONTHLY_NAME})[0]
        let invoice_preview_payload = {
            subscription_id: props.planType.sub_id,
            price_id: monthly_option.price_id,
            type: 'cancel_user',
            user_id: props.id
        }

        props.setButtonIsEnabled(false)
        await dispatch(fetchInvoicePreview(invoice_preview_payload))
        props.setInvoiceAction('cancel_user')
        props.setShowInvoicePreview(true)
        props.setButtonIsEnabled(true)
    }

    async function delete_user(){
    }

    const cancel_text = 'Cancel'
    const delete_text = 'Delete'
    return (
        <td>
                    <PrimaryButton className={'small-inline-button-left'}
                                   text={cancel_text}
                                   onClick={cancel_monthly}
                                   isEnabled={props.buttonIsEnabled}
                                   isLoading={!props.buttonIsEnabled}
                    />

                    <PrimaryButton className={'small-inline-button-right'}
                                   text={delete_text}
                                   onClick={delete_user}
                                   isEnabled={false}
                                   isLoading={!props.buttonIsEnabled}
                    />
        </td>
    )
}


function UserOptions(props: UserOptionsProps){
    const planType=useSelector(getPlanType)
    const planOptions=useSelector(getPlanOptions)
    const users=useSelector(getUsers)

    // if this is true, the user is in a trial subscription
    // if the trial is expired or not, the user will have the same option to extend account
    const trialing_manager = props.role == MANAGER_ROLE &&
                                 props.sub_name == TRIAL_NAME


    // if this is true, the manager has the option to upgrade to monthly
    // cannot cancel since weekly accounts expire at period end
    const active_weekly_manager = props.role == MANAGER_ROLE &&
                                  props.sub_name == WEEKLY_NAME &&
                                  props.expires_date != EXPIRED_TERM


    // if this is true, the manager has the option to cancel his account
    // if there are managed users, this action will cancel all of their accounts as well
    const active_monthly_manager = props.role == MANAGER_ROLE &&
                                   props.sub_name == MONTHLY_NAME &&
                                   props.active &&
                                   !props.trialing

    // if this is true, the manager has the option to resubscribe to monthly
    // in this state, the manager has cancelled account but there is still time left
    // must wait until expired to resubscribe as monthly
    const cancelled_monthly_manager = props.role == MANAGER_ROLE &&
                                      props.sub_name == MONTHLY_NAME &&
                                      props.active == false &&
                                      props.expires_date != EXPIRED_TERM &&
                                      !props.trialing


    // if this is true, the manager has the option to resubscribe as weekly or monthly
    // do not hit invoice preview in this case since there are no future invoices
    const expired_manager = props.role == MANAGER_ROLE &&
                            props.expires_date == EXPIRED_TERM &&
                            !props.trialing

    // if this is true, the manager has the option to cancel this account
    const active_user = props.role == USER_ROLE &&
                        props.active


    // if this is true, the manager has the option to resubscribe or delete this account
    const cancelled_user = props.role == USER_ROLE &&
                           props.active == false


    function user_actions(){

        if (active_weekly_manager){
            return (
                <ActiveWeeklyManagerButton
                    planOptions={planOptions}
                    planType={planType}
                    setInvoiceAction={props.setInvoiceAction}
                    setShowInvoicePreview={props.setShowInvoicePreview}
                    id={props.id}
                    buttonIsEnabled={props.buttonIsEnabled}
                    setButtonIsEnabled={props.setButtonIsEnabled}
                />
            )
        } else if (trialing_manager){
            return (
                <TrialingManagerButton
                    planOptions={planOptions}
                    planType={planType}
                    buttonIsEnabled={props.buttonIsEnabled}
                    setButtonIsEnabled={props.setButtonIsEnabled}
                />
            )
        }
        else if (active_monthly_manager) {
            return (
                <ActiveMonthlyManagerButton
                    setInvoiceAction={props.setInvoiceAction}
                    setShowInvoicePreview={props.setShowInvoicePreview}
                    users={users}
                    buttonIsEnabled={props.buttonIsEnabled}
                    setButtonIsEnabled={props.setButtonIsEnabled}
                />
            )
        } else if (cancelled_monthly_manager) {
            return <CancelledMonthlyManagerButton
                    planOptions={planOptions}
                    planType={planType}
                    setInvoiceAction={props.setInvoiceAction}
                    setShowInvoicePreview={props.setShowInvoicePreview}
                    id={props.id}
                    buttonIsEnabled={props.buttonIsEnabled}
                    setButtonIsEnabled={props.setButtonIsEnabled}
                 />
        } else if (cancelled_user) {
            return <CancelledTeamMemberButton
                    planOptions={planOptions}
                    planType={planType}
                    setInvoiceAction={props.setInvoiceAction}
                    setShowInvoicePreview={props.setShowInvoicePreview}
                    id={props.id}
                    active_manager={props.active_manager}
                    buttonIsEnabled={props.buttonIsEnabled}
                    setButtonIsEnabled={props.setButtonIsEnabled}
            />
        } else if (active_user) {
            return <ActiveTeamMemberButton
                    planOptions={planOptions}
                    planType={planType}
                    setInvoiceAction={props.setInvoiceAction}
                    setShowInvoicePreview={props.setShowInvoicePreview}
                    id={props.id}
                    buttonIsEnabled={props.buttonIsEnabled}
                    setButtonIsEnabled={props.setButtonIsEnabled}
                    />
        }


        else if (expired_manager){
            return <ExpiredMonthlyManagerButton
                    planOptions={planOptions}
                    planType={planType}
                    setInvoiceAction={props.setInvoiceAction}
                    setShowInvoicePreview={props.setShowInvoicePreview}
                    id={props.id}
                    buttonIsEnabled={props.buttonIsEnabled}
                    setButtonIsEnabled={props.setButtonIsEnabled}
                    />
        }
        else
        {
            return <td>No Actions</td>
        }

    }


    return(
        user_actions()
    )}

export default UserOptions;