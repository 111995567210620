import React, {useState} from 'react';
import {Route, Redirect, NavLink} from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';
import {Col, Container, Nav, NavItem, Row} from 'reactstrap';
import AccountInformation from './AccountInformation';
import Billing from './Billing';
import {Modal} from '../Modal/Modal';
import './AccountPage.scss';
import classNames from 'classnames';
import InvoiceForm from "./InvoiceForm/InvoiceForm";
import {getPlanOptions, getPlanType, fetchInvoicePreview} from '../../billing/billingSlice';
import {MONTHLY_NAME} from "../../constants";
import {selectClaims} from "../../auth/authSlice";
import {InvoiceAction} from "../../billing/invoice";
import SmallScreenAlert from "../Utility/SmallScreenAlert";

function AccountPage() {
    const dispatch = useDispatch();
    const claims = useSelector(selectClaims);
    const planOptions = useSelector(getPlanOptions)
    const planType = useSelector(getPlanType)
    const [newUserName, setNewUserName] = useState('');
    const [newUserEmail, setNewUserEmail] = useState('');
    const [showInvoicePreview, setShowInvoicePreview] = useState(false);
    const [invoiceAction, setInvoiceAction] = useState<InvoiceAction>('nothing')
    const [teamMgmtError, setTeamMgmtError] = useState('')

    const accountPageClassName = () =>
        classNames({
            'account-page': true,
            'blur': showInvoicePreview
        });

    function displayInvoicePreview(name: string, email: string) {
        setNewUserName(name);
        setNewUserEmail(email);
        setShowInvoicePreview(true)
    }

    async function generateNewUserInvoice() {
        let monthly_option = planOptions.filter(option => {
            return option.title === MONTHLY_NAME
        })[0]
        let invoice_preview_payload = {
            subscription_id: planType.sub_id,
            price_id: monthly_option.price_id,
            type: 'subscribe_user',
            user_id: 999 // note, user not created yet. Need to refactor backend to not require user_id
        }
        await dispatch(fetchInvoicePreview(invoice_preview_payload))
    }


    async function handleInviteUser(
        name: string,
        email: string,
        confirmEmail: string,
        enableButton: () => void,
        disableButton: () => void,
        showAddUserForm: (show: boolean) => void
    ): Promise<any> {
        if (email !== confirmEmail) {
            setTeamMgmtError('Team Member email address does not match confirmation. Please check email address')
        } else {
            disableButton()
            setTeamMgmtError('')
            await generateNewUserInvoice();
            setInvoiceAction('subscribe_user')
            displayInvoicePreview(name, email);
            enableButton()
            showAddUserForm(false)
        }
    }

    async function handleOwnerInviteUser(
        name: string,
        email: string,
        confirmEmail: string,
        enableButton: () => void,
        disableButton: () => void,
        showAddUserForm: (show: boolean) => void
    ): Promise<any> {
        if (email !== confirmEmail) {
            setTeamMgmtError('Team Member email address does not match confirmation. Please check email address')
        } else {
            disableButton()
            setTeamMgmtError('')
            setInvoiceAction('subscribe_owner_user')
            displayInvoicePreview(name, email)
            enableButton()
            showAddUserForm(false)
        }
    }


    return (
        <>
            <SmallScreenAlert/>
            <div className={accountPageClassName()}>
                <Container className="account-page-container mb-5 card mt-5">
                    <Row>
                        <Col lg={2} md={12} className={'mb-5 account-page-nav'}>
                            <Nav>
                                <NavItem className={'mb-5'}>
                                    <NavLink to="/account/settings" activeClassName="selected" className={'nav-link'}>
                                        Account Settings
                                    </NavLink>
                                </NavItem>
                                {
                                    claims.role === 'OWNER' ?
                                        <NavItem className={'mb-5'}>
                                            <NavLink to={"/account/billing/team"} activeClassName="selected" className={'nav-link'}>
                                                Manage Team
                                            </NavLink>
                                        </NavItem>
                                        : null
                                }
                                {
                                    claims.role === 'MANAGER' ?
                                        <NavItem className={'mb-5'}>
                                            <NavLink to="/account/billing" activeClassName="selected"
                                                     className={'nav-link'}>
                                                Billing
                                            </NavLink>
                                        </NavItem>
                                        : null
                                }
                            </Nav>
                        </Col>
                        <Col lg={9} md={12} className={'ps-sm-5'}>
                            <Route path="/account/settings">
                                <div className={'account-info-wrap'}>
                                    <AccountInformation/>
                                </div>
                            </Route>
                            <Route path="/account/billing">
                                {
                                    claims.role === 'MANAGER' ?
                                        [
                                            <Billing handleInviteUser={handleInviteUser}
                                                     setShowInvoicePreview={setShowInvoicePreview}
                                                     setInvoiceAction={setInvoiceAction}
                                                     teamMgmtError={teamMgmtError}/>,
                                            <Redirect to="/account/billing/team"/>
                                        ]
                                        : claims.role === 'OWNER' ?
                                            <Billing handleInviteUser={handleOwnerInviteUser}
                                                     setShowInvoicePreview={setShowInvoicePreview}
                                                     setInvoiceAction={setInvoiceAction}
                                                     teamMgmtError={teamMgmtError}/>
                                            :
                                            <Redirect to="/account/settings"/>
                                }
                            </Route>
                        </Col>
                    </Row>
                </Container>
            </div>
            {
                showInvoicePreview
                    ? (
                        <Modal onCloseModal={() => {
                            setShowInvoicePreview(false)
                        }} hideCloseButton={false}>
                            <InvoiceForm invoiceAction={invoiceAction}
                                         setShowInvoicePreview={setShowInvoicePreview}
                                         newUserEmail={newUserEmail}
                                         newUserName={newUserName}
                            ></InvoiceForm>
                        </Modal>
                    )
                    : null
            }
        </>
    );
}

export default AccountPage;
