import './HomePage.scss';
import React from 'react';
import {Container, Col, Row} from "reactstrap";
import {PrimaryButton} from "../Button/Button";
import formula_builder_preview from '../../img/landingpage_figures/formula_builder_preview.png';
import filter_preview from '../../img/landingpage_figures/filter_preview.png';
import documentation_preview from '../../img/landingpage_figures/documentation_preview.png';
import va_log_big from '../../img/landingpage_figures/VABIGLOGO.png';
import comp_assist_preview from '../../img/landingpage_figures/CompAssist.png';
import comps_tab_preview from '../../img/landingpage_figures/Comps.png';
import {Image} from "react-bootstrap";
import {useHistory} from "react-router-dom";
import {Routes} from "../../routes/routes";

function LandingRow(props: { children: React.ReactNode }) {
    return (
        <Row className={'align-items-center'}>
            <Row className={'px-md-5 landing-row'}>
                {props.children}
            </Row>
        </Row>
    )
}

function SmallPaddingLandingRow(props: { children: React.ReactNode }) {
    return (
        <Row className={'align-items-center small-padding'}>
            <Row className={'px-md-5 landing-row '}>
                {props.children}
            </Row>
        </Row>
    )
}

function HomePage() {
    const history = useHistory();

    const goTo = (route: string) => () => {
        history.push(route);
    }

    return (
        <div className='informational-page landing-page'>
            <Container fluid={true} className={'landing-container'}>
                <SmallPaddingLandingRow >

                    <Row className={'padded-bottom'}>
                        <Image className={'landing-logo'} src={va_log_big}/>
                    </Row>
                    <Row className={'padded-bottom'}>
                            <h1 className={'informational-value-prop'}>
                                Streamline business valuations with high quality data <br></br> and AI-driven peer group formation
                            </h1>
                    </Row>
                    <Row className={'padded-bottom'}>
                            <p className={'informational-p-center'}>
                            Value Analytics offers market data and workflow automation tools.
                            <br/>
                            < p className={'informational-p-center black-link'} >
                                Sign up and get the first 7 days free.
                            </p>
                        </p>
                    </Row >
                    <Row >
                        <PrimaryButton
                            className={'button-center big-button round-button'}
                            isEnabled={true}
                            text={'Start Free Trial'}
                            onClick={goTo(Routes.createAccount)}
                        />
                    </Row>
                </SmallPaddingLandingRow>
                <Row className={'logo-background'}>
                    <LandingRow >

                            <Col xs={9}>
                                <Row className={'padded-bottom'}>
                                    <h1 className='informational-h1'>
                                        Built by valuation practitioners for valuation practitioners
                                    </h1>
                                    <p className={'informational-p emphasis'}>
                                        Let your data provider work for you.
                                    </p>
                                </Row>
                                <Row>
                                    <p className={'informational-p'}>
                                        Value Analytics offers financial data and automated tools
                                        designed to serve the business valuation community. <br></br> <br></br>
                                        Our platform streamlines the market approach, removing frictions
                                        in your analysis and reducing time spent on each project.
                                    </p>
                                </Row>
                            </Col>
                            <Col>

                            </Col>


                    </LandingRow>
                </Row>
                <LandingRow>
                    <Col xs={7}>
                        <Image className={'landing-image'} src={formula_builder_preview}/>
                    </Col>
                    <Col >
                        <Row className={'left-padding'}>
                            <h1 className='informational-h1'>
                               Unlimited access to data
                            </h1>
                            <p className={'informational-p'}>
                                Subscribers have unlimited access
                                to data through our online platform and Excel
                                Add-In. <br></br> <br></br>
                                Our Excel Add-In allows subscribers to
                                easily integrate data directly into proprietary models.<br></br> <br></br>
                                No annual limits on screening or data calls.
                                No time limits.
                                <br></br><br></br>
                            </p>
                        </Row>
                        <Row className={'left-padding'}>
                            <PrimaryButton
                                className={'big-button round-button'}
                                isEnabled={true}
                                text={'Start Free Trial'}
                                onClick={goTo(Routes.createAccount)}
                            />
                        </Row>
                    </Col>
                </LandingRow>
                <LandingRow>
                    <Col>
                        <Row className={'right-padding'}>
                            <h1 className='informational-h1'>
                                Optimize peer group formation with Comp Assist
                            </h1>
                            <p className={'informational-p'}>
                                Comp Assist’s AI will significantly reduce time
                                spent forming peer groups by instantly identifying
                                and ranking the most similar businesses to a targeted company.
                                <br></br><br></br>
                                Discover hidden peers
                                that would be difficult to find with traditional filtering.
                                <br></br><br></br>
                            </p>
                        </Row>
                        <Row className={'right-padding'}>
                            <PrimaryButton
                            className={'big-button round-button'}
                            isEnabled={true}
                            text={'Start Free Trial'}
                            onClick={goTo(Routes.createAccount)}/>
                        </Row>
                    </Col>
                    <Col xs={7}>
                        <Image className={'landing-image'} src={comp_assist_preview}/>
                    </Col>
                </LandingRow>
                <LandingRow>
                    <Col xs={7}>
                        <Image className={'landing-image'} src={comps_tab_preview}/>
                    </Col>
                    <Col>
                        <Row className={'left-padding'}>
                            <h1 className='informational-h1'>
                                Receive immediate feedback on preliminary peer groups
                            </h1>
                            <p className={'informational-p'}>
                                Get a sneak peek into the results
                                of your analysis to see if selected peers deliver pricing multiples in your expected range.
                                <br></br><br></br>
                                We automatically provide financial analysis and pricing multiples
                                for you, which can be instantly exported to Excel.
                                <br></br><br></br>
                            </p>
                        </Row>
                        <Row className={'left-padding'}>
                            <PrimaryButton
                            className={'big-button round-button'}
                            isEnabled={true}
                            text={'Start Free Trial'}
                            onClick={goTo(Routes.createAccount)}/>
                        </Row>
                    </Col>

                </LandingRow>
                <LandingRow>
                    <Col md={6}>
                        <h1 className={'informational-h1'}>
                            Digest data using screening and analysis tools
                        </h1>
                        <p className={'informational-p'}>
                            Streamline the search for public comps using our robust screening and analysis
                            tools. Designed to facilitate guideline public company analysis for valuation
                            practitioners.
                        </p>
                    </Col>
                    <Col className={'mr-auto'}/>
                    <Col md={12}>
                        <Image className={'landing-image'} src={filter_preview}/>
                    </Col>
                    <Col className={'ml-auto'}>
                    </Col>
                    <Col md={6} className={'text-align-right'}>
                        <h1 className={'informational-h1'}>
                            Data quality is our highest priority
                        </h1>
                        <p className={'informational-p'}>
                            Transparent data sourcing and quality assurance procedures allow users to trust the
                            integrity of the data they use. Field definitions are available in our documentation
                            both online and in our user-friendly Excel Add-In.
                            <br></br>
                            <br></br>
                        </p>
                        <PrimaryButton
                            className={'big-button round-button'}
                            isEnabled={true}
                            text={'Documentation'}
                            onClick={goTo(Routes.documentation)}/>
                    </Col>
                </LandingRow>
                <LandingRow>
                    <Col>
                        <Row>
                            <h1 className={'informational-h1'}>
                                Save more time using custom report and analysis templates
                            </h1>
                        </Row>
                        <Row>
                            <p className={'informational-p'}>
                                Access our ever-growing inventory of templates for both analysis and reporting.
                                These templates are available to subscribers.
                            </p>
                        </Row>
                    </Col>
                    <Col>
                        <Image className={'landing-image'} src={documentation_preview}/>
                    </Col>
                </LandingRow>
                <LandingRow>
                    <Col className={'feedback-text'}>
                        <p className={'contact-us'}>
                            Value Analytics is dedicated to meeting your business valuation needs.
                            <br></br>
                            Contact us at support@valueanalytics.org
                        </p>
                    </Col>
                </LandingRow>
            </Container>
        </div>
    )
        ;
}

export default HomePage;
