import {useDispatch} from "react-redux";
import {useState} from "react";
import {refreshUserSessionInformation} from "../../../auth/authSlice";
import {fetchSubMgmtData} from "../../../billing/billingSlice";

export function useInvoiceLogic(props: { errors: string, setShowInvoicePreview: (show: boolean) => void }) {
    const errorMessage = 'Unable to process your request at this time. Please contact support@valueanalytics.org' +
        ' or try again later.'
    const dispatch = useDispatch();
    const [error, setError] = useState(props.errors);
    const [isLoading, setIsLoading] = useState(false);
    const [isEnabled, setIsEnabled] = useState(true);

    function enableButton() {
        setIsLoading(false)
        setIsEnabled(true)
    }

    function disableButton() {
        setIsLoading(true)
        setIsEnabled(false)
    }

    function onActionSuccess() {
        props.setShowInvoicePreview(false)
        dispatch(fetchSubMgmtData())
    }

    const performAction:
        (action: () => Promise<{ status: 'success' | string, error?: string }>,
         onFailure?: (response: { error?: string }) => void) =>
            () => Promise<void>
        = (action, onFailure?) =>
        async () => {
            try {
                disableButton()
                const response = await action()
                if (response.status === 'success') {
                    onActionSuccess()
                } else {
                    if (onFailure) {
                        onFailure(response);
                    } else {
                        setError(errorMessage)
                    }
                }
                dispatch(refreshUserSessionInformation(true))
                enableButton()
            } catch (e) {
                setError(errorMessage)
            }
        }

    return {
        dispatch,
        error, setError,
        isLoading,
        isEnabled,
        enableButton, disableButton,
        performAction
    }

}
