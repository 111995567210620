import './Container.scss';
import React from "react";
import classNames from "classnames";

export function Container(props: { children?: React.ReactNode, className?: string }) {
    const className = classNames(props.className, {
        'va-container': true
    });

    return (
        <div className={className}>
            {props.children}
        </div>
    )
}
