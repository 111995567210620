import React from "react";
import {FilterPickerIndustryGroup} from "./FilterPickerIndustryGroup";
import {industryGroupSearchFilter} from "../../filterPicker/filterStatePickerLogic";
import {FilterPickerSectorProps} from "../../filterPicker/filterStatePickerTypes";
import {FilterPickerParent} from "../FilterPicker/FilterPickerParent";
import {getCheckboxState} from "../../filterPicker/filterPickerUtility";

export function FilterPickerSector(props: FilterPickerSectorProps) {
    const checkboxState = getCheckboxState(props.sector);

    function handleOnSectorPicked() {
        props.onSectorPicked(props.sector);
    }

    function getPickerIndustryGroups() {
        return props.sector.industry_groups
            .filter(industryGroupSearchFilter(props))
            .map(industryGroup =>
                <FilterPickerIndustryGroup
                    key={industryGroup.industry_group_id}
                    industryGroup={industryGroup}
                    searchText={props.searchText}
                    onIndustryGroupPicked={props.onIndustryGroupPicked}
                    onIndustryPicked={props.onIndustryPicked}
                />
            );
    }

    return (
        <FilterPickerParent
            className={"picker-level-1"}
            onCheckBoxClicked={handleOnSectorPicked}
            searchText={props.searchText}
            checkboxState={checkboxState}
            label={props.sector.sector_name}
            children={ getPickerIndustryGroups() }
        />
    );
}
