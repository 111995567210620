import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {MONTHLY_NAME, WEEKLY_NAME, TRIAL_NAME} from "../constants";
import {
    PaymentIntentResponse,
    ICreatePaymentIntent,
    ICreateStripeCustomer,
    CreateStripeCustomerResponse,
    ICreateStripeSubscription,
    CreateStripeSubscriptionResponse
} from "../api/requestsTypes";
import {
    createPaymentIntent,
    createStripeCustomer,
    createStripeSubscription,
    getPlanOptions,
    IPlanOption,
    checkToken,
    ICheckTokenRequest
} from "../api/requests";
import {RootState} from "../store";
import {refreshUserSessionInformation} from "../auth/authSlice";
import {resetStateOnSignOut} from "../auth/signOut";

export interface IUser {
    fullName: string
    email: string,
    password: string,
    stripeCustomerId: string
}

export interface IPlan {
    seats: string
    planType: string,
}

interface ISignupSlice {
    user: IUser,
    paymentIntentSecret: string,
    plan: IPlan,
    signUpSuccess: boolean,
    planOptions: IPlanOption[],
    selectedPlan: IPlanOption,
    isLoadingOptions: boolean
}

export interface ISignupSliceState {
    signUpSlice: ISignupSlice
}

export const postPaymentIntent = createAsyncThunk<PaymentIntentResponse, ICreatePaymentIntent>(
    'signUpSlice/CreatePaymentIntent',
    async (data, thunkAPI) => {
        return await createPaymentIntent(data)
    }
);

export const postStripeCustomer = createAsyncThunk<CreateStripeCustomerResponse, ICreateStripeCustomer>(
    'signUpSlice/CreateStripeCustomer',
    async (data, thunkAPI) => {
        return await createStripeCustomer(data)
    }
);

export const postStripeSubscription = createAsyncThunk<CreateStripeSubscriptionResponse, ICreateStripeSubscription>(
    'signUpSlice/CreateStripeSubscription',
    async (data, thunkAPI) => {
        return await createStripeSubscription(data)
    }
);

export const fetchPlanOptions = createAsyncThunk<IPlanOption[], void, {state: RootState}>(
    'signUpSlice/fetchPlanOptions',
    async (arg, thunkAPI) => {
        const planOptions = await getPlanOptions();
         // explicitly set the selected_plan attribute to false when before rendering the options
        planOptions.forEach(plan=> {
            plan.selected_plan = false
        })
        await thunkAPI.dispatch(refreshUserSessionInformation(true));
        return planOptions;

    }
);

export const checkPromoCode = createAsyncThunk(
    'signUpSlice/checkPromoCode',
    async(data:any, thunkAPI) =>{
        const tokenToCheck : ICheckTokenRequest = {
            token_name: data.planDetails.coupon_name,
            token_value: data.promoCode

        }
        const tokenResults = await checkToken(tokenToCheck)
        return {
            priceId: data.planDetails.price_id,
            tokenResults: tokenResults
        }


    }

)

const initialState: ISignupSlice = {
    user: {email: "", fullName: "", password: "", stripeCustomerId: ""} as IUser,
    plan: {planType: "", seats: ""} as IPlan,
    paymentIntentSecret: "" as string,
    signUpSuccess: false,
    planOptions: [],
    isLoadingOptions: false,
    selectedPlan: {} as IPlanOption
};

export const signUpSlice = createSlice({
    name: 'signUpSlice',
    initialState,
    reducers: {
        updateSeats: (state, action) => {
            state.plan.seats = action.payload;
        },
        updatePlanType: (state, action) => {
            state.plan.planType = action.payload;
        },
        updateEmail: (state, action) => {
            state.user.email = action.payload;
        },
        updateName: (state, action) => {
            state.user.fullName = action.payload;
        },
        updatePlanOptions: (state, action) => {
            state.planOptions.forEach(plan => {
                if (plan.price_id === action.payload){
                    plan.selected_plan = true;
                    state.selectedPlan = plan;
                } else {
                    plan.selected_plan = false;
                }
            })
        }
    },
    extraReducers: builder => {
        builder.addCase(postStripeCustomer.fulfilled, (state, action) => {
            switch(action.payload.status) {
                case 'success':
                    state.user.stripeCustomerId = action.payload.customerId;
                    break;
                case 'error':
                    break;
            }
        });

        builder.addCase(fetchPlanOptions.fulfilled, (state, action) => {
            state.planOptions = action.payload;
            state.isLoadingOptions = false;
        });

        builder.addCase(fetchPlanOptions.pending, (state, action) => {
            state.isLoadingOptions = true;
        });

        builder.addCase(checkPromoCode.fulfilled, (state, action) => {
            if (action.payload.tokenResults.status == "success"){
                // update the coupon_applied prop to true for the selected plan
                state.selectedPlan.coupon_applied = true
                // store the secret for creating the subscription
                state.selectedPlan.promo_secret = action.payload.tokenResults.token_secret
                state.selectedPlan.promo_attempt_failed = false
            }
            else{
                state.selectedPlan.promo_attempt_failed = true
            }

        });

        resetStateOnSignOut(builder, initialState);
    }
});


export const {
    updateSeats,
    updatePlanType,
    updateEmail,
    updateName,
    updatePlanOptions,
} = signUpSlice.actions;
export const selectUser = (state: ISignupSliceState) => state.signUpSlice.user;
export const selectPlan = (state: ISignupSliceState) => {
    return state.signUpSlice.plan;
}
export const selectPlanOptions = function (state: ISignupSliceState)
{
    let order = [TRIAL_NAME, MONTHLY_NAME, WEEKLY_NAME]
    let ordered_options:IPlanOption[] = []
    order.forEach(plan=>{
        state.signUpSlice.planOptions.forEach(option=>{
            if (plan==option.title) {
                ordered_options.push(option)
            }
        })
    })
    return ordered_options
};
export const selectPlanDetails = (state: ISignupSliceState) => state.signUpSlice.selectedPlan;
export const selectIsLoadingOptions = (state: ISignupSliceState) => state.signUpSlice.isLoadingOptions;
export default signUpSlice.reducer;

