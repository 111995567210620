import React from "react";

export function BxsDownArrow() {
    return (
        <svg width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M0.792877 2.076C0.761324 2.13666 0.746887 2.20477 0.751115 2.27302C0.755343 2.34126 0.778077 2.40707 0.816876 2.46337L4.19188 7.33838C4.22639 7.38819 4.27246 7.4289 4.32614 7.45701C4.37983 7.48513 4.43953 7.49982 4.50013 7.49982C4.56073 7.49982 4.62043 7.48513 4.67411 7.45701C4.72779 7.4289 4.77386 7.38819 4.80838 7.33838L8.18338 2.46337C8.2223 2.40712 8.2451 2.34128 8.24929 2.27299C8.25349 2.20471 8.23892 2.13657 8.20717 2.07597C8.17543 2.01537 8.1277 1.96461 8.06917 1.92919C8.01065 1.89376 7.94354 1.87502 7.87513 1.875L1.12513 1.875C1.05667 1.87499 0.989526 1.89371 0.930958 1.92915C0.87239 1.96458 0.824638 2.01536 0.792877 2.076Z"
                fill="#979797"/>
        </svg>
    );
}

export function BxsRightArrow() {
    return (
        <svg width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M2.076 8.20712C2.13666 8.23868 2.20477 8.25311 2.27302 8.24889C2.34126 8.24466 2.40707 8.22192 2.46337 8.18312L7.33838 4.80812C7.38819 4.77361 7.4289 4.72754 7.45701 4.67386C7.48513 4.62017 7.49982 4.56047 7.49982 4.49987C7.49982 4.43927 7.48513 4.37957 7.45701 4.32589C7.4289 4.27221 7.38819 4.22614 7.33838 4.19162L2.46337 0.816624C2.40712 0.777696 2.34128 0.7549 2.27299 0.750706C2.20471 0.746511 2.13657 0.761077 2.07597 0.792826C2.01537 0.824575 1.96461 0.872297 1.92919 0.930826C1.89376 0.989355 1.87502 1.05646 1.875 1.12487V7.87487C1.87499 7.94332 1.89371 8.01047 1.92915 8.06904C1.96458 8.12761 2.01536 8.17536 2.076 8.20712Z"
                fill="#979797"/>
        </svg>
    );
}

