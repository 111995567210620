import './CompsSelectedCompsGroup.scss';
import React from "react";
import {MinusButton, PrimaryButton, SecondaryButton} from "../Button/Button";
import {SaveWithNameButton} from "../SaveWithNameButton/SaveWithNameButton";
import {Container} from "../Container/Container";
import {
    clearCompsGroup,
    CompanyGroup,
    ICompany,
    removeCompsFromGroup,
    saveUserCompSet, selectCompsGroupId, selectCompsGroupName,
    selectIsSavingUserCompSet,
    setCompanyGroupName
} from "../../comps/compsGroupSlice";
import {useDispatch, useSelector} from "react-redux";
import {fetchCompSet} from "../../comps/compsTableSlice";
import {Action, ThunkDispatch} from "@reduxjs/toolkit";
import {RootState} from "../../store";
import {fetchUserCompSets} from "../../comps/savedCompsSets";

export default CompsSelectedCompsGroup;

export interface ICompsSelectedCompsGroupProps {
    onClick: () => void;
    buttonText: string;
    companies: CompanyGroup;
}

function CompsSelectedCompsGroup(props: ICompsSelectedCompsGroupProps) {
    const companyList = Object.keys(props.companies).map(i => props.companies[i]);
    const isCompsGroupEmpty = companyList.length === 0;
    const isSavingCompSet = useSelector(selectIsSavingUserCompSet);
    const companyGroupName = useSelector(selectCompsGroupName);
    const compsGroupId = useSelector(selectCompsGroupId);
    const dispatch = useDispatch<ThunkDispatch<RootState, any, Action>>();

    const handleOnRemoveCompany = (company: ICompany) => {
        dispatch(removeCompsFromGroup(company));
        dispatch(fetchCompSet());
    }

    function handleOnSaveCompSet() {
        dispatch(saveUserCompSet())
            .then(i => dispatch(fetchUserCompSets()));
    }

    function handleOnNameChange(text: string) {
        dispatch(setCompanyGroupName(text));
    }


    function handleOnClear() {
        dispatch(clearCompsGroup());
    }

    return (
        <Container className={'selected-comps-groups-container'}>
            <div className={'font-blue-modal-header header-name'}>Selected Comps</div>
            {
                isCompsGroupEmpty
                    ? <EmptyCompsGroupText/>
                    : <div className={'ticker-container'}>
                        <SaveWithNameButton
                            isEnabled={true}
                            onNameChange={(name) => handleOnNameChange(name)}
                            onSave={handleOnSaveCompSet}
                            text={companyGroupName}
                            placeHolder={'give your comps group a name'}
                            isLoading={isSavingCompSet}
                            isEditing={compsGroupId > 0}
                        />
                        <div className={'selected-comps-group'}>
                            {
                                companyList.map(company =>
                                    <Company
                                        key={company.ticker}
                                        comp={company}
                                        onRemoveCompany={() => handleOnRemoveCompany(company)}/>
                                )
                            }
                        </div>
                    </div>
            }
            <div className={'selected-comps-button'}>
                <SecondaryButton text={'Clear'} onClick={handleOnClear} isEnabled={true}/>
                <PrimaryButton text={props.buttonText} onClick={props.onClick} isEnabled={true}/>
            </div>
        </Container>
    )
}

function EmptyCompsGroupText() {
    return (
        <div className={"empty-comps-group-text"}>
            Your comps group is empty.
        </div>
    );
}


function Company(props: { comp: ICompany, onRemoveCompany: () => void }) {
    return (
        <>
            <div className={"remove-button roboto-regular-12"}>
                <MinusButton text={''} onClick={props.onRemoveCompany} isEnabled={true}/>
            </div>
            <div className={"ticker roboto-regular-12"}>
                {props.comp.ticker}
            </div>
        </>
    )
}
