import moment from "moment";

export function getLocalMonthDayYear(isoString: string): string {
    return getMomentFromIsoString(isoString)
        .local()
        .format('MM/DD/YYYY');
}

export function getMomentFromIsoString(isoString: string): moment.Moment {
    return moment(isoString, moment.ISO_8601);
}

export function getDateFromIsoString(isoString: string): Date {
    return getMomentFromIsoString(isoString).toDate();
}

export function getUtcNowIsoString(): string {
    return moment.utc().toISOString();
}

export function getUtcYearDayMonth(isoString: string, format: string = 'YYYY-MM-DD'): string {
    return getMomentFromIsoString(isoString)
        .format(format);
}


