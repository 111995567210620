import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {getTickers} from "../api/requests";
import {RootState} from "../store";
import {resetStateOnSignOut} from "../auth/signOut";
import {ITicker} from "../types";

interface ITickerPickerSlice {
    tickers: ITicker[],
    isLoading: boolean
}

interface ITickerPickerSliceState {
    compsTickerPickerSlice: ITickerPickerSlice;
}

export const fetchTickers = createAsyncThunk<ITicker[], {bypassCache: boolean}, {state: RootState}>(
    'compsTickerPickerSlice/fetchTickers',
    async (thunkAPI) => {
        return await getTickers();
    },
    {
       condition: ({bypassCache}, {getState}) => {
           const {isLoading, tickers} = getState().compsTickerPickerSlice;
           if(isLoading) {
               return false;
           }

           if(bypassCache){
              return true;
           }

           return tickers.length <= 0;
       }
    }
);

const initialState = {
    tickers: [] as ITicker[],
    isLoading: false
};

export const compsTickerPickerSlice = createSlice({
    name: 'compsTickerPickerSlice',
    initialState,
    reducers: {
    },
    extraReducers: builder => {
        builder.addCase(fetchTickers.fulfilled, (state, action) => {
            state.tickers = action.payload;
            state.isLoading = false;
        });

        builder.addCase(fetchTickers.rejected, state => {
            state.tickers = [] as ITicker[];
            state.isLoading = false;
        });

        builder.addCase(fetchTickers.pending, state => {
            state.isLoading = true;
        });

        resetStateOnSignOut(builder, initialState);
    },
});


export default compsTickerPickerSlice.reducer;

export const selectTickers = (state: ITickerPickerSliceState) =>
    state.compsTickerPickerSlice.tickers;

export const selectIsFetchingTickers = (state: ITickerPickerSliceState) =>
    state.compsTickerPickerSlice.isLoading;
