import Checkbox, {CheckboxState} from "../Checkbox/Checkbox";
import React from "react";

import './FilterPicker.scss';
import {PickerLevel} from "../../filterPicker/filterPickerUtility";

interface FilterPickerChildProps {
    className: PickerLevel
    checkboxState: CheckboxState
    handleOnClick: () => void
    label: string
}

export function FilterPickerChild(props: FilterPickerChildProps) {
    return (
        <div className={props.className}>
            <div className={'picker'}>
                <Checkbox
                    className={'picker-checkbox clickable'}
                    checkboxType={props.checkboxState}
                    onClick={props.handleOnClick}
                />
                <span className={"picker-label roboto-regular-12"}>
                    {props.label}
                </span>
            </div>
        </div>
    );
}
