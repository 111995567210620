import React, {useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {selectClaims} from '../../auth/authSlice';
import {getPlanType} from '../../billing/billingSlice';
import {FormInputTyped} from '../FormInput/FormInputTyped';
import {PrimaryButton} from "../Button/Button";
import {Col, Form, Row} from "reactstrap";
import './AddNewUser.scss';

function AddNewUser(props: any) {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [confirmEmail, setConfirmEmail] = useState('');
    const userClaims = useSelector(selectClaims);
    const planType = useSelector(getPlanType);

    const [isLoading, setIsloading] = useState(false)
    const [isEnabled, setIsEnabled] = useState(true)

    function enableButton() {
        setIsloading(false)
        setIsEnabled(true)
    }

    function disableButton() {
        setIsloading(true)
        setIsEnabled(false)
    }


    const handleChange = (dispatch: React.Dispatch<React.SetStateAction<string>>) =>
        (event: React.ChangeEvent<HTMLInputElement>) =>
            dispatch(event.target.value);

    const handleClick = () => {

        props.handleInviteUser(
            name,
            email,
            confirmEmail,
            enableButton,
            disableButton,
            props.setShowAddNewUserForm
        )

    }


    return (
        <>
            <Form className='row new-user'>
                <Col>
                    <FormInputTyped
                        for={'name'}
                        id={'name'}
                        value={''}
                        placeholder={'First and Last Name'}
                        onChange={handleChange(setName)}
                    >
                        Team Member First and Last Name
                    </FormInputTyped>
                </Col>
                <Col>
                    <FormInputTyped
                        for={'email'}
                        id={'email'}
                        value={''}
                        placeholder={'Email Address'}
                        onChange={handleChange(setEmail)}
                    >
                        Team Member Email Address
                    </FormInputTyped>
                </Col>
                <Col>
                    <FormInputTyped
                        for={'email'}
                        id={'email'}
                        value={''}
                        placeholder={'Email Address'}
                        onChange={handleChange(setConfirmEmail)}
                    >
                        Confirm Email Address
                    </FormInputTyped>
                </Col>
            </Form>
            <Row>
                <Col>
                    <PrimaryButton className={'inline-button'}
                                   text={'Invite Team Member'}
                                   onClick={handleClick}
                                   isEnabled={name != '' && email != '' && confirmEmail != '' && isEnabled}
                                   isLoading={isLoading}></PrimaryButton>
                </Col>
            </Row>

        </>
    );
}

export default AddNewUser;
