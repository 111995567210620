import {InvoicePreview, lineItem} from "../../../api/requestsTypes";
import {useInvoiceLogic} from "./UseInvoiceLogic";
import {createManagedUser} from "../../../api/requests";
import {fetchSubMgmtData} from "../../../billing/billingSlice";
import {OrderSummary} from "./OrderSummary";
import {PrimaryButton} from "../../Button/Button";
import {OrderInfo} from "./OrderInfo";
import React from "react";

interface SubscribeUserProps {
    invoice: InvoicePreview
    newUserEmail: string
    newUserName: string
    setShowInvoicePreview: (show: boolean) => void
    line_items: lineItem[]
    errors: string

}

export function OwnerSubscribeUserInvoice(props: SubscribeUserProps) {
    const {
        error, setError,
        isLoading, isEnabled,
        performAction
    } = useInvoiceLogic(props)
    const button_text = 'Subscribe'
    const desc_text_2 = `Press "Subscribe" to add ${props.newUserName} to your account! ` +
        `We will send an email to ${props.newUserEmail} with login instructions.  You can edit your team size at any time.`
    const due_text = 'Total due on next Invoice'
    const inviteManagedUser = performAction(
        inviteManagedUserAction,
        (response) => setError(String(response.error))
    )

    function inviteManagedUserAction() {
        let payload = {
            'fullName': props.newUserName,
            'email': props.newUserEmail
        }

        return createManagedUser(payload)
    }

    let orderSummary = <><OrderSummary
        errors={props.errors}
        updateError={error}
        descText1={''}
        descText2={desc_text_2}
        actionButton={
            <PrimaryButton className={'right-inline-button'}
                           text={button_text}
                           onClick={inviteManagedUser}
                           isEnabled={isEnabled}
                           isLoading={isLoading}/>
        }
        orderInfo={
            <OrderInfo
                lineItems={[{amount: '0', description: ''}]}
                dueText={due_text}
                total={'0'}/>
        }
    /></>;
    return orderSummary
}



export function SubscribeUserInvoice(props: SubscribeUserProps) {
    const {
        error, setError,
        isLoading, isEnabled,
        performAction
    } = useInvoiceLogic(props)
    const button_text = 'Subscribe'
    const desc_text_1 = 'Adding a Team Member to your account does not generate an immediate invoice. Your monthly subscription ' +
        ' total will be adjusted on the next billing cycle.'
    const desc_text_2 = `Press "Subscribe" to adjust your monthly subscription and add ${props.newUserName} to your account! ` +
        `We will send an email to ${props.newUserEmail} with login instructions.  You can edit your team size at any time.`
    const due_text = 'Total due on next Invoice'
    const inviteManagedUser = performAction(
        inviteManagedUserAction,
        (response) => setError(String(response.error))
    )

    function inviteManagedUserAction() {
        let payload = {
            'fullName': props.newUserName,
            'email': props.newUserEmail
        }

        return createManagedUser(payload)
    }

    return (
        <OrderSummary
            errors={props.errors}
            updateError={error}
            descText1={desc_text_1}
            descText2={desc_text_2}
            actionButton={
                <PrimaryButton className={'right-inline-button'}
                               text={button_text}
                               onClick={inviteManagedUser}
                               isEnabled={isEnabled}
                               isLoading={isLoading}/>
            }
            orderInfo={
                <OrderInfo
                    lineItems={props.line_items}
                    dueText={due_text}
                    total={props.invoice.next_invoice.total}/>
            }
        />
    )
}
