import React from 'react';
import "../SignupForm/SignupForm.scss";

export default function FormInput(props: any) {
    return (
        <label htmlFor={props.for} className="form-input">
            {props.children}
            <input
                className={props.className}
                type={props.type}
                id={props.id}
                placeholder={props.placeholder}
                name={props.name}
                onChange={e => props.onChange(e.target.value.toUpperCase())}
            />
        </label>
    );
}

