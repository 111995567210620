import {Row, Col} from 'reactstrap'
import {Image} from 'react-bootstrap'

function TeamMember(props: any) {
    return (
        <div className="profile card-style">
            <Row>
                <Col xl={3} lg={4} md={12} sm={12} className={'mb-3'}>
                    <div className={'avatar'}>
                        <Image roundedCircle={true} className={'avatar'} alt="avatar" src={props.avatar}></Image>
                    </div>
                </Col>
                <Col xl={9} lg={8} md={12} sm={12}>
                    <div className="bio">
                        <h2>{props.teamMember}</h2>
                        <p>{props.bio}</p>
                    </div>
                </Col>
            </Row>
        </div>
    );
}

export default TeamMember;
