import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {
    IAvailableColumn,
    IDistancingOptionsResponse,
    INearestBusinessesRequest,
    INearestBusinessesResponse
} from "../api/requestsTypes";
import {RootState} from "../store";
import {getDistancingOptions, getNearestBusinesses} from "../api/requests";
import {resetStateOnSignOut} from "../auth/signOut";
import {FilterResultRow, ITableHeader, ITicker, TickerItem} from "../types";
import {formatNumber} from "../utility/numberFormatter";

type NearestBusinesses = INearestBusinessesResponse|null;

export interface ICompAssistColumn extends IAvailableColumn{
    selected: boolean
}


interface ICompAssistSlice{
    isFetchingDistancingOptions: boolean
    isFetchingNearestBusinesses: boolean
    nearestBusinesses: NearestBusinesses
    ticker: TickerItem,
    availableColumns: ICompAssistColumn[],
    availableTickers: ITicker[]
}

interface ICompAssistSliceState{
    compAssistSlice: ICompAssistSlice
}

interface ITypeTableHeader extends ITableHeader{
   type: string
}


    function getTableRow(headers: ITypeTableHeader[] ,business: any) {
            let row: FilterResultRow = [];

            for (let header of headers) {
                if (business[header.id] === undefined) {
                    row.push({
                        column: header.id,
                        name: header.name,
                        value: "",
                        type: header.type
                    })
                    continue
                }

                row.push({
                    column: header.id,
                    name: header.name,
                    value: business[header.id],
                    type: header.type
                })
            }

            row.push({
                column: 'uid',
                name: 'uid',
                value: business.uid,
                type: 'text'
            })

            return row
    }


export const fetchDistancingOptions = createAsyncThunk<IDistancingOptionsResponse, void, {state: RootState}>(
    'compAssist/fetchDistancingOptions',
    async (_, thunkAPI) => {
        return await getDistancingOptions()
    },
    {
        condition: (_, {getState}) => {
            const {isFetchingDistancingOptions, availableColumns} = getState().compAssistSlice;

            if(isFetchingDistancingOptions){
                return false;
            }

            return true;
        }
    }
);

export const fetchNearestBussiness = createAsyncThunk<INearestBusinessesResponse, void, {state: RootState}>(
    'compAssist/fetchNearestBusiness',
    async(_, thunkAPI) => {
        const columns = thunkAPI.getState().compAssistSlice.availableColumns
            .filter((column) => column.selected)
            .map((column) => column.column_name);
        const ticker = thunkAPI.getState().compAssistSlice.ticker;
        const data: INearestBusinessesRequest = {
            target: {
                ticker: ticker.ticker,
                uid: ticker.uid
            },
            columns: columns
        }
        return await getNearestBusinesses(data);
    },
    {
        condition: (_, {getState}) => {
            const {isFetchingNearestBusinesses, ticker, availableColumns} = getState().compAssistSlice;

            if(isFetchingNearestBusinesses){
                return false
            }

            if(ticker.uid === -1){
                return false
            }

            if(availableColumns.filter((column) => column.selected).length === 0){
                return false
            }
        }
    }
);

const initialState: ICompAssistSlice = {
        isFetchingDistancingOptions: false,
        nearestBusinesses: null,
        isFetchingNearestBusinesses: false,
        ticker:{
            company_name: "",
            id: -1,
            ticker: "",
            uid: -1
        },
        availableColumns: [],
        availableTickers: []
}

function selectDefaultColumns(availableColumns: IAvailableColumn[]) {
                return availableColumns.map((column) => {
                    return {
                        ...column,
                        selected: column.default
                    }
                });
            }
export const compAssistSlice= createSlice({
    name: 'compAssistSlice',
    initialState: initialState,
    reducers: {
        setTicker: (state, action: PayloadAction<TickerItem>) => {
            state.ticker = action.payload
        },
        setColumn: (state, action: PayloadAction<ICompAssistColumn>) => {
            state.availableColumns = state.availableColumns.map((column) => {
                if(column.column_name === action.payload.column_name){
                    return action.payload
                }
                return column
            });
        },
        resetSelections: (state) => {
            state.availableColumns = selectDefaultColumns(state.availableColumns)
            state.ticker = initialState.ticker
        }
    },
    extraReducers: builder => {
        builder.addCase(fetchDistancingOptions.fulfilled, (state, action) => {
            state.availableColumns = selectDefaultColumns(action.payload.distancing_options.available_columns)
            state.availableTickers = action.payload.distancing_options.available_tickers
            state.isFetchingDistancingOptions = false
        });
        builder.addCase(fetchDistancingOptions.pending, (state, action) => {
            state.isFetchingDistancingOptions = true
        });
        builder.addCase(fetchDistancingOptions.rejected, (state, action) => {
            state.isFetchingDistancingOptions = false
        });
        builder.addCase(fetchNearestBussiness.fulfilled, (state, action) => {
            state.nearestBusinesses = action.payload
            state.isFetchingNearestBusinesses = false
        });
        builder.addCase(fetchNearestBussiness.pending, (state, action) => {
            state.isFetchingNearestBusinesses = true
        });
        builder.addCase(fetchNearestBussiness.rejected, (state, action) => {
            state.isFetchingNearestBusinesses = false;
        })

        resetStateOnSignOut(builder, initialState);
    }
});

export const selectSelectableColumnGroups = (state: ICompAssistSliceState): {[key: string]: ICompAssistColumn[]} => {
    // get a dictionary of columns that are selectable grouped by group
    if (state.compAssistSlice.availableColumns.length === 0){
        return {}
    }

    const selectableColumns = state.compAssistSlice.availableColumns
        .filter((column) => column.selectable)
        .reduce((acc, column) => {
            if(column.group === null){
                return acc
            }
            if(acc[column.group] === undefined){
                acc[column.group] = []
            }
            acc[column.group].push(column)
            return acc
        }, {} as {[key: string]: ICompAssistColumn[]});

    return selectableColumns
}

export default compAssistSlice.reducer;
export const {
    setTicker,
    setColumn,
    resetSelections
} = compAssistSlice.actions;

export const selectTicker = (state: ICompAssistSliceState): TickerItem => state.compAssistSlice.ticker;
export const selectTableData = (state: ICompAssistSliceState): {headers: ITableHeader[], rows: FilterResultRow[], subHeaders: ITableHeader[]} => {
    const {nearestBusinesses, availableColumns} = state.compAssistSlice;
    if(nearestBusinesses === null || nearestBusinesses === undefined){
        return {
            headers: [],
            subHeaders: [],
            rows: []
        }
    }

    const headers: ITypeTableHeader[] = availableColumns.map((column) => {
        return {
            id: column.column_name,
            name: column.display_name,
            type: column.type,
            highlight: column.selected
        }
    });

    if(nearestBusinesses.data.results === undefined){
        return {
            headers: headers,
            subHeaders: [],
            rows: []
        }
    }

    const subHeaders:ITableHeader[] = getTableRow(headers, nearestBusinesses.data.results[0])
        .map((column) => {
            return {
                id: column.column,
                name: formatNumber(column.value, column.type === 'integer_m'? 'integer_m_f': column.type),
                highlight: false,
            }
        });

    const rows = nearestBusinesses.data.results
        .slice(1)
        .map((business) => getTableRow(headers, business))

    return {
        headers: headers,
        subHeaders: subHeaders,
        rows: rows
    }
}

export const selectIsFetchingDistancingOptions = (state: ICompAssistSliceState): boolean => state.compAssistSlice.isFetchingDistancingOptions;
export const selectIsFetchingNearestBusinesses = (state: ICompAssistSliceState): boolean => state.compAssistSlice.isFetchingNearestBusinesses;
export const selectCanSearchForCompanies = (state: ICompAssistSliceState): boolean => {
    const {ticker, availableColumns} = state.compAssistSlice;
    if(ticker.uid === -1){
        return false
    }

    if(availableColumns.filter((column) => column.selected).length === 0){
        return false
    }

    return true
}

export const selectAvailableTickers = (state: ICompAssistSliceState): ITicker[] => state.compAssistSlice.availableTickers;
