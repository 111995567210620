import './CompsMultiplesTable.scss'
import '../Table/Table.scss'
import React from 'react';
import {useSelector} from "react-redux";
import {selectMultiples} from "../../comps/compsTableSlice";
import {formatNumber} from "../../utility/numberFormatter";
import {std} from 'mathjs';
import classNames from "classnames";
import {CompsTable} from "./CompsTableInput";


export default CompsMultiplesTable;


function CompsMultiplesTable() {
    const {multiples, values, type} = useSelector(selectMultiples);
    const rowFilter = ['Maximum', 'Upper Quartile', 'Average', 'Median', 'Lower Quartile', 'Minimum'];
    const headers = getHeaders(multiples);
    const allRows = getAllRows(values);
    const nonFixedRows = getNonFixedRows(allRows, rowFilter);
    const fixedRows = getFixedRows(allRows, rowFilter);
    const selectedMultiples = getRow('Median', values);
    const sizeAdjustment = getSizeAdjustment(headers);
    const sizeAdjustedMultiples = getSizeAdjustedMultiples(selectedMultiples, sizeAdjustment);
    const coefficientOfVariation = getCoefficientOfVariation(nonFixedRows, getRow('Average', values));

    return (
        <CompsTable className={'comps-conclusion-table'}>
            <div className={'comps-multiples-table'}>
                <Table
                    headers={headers}
                    nonFixedRows={nonFixedRows}
                    fixedRows={fixedRows}
                    selectedMultiples={selectedMultiples}
                    sizeAdjustment={sizeAdjustment}
                    sizeAdjustedMultiples={sizeAdjustedMultiples}
                    coefficientOfVariation={coefficientOfVariation}
                    datatype={type}/>
            </div>
        </CompsTable>
    )
}

interface ITableProps {
    headers: string[];
    nonFixedRows: (string | number)[][];
    fixedRows: (string | number)[][];
    selectedMultiples: (string | number)[];
    sizeAdjustment: number[];
    sizeAdjustedMultiples: (string | number)[];
    coefficientOfVariation: (string | number)[];
    datatype: string;
}

function Table(props: ITableProps) {
    return (
        <table className={'va-table'}>
            <thead>
            <tr>
                {
                    props.headers.map(header =>
                        <th>
                            {header}
                        </th>
                    )
                }
            </tr>
            </thead>
            <tbody>
            {
                props.nonFixedRows.length > 0 ?
                    <>
                        {
                            props.nonFixedRows
                                .map((row, index) =>
                                    <tr key={index}>
                                        {
                                            row.map((i, index) =>
                                                <td key={index}>
                                                    <div>{index === 0 ? i : formatNumber(i, props.datatype)}</div>
                                                </td>
                                            )
                                        }
                                    </tr>
                                )
                        }
                    </>
                    : null
            }
            {
                props.fixedRows.length > 0 ?
                    <>
                        {
                            props.fixedRows
                                .map((row, rowindex) =>
                                    <tr key={rowindex}>
                                        {
                                            row.map((i, columnindex) =>
                                                <td key={columnindex} className={`${rowindex === 0 ? 'line' : ''}`}>
                                                    <div>{columnindex === 0 ? i : formatNumber(i, props.datatype)}</div>
                                                </td>
                                            )
                                        }
                                    </tr>
                                )
                        }
                    </>
                    : null

            }
            {
                props.fixedRows.length > 0 ?
                    <>
                        <CalculatedRow
                            title={'Selected Multiples'}
                            row={props.selectedMultiples}
                            datatype={props.datatype}
                            border={true}
                        />
                        <CalculatedRow
                            row={props.coefficientOfVariation}
                            datatype={'percentage'}
                            title={'Coefficient Of Variation'}
                            border={false}
                        />
                    </>
                    : null
            }

            </tbody>
        </table>
    );
}

interface ICalculatedRowProps {
    row: (string | number)[];
    datatype: string;
    title: string;
    border: boolean;
}

function CalculatedRow(props: ICalculatedRowProps) {
    const showBorder = classNames({
        'line': props.border
    }) ;

    return (
        <tr>
            <td className={showBorder}>
                <div>{props.title}</div>
            </td>
            {
                props.row.map((i, index) =>
                    <td key={index} className={showBorder}>
                        <div>{formatNumber(i, props.datatype)}</div>
                    </td>
                )
            }
        </tr>
    );
}


function getSizeAdjustment(headers: string[]) {
    const sizeAdjustment = headers.map(i => 0.03);
    sizeAdjustment.pop();
    return sizeAdjustment;
}

function getRow(rowName: string, values: any[] | { [p: string]: number[] }[]): number[] {
    return values
        .filter(i => i[rowName])
        .flatMap(i => i[rowName]);
}

function getFixedRows(allRows: any[][], rowFilter: string[]) {
    return allRows.filter(i => rowFilter.indexOf(i[0].toString()) >= 0);
}

function getNonFixedRows(allRows: any[][], rowFilter: string[]) {
    return allRows.filter(i => rowFilter.indexOf(i[0].toString()) === -1);
}

function getHeaders(multiples: { name: string, heading: string }[]) {
    const headers = [
        multiples.length > 0 ? 'Company' : '',
        ...multiples.map(i => i.name + ' ' + i.heading)
    ];
    return headers;
}

function getAllRows(values: { [p: string]: number[] }[]) {
    const allRows = values.map(i => {
        const companyName = Object.keys(i)[0];
        const data = i[companyName];
        return [companyName, ...data];
    });
    return allRows;
}


function getSizeAdjustedMultiples(selectedMultiples: number[], sizeAdjustment: number[]): (string | number)[] {
    let result = [];
    for (let i = 0; i < selectedMultiples.length; i++) {
        try {
            let calculation = 1 / ((1 / selectedMultiples[i]) + sizeAdjustment[i]);
            result.push(calculation);
        } catch {
            result.push('N/A');
        }
    }
    return result;
}

function getCoefficientOfVariation(nonFixedRows: any[][], averages: number[]): (string | number)[] {
    let result: (string | number)[] = [];
    const numberOfRows = nonFixedRows.length;
    const numberOfColumns = nonFixedRows[0]?.length;

    if (!numberOfColumns || !numberOfColumns) {
        return result;
    }
    for (let column = 1; column < numberOfColumns; column++) {
        let columns = [];
        for (let row = 0; row < numberOfRows; row++) {
            const value = nonFixedRows[row][column];
            if (value != 'N/A') {
                columns.push(value);
            }
        }

        if (columns.length > 0) {
            let sum = 0;
            for (let i in columns) {
                sum += columns[i]
            }
            let avg = sum / columns.length

            if (avg != 0)
                result.push(std(columns) / avg * 100)
            else {
                result.push('N/A');
            }
        } else {
            result.push('N/A')
        }


    }

    return result;
}
