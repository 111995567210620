import React, {ReactNode} from "react";
import {useSelector} from "react-redux";
import {
    selectSelectedFilterState
} from "../../filtering/selectedFilterSlice";
import {FilterStateNumericRange} from "./FilterStateNumericRange";
import {FilterPickerSicCodes} from "../FilterPickerSicCodes/FilterPickerSicCodes";
import {FilterContainer} from "./FilterContainer";
import {getUid} from "../../filtering/filterStateLogic";
import {FilterPickerRegular} from "../FilterPickerRegular/FilterPickerRegular";
import {getPickerSelector} from "../../filterPicker/filterPickerUtility";
import {FilterState} from "../../filtering/filterStateTypes";
import FilterKeywordSearch from "./FilterKeywordSearch";

function FilterContainerInactive() {
    return (
        <FilterContainer isActive={false} >
            <div className={'filter-container-inactive'}>
                <span className={'font-place-holder'}>Select Criteria From Filter List</span>
            </div>
        </FilterContainer>
    )
}

function FilterContainerActive(props: { children: ReactNode }) {
    return (
        <FilterContainer isActive={true}>
            {props.children}
        </FilterContainer>
    )
}

function FilterStateComponentFactory(filter: FilterState) {
    const key = getUid(filter);
    switch (filter.type) {
        case "numeric":
            return <FilterStateNumericRange key={key} filter={filter}/>
        case "sicCodePicker":
            return <FilterPickerSicCodes key={key} filter={filter}/>
        case "picker":
            return <FilterPickerRegular
                key={key}
                filter={filter}
                stateSelector={getPickerSelector(filter.filterType)}/>
        case "keywordsearch":
            return <FilterKeywordSearch key={key} filter={filter}/>
    }
}

export function SelectedFiltersContainer() {
    const filters: FilterState[] = useSelector(selectSelectedFilterState);
    const hasFilters = filters.length > 0;

    return hasFilters ?
        <FilterContainerActive>
            {
                filters
                    .map(FilterStateComponentFactory)
            }
        </FilterContainerActive>
        : <FilterContainerInactive/>
}

export default SelectedFiltersContainer;
