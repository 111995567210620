import './SaveWithNameButton.scss';
import {SaveButton, SecondaryButton} from "../Button/Button";
import React, {useState} from "react";
import classNames from "classnames";

interface ContainerSaveButtonProps {
    text: string;
    onNameChange: (value: string) => void;
    onSave: () => void;
    isEnabled: boolean;
    placeHolder: string;
    isLoading: boolean;
    isEditing: boolean;
}

export function SaveWithNameButton(props: ContainerSaveButtonProps) {
    const [isSaveButtonClicked, setSaveButtonClicked] = useState(false);

    function getInputContainerCss(isSaveButtonClicked: boolean): string {
        return isSaveButtonClicked
            ? 'container-save-clicked'
            : 'container-save-not-clicked';
    }

    function handleSaveButtonClicked() {
        if (!props.isEnabled) {
            return;
        }

        setSaveButtonClicked(true)
    }

    const saveButtonClassName = classNames({
        clickable: props.isEnabled && !isSaveButtonClicked
    })


    return <div className={"container-save"}>
        {
            <div className={"container-save-input " + getInputContainerCss(isSaveButtonClicked)}>
               <SaveButton
                    isEnabled={props.isEnabled && !isSaveButtonClicked}
                    onClick={handleSaveButtonClicked}
                    text={''}
                    className={saveButtonClassName}
                    isEditing={props.isEditing}
                />
                {
                    isSaveButtonClicked || props.text !== ""
                        ? <>
                            <input
                                type="text"
                                value={props.text}
                                placeholder={props.placeHolder}
                                onChange={e => props.onNameChange(e.target.value)}
                            />
                            <SecondaryButton
                                text={"Save"}
                                onClick={props.onSave}
                                isEnabled={true}
                                isLoading={props.isLoading}
                            />
                        </>
                        : null
                }
            </div>
        }
    </div>;
}
