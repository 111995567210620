import React, {useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {updateAccountInformation} from '../../auth/authSlice';
import {FormInputTyped} from '../FormInput/FormInputTyped';
import {ResetPassword} from './ResetPassword'
import {Col, Form, Row} from 'reactstrap';
import './AccountInformation.scss'


import {selectAttributes} from '../../auth/authSlice';

function AccountInformation() {
    const userAttributes = useSelector(selectAttributes);

    const [fullName, setFullName] = useState('');
    const [email, setEmail] = useState('');
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');
    const [error, setError] = useState('');

    const dispatch = useDispatch();

    const saveChanges = (e: any) => {
        e.preventDefault();
        if (newPassword !== confirmNewPassword) {
            // Show error
            setError('Passwords do not match');
        } else {
            setError('');
            dispatch(updateAccountInformation({
                fullName: fullName,
                email: email,
                currentPassword: currentPassword,
                newPassword: newPassword
            }));
        }
    }

    return (
        <Form className="row acct-info-container">
            <h3>Account Information</h3>
            <FormInputTyped
                className="read-only"
                type="text"
                for="full-name"
                value={userAttributes.fullName}
                id="full-name"
                placeholder="Full Name"
                name="full-name"
                onChange={e => setFullName(e.target.value)}
            >
                Full Name *
            </FormInputTyped>
            <FormInputTyped
                className="read-only"
                type="email"
                for="email"
                value={userAttributes.email}
                id="email"
                placeholder="Email Address"
                name="email"
                onChange={e => setEmail(e.target.value)}
            >
                <p>Email Address *</p>
            </FormInputTyped>
            <p className={'note mb-5'}>* Contact <span className={'email'}>support@valueanalytics</span> to update name
                or email address.</p>
            <ResetPassword/>
        </Form>
    );
}

export default AccountInformation;
