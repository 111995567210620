import "react-datepicker/dist/react-datepicker.css";
import './DateSelector.scss'
import React from 'react';
import DatePicker from 'react-datepicker';
import {DatePickerIcon} from "../Svg/Svg";
import {InputBox} from "../InputBox/InputBox";



export type DateChange = Date | [Date, Date] | null;

export function DateSelector(props: {date: Date, onDateChange: (date: Date | null) => void}) {

    function handleChange(date: DateChange) {
        if (date === null) {
            props.onDateChange(null);
        } else if (Array.isArray(date)) {
            props.onDateChange(date[0])
        } else {
            props.onDateChange(date);
        }
    }

    return (
        <InputBox>
            <DatePickerIcon/>
            <DatePicker selected={props.date} onChange={handleChange} todayButton={"Select Today"}  />
        </InputBox>
    )
}
