import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit'
import {swapIndexes} from "../utility/utility";
import {getUid, initFilterState} from './filterStateLogic';
import * as _ from 'lodash';
import {FilterState} from "./filterStateTypes";
import {getFilterCounts, saveUserFilter} from "../api/requests";
import {RootState} from '../store';
import {FilterCountsResponse, ISavedFilter, SavedFilterResponse} from "../api/requestsTypes";
import {getFilterCountsRequest} from '../api/filterCountRequest';
import moment from 'moment';
import {IFilter} from './filter';
import {filter_checker} from './filterStateTypes'
import {normalizeNumber} from "../utility/numberFormatter";
import {resetStateOnSignOut, signOutAction} from '../auth/signOut';

interface ISelectedFilterSlice {
    filterId: number;
    filterName: string;
    selectedFilterState: FilterState[];
    isSaving: boolean;
    isCounting: boolean;
    errors: string[];
}

interface ISelectedFilterSliceState {
    selectedFilterSlice: ISelectedFilterSlice
}

export const saveSelectedFilter = createAsyncThunk<SavedFilterResponse,
    string,
    { state: RootState }>(
    'filter/saveSelectedFilters',
    async (filterName, thunkAPI) => {
        const {filterId, selectedFilterState} = thunkAPI.getState().selectedFilterSlice;
        const saveFilter: ISavedFilter = {
            filterId: filterId,
            filterName: filterName,
            filterStates: selectedFilterState,
            isoString: moment.utc().toISOString()
        }
        return await saveUserFilter(saveFilter);
    }
);

export const validateFilterValues = (filterState:  FilterState[]): string[] => {
    let bad_filters: string[] = []
    filterState.forEach(filter=>{
        let good_filter = filter_checker(filter)
        if (!good_filter){
            bad_filters.push(filter.displayName)
        }
    })

    return bad_filters

}



export const getSelectedFilterCounts = createAsyncThunk<FilterCountsResponse, void, { state: RootState }>(
    'filter/getSelectedFilterCounts',
    async (nothing, thunkAPI) => {
        const {selectedFilterState} = thunkAPI.getState().selectedFilterSlice;

        let bad_filters = validateFilterValues(selectedFilterState)

        if(selectedFilterState.length <= 0) {
            return [];
        }

        if (bad_filters.length > 0){
            return thunkAPI.rejectWithValue(bad_filters)
        }


        const request = getFilterCountsRequest(selectedFilterState)
        return await getFilterCounts(request);
    }
);

const initialState: ISelectedFilterSlice  = {
        filterId: 0,
        filterName: '',
        selectedFilterState: [],
        isSaving: false,
        isCounting: false,
        errors: []
};

export const selectedFilterSlice = createSlice({
    name: 'selectedFilterSlice',
    initialState,
    reducers: {
        addSelectedFilterState: (state, action: PayloadAction<IFilter>) => {
            const index = state.selectedFilterState.length;
            const filterState = initFilterState(index, action.payload);
            const uidIsInList = _
                .some(state.selectedFilterState,
                    filter => getUid(filter) === getUid(filterState)
                );
            if (!uidIsInList) {
                state.selectedFilterState.push(filterState);
            }
        },
        loadSavedFilter: (state, action: PayloadAction<ISavedFilter>) => {
            const savedFilter = action.payload;
            state.filterId = savedFilter.filterId;
            state.filterName = savedFilter.filterName;
            state.selectedFilterState = savedFilter.filterStates;
            state.isSaving = false;
            state.isCounting = false;
            state.errors = [];
        },
        removeSelectedFilterState: (state, action: PayloadAction<FilterState>) => {
            state.selectedFilterState.splice(action.payload.ordinal, 1);
            for (let i = action.payload.ordinal; i < state.selectedFilterState.length; i++) {
                state.selectedFilterState[i].ordinal = i;
            }
        },
        clearSelectedFilterState: (state) => {
            return initialState;
        },
        moveDownSelectedFilterState: (state, action: PayloadAction<FilterState>) => {
            let filters = state.selectedFilterState;
            let index = action.payload.ordinal;

            if (index >= filters.length - 1) {
                return;
            }

            swapIndexes(filters, index, index + 1);
            filters[index].ordinal = index;
            filters[index + 1].ordinal = index + 1;
        },
        moveUpSelectedFilterState: (state, action: PayloadAction<FilterState>) => {
            let index = action.payload.ordinal;

            if (index <= 0) {
                return;
            }

            swapIndexes(state.selectedFilterState, index, index - 1);
            state.selectedFilterState[index].ordinal = index;
            state.selectedFilterState[index - 1].ordinal = index - 1;
        },
        updateSelectedFilterState: (state, action: PayloadAction<FilterState>) => {
            state.selectedFilterState[action.payload.ordinal] = action.payload;
        },
        updateSelectedFilterName: (state, action: PayloadAction<string>) => {
            state.filterName = action.payload;
        }
    },
    extraReducers: builder => {
        builder.addCase(saveSelectedFilter.rejected, (state, action) => {
            state.isSaving = false;
            state.errors = ["failed to save filter"];
        });
        builder.addCase(saveSelectedFilter.pending, (state, action) => {
            state.isSaving = true;
        });
        builder.addCase(saveSelectedFilter.fulfilled, (state, action) => {
            state.isSaving = false;
            switch (action.payload.status) {
                case "success":
                    state.filterId = action.payload.filterId;
                    state.errors = [];
                    break;
                case "error":
                    state.errors = action.payload.errors;
                    break;
            }
        });
        builder.addCase(getSelectedFilterCounts.fulfilled, (state, action) => {
            const counts = action.payload;
            for (let i = 0; i < counts.length; i++) {
                state.selectedFilterState[i].filterCount = counts[i];
            }
            state.isCounting = false;
            state.errors = [];
        });
        builder.addCase(getSelectedFilterCounts.pending, (state, action) => {
            state.isCounting = true;
        });
        builder.addCase(getSelectedFilterCounts.rejected, (state, action:any) => {
            // wrap this in a try/catch block since we can't
            // guarantee action will contain our bad_filters payload
            let error_message : string;
            try {

                let plural: string = action.payload.length > 1 ? 's' : ''
                error_message = `No value provided for filter${plural}: ${action.payload.join(', ')}.
                                         Please provide value${plural} or remove filter${plural}.`
            } catch(error){
                error_message = ""
            }
            state.isCounting = false;
            state.errors = [error_message];
        });

        resetStateOnSignOut(builder, initialState);
    }
});

export const {
    addSelectedFilterState,
    loadSavedFilter,
    removeSelectedFilterState,
    clearSelectedFilterState,
    moveDownSelectedFilterState,
    moveUpSelectedFilterState,
    updateSelectedFilterState,
    updateSelectedFilterName
} = selectedFilterSlice.actions;

export const selectSelectedFilterState = (state: ISelectedFilterSliceState): FilterState[] =>
    state.selectedFilterSlice.selectedFilterState;

export const selectIsSaving = (state: ISelectedFilterSliceState): boolean =>
    state.selectedFilterSlice.isSaving;

export const selectErrors = (state: ISelectedFilterSliceState): string[] =>
    state.selectedFilterSlice.errors;

export const selectIsCounting = (state: ISelectedFilterSliceState): boolean =>
    state.selectedFilterSlice.isCounting;

export const selectFilterName = (state: ISelectedFilterSliceState): string =>
    state.selectedFilterSlice.filterName;

export const selectSelectedFilterId = (state: ISelectedFilterSliceState): number =>
    state.selectedFilterSlice.filterId;

export default selectedFilterSlice.reducer
