import './SizePremiumsPage.scss';
import SmallScreenAlert from "../Utility/SmallScreenAlert";
import {Col, Container, Nav, NavItem, Row, Collapse, Navbar, NavbarToggler} from "reactstrap";
import {NavLink as NavLinkRouter, Route, Switch} from "react-router-dom";
import {Routes} from "../../routes/routes";
import Premiums from "./Premiums";
import Methodology from "./Methodology";
import {useEffect, useState} from "react";
import WithActiveSubscription from "../PadLock/WithActiveSubscription";
import {useDispatch, useSelector} from "react-redux";
import {fetchSizePremiums, selectSizePremiumsSlice} from "../../sizePremiums/sizePremiumsSlice";
import {ISizePremium} from "../../sizePremiums/sizePremium";
import {Loader} from "../Loader/Loader"

function PremiumNavItem(props: {
    route: string,
    displayName: string,
    handleClick: () => void
}) {
    return (
        <NavItem>
            <NavLinkRouter
                to={props.route}
                activeClassName="selected"
                className={'nav-link'}
                onClick={props.handleClick}
            >
                {props.displayName}
            </NavLinkRouter>
        </NavItem>
    )
}

interface IPremiumNavGroupItem {
    route: string
    premium: ISizePremium
}

function PremiumGroup(props: { group: IPremiumNavGroupItem[], onClickHandler: () => void }) {
    return (
        <div className={'premium-group'}>
            {
                props.group.map((i) => (
                    <PremiumNavItem key={i.route}
                                    route={i.route}
                                    displayName={i.premium.title}
                                    handleClick={props.onClickHandler}/>
                ))
            }
        </div>
    )
}

function PremiumRoute(props: {
    children: React.ReactNode,
    isLoading: boolean
}) {
    return (
        <WithActiveSubscription fullscreen={false}>
            <Loader isLoading={props.isLoading}></Loader>
            {props.children}
        </WithActiveSubscription>
    )
}


export default function SizePremiumsPage() {
    const [isOpen, setIsOpen] = useState(false);
    const sizePremiumsSlice = useSelector(selectSizePremiumsSlice)
    const dispatch = useDispatch()
    const premiums =
        {
            group1: [
                {
                    route: Routes.sizePremiums.normalizedRiskFreeRates,
                    premium: sizePremiumsSlice.sizePremiums.normalizedRiskFreeRates
                },
                {
                    route: Routes.sizePremiums.equityRiskPremium,
                    premium: sizePremiumsSlice.sizePremiums.equityRiskPremium
                }
            ],
            group2: [
                {
                    route: Routes.sizePremiums.baselineSizePremiums,
                    premium: sizePremiumsSlice.sizePremiums.baselineSizePremium
                },
                {
                    route: Routes.sizePremiums.historicalERP,
                    premium: sizePremiumsSlice.sizePremiums.historicalERP
                },
                {
                    route: Routes.sizePremiums.equallyWeightedReturns,
                    premium: sizePremiumsSlice.sizePremiums.equallyWeightedReturns
                },
                {
                    route: Routes.sizePremiums.fortyYearEstimationWindow,
                    premium: sizePremiumsSlice.sizePremiums.fortyYearEstimationWindow
                },
                {
                    route: Routes.sizePremiums.geometricAverageReturns,
                    premium: sizePremiumsSlice.sizePremiums.geometricAverageReturns
                },
                {
                    route: Routes.sizePremiums.fiveYearTreasuryRateAsRiskFreeRate,
                    premium: sizePremiumsSlice.sizePremiums.fiveYearTreasuryRateAsRiskFreeRate
                },
                {
                    route: Routes.sizePremiums.bloomAdjustedBeta,
                    premium: sizePremiumsSlice.sizePremiums.blumeAdjustedBeta
                }
            ]
        }


    useEffect(() => {
        dispatch(fetchSizePremiums())
    }, []);

    function toggleNav() {
        setIsOpen(!isOpen);
    }

    function closeNav() {
        setIsOpen(false);
    }

    return (
        <div className={'size-premiums-page'}>
            <SmallScreenAlert/>
            <Container className={'my-5 full-height'}>
                <Row className={'gx-5 card-style full-height'}>
                    <Col xl={3} lg={3} md={12} className={'pb-5 left-side-nav-container'}>
                        <Navbar expand={'lg'} light>
                            <NavbarToggler onClick={toggleNav}/>
                            <Collapse isOpen={isOpen} navbar>
                                <Nav vertical>
                                    <div className={'premium-group'}>
                                        <PremiumNavItem route={Routes.sizePremiums.methodology}
                                                        displayName={sizePremiumsSlice.methodology.title}
                                                        handleClick={closeNav}/>
                                    </div>
                                    <PremiumGroup group={premiums.group1} onClickHandler={closeNav}/>
                                    <PremiumGroup group={premiums.group2} onClickHandler={closeNav}/>
                                </Nav>
                            </Collapse>
                        </Navbar>
                    </Col>
                    <Col className={'table-container'}>
                        <Switch>
                            <Route exact path={Routes.sizePremiums.methodology}>
                                <Methodology
                                    title={sizePremiumsSlice.methodology.title}
                                    description={sizePremiumsSlice.methodology.description}
                                    data={sizePremiumsSlice.methodology.data}
                                />
                            </Route>
                            {
                                premiums.group1.concat(premiums.group2).map(i =>
                                    <Route key={i.route} exact path={i.route}>
                                        <PremiumRoute isLoading={sizePremiumsSlice.isLoadingSizePremiums}>
                                            <Premiums
                                                title={i.premium.title}
                                                data={i.premium.data}/>
                                        </PremiumRoute>
                                    </Route>)
                            }
                        </Switch>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}
