import './CompsPage.scss';
import React, {useEffect, useState} from 'react'
import CompsSavedCompsGroups from './CompsSavedCompsGroups';
import CompsSelectedCompsGroup from "../CompsSelectedCompsGroup/CompsSelectedCompsGroup";
import {useDispatch, useSelector} from "react-redux";
import {selectSelectedCompsGroup} from "../../comps/compsGroupSlice";
import {CompsTableContainer} from "../CompsTable/CompsTableContainer";
import {Modal} from '../Modal/Modal';
import classNames from 'classnames';
import {CompsConclusionTable} from "../CompsTable/CompsConclusionTable";
import CompsMultiplesTable from "../CompsTable/CompsMultiplesTable";
import FinancialHighlightsTable from "../CompsTable/FinancialHighlightsTable";
import RanksTable from '../CompsTable/RanksTable';
import BusinessDescriptionTable from '../CompsTable/BusinessDescriptionTable';
import {fetchCompSet, selectTableData, selectCompanyNetDebt, selectRowDictionary} from "../../comps/compsTableSlice";
import {selectValuationDate} from "../../comps/compsGroupSlice";
import { exportFromCompSet } from '../../exportProcess/exporter';
import {fetchUserCompSets} from "../../comps/savedCompsSets";
import SmallScreenAlert from "../Utility/SmallScreenAlert";
import {CompsTickerPicker} from "./CompsTickerPicker";
import {fetchTickers} from "../../comps/compsTickerPickerSlice";

function CompsPage() {
    const companyGroup = useSelector(selectSelectedCompsGroup)
    const [showColumnEditor, setShowColumnEditor] = useState(false);
    const [showTickerPicker, setShowTickerPicker] = useState(false);
    const [isExporting, setIsExporting] = useState(false);

    const tableData = useSelector(selectTableData);
    const valuationDate = useSelector(selectValuationDate);
    const companyNetDebt = useSelector(selectCompanyNetDebt);
    const userInputDictionary = useSelector(selectRowDictionary);

    const companyList = Object.keys(companyGroup)
        .map(i => companyGroup[i])
        .map((comp, index) => ({
            companyId: index,
            ticker: comp.ticker
        }));
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchCompSet());
        dispatch(fetchUserCompSets());
        dispatch(fetchTickers({bypassCache: true}));
    }, []);


    const tabs = {
        "Conclusion": {
            title: 'Indication of Value',
            table: () => <CompsConclusionTable/>
        },
        "Multiples": {
            title: "Pricing Multiples",
            table: () => <CompsMultiplesTable/>
        },
        "Financial Highlights": {
            title: 'Financial Highlights and Ratio Analysis',
            table: () => <FinancialHighlightsTable/>
        },
        "Ranks": {
            title: 'Relative Ranking',
            table: () => <RanksTable/>
        },
        "Desc": {
            title: 'Business Description',
            table: () => <BusinessDescriptionTable/>
        },
    };
    const compsPageClassName =
        classNames({
            'blur': showColumnEditor || showTickerPicker,
        });

    function handleOnShowColumnEditor() {
        return setShowColumnEditor(true);
    }

    function handleOnCloseColumnEditor() {
        return setShowColumnEditor(false);
    }

    function handleOnCloseTickerPicker() {
        return setShowTickerPicker(false);
    }

    function handleOnShowTickerPicker() {
        return setShowTickerPicker(true);
    }

    function handleOnExportButtonClicked() {
        setIsExporting(true);

        // currently there is no way to know which company failed to return data from the api
        // therefore we must use the tableData.descriptions to determine how many companies are
        // actually being exported.
        const companyCount = tableData?.descriptions
            ? tableData?.descriptions.length
            : 0

        exportFromCompSet(companyCount, tableData, valuationDate, companyNetDebt, userInputDictionary)
            .finally(() => {
               setIsExporting(false);
            });
    }

    return (
        <>
            <SmallScreenAlert/>
            <div className={classNames('va-page comps-page py-5',compsPageClassName)}>
                <div className={'comps-page-saved-comps-groups'}>
                    <CompsSavedCompsGroups />
                </div>
                <div className={'comps-page-selected-comps-group'}>
                    <CompsSelectedCompsGroup
                        companies={companyGroup}
                        onClick={handleOnShowTickerPicker}
                        buttonText={'Add Ticker'}
                    />
                </div>
                {
                    companyList.length > 0 ?
                        <div className={'comps-page-table'}>
                            <CompsTableContainer tabs={tabs}
                            onShowColumnEditor={handleOnShowColumnEditor}
                            onExportButtonClicked={handleOnExportButtonClicked}
                            isExporting={isExporting}/>
                        </div>
                        : null
                }
            </div>
            {
                showColumnEditor
                    ? (
                        <Modal onCloseModal={handleOnCloseColumnEditor}>
                        </Modal>
                    )
                    : null
            }
            {
                showTickerPicker
                    ? (
                        <Modal onCloseModal={handleOnCloseTickerPicker}>
                            <CompsTickerPicker/>
                        </Modal>
                    )
                    : null
            }
        </>
    )
}

export default CompsPage;
