import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {IFilter, IFilterDictionary} from "./filter";
import {resetStateOnSignOut} from "../auth/signOut";

interface IPinnedFiltersSlice {
   filters: IFilterDictionary,
}

interface IPinnedFiltersSliceState {
    pinnedFilterSlice: IPinnedFiltersSlice;
}

const initialState: IPinnedFiltersSlice = {
    filters: {} as IFilterDictionary
};

export const pinnedFilterSlice = createSlice({
    name: 'pinnedFilterSlice',
    initialState,
    reducers: {
        addPinnedFilter: (state, action: PayloadAction<IFilter>) => {
            if (state.filters[action.payload.value] === undefined) {
                state.filters[action.payload.value] = action.payload;
            }
        },
        removePinnedFilter: (state, action: PayloadAction<IFilter>) => {
            delete state.filters[action.payload.value];
        },
    },
    extraReducers: builder => {
        resetStateOnSignOut(builder, initialState);
    }
});


export const {
    addPinnedFilter,
   removePinnedFilter
} = pinnedFilterSlice.actions;

export const selectPinnedFilters = (state: IPinnedFiltersSliceState) =>
    state.pinnedFilterSlice.filters;

export const selectIsFilterPinned = (name: string) => (state: IPinnedFiltersSliceState): boolean =>
    state.pinnedFilterSlice.filters[name] !== undefined;

export default pinnedFilterSlice;


