import React from 'react';


interface FeatureProps {
    heading: string
    children: React.ReactNode
    src: string
}

function Feature(props: FeatureProps) {
  return (
    <div className="feature">
     {
        props.src
          ? <div className="feature-icon"><img src={props.src} alt="" /></div>
          : ""
      }
      <h4>{props.heading}</h4>
      <p>{props.children}</p>
    </div>
  );
}

export default Feature;