import './BusinessDescription.scss';
import React from 'react';
import {useSelector} from "react-redux";
import {selectDescriptions} from "../../comps/compsTableSlice";
import {CompsTable} from "./CompsTableInput";

export default BusinessDescriptionTable;

function BusinessDescriptionTable() {
    const descriptions = useSelector(selectDescriptions);

    return (
        <CompsTable className={'business-description'}>
            <table className={'va-table'}>
                <thead>
                <tr>
                    <th>Company</th>
                    <th>Ticker</th>
                    <th>Description</th>
                </tr>
                </thead>
                <tbody>
                {
                    descriptions.map(i =>
                        <tr key={i.company}>
                            <td>{i.company}</td>
                            <td>{i.ticker}</td>
                            <td>{i.description}</td>
                        </tr>
                    )
                }
                </tbody>
            </table>
        </CompsTable>
    )
}
