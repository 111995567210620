import './TickerPicker.scss';
import React, {useState} from "react";
import FilterSearchBar from "../FilterContainer/FilterSearchBar";
import {List} from "../List/List";
import {Loader} from '../Loader/Loader';
import {ITicker, TickerItem} from "../../types";

function getTickerItems(tickers: ITicker[]) {
    return tickers.map(i => ({
        ...i,
        id: i.uid,
        key: i.uid
    }));
}

interface TickerPickerItemProps {
    ticker: TickerItem;
    onTickerPicked: (ticker: TickerItem) => void;
}

export function TickerPickerItem(props: TickerPickerItemProps) {
    return (
        <tr
            className={'clickable'}
            onClick={() => props.onTickerPicked(props.ticker)}>
            <td className={'ticker-item ticker'}>
                {props.ticker.ticker}
            </td>
            <td className={'ticker-item'}>
                {props.ticker.company_name}
            </td>
        </tr>
    );
}

export function TickerPicker(props: {
    onTickerPicked: (ticker: TickerItem) => void,
    excludeTickers?: number[],
    tickers: ITicker[],
    isLoading: boolean
}) {
    const tickerList = getTickerItems(props.tickers);
    const [searchText, setSearchText] = useState('');

    const mapper = (ticker: TickerItem) =>
        <TickerPickerItem ticker={ticker} onTickerPicked={props.onTickerPicked}/>

    function filterTickers(): TickerItem[] {
        let filteredTickers = tickerList
            .sort((a, b) => (a.ticker > b.ticker) ? 1 : -1);

        if(props.excludeTickers &&
            props.excludeTickers.length > 0){
            filteredTickers = filteredTickers.filter(i => props.excludeTickers?.indexOf(i.uid) === -1)
        }

        if (searchText.length) {
            filteredTickers = filteredTickers
                .filter(i => i.ticker
                    .toLowerCase()
                    .includes(searchText.toLowerCase()))
        }

        return filteredTickers;
    }

    return (
        <div className={'ticker-picker'}>
            <FilterSearchBar handleSearch={setSearchText} placeholder={'search for a ticker ...'}/>
            <Loader isLoading={props.isLoading}/>
            <List headers={["Ticker", "Company Name"]} items={filterTickers()} children={mapper}/>
        </div>
    );
}

