import {IIndustryGroupState, IIndustryState} from "../filtering/sicCodeState";
import {FilterPickerIndustryGroupProps, FilterPickerSectorProps} from "./filterStatePickerTypes";
import * as _ from "lodash";
import {includesString} from "./filterPickerUtility";

export function industrySearchFilter(props: FilterPickerIndustryGroupProps) {
    return (i: IIndustryState) =>
        includesString(props.searchText, i.industry_name) ||
        includesString(props.searchText, i.sic_code);
}

export function industryGroupSearchFilter(props: FilterPickerSectorProps) {
    return (i: IIndustryGroupState) =>
        includesString(props.searchText, i.industry_group_name) ||
        _.some(i.industries, i => includesString(props.searchText, i.industry_name));
}
