import React, {useState} from 'react';
import FormInput from '../FormInput/FormInput';
import {useDispatch, useSelector} from "react-redux";
import {selectPlanDetails, checkPromoCode} from "../../signup/signUpSlice";
import {Action, ThunkDispatch} from "@reduxjs/toolkit";
import {RootState} from "../../store";
import classNames from "classnames"



function TrialOrderSummary(props: any) {
  const planDetails = useSelector(selectPlanDetails);
  const dispatch = useDispatch<ThunkDispatch<RootState, any, Action>>();

  return (
      <div className="order-summary-sign">
      <div className="order-info">
        <div className={'left'}>
          {
            `${planDetails.title} Free Trial`
          }
        </div>
        <div className={'right'}>
          <p>
          </p>
        </div>
        <div>
          <p className={'bold'}>Total due now</p>
        </div>
        <div>
          <p className={'bold'}>{'$0.00'}</p>
        </div>

        </div>
      <p className={'underlined'}>
        {planDetails.title} Free Trial will expire after {planDetails.trial_period_days} days</p>
    </div>
  )
}

function PaidOrderSummary(pops: any) {

  const [promoCode, setPromoCode] = useState('');
  const planDetails = useSelector(selectPlanDetails);
  const dispatch = useDispatch<ThunkDispatch<RootState, any, Action>>();

  function handleChange(code: string) {
    setPromoCode(code)
  }

  function applyPromo() {
    dispatch(checkPromoCode(
        {
          promoCode:promoCode,
          planDetails:planDetails
        }
    ))

  }

  const leftHeader = classNames({
    'left': true,
    'coupon-applied': planDetails.coupon_applied
  })

  const rightHeader = classNames({
    'right': true,
    'coupon-applied': planDetails.coupon_applied
  })

  return (
    <div className="order-summary-sign">
      <div className="order-info">
        <div className={leftHeader}>
          {
            planDetails.coupon_applied ?
            planDetails.coupon_display_name:
            planDetails.title
          }
        </div>
        <div className={rightHeader}>
          <p>{
              planDetails.coupon_applied ?
              planDetails.coupon_price + ' ' + planDetails.sub_period.toLowerCase():
              planDetails.display_price + ' ' + planDetails.sub_period.toLowerCase()
             }
          </p>
        </div>
        <div>
          <p className={'bold'}>Total due now</p>
          <p className={'bold'}>{planDetails.trial_period ?
              `Total due after ${planDetails.trial_period_days} days` : ''}</p>
          <p className={'bold'}>{planDetails.billing_frequency}</p>
        </div>
        <div>
          <p className={'bold'}>{planDetails.coupon_applied ?
                  planDetails.coupon_price:
                  planDetails.display_due_now}</p>
          <p className={'bold'}>{
              planDetails.billing_frequency.includes('once') ? '' :
              planDetails.coupon_applied ?
                  planDetails.coupon_price:
                  planDetails.display_price
              }</p>

        </div>

        </div>
      <p className={'underlined'}>{planDetails.description}</p>
      { planDetails.coupon_available &&
        <div className="promo">
          <p>Promo</p>
          <div className="promo-code">
            <FormInput
              for="promo"
              type="text"
              name="promocode"
              id="promo"
              placeholder="Promo Code"
              onChange={handleChange}
            />
            <a onClick={applyPromo}
            >Apply</a>
          </div>
          <br></br>
        </div>

      }
      {
        'promo_attempt_failed' in planDetails ?
            planDetails.promo_attempt_failed ?
                <p className={"coupon-failed"}>Incorrect Promo Code</p>:
                <p className={"coupon-applied"}>Promo Code Applied!</p>:
            ""
      }
    </div>
  );

}


function OrderSummary(props: any) {

  const planDetails = useSelector(selectPlanDetails);

  return (
      planDetails.trial_period ? <TrialOrderSummary/> : <PaidOrderSummary/>
  )
}

export default OrderSummary;