import React from 'react'
import {InputBox} from "../InputBox/InputBox";
import {MoneyIcon} from "../Svg/Svg";

interface InputMoneyProps {
    onChange: (text: string) => void;
    value: string;
}

export function InputMoney(props: InputMoneyProps) {
    return (
        <InputBox>
            <MoneyIcon/>
            <input
                type={'text'}
                value={props.value}
                onChange={(e) => props.onChange(e.target.value)}
            />
        </InputBox>
    )
}

