export type FilterState
    = INumericFilter
    | ISicCodePicker
    | IKeywordSearchFilter
    | IPickerFilter;

export interface IFilterStateDisplayInfo {
    ordinal: number;
    displayName: string;
    filterCount: number;
    displayType: string;
}

export interface INumericFilter extends IFilterStateDisplayInfo {
    type: "numeric";
    column: string;
    filterType: string;
    range: [number, number | null];
}

export type RegularFilterPickers = "exchanges" | "state_hq" | "years_of_data";
export type KeywordSearchFilters = 'business_description';

export interface IPickerFilter extends IFilterStateDisplayInfo {
    type: "picker";
    filterType: RegularFilterPickers;
    value: string[];
}

export interface ISicCodePicker extends IFilterStateDisplayInfo {
    type: "sicCodePicker";
    sector_id: string[];
    industry_group_id: string[];
    sic_code: string[];
}

export interface IKeywordSearchFilter extends IFilterStateDisplayInfo {
    type: "keywordsearch";
    filterType: KeywordSearchFilters;
    value: string;
}

export function filter_checker(filter: FilterState): boolean {

    switch (filter.type) {

        case "numeric": {
            return !isNaN(filter.range[0]) && !isNaN(filter.range[0])
        }
        case "picker": {
            return filter.value.length > 0
        }
        case "sicCodePicker": {
            return filter.sector_id.length > 0 || filter.industry_group_id.length > 0 || filter.sic_code.length > 0
        }
        case "keywordsearch": {
            return filter.value != ""

        }

    }

}

