import React, {ReactNode, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {PrimaryButton, SecondaryButton} from "../Button/Button";
import trash from "../../img/trash.svg";
import './FilterStateContainer.scss';
import {
    getSelectedFilterCounts,
    moveDownSelectedFilterState,
    moveUpSelectedFilterState,
    removeSelectedFilterState, selectIsCounting
} from "../../filtering/selectedFilterSlice";
import {FilterState} from "../../filtering/filterStateTypes";
import {BxsMoveFilterDownArrow, BxsMoveFilterUpArrow} from "../Svg/Svg";
import {selectSelectedFilterState} from "../../filtering/selectedFilterSlice";
import {Loader} from "../Loader/Loader";
import {clearErrorMessage} from "../../filtering/filterTableSlice";
import {InfoTip} from "../Utility/InfoTip";


export function FilterStateContainer(props: {
    filter: FilterState,
    onDeleteFilter: () => void,
    children: ReactNode
}) {
    const [isClosed, setIsClosed] = useState(true)
    const [editText, setEditText] = useState('Edit')
    const dispatch = useDispatch()
    const isCounting = useSelector(selectIsCounting)
    const selectedFilters = useSelector(selectSelectedFilterState)

    function handleOpenClose() {
        if (isClosed) {
            setIsClosed(false)
            setEditText('Close')
        } else {
            setIsClosed(true)
            setEditText('Edit')
        }

    }

    function handleOnTrashClicked() {
        if (isCounting) {
            return;
        }
        props.onDeleteFilter();
        dispatch(removeSelectedFilterState(props.filter));
    }

    function handleOnMoveDown() {
        if (isCounting) {
            return;
        }
        dispatch(moveDownSelectedFilterState(props.filter));
        dispatch(getSelectedFilterCounts())
    }

    function handleOnMoveUp() {
        if (isCounting) {
            return;
        }
        dispatch(moveUpSelectedFilterState(props.filter));
        dispatch(getSelectedFilterCounts())
    }

    function handleOnApplyClicked() {
        dispatch(clearErrorMessage())
        dispatch(getSelectedFilterCounts());
    }

    return (
        <div className={'filter-state-container'}>
            <div className={'filter-header'}>
                <div className={'filter-move-arrows'}>
                    <button
                        className={selectedFilters.length > 1 ? 'filter-move-arrow-up clickable' : 'Disabled'}
                        onClick={handleOnMoveUp}
                    >
                        <BxsMoveFilterUpArrow/>
                    </button>
                    <button
                        className={selectedFilters.length > 1 ? 'filter-move-arrow-down clickable' : 'Disabled'}
                        onClick={handleOnMoveDown}
                    >
                        <BxsMoveFilterDownArrow/>
                    </button>
                </div>
                <span className={'roboto-regular-12 font-valign'}>{props.filter.displayName}</span>
                 <div>
                    <span className={'roboto-blue-underlined-12-inline font-valign'} id={"match-count"}>
                        {props.filter.filterCount} Matches



                    </span>
                    <InfoTip message={
                            "Matches are businesses that meet the filtering criteria for this filter and previously applied filters." +
                            " Continue adding filters to reduce the number of matches."
                        } placement={"right"} size={15} id={'1'}></InfoTip>
                </div>


                

                <SecondaryButton
                    onClick={handleOpenClose}
                    text={editText}
                    isEnabled={true}
                />
                <PrimaryButton
                    text={'Apply'}
                    onClick={handleOnApplyClicked}
                    isEnabled={!isCounting}
                    isLoading={isCounting}
                />
                <button
                    className={'filter-trash-button clickable'}
                    onClick={handleOnTrashClicked}
                >
                    <img className={'filter-trash'} src={trash}/>
                </button>
            </div>
            {
                isClosed ? null :
                    <div className={'filter-controls'}>
                        {props.children}
                    </div>
            }
        </div>
    )
}
