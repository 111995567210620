import React from 'react';
import './TermsConditions.scss';
import '../../App.scss'
import {Container} from "reactstrap";


function Privacy() {
    return (
        <div className='informational-page' role='main'>
            <Container>
                    <main>
                        <div className='card-style top-bottom-margin'>
                            <h1 className='tc-h1'>Privacy Policy</h1>
                            <h1 className='modified'>Last modified 5/27/2022</h1>
                            <h1 className='p-header-1'>1. INTRODUCTION</h1>
                            <p>Value Analytics, LLC (“Value Analytics”) respects your privacy and is committed to
                                protecting
                                it through our compliance with this policy. This policy describes the types of
                                information
                                we may collect from you or that you may provide when you visit www.ValueAnalytics.org
                                (the
                                “Website”) or download the Value Analytics Excel Add-In (the “Add-In”) and our practices
                                for
                                collecting, using, maintaining, protecting, and disclosing that information.
                                This policy applies to information we collect:

                            </p>
                            <ul>
                                <li>On the Website.</li>
                                <li>Through the Add-In.</li>
                                <li>In email, text, and other electronic messages between you and the Website or Value
                                    Analytics.
                                </li>
                                <li>On the Company’s accounts on Twitter®, Facebook®, LinkedIn®, Google Plus®,
                                    Instagram®,
                                    YouTube®, and/or any other social media website.
                                </li>
                            </ul>
                            <p>Please read this policy carefully to understand our policies and practices regarding your
                                information and how we will treat it. Going forward, references to the Website are
                                inclusive
                                of the Add-In. If you do not agree with our policies and practices, then do not use our
                                Website. By accessing or using this Website, you agree to this privacy policy. This
                                policy
                                may change from time to time. Your continued use of this Website after we make changes
                                is
                                deemed to be acceptance of those changes, so please check the policy periodically for
                                updates. </p>
                            <h1 className='p-header-1'>2. WHAT INFORMATION WE COLLECT</h1>
                            <p>We collect several types of information from and about users of our Website, including
                                the
                                following types and categories of information:</p>
                            <ul>
                                <li>Identifiers such as your name, postal address, unique personal identifier, online
                                    identifier, Internet Protocol (IP) address, email address, telephone number or other
                                    similar identifiers.
                                </li>
                                <li>Other categories of information that may be defined as personal information under
                                    state
                                    or federal law.
                                </li>
                                <li>A persistent identifier (such as a “cookie,” device or processor serial number, or
                                    an IP
                                    address).
                                </li>
                                <li>Information about how you use the Website, including browsing and search history and
                                    how
                                    you interact with ads or campaigns provided through the Website.
                                </li>
                                <li>Information about your computer or mobile device, including your physical location,
                                    browser and operating system type, and referring URLs.
                                </li>
                                <li>Inferences drawn from any of the information described in this Section 2 to create a
                                    profile about you and your preferences.
                                </li>
                                <li>Credit card or debit card information for processing payments by our third party
                                    payment
                                    vendor.
                                </li>
                            </ul>
                            <h1 className='p-header-1'>3. HOW WE COLLECT INFORMATION</h1>
                            <p>We collect information from you when you provide it to us, such as by submitting
                                information
                                when making purchases or setting up an account, and automatically as you navigate
                                through
                                the Website. Information collected automatically may include usage details, IP
                                addresses,
                                and information collected through cookies, web beacons, and other tracking
                                technologies.</p>
                            <p>As you navigate through and interact with our Website, we may use automatic data
                                collection
                                technologies to collect certain information about your equipment, browsing actions, and
                                patterns, including:</p>
                            <ul>
                                <li>Details of your visits to our Website, including traffic data, location data, logs,
                                    and
                                    other communication data and the resources that you access and use on the Website.
                                </li>
                                <li>Information about your computer and internet connection, including your IP address,
                                    operating system, and browser type.
                                </li>
                                <li>We also may use these technologies to collect information about your online
                                    activities
                                    over time and across third-party websites or other online services (behavioral
                                    tracking).
                                </li>
                                <li>The information we collect automatically may include personal information and/or we
                                    may
                                    maintain it or associate this information with personal information we collect in
                                    other
                                    ways or receive from third parties. It helps us to improve our Website and to
                                    deliver a
                                    better and more personalized service, including by enabling us to: estimate our
                                    audience
                                    size and usage patterns; store information about your preferences, allowing us to
                                    customize our Website according to your individual interests; speed up your
                                    searches;
                                    recognize you when you return to our Website.
                                </li>

                            </ul>
                            <p>Some content or applications on the Website may be provided or supported by
                                third-parties,
                                including advertisers, ad networks and servers, content providers, application
                                providers,
                                and vendors that provide services to us that we use to support our business. These third
                                parties may use cookies alone or in conjunction with web beacons or other tracking
                                technologies to collect information about you when you use our website. We do not
                                control
                                these third parties' tracking technologies or how they may be used. If you have any
                                questions about an advertisement or other targeted content, you should contact the
                                responsible provider directly. </p>
                            <h1 className='p-header-1'>4. HOW WE USE YOUR INFORMATION</h1>
                            <p>We use information that we collect about you or that you provide to us, including any
                                personal information:</p>
                            <ul>
                                <li>To present our Website and its contents to you.</li>
                                <li>To provide you with information, products, or services that you request from us.
                                </li>
                                <li>To fulfill any other purpose for which you provide it.</li>
                                <li>To provide information to you about our products and services or those of our
                                    business
                                    partners.
                                </li>
                                <li>To carry out our obligations and enforce our rights arising from any contracts
                                    entered
                                    into between you and us.
                                </li>
                                <li>To notify you about changes to our Website or any products or services we offer or
                                    provide though it.
                                </li>
                                <li>To create aggregated anonymous data about the usage of the Website and to use this
                                    aggregated anonymous data for our own business purposes or to provide it to third
                                    service providers that provide services on our behalf.
                                </li>
                                <li>To process purchases you make through the Website.</li>
                            </ul>
                            <h1 className='p-header-1'>5. DISCLOSURE OF YOUR INFORMATION</h1>
                            <p>We may disclose aggregated information about our users, and information that does not
                                identify any individual without restriction. </p>
                            <p>We may disclose personal information that we collect or you provide as described in this
                                privacy policy:</p>
                            <ul>
                                <li>To our subsidiaries and affiliates.</li>
                                <li>To contractors, service providers, and other third parties we use to support our
                                    business. For example, your payment information will be provided to our third-party
                                    payment processor to process purchases you make through the Website.
                                </li>
                                <li>To a buyer or other successor in the event of a merger, divestiture, restructuring,
                                    reorganization, dissolution, or other sale or transfer of some or all of Value
                                    Analytics’ assets.
                                </li>
                                <li>To third parties to market their products or services to you if you have consented
                                    to/not opted out of these disclosures
                                </li>
                                <li>To fulfill the purpose for which you provide it.</li>
                                <li>For any other purpose disclosed by us when you provide the information.</li>
                                <li>For any other purpose with your consent.</li>
                            </ul>
                            <p>We may also disclose your personal information:</p>
                            <ul>
                                <li>To comply with any court order, law, or legal process, including to respond to any
                                    government or regulatory request.
                                </li>
                                <li>To enforce or apply our terms of use and other agreements, including for billing and
                                    collection purposes.
                                </li>
                                <li>If we believe disclosure is necessary or appropriate to protect the rights,
                                    property, or
                                    safety of Value Analytics’, our customers, or others.
                                </li>
                            </ul>
                            <h1 className='p-header-1'>6. COMPLIANCE WITH THE CHILDREN’S ONLINE PRIVACY PROTECTION ACT
                                (“COPPA”)</h1>
                            <p>The Services are not designed or intended for children under the age of thirteen (a
                                “Child”)
                                and may not be used by a Child. Value Analytics will delete any information that Value
                                Analytics suspects may relate to a Child. </p>
                            <h1 className='p-header-1'>7. COMPLIANCE WITH THE CALIFORNIA CONSUMER PRIVACY ACT
                                (“CCPA”)</h1>
                            <p>California residents have certain rights under the CCPA with respect to personal
                                information
                                that is collected, sold, and/or used for business purposes. In the last 12 months, Value
                                Analytics may have collected those categories of information outlined in Section 2 from
                                the
                                various sources identified in Section 3. California residents have the following rights
                                with
                                respect to personal information collected by Value Analytics: </p>
                            <ul>
                                <li>Request for Information. You can also request a copy of your personal information
                                    and
                                    how it has been used and shared over the last 12 months directly from Value
                                    Analytics.
                                    In particular, you have the right to request: (1) categories of personal information
                                    that have been collected; (2) the categories of sources of this personal
                                    information;
                                    (3) the business or commercial purpose for collecting the personal information; (4)
                                    categories of third parties with whom Value Analytics shares personal information;
                                    and
                                    (5) specific pieces of personal information that Value Analytics has collected about
                                    you.
                                </li>
                                <li>Deletion of Information. Value Analytics users can request that Value Analytics
                                    delete
                                    your personal information on your behalf at any time.
                                </li>
                                <li>Nondiscrimination. Value Analytics will not discriminate against any user that
                                    exercises
                                    their rights under CCPA. However, certain aspects of the Website or Value Analytics’
                                    products or services may depend on Value Analytics’ ability to collect and share
                                    your
                                    personal information. So, the nature and quality of services Value Analytics
                                    provides to
                                    you may depend on Value Analytics’ ability to collect and share this personal
                                    information.
                                </li>
                                <li>Requests for copies of personal information, or deletion of personal information,
                                    may be
                                    made using the contacts set forth below.
                                </li>
                            </ul>

                            <p>Value Analytics does not sell any personal information. In the last 12 months, Value
                                Analytics may have collected and/or disclosed your personal information to enable Value
                                Analytics to provide its products and services and run its business, including for the
                                following business purposes:</p>
                            <ul>
                                <li>To detect security incidents and to protect the Value Analytics against malicious,
                                    deceptive, fraudulent, or illegal activity and prosecute those responsible.
                                </li>
                                <li>To troubleshoot, debug, or correct errors in the Website or Value Analytics’
                                    products or
                                    services.
                                </li>
                                <li>For other short term or transient use where the personal information is not
                                    disclosed to
                                    a third party.
                                </li>
                                <li>To provide customer service, process analytics, and other similar services performed
                                    on
                                    behalf of Value Analytics.
                                </li>
                                <li>For Value Analytics’ own internal research and development purposes and to improve
                                    the
                                    quality, safety, and features Value Analytics’ products and services.
                                </li>
                                <li>To audit interactions between users and transactions through the Website, including
                                    interactions with ads or campaigns provided through the Website.
                                </li>
                            </ul>
                            <h1 className='p-header-1'>8. COMPLIANCE WITH INTERNATIONAL PRIVACY LAWS</h1>
                            <p>If you are visiting the Website from outside the United States, please be aware that you
                                are
                                sending information to the United States where Value Analytics’ servers are located.
                                Information you submit may then be transferred within the United States or back out of
                                the
                                United States to other countries outside of your country of residence, depending on the
                                type
                                of information and how it is stored by Value Analytics. These countries (including the
                                United States) may not necessarily have data protection laws as comprehensive or
                                protective
                                as your country of residence; however, our collection, storage, and use of your data
                                will at
                                all times continue to be governed by this Privacy Policy.</p>
                            <p>If you are a member of the European Union (EU), you have special rights under the General
                                Data Protection Regulation (GDPR). Those include: </p>
                            <ul>
                                <li>The right to object to the processing of your data and the right to portability of
                                    your
                                    data. All complaints must be sent to Derek@ValueAnalytics.org.
                                </li>
                                <li>The right to erasure, rectification, access, or to seek restrictions to the
                                    processing
                                    of your personal data in our system. To the extent you provide consent to our
                                    processing
                                    of your personal data, you have the right to withdraw that consent at any time. Any
                                    withdrawal of consent does not apply to data collected lawfully prior to such
                                    consent.
                                </li>
                                <li>The right to lodge a complaint with a supervisory authority containing jurisdiction
                                    over
                                    GDPR related issues.
                                </li>
                            </ul>
                            <h1 className='p-header-1'>9. CONTACT</h1>
                            <p>For questions regarding this privacy policy please contact us at Derek@ValueAnalytics.org
                                or
                                Adam@ValueAnalytics.org.</p>
                        </div>
                    </main>
            </Container>
        </div>
    )
}

export default Privacy;
