import './FilterKeywordSearch.scss'
import React from 'react-dom';
import {FilterStateContainer} from "./FilterStateContainer";
import {IKeywordSearchFilter} from "../../filtering/filterStateTypes";
import {useDispatch} from "react-redux";
import {
    updateSelectedFilterState
} from '../../filtering/selectedFilterSlice';
import {getKeywordSearchState} from "../../filtering/filterStateLogic";
import FilterSearchBar from "./FilterSearchBar";

export interface FilterKeywordSearchProps {
    filter: IKeywordSearchFilter;
}

function FilterKeywordSearch(props: FilterKeywordSearchProps) {
    const dispatch = useDispatch();
    function handleOnChange(value: string) {
        dispatch(
            updateSelectedFilterState(
                getKeywordSearchState(props, value)));
    }

    return (
        <FilterStateContainer filter={props.filter} onDeleteFilter={() => null}>
            <div className={'filter-keyword-search'}>
                <FilterSearchBar handleSearch={handleOnChange} searchText={props.filter.value}
                placeholder={'search defaults to logical AND between terms. Specify "or" for a logical OR. Specify "not" for logical NOT'}/>
            </div>
        </FilterStateContainer>
    )
}

export default FilterKeywordSearch;
