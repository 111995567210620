import './TableColumnEditorColumnOptions.scss'
import {List} from "../List/List";
import {IColumnData} from "../../table/filterTable";

export default TableColumnEditorColumnOptions;

interface ListItemProps {
    id: number | string;
    value: string;
    onClick: () => void;
}

function ListItem(props: ListItemProps) {
    return <div className={'column-options-list-item clickable'} onClick={props.onClick}>{props.value}</div>
}

interface TableColumnEditorColumnOptionsProps {
    columns: IColumnData[];
    onColumnSelected: (column: IColumnData) => void;
}

function TableColumnEditorColumnOptions(props: TableColumnEditorColumnOptionsProps) {

    const items = props.columns
        .map(column => (
            {
                id: column.id,
                name: column.name
            }
        ));

    const mapper = (item: { id: string, name: string }) =>
        <ListItem
            id={item.id}
            value={item.name}
            onClick={() => props.onColumnSelected(
                {
                    id: item.id,
                    name: item.name
                }
            )}
        />

    return <List headers={[]} items={items} children={mapper}/>
}
