import './TableTabContainer.scss';
import {TableTab} from "./TableTab";
import {useState} from "react";

export function TableTabContainer(
    props: {
        tabNames: string[],
        onTabChange: (tabName: string) => void,
        isLoading: boolean
    }) {

    const [activeTabName, setActiveTabName] = useState(props.tabNames[0]);

    const handleOnTabChange = (name: string) => () => {
        props.onTabChange(name);
        setActiveTabName(name);
    }

    return (
        <div className={'table-tab-container'}>
            {
                props.tabNames
                    .map(i =>
                        <div key={i} className={'table-tab-wrap clickable'} onClick={handleOnTabChange(i)}>
                            <TableTab name={i} isActive={activeTabName === i} isLoading={props.isLoading}/>
                        </div>
                    )
            }
        </div>
    )
}
