import React from 'react';
import classNames from "classnames";
import './PlanOptionsPage.scss'
import { PrimaryButton } from '../Button/Button';
import {IPlanOption} from "../../api/requests";


interface PlanOptionProps {
    option: IPlanOption;
    onFormToggle: (id: string) => void;
    selected: boolean;
}

function PlanOption(props: PlanOptionProps) {
    const optionClassName = classNames( {
        'option': true,
        'best-value': props.option.best_value,
    })

  return (
    <div className={optionClassName}>
      <h4 className="title">{props.option.title}</h4>

        {
            props.option.trial_period ?
                <p className="trial plan-desc">{`${props.option.trial_period_days} days free,
                 no commitment!`}</p>
                : <div className="plan-desc">
                  <p className="price">{props.option.display_price}</p>
                  <p className="subtitle">{props.option.sub_period}</p>
                  <p className="subtitle">{props.option.billing_frequency}</p>
                  </div>
        }



      <div className="get-started">
        <PrimaryButton
          className={'inline-button'}
          text={props.selected ? 'Plan Selected' : 'Get Started'}
          onClick={() => {
              props.onFormToggle(props.option.price_id)
            }
          }
          isEnabled={true}
        />
      </div>
    </div>
  )
}

export default PlanOption;
