import {CardElement, ElementsConsumer, useElements, useStripe} from "@stripe/react-stripe-js";
import {useDispatch} from "react-redux";
import {Action, ThunkDispatch, unwrapResult} from "@reduxjs/toolkit";
import {RootState} from "../../store";
import {CreateStripeSubscriptionResponse, ICreateStripeSubscription} from "../../api/requestsTypes";
import {postStripeSubscription} from "../../signup/signUpSlice";
import React, {useState} from "react";
import {toInteger} from "lodash";
import {Stripe, StripeCardElement, StripeCardElementChangeEvent, StripeError} from "@stripe/stripe-js";
import {IPlanOption} from "../../api/requests";
import '../Button/Button.scss'
import stripeJs from "@stripe/stripe-js";
import {addPaymentMethod, handleCardSetup} from "../AccountPage/UpdateCard";
import {getSetupIntent} from "../../api/requests";
import {setDefaultPayment} from "../../billing/billingSlice";


interface CheckoutFormProps {
    onUpdatingSubscription: (isUpdatingSubscription: boolean) => void;
    onError: (message: string) => void;
    planId: string;
    customerId: string;
    acceptedTerms: boolean;
    planDetails: IPlanOption;
    referredBy: string;
    isUpgrading: boolean;
}

interface CheckoutStatusSuccess {
    status: 'success'
    message: string
}

interface CheckoutStatusError {
    status: 'error'
    message: string
}

type CheckoutStatus
    = CheckoutStatusSuccess
    | CheckoutStatusError

function getCheckoutStatus(error: undefined | StripeError): CheckoutStatus {
    if (!error) {
        return {
            status: 'success',
            message: 'Card was processed successfully.'
        }
    }

    if (error &&
        error.type === 'card_error' &&
        error.message) {

        return {
            status: 'error',
            message: error.message
        }
    }

    return {
        status: 'error',
        message: 'Something went wrong.'
    }
}

async function confirmCardSetup(
    stripe: Stripe,
    card: StripeCardElement,
    clientSecret: string): Promise<CheckoutStatus> {
    // need to test if this will actually charge the default card when the trial period is over
    const setupIntentResult = await stripe.confirmCardSetup(
        clientSecret,
        {
            payment_method: {
                card: card
            }
        });

    return getCheckoutStatus(setupIntentResult.error);
}

async function confirmCardPayment(
    stripe: Stripe,
    card: StripeCardElement,
    clientSecret: string): Promise<CheckoutStatus> {

    const confirmCardPaymentResult = await stripe.confirmCardPayment(
        clientSecret,
        {
            payment_method: {
                card: card
            },
            setup_future_usage: 'off_session'
        });

    return getCheckoutStatus(confirmCardPaymentResult.error);
}

export const CheckoutForm = (props: CheckoutFormProps) => {


    function getCheckoutForm(){

        if (props.isUpgrading) { // user is upgrading from a trial period
            return <UpgradeCheckoutForm {...props}></UpgradeCheckoutForm>
        } else if (!props.planDetails.trial_period && !props.isUpgrading) { // user has selected a plan without a trial
            return <NoTrialCheckoutForm {...props}></NoTrialCheckoutForm>
        } else if (props.planDetails.trial_period && !props.isUpgrading) {  // user has selected a trial plan
            return <TrialCheckoutForm {...props}></TrialCheckoutForm>
        } else { //  should not make it here
            return <p></p>
        }

    }

    return (
        getCheckoutForm()
    );
}

// breaking out these functions since they are used by multiple forms
function getSubscription(props: CheckoutFormProps) {
        const sub: ICreateStripeSubscription = {
            priceId: props.planId,
            customerId: props.customerId,
            quantity: toInteger('1'),
            referredBy: props.referredBy
        }

        if (props.planDetails.coupon_applied && 'promo_secret' in props.planDetails) {
            sub['coupon'] = props.planDetails.promo_secret
        }
        return sub;
    }

function clearErrors(props: CheckoutFormProps) {
        props.onError('');
    }

async function handleCardPayment(
        response: CreateStripeSubscriptionResponse,
        elements: stripeJs.StripeElements | null,
        stripe: Stripe | null
    ): Promise<CheckoutStatus> {

        const card = elements!.getElement(CardElement);
        if (response.status === 'error') {
            return {
                status: 'error',
                message: response.errors.join(',')
            }
        }

        if (stripe === null ||
            card === null) {
            return {
                status: 'error',
                message: 'Something went wrong.'
            };
        }

        switch (response.client_secret.type) {
            case 'setup_intent':
                return await confirmCardSetup(stripe, card, response.client_secret.secret);
            case 'payment_intent':
                return await confirmCardPayment(stripe, card, response.client_secret.secret);
            case 'None':
                return {
                    status: 'error',
                    message: 'Something went wrong.'
                };
        }
    }

async function handleCheckoutComplete(checkoutStatus: CheckoutStatus, props: CheckoutFormProps) {
        switch (checkoutStatus.status) {
            case 'error':
                props.onError(checkoutStatus.message);
                props.onUpdatingSubscription(false);
        }
    }


const handleChange = async (
        event: StripeCardElementChangeEvent,
        props: CheckoutFormProps,
        setCardIsReady: (arg:boolean) => void
    ) => {
    // Listen for changes in the CardElement
    // and display any errors as the customer types their card details
    if (event.complete) {
        setCardIsReady(true);
        clearErrors(props);
        return;
    }

    if (event.error) {
        props.onError(event.error.message);
        props.onUpdatingSubscription(false);
        setCardIsReady(false);
        return;
    }

    props.onError('');
};


export const UpgradeCheckoutForm = (props: CheckoutFormProps) => {

    const stripe = useStripe();
    const elements = useElements();

    const dispatch = useDispatch<ThunkDispatch<RootState, any, Action>>();
    const [cardIsReady, setCardIsReady] = useState(false);


    const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (event) => {
        event.preventDefault();

        if (!cardIsReady) {
            props.onError('Enter your card details.');
            return;
        }

        clearErrors(props);

        if (!props.acceptedTerms) {
            props.onError('You must accept the terms.');
            return;
        }


        const sub = getSubscription(props);
        props.onUpdatingSubscription(true);

        dispatch(postStripeSubscription(sub))
            .then(unwrapResult)
            .then((response)=>handleCardPayment(response, elements, stripe))
            .then((response) => handleCheckoutComplete(response, props))
            .catch(error => {
                props.onUpdatingSubscription(false);
                props.onError('Something went wrong.')
            });

    };


    return (
        <ElementsConsumer>
            {({stripe, elements}) => (
                <form onSubmit={handleSubmit}>

                        <div>
                            <h4>Card</h4>
                            <div className="card-element">
                                <CardElement
                                    onChange={(event)=>handleChange(event, props, setCardIsReady)}
                                    options={{
                                        style: {
                                            base: {
                                                padding: '14px 18px',
                                                fontSize: '12px',
                                            }
                                        }
                                    }}
                                />
                            </div>
                        </div>
                    <button className={'button primary subscribe'} type="submit" disabled={!stripe}>
                        Subscribe
                    </button>
                </form>
            )}
        </ElementsConsumer>
    )
}

export const NoTrialCheckoutForm = (props: CheckoutFormProps) => {

    const stripe = useStripe();
    const elements = useElements();

    const dispatch = useDispatch<ThunkDispatch<RootState, any, Action>>();
    const [cardIsReady, setCardIsReady] = useState(false);


    const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (event) => {
        event.preventDefault();

        if (!cardIsReady) {
            props.onError('Enter your card details.');
            return;
        }

        clearErrors(props);

        if (!props.acceptedTerms) {
            props.onError('You must accept the terms.');
            return;
        }

        if (props.referredBy == '') {
            props.onError('You must provide a referral. Enter "none" if you found us on your own.');
            return;
        }

        const sub = getSubscription(props);
        props.onUpdatingSubscription(true);


        dispatch(postStripeSubscription(sub))
            .then(unwrapResult)
            .then((response)=>handleCardPayment(response, elements, stripe))
            .then((response) => handleCheckoutComplete(response, props))
            .catch(error => {
                props.onUpdatingSubscription(false);
                props.onError('Something went wrong.')
            });

    };



    return (
        <ElementsConsumer>
            {({stripe, elements}) => (
                <form onSubmit={handleSubmit}>

                        <div>
                            <h4>Card</h4>
                            <div className="card-element">
                                <CardElement
                                    onChange={(event)=>handleChange(event, props, setCardIsReady)}
                                    options={{
                                        style: {
                                            base: {
                                                padding: '14px 18px',
                                                fontSize: '12px',
                                            }
                                        }
                                    }}
                                />
                            </div>
                        </div>
                    <button className={'button primary subscribe'} type="submit" disabled={!stripe}>
                        Subscribe
                    </button>
                </form>
            )}
        </ElementsConsumer>
    )

}

export const TrialCheckoutForm = (props: CheckoutFormProps) => {

    const dispatch = useDispatch<ThunkDispatch<RootState, any, Action>>();
    const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (event) => {

        event.preventDefault();
        clearErrors(props);

        if (!props.acceptedTerms) {
            props.onError('You must accept the terms.');
            return;
        }

        if (props.referredBy == '') {
            props.onError('You must provide a referral. Enter "none" if you found us on your own.');
            return;
        }

        const sub = getSubscription(props);
        props.onUpdatingSubscription(true);


        // if there is a trial period, then we create a subscription without requiring card information
        dispatch(postStripeSubscription(sub)).catch(error => {
                            props.onUpdatingSubscription(false);
                            props.onError('Something went wrong.')
                        });

    };

    return (
            <form onSubmit={handleSubmit}>
                <button className={'button primary subscribe'} type="submit">
                    Subscribe
                </button>
            </form>
    )

}


