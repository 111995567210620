export const fhCompsRowMap = [
    null,
    'LFY Revenue (MM)',
    'LFY EBITDA (MM)',
    'Enterprise Value (MM)',
    'Market Capitalization (MM)',
    'Assets (MM)',
    'Book Equity (MM)',
    'Net Debt (MM)',
    'Net Working Capital (MM)',
    null,
    null,
    'LFY Revenue Growth Rate (%)',
    'Revenue 3-Year CAGR (%)',
    'LFY EBITDA Growth Rate (%)',
    'EBITDA 3-Year CAGR (%)',
    null,
    null,
    'Inventory Turnover',
    'Asset Turnover',
    null,
    null,
    'Current Ratio',
    'Net Working Capital / Revenue (%)',
    null,
    'Days in Accounts Receivable',
    'Days in Inventories',
    'Days in Accounts Payable',
    'Net Trade Cycle',
    null,
    null,
    'Debt Capitalization (%)',
    'Equity Capitalization (%)',
    'Liabilities / Equity',
    'Assets / Equity',
    'Times Interest Earned',
    null,
    null,
    'Gross Profit Margin (%)',
    'EBITDA Margin (%)',
    'EBIT Margin (%)',
    'Net Profit Margin (%)',
    'Return on Assets (%)',
    'Return on Equity (%)',
    null,
    null,
    'Capital Expenditures / Revenue (%)',
    'Income Tax Rate (%)',
    null,
    null,
    'Net Profit Margin (%)',
    'Assets / Equity',
    'Asset Turnover',
    'Return on Equity (%)'
];

export const multiplesColumnMap = [
    'Earnings MVE / LFY',
    'Earnings MVE / 5-Year Avg.',
    'Earnings MVE / 3-Year Avg.',
    'Gross Cash Flow MVE / LFY',
    'Gross Cash Flow MVE / 5-Year Avg.',
    'Gross Cash Flow MVE / 3-Year Avg.',
    'EBIT MVE / LFY',
    'EBIT MVE / 5-Year Avg.',
    'EBIT MVE / 3-Year Avg.',
    'EBITDA MVE / LFY',
    'EBITDA MVE / 5-Year Avg.',
    'EBITDA MVE / 3-Year Avg.',
    'Revenue MVE / LFY',
    'Revenue MVE / 5-Year Avg.',
    'Revenue MVE / 3-Year Avg.',
    'Gross Profit MVE / LFY',
    'Gross Profit MVE / 5-Year Avg.',
    'Gross Profit MVE / 3-Year Avg.',
    'Book Value MVE / LFY',
    'Book Value MVE / 5-Year Avg.',
    'Book Value MVE / 3-Year Avg.',
];

export const ranksSheetMap = [
    [
      'Revenue (MM)',
      'Gross Margin (%)',
      'EBITDA Margin (%)',
      '3-Year CAGR of Revenue (%)',
      '3-Year CAGR of EBITDA (%)'
    ],
    [
      'Return on Assets (%)',
      'Return on Equity (%)',
      'Current Ratio',
      'Net Working Capital / Revenue (%)',
      'Times Interest Earned'
    ],
    [
      'Capital Expenditures / Revenue (%)',
      'Inventory Turnover',
      'Assets (MM)',
      'Debt Capitalization (%)',
      'Equity Capitalization (%)'
    ],
    [
      'Net Trade Cycle',
      'Asset Turnover',
      'Days in Accounts Receivable',
      'Days in Inventory',
      'Days in Accounts Payable'
    ]
  ];

type ValuationDateMap = {
    [key: string]: string
}
export const valuationDateMap: ValuationDateMap = {
  FHcomps: 'E3',
  Multiples: 'C3',
  Ranks: 'C3',
  Conc: 'C3'
};
