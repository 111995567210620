import {useInvoiceLogic} from "./UseInvoiceLogic";
import {reSubscribe} from "../../../api/requests";
import {fetchSubMgmtData} from "../../../billing/billingSlice";
import {OrderSummary} from "./OrderSummary";
import {PrimaryButton} from "../../Button/Button";
import {OrderInfo} from "./OrderInfo";
import React from "react";
import {MonthlyUpgradeProps} from "./MonthlyUpgradeProps";

export function MonthlyUpgradeInvoice(props: MonthlyUpgradeProps) {
    const {
        error,
        isLoading, isEnabled,
        performAction
    } = useInvoiceLogic(props)

    const button_text = 'Subscribe'
    const desc_text_1 = 'Upgrading to a monthly subscription will charge your card on file for a month of service less any time remaining ' +
        'on your Week Pass. Your card will be billed on a monthly basis following the initial charge.'
    const desc_text_2 = 'Press "Subscribe" to complete the following invoice and upgrade your account! You can cancel the monthly account at any time.'
    const due_text = 'Total due on next Invoice'
    const upgradeAccount = performAction(upgradeAccountAction);

    function upgradeAccountAction() {
        let payload = {
            ...props.invoice.invoice_preview_params,     // important to use the same params we sent to preview
            proration_date: props.invoice.proration_date // add the proration date to payload
        }

        return reSubscribe(payload)
    }

    return (
        <OrderSummary
            errors={props.errors}
            updateError={error}
            descText1={desc_text_1}
            descText2={desc_text_2}
            actionButton={
                <PrimaryButton className={'right-inline-button'}
                               text={button_text}
                               onClick={upgradeAccount}
                               isEnabled={isEnabled}
                               isLoading={isLoading}/>
            }
            orderInfo={
                <OrderInfo
                    lineItems={props.line_items}
                    dueText={due_text}
                    total={props.invoice.next_invoice.total}>
                    <div>
                        <p className={'left bold'}>{'Total due next month'}</p>
                    </div>
                    <div>
                        <p className={'right bold'}>{props.invoice.typical_monthly.amount}</p>
                    </div>
                </OrderInfo>
            }
        />
    )
}
