import React from "react";

interface OrderSummaryParams {
    errors: string;
    updateError: string;
    descText1: string;
    descText2: string;
    actionButton: React.ReactNode;
    orderInfo?: React.ReactNode;
}

export function OrderSummary(props: OrderSummaryParams) {
    return (
        <div className={"order-summary"}>
            {props.errors ? "" :
                <div>
                    <p className={'underlined'}>
                        {props.descText1}
                        <br/><br/>
                        {props.descText2}
                    </p>
                    {
                        props.orderInfo
                            ? props.orderInfo
                            : null
                    }
                    {props.actionButton}
                </div>
            }
            <p className={'update-error'}>{props.updateError}</p>
        </div>
    )
}
