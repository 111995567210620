import {RowDictionary} from "../comps/compsTableSlice";

export const cellAlphaMap = ['A','B','C','D','E','F','G','H','I','J','K',
    'L','M','N','O','P','Q','R','S','T','U','V',
    'W','X','Y','Z','AA','AB','AC','AD','AE','AF',
    'AG','AH','AI','AJ','AK','AL','AM','AN','AO','AP',
    'AQ','AR','AS','AT','AU','AV','AW','AX','AY','AZ'
    ];


export const cellNumberFormat = '$#,##0_);($#,##0)';
export const cellDecimalFormat = '0.0_) ;(0.0)';
export const cellPercentageFormat= '_(* #,##0.0%_);_(* (#,##0.0%);_(* "-"??_);_(@_)';
export const dateFormat = 'm/d/yy'

export const getAlphaFromCellRef = (cellRef: string) => {
    return (cellRef.match(/[A-Z]/g) ?? []).join('');
}

export const getNumericFromCellRef = (cellRef: string) => {
    return (cellRef.match(/[0-9]/g) ?? []).join('');
}

// Returns a cell reference key
export const moveRight = (sourceCell: string, count: number) => {
    // Move right from the current cell for {count} positions
    let a = getAlphaFromCellRef(sourceCell);
    let newRef = cellAlphaMap.indexOf(a) + count;
    return `${cellAlphaMap[newRef]}${getNumericFromCellRef(sourceCell)}`;
};

// Returns a cell reference key
export const moveDown = (sourceCell: string, count: number) => {
    // Move down from the current cell for {count} positions
    return getAlphaFromCellRef(sourceCell) + (Number(getNumericFromCellRef(sourceCell)) + count);
};

// Sets the cell value directly
export const setCellValue = (sheet: any, cellRef: string, value: any, cellFormat?: string) => {
    if(sheet[cellRef] == undefined) {
      // Make new cell
      sheet[cellRef] = {
        t: "n",
        v: ""
      };
    }
    // Some values are either NaN or null, display 0 instead.
    sheet[cellRef].v = (value == "NaN" || value == null) ? '' : value;
    if(typeof(value) == 'number') {
      sheet[cellRef].t = 'n';
    } else {
        sheet[cellRef].t = 's';
    }
    if(sheet[cellRef].w != undefined)  {
        delete(sheet[cellRef].w)
    }
    if(cellFormat != undefined) {
        sheet[cellRef].z = cellFormat;
    }
}

const alphaNumericSort = (arr: Array<Object>) => {
    const sorter = (a: any, b: any) => {
       const isNumber = (v: any) => (+v).toString() === v;
       const aPart = a.match(/\d+|\D+/g);
       const bPart = b.match(/\d+|\D+/g);
       let i = 0; let len = Math.min(aPart.length, bPart.length);
       while (i < len && aPart[i] === bPart[i]) { i++; };
          if (i === len) {
             return aPart.length - bPart.length;
       };
       if (isNumber(aPart[i]) && isNumber(bPart[i])) {
          return aPart[i] - bPart[i];
      };
      return aPart[i].localeCompare(bPart[i]);
    };
    arr.sort(sorter);
  };
  
export const insertColumns = (worksheet: any, columns: number, startingCol: string) => {
  // update ref array with new max
  let front = worksheet['!ref'].split(':')[0];
  let back = worksheet['!ref'].split(':')[1];
  back = moveRight(back, columns);
  worksheet['!ref'] = `${front}:${back}`;

  const currentKeys: string[] = Object
    .keys(worksheet)
    .filter(key => key.charCodeAt(0) > startingCol.charCodeAt(0));
  alphaNumericSort(currentKeys);
  currentKeys.reverse();
  
  let modifiedKeys = currentKeys.map(key => {
    return key.replace(/[A-Z]+/, (match: string) => {
      let char0indexed = 'A'.charCodeAt(0) + 1;
      
      if (match.length === 1) {
        let charCode = match.charCodeAt(0);
        let newCharCode = charCode + columns;
        if (newCharCode - char0indexed > 26) {
          return 'A' + String.fromCharCode((newCharCode - char0indexed) % 26 + char0indexed)
        } else {
          return String.fromCharCode(newCharCode);
        }
      } else if (match.length === 2) {
        let charCode = match.substring(1).charCodeAt(0);
        let newCharCode = charCode + columns;
        if (newCharCode - char0indexed > 26) {
          return 'B' + String.fromCharCode((newCharCode - char0indexed) % 26 + char0indexed)
        } else {
          return 'A' + String.fromCharCode(newCharCode);
        }
      }
      return match;
    });
  });

  for (let i = 0; i < currentKeys.length; i++) {
      // JSON back and forth to make deep copy of cell
      worksheet[modifiedKeys[i]] = JSON.parse(JSON.stringify(worksheet[currentKeys[i]]));
      worksheet[currentKeys[i]] = {t: "n", v: ""};
  };
}

export function insertRows(worksheet: any, rows: number, startingRow: number) {
  // update ref array with new max
  let front = worksheet['!ref'].split(':')[0];
  let back = worksheet['!ref'].split(':')[1];
  back = moveDown(back, rows);
  worksheet['!ref'] = `${front}:${back}`;

  const currentKeys: string[] = Object
    .keys(worksheet)
    .filter((key:string) => {
      return Number(key.match(/\d+/)) > startingRow;
    });
  alphaNumericSort(currentKeys);
  currentKeys.reverse();

  const modifiedKeys = currentKeys.map(key => {
    return key.replace(/\d+/, (match:string) => {
      return (Number(key.match(/\d+/)) + rows).toString();
    });
  });

  for (let i = 0; i < currentKeys.length; i++) {
    worksheet[modifiedKeys[i]] = JSON.parse(JSON.stringify(worksheet[currentKeys[i]]));
    worksheet[currentKeys[i]] = {t: "n", v: ""};
  }
}
