import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {ICompSetResponse, ICompSetRequest, IMultiples} from "../api/requestsTypes";
import {getCompSet} from "../api/requests";
import {RootState} from "../store";
import {CompanyGroup} from "./compsGroupSlice";
import _ from "lodash";
import {getDateFromIsoString} from "../utility/dates";
import {resetStateOnSignOut} from "../auth/signOut";

interface ICalculatedCell {
    id: number;
    measureOfPerformance: string;
    companyResults: string;
    selectedMultiples: number | '-';
}

export type RowDictionary = {[key: number]: ICalculatedCell}

interface ICompsTableSlice {
    tableData: ICompSetResponse,
    companyNetDebt: string,
    rowsDictionary: RowDictionary,
    isFetchingCompset: boolean
}

interface ICompsTableSliceState {
    compsTableSlice: ICompsTableSlice,
}
function format_compset_date(date:Date){
    let year = String(date.getFullYear())
    let month = String(date.getMonth() + 1).padStart(2, '0')
    let day = String(date.getDate()).padStart(2, '0')
    let date_str = `${year}-${month}-${day}`
    return date_str
}
function getCompSetRequest(companyGroup: CompanyGroup, valuationDate: Date) {

    const uids = Object.keys(companyGroup).map(i => companyGroup[i].uid);
    const date_str = format_compset_date(valuationDate)
    const request: ICompSetRequest = {
        compset: uids,
        date: date_str
    }
    return request;
}

export const fetchCompSet = createAsyncThunk<ICompSetResponse, void, {state: RootState}>(
    'compsTable/fetchCompSet',
    async ( _, {getState}) => {
        const {companyGroup, valuationDate } = getState().selectedCompsGroupSlice;
        const localValuationDate = getDateFromIsoString(valuationDate)
        const request = getCompSetRequest(companyGroup, localValuationDate);
        return await getCompSet(request);
    },
    {
        condition: (_, {getState}) => {
            const {companyGroup, valuationDate} = getState().selectedCompsGroupSlice;
            const localValuationDate = getDateFromIsoString(valuationDate)
            const request = getCompSetRequest(companyGroup, localValuationDate);
            if(!request.compset.length) {
               return false;
            }

            return true;
        }
    }
);

const initialState: ICompsTableSlice = {
    tableData: {
        fh_comps: {columns: [], values: []},
        multiples: {type: "", multiples: [], values: []},
        ranks: {},
        descriptions: [],
        results: { message: '', data_found: 'none'}
    },
    companyNetDebt: '',
    rowsDictionary: {},
    isFetchingCompset: false
}

function initRowsDictionary(multiples: IMultiples, rowsDictionary: RowDictionary): _.Dictionary<ICalculatedCell> {
    const multiplesDictionary = multiples.values.reduce((obj, row) => {
        const key = Object.keys(row)[0];
        const data = row[key];
        return {
            ...obj,
            [key]: data
        }
    }, {});
    const rows: ICalculatedCell[] = multiples.multiples
        .map((i, index) => {
            const median = multiplesDictionary['Median'][index]
            const oldCompanyResult = rowsDictionary[index]?.companyResults;
            return {
                id: index,
                measureOfPerformance: `${i.name} ${i.heading}`,
                companyResults: oldCompanyResult ? oldCompanyResult : '',
                selectedMultiples: median
            }
        });
    return _.keyBy(rows, 'id');
}

export const compsTableSlice = createSlice({
    name: 'compsTableSlice',
    initialState,
    reducers: {
        setSubjectCompanyNetDebt: (state, action) => {
           state.companyNetDebt = action.payload
        },
        updateRowsDictionary: (state, action:PayloadAction<{key: number, value: string}>) => {
             state.rowsDictionary[action.payload.key].companyResults = action.payload.value;
        },
        setRowsDictionary: (state, action: PayloadAction<RowDictionary>) => {
            state.rowsDictionary = action.payload;
        }
    },
    extraReducers: builder => {
        builder.addCase(fetchCompSet.fulfilled, (state, action) => {
            state.tableData = action.payload;
            state.rowsDictionary = initRowsDictionary(action.payload.multiples, state.rowsDictionary);
            state.isFetchingCompset = false;
        });

        builder.addCase(fetchCompSet.rejected, (state, action) => {
            state.isFetchingCompset = false;
        });

        builder.addCase(fetchCompSet.pending, (state, action) => {
            state.isFetchingCompset = true;
        });

        resetStateOnSignOut(builder, initialState);
    }
});

export const {setSubjectCompanyNetDebt, updateRowsDictionary, setRowsDictionary} = compsTableSlice.actions;
export default compsTableSlice.reducer;

export const selectMultiples = (state: ICompsTableSliceState): IMultiples => {
    const multiples = state.compsTableSlice?.tableData?.multiples;
    if (multiples === undefined) {
        return {
            multiples: [],
            values: [],
            type: ''
        }
    }

    return multiples;
}

export const selectFhComps = (state: ICompsTableSliceState) => {
    const fhComps = state.compsTableSlice?.tableData?.fh_comps;

    if(fhComps === undefined) {
        return {
            columns: [],
            values: []
        }
    }

    return fhComps;
}

export const selectRanks = (state: ICompsTableSliceState) => {
    const ranks = state.compsTableSlice?.tableData?.ranks;

    if(ranks === undefined) {
        return {}
    }

    return ranks;
}

export const selectDescriptions = (state: ICompsTableSliceState) =>
    state.compsTableSlice?.tableData?.descriptions

export const selectTableData = (state: ICompsTableSliceState) =>
    state.compsTableSlice?.tableData;

export const selectCompsetTableResults = (state: ICompsTableSliceState) =>
    state.compsTableSlice?.tableData?.results;

export const selectCompanyNetDebt = (state: ICompsTableSliceState) =>
    state.compsTableSlice.companyNetDebt;

export const selectRowDictionary = (state: ICompsTableSliceState) =>
    state.compsTableSlice.rowsDictionary;

export const selectIsFetchingCompset = (state: ICompsTableSliceState) =>
    state.compsTableSlice.isFetchingCompset;
