import {useInvoiceLogic} from "./UseInvoiceLogic";
import {cancelSubscription} from "../../../api/requests";
import {fetchSubMgmtData} from "../../../billing/billingSlice";
import {OrderSummary} from "./OrderSummary";
import {PrimaryButton} from "../../Button/Button";
import {OrderInfo} from "./OrderInfo";
import React from "react";
import {CancelUserProps} from "./CancelUserProps";

export function CancelUserInvoice(props: CancelUserProps) {
    const {
        error,
        isLoading, isEnabled,
        performAction
    } = useInvoiceLogic(props)
    const user_id = props.invoice.invoice_preview_params.user_id
    const user_info = props.users.filter(user => {
        return user.id === user_id
    })[0]

    const button_text = 'Cancel'
    const desc_text_1 = `Cancelling ${user_info.name}'s subscription will not affect the subscription of your account or other Team Members.` +
        ` ${user_info.name} will still have access until the end of the current subscription period.`
    const desc_text_2 = `Press "Cancel" to terminate access for ${user_info.name} at the end of the current subscription and reduce your monthly subscription total.`
    const due_text = 'Total due on next Invoice'
    const cancelUserSubscription = performAction(cancelUserSubscriptionAction)

    function cancelUserSubscriptionAction() {
        let payload = {
            'cancelled_ids': [props.invoice.invoice_preview_params.user_id],
            'type': 'cancel_user',
            'subscription_id': props.plan.sub_id
        }

        return cancelSubscription(payload)
    }

    return (
        <OrderSummary
            errors={props.errors}
            updateError={error}
            descText1={desc_text_1}
            descText2={desc_text_2}
            actionButton={
                <PrimaryButton className={'right-inline-button'}
                               text={button_text}
                               onClick={cancelUserSubscription}
                               isEnabled={isEnabled}
                               isLoading={isLoading}/>
            }
            orderInfo={
                <OrderInfo
                    lineItems={props.line_items}
                    dueText={due_text}
                    total={props.invoice.next_invoice.total}/>
            }
        />
    )
}
