import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {ICompSetRequest} from "../api/requestsTypes";
import {getUtcNowIsoString, getUtcYearDayMonth} from "../utility/dates";
import {
    ISaveUserCompSetResult,
    IUserCompSet,
    saveCompSet
} from "../api/requests";
import {RootState} from "../store";
import _ from 'lodash';
import {fetchTickers} from "./compsTickerPickerSlice";
import {resetStateOnSignOut} from "../auth/signOut";

export interface ICompany {
    ticker: string;
    uid: number;
}

export type CompanyGroup = { [ticker: string]: ICompany };

interface ISelectedCompsGroupSlice {
    companyGroup: CompanyGroup;
    valuationDate: string;
    id: number;
    companyGroupName: string;
    isLoadingUserCompSet: boolean;
}

interface ISelectedCompsGroupSliceState {
    selectedCompsGroupSlice: ISelectedCompsGroupSlice
}

function getCompSetFromCompanyGroup(companyGroup: CompanyGroup): number[] {
    return Object.keys(companyGroup).map(key => companyGroup[key].uid);
}

export const saveUserCompSet = createAsyncThunk<ISaveUserCompSetResult, void, { state: RootState }>(
    'savedUserCompSetSlice/saveUserCompSet',
    async (arg, {getState, rejectWithValue}) => {
        const {id, companyGroup, valuationDate, companyGroupName} = getState().selectedCompsGroupSlice;
        const compset = getCompSetFromCompanyGroup(companyGroup)

        if (!compset.length) {
            rejectWithValue('Your compset is empty.');
        }

        return await saveCompSet({
            id: id,
            compset: compset,
            valuation_date: valuationDate,
            update_date: getUtcNowIsoString(),
            compset_name: companyGroupName
        });
    }
);

interface ILoadCompSetResult {
    id: number;
    companyGroup: CompanyGroup;
    valuation_date: string;
    compset_name: string;
}

export const loadUserCompSet = createAsyncThunk<ILoadCompSetResult, IUserCompSet, { state: RootState }>(
    'savedUserCompSetSlice/loadUserCompSet',
    async (arg, thunkAPI) => {
        await thunkAPI.dispatch(fetchTickers({bypassCache: false}));
        const tickers = thunkAPI.getState().compsTickerPickerSlice.tickers;
        if (!tickers) {
            thunkAPI.rejectWithValue('No tickers');
        }
        const tickerDict = _.keyBy(tickers, i => i.uid);
        return {
            id: arg.id,
            compset_name: arg.compset_name,
            companyGroup: arg.compset
                .reduce((result, i) => {
                    if (tickerDict[i]) {
                        const item = tickerDict[i];
                        return {
                            ...result,
                            [item.ticker]: item
                        }
                    }

                    return result;
                }, {}),
            valuation_date: arg.valuation_date
        }
    }
);

const initialState: ISelectedCompsGroupSlice = {
    companyGroup: {} as CompanyGroup,
    valuationDate: getUtcNowIsoString(),
    companyGroupName: '',
    id: 0,
    isLoadingUserCompSet: false,
};


export const selectedCompsGroupSlice = createSlice({
    name: 'selectedCompsGroupSlice',
    initialState,
    reducers: {
        addCompsToGroup: (state, action: PayloadAction<ICompany>) => {
            const company = action.payload;
            if (state.companyGroup[company.ticker] === undefined) {
                state.companyGroup[company.ticker] = company;
            }
        },
        removeCompsFromGroup: (state, action: PayloadAction<ICompany>) => {
            delete state.companyGroup[action.payload.ticker];
        },
        setValuationDate: (state, action: PayloadAction<string>) => {
            state.valuationDate = action.payload;
        },
        setCompanyGroupName: (state, action: PayloadAction<string>) => {
            state.companyGroupName = action.payload;
        },
        clearCompsGroup: (state) => {
            const initData= initialState;
            state.valuationDate = initData.valuationDate;
            state.companyGroup = initData.companyGroup;
            state.isLoadingUserCompSet = initData.isLoadingUserCompSet;
            state.companyGroupName = initData.companyGroupName;
            state.id = initData.id;
        }
    },
    extraReducers: builder => {
        builder.addCase(saveUserCompSet.pending, (state, action) => {
            state.isLoadingUserCompSet = true;
        });

        builder.addCase(saveUserCompSet.fulfilled, (state, action) => {
            state.isLoadingUserCompSet = false;

            if (action.payload.status === 'error') {
                return;
            }

            state.id = action.payload.compsetId;
        });

        builder.addCase(saveUserCompSet.rejected, (state, action) => {
            state.isLoadingUserCompSet = false;
        });

        builder.addCase(loadUserCompSet.rejected, (state, action) => {
            const initData = initialState;
            state.valuationDate = initData.valuationDate;
            state.companyGroup = initData.companyGroup;
            state.isLoadingUserCompSet = initData.isLoadingUserCompSet;
            state.companyGroupName = initData.companyGroupName;
            state.id = initData.id;
        });

        builder.addCase(loadUserCompSet.pending, (state, action) => {
            state.isLoadingUserCompSet = true;
        });

        builder.addCase(loadUserCompSet.fulfilled, (state, action) => {
            const compSet = action.payload;
            state.companyGroupName = compSet.compset_name;
            state.companyGroup = compSet.companyGroup;
            state.valuationDate = compSet.valuation_date;
            state.id = compSet.id;
            state.isLoadingUserCompSet = false;
        });

        resetStateOnSignOut(builder, initialState);
    }
});

export default selectedCompsGroupSlice.reducer;

export const {
    addCompsToGroup,
    removeCompsFromGroup,
    setValuationDate,
    setCompanyGroupName,
    clearCompsGroup
} = selectedCompsGroupSlice.actions;

export const selectSelectedCompsGroup = (state: ISelectedCompsGroupSliceState): CompanyGroup =>
    state.selectedCompsGroupSlice.companyGroup;

export const selectCompsGroupName = (state: ISelectedCompsGroupSliceState): string =>
    state.selectedCompsGroupSlice.companyGroupName;

export const selectValuationDate = (state: ISelectedCompsGroupSliceState): string =>
    state.selectedCompsGroupSlice.valuationDate;

export const selectIsSavingUserCompSet = (state: ISelectedCompsGroupSliceState): boolean =>
    state.selectedCompsGroupSlice.isLoadingUserCompSet;

export const selectCompsGroupId = (state: ISelectedCompsGroupSliceState): number =>
    state.selectedCompsGroupSlice.id;

export const selectCompSet = (state: ISelectedCompsGroupSliceState): ICompSetRequest => {
    const {valuationDate, companyGroup} = state.selectedCompsGroupSlice;
    const compSet = Object
        .keys(companyGroup)
        .map(key => companyGroup[key].uid);

    return {
        compset: compSet,
        date: getUtcYearDayMonth(valuationDate)
    }
}
