import React, {useEffect, useState} from "react";
import {FilterPickerSector} from "./FilterPickerSector";
import {useDispatch, useSelector} from "react-redux";
import {selectSicCodes} from "../../filtering/filterSlice";
import {
    clearSectors,
    industryClicked,
    industryGroupSectorClicked,
    sectorFilterClicked, selectCleared,
    selectSicCodeState,
    setSectors
} from "../../filtering/sectorSlice";
import {getSicCodePickerState} from "../../filtering/filterStateLogic";
import {IIndustryGroupState, IIndustryState, ISectorState} from "../../filtering/sicCodeState";
import {updateSelectedFilterState} from "../../filtering/selectedFilterSlice";
import {FilterPickerWithSearch} from "../FilterPicker/FilterPickerWithSearch";
import {ISicCodePicker} from "../../filtering/filterStateTypes";

export function FilterPickerSicCodes(props: { filter: ISicCodePicker }) {
    const [searchText, setSearchText] = useState("");
    const dispatch = useDispatch();
    const codes = useSelector(selectSicCodes);
    const sectors = useSelector(selectSicCodeState);
    const sectorsCleared = useSelector(selectCleared);

    useEffect(() => {
        const data = {
            filterState: props.filter,
            sicCodesData: codes
        };
        dispatch(setSectors(data));
    }, [codes, sectorsCleared])

    useEffect(() => {
        dispatch(
            updateSelectedFilterState(
                getSicCodePickerState(props.filter, sectors)))
    }, [sectors]);

    function handleOnDeleteFilter() {
        dispatch(clearSectors())
    }

    function handleOnSectorPicked(sector: ISectorState) {
        dispatch(sectorFilterClicked(sector));
    }

    function handleOnIndustryGroupPicked(industryGroup: IIndustryGroupState) {
        dispatch(industryGroupSectorClicked(industryGroup));
    }

    function handleOnIndustryPicked(industry: IIndustryState) {
        dispatch(industryClicked(industry));
    }

    function getSectors() {
        return sectors.map(sector =>
            <FilterPickerSector
                key={sector.sector_name}
                sector={sector}
                searchText={searchText}
                onSectorPicked={handleOnSectorPicked}
                onIndustryGroupPicked={handleOnIndustryGroupPicked}
                onIndustryPicked={handleOnIndustryPicked}
            />
        );
    }

    return (
        <FilterPickerWithSearch
            filter={props.filter}
            onDeleteFilter={handleOnDeleteFilter}
            handleSearch={setSearchText}
        >
            {getSectors()}
        </FilterPickerWithSearch>
    )
}
