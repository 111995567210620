import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {getFilterResults} from "../api/requests";
import {RootState} from "../store";
import {getFilterCountsRequest} from "../api/filterCountRequest";
import {getSelectedFilterCounts, validateFilterValues} from "./selectedFilterSlice";
import {swapIndexes} from "../utility/utility";
import {resetStateOnSignOut} from "../auth/signOut";
import {IColumnData} from "../table/filterTable";
import {FilterResultColumn, FilterResultRow, FilterResults, ITableHeader} from "../types";

export type FilterDictionary = { [uid: string]: { [column: string]: FilterResultColumn} };

const MAX_FILTER_COUNT = 200;

interface IFilterTableSlice {
    error: string;
    filterResults: FilterResults;
    filterDictionary: FilterDictionary;
    headers: string [];
    page: number;
    isLoading: boolean;
}

interface IFilterTableSliceState {
    filterTableSlice: IFilterTableSlice
}


const initVisibleColumns = (): string[] => [
    'ticker',
    'company_name',
    'exchange',
    'p_volume',
    'sic_code',
    'p_enterprise_value',
    'is_revenue',
    'p_ebitda_margin',
    'description',
    'uid'
];

export const getSelectedFilterResults = createAsyncThunk<FilterResults, void, { state: RootState }>(
    'filter/getSelectedFilterResults',
    async (nothing, thunkAPI) => {
        const {selectedFilterState} = thunkAPI.getState().selectedFilterSlice;

        const filter = selectedFilterState[selectedFilterState.length - 1];

        if(filter.filterCount > MAX_FILTER_COUNT) {
          return thunkAPI.rejectWithValue(`The maximum number of companies you can request data for is ${MAX_FILTER_COUNT}. Please modify your filters.`)
        }


        let bad_filters = validateFilterValues(selectedFilterState)
        if (bad_filters.length > 0){
            // if there are bad filters, dispatch filterCounts method to
            // throw error and update the filter state
            // then do nothing
            thunkAPI.dispatch(getSelectedFilterCounts())
            return []
        }

        const request = getFilterCountsRequest(selectedFilterState);
        return await getFilterResults(request);
    }
);

export function getVisibleColumns(headers: string[], filterResults: FilterResults): FilterResults {
    return filterResults
        .map(row =>
            row.filter(column =>
                headers.includes(column.name)))
}

export function sortVisibleColumns(headers: string[], filterResults: FilterResults): FilterResults {
    let lookUp = getHeaderColumnOrderLookUp(headers);

    return filterResults.map(row =>
        row.sort((x, y) => {
            const first = lookUp.get(x.name);
            const second = lookUp.get(y.name);

            if (first === undefined || second === undefined) {
                throw Error('Column is not in list');
            }

            if (first === second) {
                return 0;
            }

            return first < second
                ? -1
                : 1;
        }));
}

const initialState: IFilterTableSlice = {
    filterResults: [],
    filterDictionary: {},
    headers: initVisibleColumns(),
    page: 0,
    isLoading: false,
    error: ''
};

export const filterTableSlice = createSlice({
    name: 'filterTableSlice',
    initialState,
    reducers: {
        addColumn: (state, action: PayloadAction<IColumnData>) => {
            const {id} = action.payload;
            const isSelected = state.headers
                .filter(i => i === id).length > 0;

            if (isSelected) {
                return;
            }

            state.headers.push(id);
        },
        removeColumn: (state, action) => {
            const {id} = action.payload;
            state.headers = state.headers
                .filter(i => i !== id);
        },
        moveDownColumn: (state, action: PayloadAction<IColumnData>) => {
            if(state.headers.length < 2) {
                return;
            }
            const index = state.headers.indexOf(action.payload.id);
            if(index >= state.headers.length - 2) {
                return;
            }
            swapIndexes(state.headers, index, index + 1);
        },
        moveUpColumn: (state, action: PayloadAction<IColumnData>) => {
            if (state.headers.length < 2) {
                return;
            }
            const index = state.headers.indexOf(action.payload.id);
            if (index <= 0) {
                return;
            }
            swapIndexes(state.headers, index, index - 1);
        },
        clearErrorMessage: (state) => {
            state.error = '';
        }
    },
    extraReducers: builder => {
        builder.addCase(getSelectedFilterResults.fulfilled, (state, action) => {
            state.error = ''
            state.filterResults = action.payload;
            state.filterDictionary = normalizeFilters(action.payload);
            state.isLoading = false;
        });

        builder.addCase(getSelectedFilterResults.rejected, (state, action: any) => {
            if(typeof(action.payload) === 'string') {
                state.error = action.payload;
            }
            state.filterResults = [];
            state.filterDictionary = {};
            state.isLoading = false;
        });

        builder.addCase(getSelectedFilterResults.pending, (state, action) => {
            state.isLoading = true;
        });

        resetStateOnSignOut(builder, initialState);
    }
});

export const {
    addColumn,
    removeColumn,
    moveDownColumn,
    moveUpColumn,
    clearErrorMessage
} = filterTableSlice.actions;

export const selectFilterTableHeaders = (state: IFilterTableSliceState): ITableHeader[] => {
    const filterDictionary = state.filterTableSlice.filterDictionary;
    const columns = filterDictionary[Object.keys(filterDictionary)[0]];
    const headers = state.filterTableSlice.headers;

    if(!columns) {
        return [];
    }

    return getTableHeaders(headers, columns)
        .filter(i => i.id !== 'uid');
}

export const selectFilterTableRows = (state: IFilterTableSliceState): FilterResults => {
    const filterDictionary = state.filterTableSlice.filterDictionary;
    const headers = state.filterTableSlice.headers;

    return getTableRows(filterDictionary, headers);
}

export const selectFilterTableLoading = (state: IFilterTableSliceState): boolean =>
    state.filterTableSlice.isLoading;

export const selectFilterTableError = (state: IFilterTableSliceState): string =>
    state.filterTableSlice.error;

export const selectFilterDictionary = (state: IFilterTableSliceState): FilterDictionary =>
    state.filterTableSlice.filterDictionary;

export const selectAllColumns = (state: IFilterTableSliceState): IColumnData[] => {
    const data = state.filterTableSlice.filterResults;
    if (data[0] === undefined) {
        return [];
    }
    return data[0]
        .filter(i => i.column !== 'uid')
        .map(i => ({
            id: i.column,
            name: i.name
        }));
}

export default filterTableSlice.reducer;

function getTableHeaders(headers: string[], columns: { [p: string]: FilterResultColumn }): ITableHeader[] {
    return headers.map(header => {
        const displayName = columns[header]?.name;
        if (displayName) {
            return {
                id: header,
                name: displayName,
                highlight: false
            };
        }

        return {
            id: '',
            name: '',
            highlight: false
        };
    });
}


function getTableRows(filterDictionary: FilterDictionary, headers: string[]): FilterResults {
    let result: FilterResults = [];

    for (let uid of Object.keys(filterDictionary)) {
        let row: FilterResultRow = [];
        for (let header of headers) {
            let column = filterDictionary[uid][header];
            if (column) {
                row.push(column);
            } else {
                row.push({column: header, name: header, type: "text", value: ""});
            }
        }
        result.push(row);
    }
    return result;
}

function normalizeFilters(payload: FilterResultRow[]): FilterDictionary {
    return payload.reduce((props, row) => {
        const uid = row.find(i => i.column === 'uid')?.value;

        if (!uid) {
            return props;
        }

        const obj = row.reduce((objProps, columnData) => {
            return {
                ...objProps,
                [columnData.column]: columnData
            };
        }, {});

        return {
            ...props,
            [uid]: obj
        }
    }, {});
}


function getHeaderColumnOrderLookUp(headers: string[]) {
    let record = new Map<string, number>();
    for (let i = 0; i < headers.length; i++) {
        record.set(headers[i], i);
    }
    return record;
}
