import './TableColumnEditor.scss';
import React from 'react';
import TableColumnEditorSelectedColumn from "./TableColumnEditorSelectedColumn";
import TableColumnEditorColumnOptions from "./TableColumnEditorColumnOptions";
import {EditColumnsSvg} from "../Svg/Svg";
import {IColumnData} from "../../table/filterTable";

interface TableColumnEditorParams {
    columns: IColumnData[];
    selectedColumns: IColumnData[];
    onAddColumn: (column: IColumnData) => void;
    onRemoveColumn: (column: IColumnData) => void;
    onMoveColumnDown: (column: IColumnData) => void;
    onMoveColumnUp: (column: IColumnData) => void;
}

export function TableColumnEditor(props: TableColumnEditorParams) {
    const columnsNotShowing = getColumnsNotShowing(props.columns, props.selectedColumns);

    function getColumnsNotShowing(allColumns: IColumnData[], selectedColumns: IColumnData[]) {
        const selectedColumnIds = selectedColumns.map(i => i.id);
        return allColumns.filter(i => selectedColumnIds.indexOf(i.id) < 0);
    }

    function handleColumnSelected(column: IColumnData) {
        props.onAddColumn(column);
    }

    function handleColumnRemoved(column: IColumnData) {
        props.onRemoveColumn(column);
    }

    function handleMoveColumnDown(column: IColumnData) {
        props.onMoveColumnDown(column);
    }

    function handleMoveColumnUp(column: IColumnData) {
        props.onMoveColumnUp(column);
    }


    return (
        <div className={'table-column-editor'}>
            <div className={'roboto-regular-14 editor-title'}>
                <EditColumnsSvg/>
                <div>Edit Columns</div>
            </div>
            <div className={'roboto-regular-14 editor-header'}>
                <div>Selected Columns</div>
                <div>Column Options</div>
            </div>
            <div className={'editor-body'}>
                <div className={'editor-body-item-wrap'}>
                    <TableColumnEditorSelectedColumn
                        columns={props.selectedColumns}
                        onColumnRemoved={handleColumnRemoved}
                        onMoveColumnDown={handleMoveColumnDown}
                        onMoveColumnUp={handleMoveColumnUp}
                    />
                </div>
                <div className={'editor-body-item-wrap'}>
                    <TableColumnEditorColumnOptions
                        columns={columnsNotShowing}
                        onColumnSelected={handleColumnSelected}
                    />
                </div>
            </div>
        </div>
    )
}
