import React from "react";
import './CriteriaCategoryContainer.scss'
import {IFilterCategoryExpandable, IFilterCategoryNonExpandable} from "../../filtering/filter";
import {FilterCategoryExpandable} from "../FilterCategory/FilterCategoryExpandable";
import FilterCategoryNonExpandable from "../FilterCategory/FilterCategoryNonExpandable";
import {IS, CF, BS, V, P, EC, BD, SC, EX, YF, HQ} from "../../utility/constants"
export interface CriteriaCategoryContainerProps {
    show: boolean;
    expandableCategories: IFilterCategoryExpandable[];
    nonExpandableCategories: IFilterCategoryNonExpandable[];
    isSearchingForText: boolean;
}

function get_ordered_filters(props: CriteriaCategoryContainerProps, quant_order: string[], qual_order: string[]){
    let ordered_filters = []
    for (let i=0; i < Math.max(quant_order.length, qual_order.length); i++) {

        if (i<quant_order.length) {
            let quant_item = props.expandableCategories.filter((x) => x.table == quant_order[i])[0]
            if (quant_item) {
                ordered_filters.push(quant_item)
            }
        }
        if (i<qual_order.length) {
            let qual_item = props.nonExpandableCategories.filter((x) => x.filter.name == qual_order[i])[0]
            if (qual_item) {
                ordered_filters.push(qual_item)
            }
        }

    }
    return ordered_filters
}

function CriteriaCategoryContainer(props: CriteriaCategoryContainerProps) {
    const cssClass = props.show ? "show" : "hide";
    let quant_order = [IS, CF, BS, V, P, EC]
    let qual_order = [BD, SC, EX, YF, HQ]

    let ordered_filters = get_ordered_filters(props, quant_order, qual_order)
    return (
        <div className={`criteria-category-container ${cssClass}`}>
            {
                props.show
                    ? <div className={"criteria-category-container-grid"}>
                        {
                            ordered_filters.map((prop) => {

                                 // @ts-ignore
                                if (prop.filter) {
                                     // @ts-ignore
                                    return <FilterCategoryNonExpandable key={prop.filter.value} category={prop}/>
                                    // @ts-ignore
                                } else if (prop.table) {
                                     // @ts-ignore
                                    return <FilterCategoryExpandable key={prop.table} category={prop}
                                                          isSearchingForText={props.isSearchingForText}/>
                                 }

                            })
                        }

                    </div>
                    : null
            }
        </div>
    );
}

export default CriteriaCategoryContainer;
