import './CompsSavedCompsGroups.scss';
import React, {useEffect} from "react";
import {SecondaryButton} from "../Button/Button";
import {Container} from "../Container/Container";
import {useDispatch, useSelector} from "react-redux";
import { Loader } from '../Loader/Loader';
import {IUserCompSet} from "../../api/requests";
import {getLocalMonthDayYear} from "../../utility/dates";
import {
    deleteUserCompSet, fetchUserCompSets,
    selectIsDeleteingUserCompSet,
    selectIsFetchingUserCompSets,
    selectSavedUserCompSets
} from "../../comps/savedCompsSets";
import {clearCompsGroup, loadUserCompSet, selectCompsGroupId} from "../../comps/compsGroupSlice";
import {fetchCompSet} from "../../comps/compsTableSlice";
import {Action, ThunkDispatch} from "@reduxjs/toolkit";
import {RootState} from "../../store";

export default CompsSavedCompsGroups;

function CompsSavedCompsGroups() {
    const savedCompSets = useSelector(selectSavedUserCompSets);
    const isFetchingUserCompSet = useSelector(selectIsFetchingUserCompSets);
    const isDeletingUserCompSet = useSelector(selectIsDeleteingUserCompSet);
    const selectedCompsGroupId = useSelector(selectCompsGroupId);
    const dispatch = useDispatch<ThunkDispatch<RootState, any, Action>>();

    function handleOnLoad(userCompSet: IUserCompSet) {
        dispatch(loadUserCompSet(userCompSet))
            .then(i => dispatch(fetchCompSet()));
    }

    function handleOnDelete(userCompSet: IUserCompSet) {
        if(userCompSet.id === selectedCompsGroupId) {
            dispatch(clearCompsGroup());
        }
       dispatch(deleteUserCompSet(userCompSet))
           .then(i => dispatch(fetchUserCompSets()));
    }

    return (
        <Container className={'saved-comps-groups-container'}>
            <div className={'font-blue-modal-header header-name'}> Saved Comps</div>
            <div className={'saved-comps-groups'}>
                <span className={'date roboto-regular-14'}>Last Updated</span>
                <span className={'name roboto-regular-14'}>Name</span>
                <span className={'date-picker roboto-regular-14'}>Valuation Date</span>
                <span className={'button1'}/>
                <span className={'button2'}/>
                {savedCompSets.map(i =>
                    <CompsSavedGroup
                        key={i.id}
                        savedComps={i}
                        onLoad={handleOnLoad}
                        onDelete={handleOnDelete}
                    />)
                }
            </div>
            <Loader isLoading={isFetchingUserCompSet || isDeletingUserCompSet}/>
        </Container>
    );
}


interface CompsSavedGroupParams {
    savedComps: IUserCompSet;
    onLoad: (compSet: IUserCompSet) => void;
    onDelete: (compSet: IUserCompSet) => void;
}

function CompsSavedGroup(props: CompsSavedGroupParams) {
    return (
        <>
            <div className={'date roboto-regular-12'}>
                {getLocalMonthDayYear(props.savedComps.update_date)}
            </div>
            <div className={'name roboto-regular-12'}>
                {props.savedComps.compset_name}
            </div>
            <div className={'date-picker roboto-regular-12'}>
                {getLocalMonthDayYear(props.savedComps.valuation_date)}
            </div>
            <div className={'button1'}>
                <SecondaryButton text={'Load'} onClick={() => props.onLoad(props.savedComps)} isEnabled={true}/>
            </div>
            <div className={'button2'}>
                <SecondaryButton text={'Delete'} onClick={() => props.onDelete(props.savedComps)} isEnabled={true}/>
            </div>
        </>
    );
}
