import {MonthlyUpgradeProps} from "./MonthlyUpgradeProps";
import {useInvoiceLogic} from "./UseInvoiceLogic";
import {WEEKLY_NAME} from "../../../constants";
import {reSubscribe} from "../../../api/requests";
import {fetchSubMgmtData} from "../../../billing/billingSlice";
import {OrderSummary} from "./OrderSummary";
import {PrimaryButton} from "../../Button/Button";
import {OrderInfo} from "./OrderInfo";
import React from "react";

export function ReSubscribeManager(props: MonthlyUpgradeProps) {
    const {
        error,
        isLoading, isEnabled,
        performAction
    } = useInvoiceLogic(props)
    const button_text = 'Subscribe'
    let desc_text_1
    let desc_text_2
    let monthly_plan = props.invoice.typical_monthly.description === WEEKLY_NAME

    if (monthly_plan) {
        desc_text_1 = `Subscribing to a ${props.invoice.typical_monthly.description} will charge your card on file for a week of access. `
            + 'Data access will expire 7 days from purchase.'
        desc_text_2 = 'Press "Subscribe" to complete the following invoice.  You will only billed once for this plan.'
    } else {
        desc_text_1 = `Subscribing to a ${props.invoice.typical_monthly.description} will charge your card for the first month of service. `
            + 'Your card will be billed on a monthly basis following the initial charge.'

        desc_text_2 = 'Press "Subscribe" to complete the following invoice and allow Value Analytics to charge your card monthly.'
    }

    const due_text = 'Total due now'
    const upgradeAccount = performAction(upgradeAccountAction);

    function upgradeAccountAction() {
        let payload = {
            ...props.invoice.invoice_preview_params
        }
        return reSubscribe(payload)
    }

    return (
        <OrderSummary
            errors={props.errors}
            updateError={error}
            descText1={desc_text_1}
            descText2={desc_text_2}
            actionButton={
                <PrimaryButton className={'right-inline-button'}
                               text={button_text}
                               onClick={upgradeAccount}
                               isEnabled={isEnabled}
                               isLoading={isLoading}/>
            }
            orderInfo={
                <OrderInfo
                    lineItems={props.line_items}
                    dueText={due_text}
                    total={props.invoice.next_invoice.total}
                >
                    {monthly_plan ? "" :
                        [<div>
                            <p className={'left bold'}>{'Total due next month'}</p>
                        </div>,
                            <div>
                                <p className={'right bold'}>{props.invoice.typical_monthly.amount}</p>
                            </div>]
                    }
                </OrderInfo>
            }
        />
    )
}
