import React from "react";
import {FilterPickerIndustryProps} from "../../filterPicker/filterStatePickerTypes";
import {FilterPickerChild} from "../FilterPicker/FilterPickerChild";
import {getCheckboxState} from "../../filterPicker/filterPickerUtility";

export function FilterPickerIndustry(props: FilterPickerIndustryProps) {
    const checkboxState = getCheckboxState(props.industry);

    function handleOnClick() {
        props.onIndustryPicked(props.industry);
    }

    return (
        <FilterPickerChild
            className={"picker-level-3"}
            checkboxState={checkboxState}
            handleOnClick={handleOnClick}
            label={props.industry.industry_name}
        />
    )
}
