export const ValueAnalyticsDocuments = {
    privacyPolicy: {
        name: 'Privacy Policy',
        desc: '',
        url: 'https://va-frontend-assets.s3.us-east-2.amazonaws.com/dev/documentation/terms/Value-Analytics-Privacy-Policy-3-2021.docx'
    },
    termsAndConditions: {
        name: 'Terms and Conditions',
        desc: '',
        url: 'https://va-frontend-assets.s3.us-east-2.amazonaws.com/dev/documentation/terms/Value-Analytics-Terms-and-Conditions-3-2021.docx'
    }
}
