import React from 'react'
import {FormGroup, Input, Label} from "reactstrap";
import './InputCheckbox.scss'


export function InputCheckbox(props: { onChange: (event: React.ChangeEvent<HTMLInputElement>) => void, label: string, isChecked: boolean }) {
    return (
        <FormGroup check className={'pt-2'}>
            <Input type={'checkbox'} checked={props.isChecked} onChange={props.onChange}/>
            <Label check className={'roboto-regular-18'}>{props.label}</Label>
        </FormGroup>
    )
}
