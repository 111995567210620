import React, {useState} from 'react'
import "./VaNavbar.scss"
import logo from "../../img/VA_Logo_New_1.svg"
import { useSelector} from "react-redux";
import {selectAttributes, selectHasSubscribed, selectIsLoggedIn} from "../../auth/authSlice";
import {Routes} from "../../routes/routes";
import {UserPortrait} from "../Svg/Svg";
import CollapsibleArrow from "../CollapsibleArrow/CollapsibleArrow";
import {Link, useHistory} from "react-router-dom";
import {
    Collapse, DropdownItem, DropdownMenu, DropdownToggle,
    Nav, Navbar, NavbarBrand, NavbarToggler, NavItem,
    UncontrolledDropdown
} from "reactstrap";
import classNames from "classnames";
import { Auth } from 'aws-amplify';
import RouteService from "../../routes/routeService";


function DropDownMenuItemDivider() {
    return (
        <DropdownItem divider style={{color: 'white', borderTopColor: 'white'}}/>
    )
}

function VaLink(props: { route: string, displayName: string, activeRoutes?: string[], collapsed?: boolean, className?: string, onClick: () => void }) {
    const history = useHistory();

    function isActiveLink(){
        let routes: string[] = [props.route]
        if(props.activeRoutes){
            routes = routes.concat(RouteService.getChildRoutes(props.activeRoutes))
        }
        return routes.includes(history.location.pathname);
    }

    return (
        <VaNavItem collapsed={props.collapsed}>
            <Link
                onClick={props.onClick}
                className={classNames(props.className, {
                    'va-link': true,
                    'active': isActiveLink(),
                })}
                to={props.route}>
                {props.displayName}
            </Link>
        </VaNavItem>
    );
}

function VaNavItem(props: { collapsed?: boolean, children: React.ReactNode }) {
    return <NavItem className={classNames({
        'd-xl-none': props.collapsed,
        'ps-md-4': true,
    })}>
        {props.children}
    </NavItem>
}

function PortraitItem(props: { children: React.ReactNode }) {
    return <DropdownItem style={{
        backgroundColor: "#061F61",
        textAlign: "center"
    }}>
        {props.children}
    </DropdownItem>
}

function Portrait(props: { onClick: () => void }) {
    const attributes = useSelector(selectAttributes)
    const name = attributes.fullName ? attributes.fullName : attributes.email;
    const email = attributes.fullName ? attributes.email : '';

    return (
        <UncontrolledDropdown
            className={"d-none d-xl-block va-dropdown justify-content-center d-flex"}>
            <DropdownToggle style={{
                backgroundColor: "#061F61",
                border: "none",
                borderRadius: "30px"
            }}>
                <div className={"nav-signout clickable"} onClick={props.onClick}>
                    <UserPortrait/>
                    <div className={"user-info"}>
                        <div className={"user-name roboto-medium-18"}>{name}</div>
                        <div className={"user-email"}>{email}</div>
                    </div>
                    <CollapsibleArrow isExpanded={false}/>
                </div>
            </DropdownToggle>
            <DropdownMenu style={{
                backgroundColor: "#061F61",
                width: '100%',
                marginTop: "20px"
            }}>
                <PortraitItem>
                    <Link className={"drop-link"} to={"/account/settings"}>Account</Link>
                </PortraitItem>
                <DropDownMenuItemDivider/>
                <PortraitItem>
                    <Link
                        className={"drop-link"}
                        to={Routes.homePage}
                        onClick={() => Auth.signOut()}>Sign Out
                    </Link>
                </PortraitItem>
            </DropdownMenu>
        </UncontrolledDropdown>
    );
}

function VaNavBar(props: { showSignOut: boolean, handlePortraitClicked: () => void }) {
    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => setIsOpen(!isOpen);
    const isLoggedIn = useSelector(selectIsLoggedIn)
    const hasSubscribed = useSelector(selectHasSubscribed)

    function handleOnLinkClicked() {
        setIsOpen(false);
    }

    return (
        <div className={'va-navbar'}>
            <Navbar expand={'xl ms-md-2 me-md-5'} light>
                <NavbarBrand href={Routes.homePage}>
                    <img className={'nav-bar-logo'} alt="company logo" src={logo}/>
                </NavbarBrand>
                <NavbarToggler onClick={toggle} />
                <Collapse isOpen={isOpen} navbar>
                    <Nav className={'me-auto roboto-thin-16'} navbar justified={false} fill={false}>
                        <VaLink route={Routes.homePage} displayName={'Home'} onClick={handleOnLinkClicked}/>
                        <VaLink route={Routes.compAssist} displayName={'Comp Assist'} onClick={handleOnLinkClicked}/>
                        <VaLink route={Routes.filtering} displayName={'Filtering'} onClick={handleOnLinkClicked}/>
                        <VaLink route={Routes.comps} displayName={'Comps'} onClick={handleOnLinkClicked}/>
                        <VaLink route={Routes.sizePremiums.methodology} displayName={'Size Premiums'} activeRoutes={RouteService.getChildRoutes(Routes.sizePremiums)} onClick={handleOnLinkClicked}/>
                        <VaLink route={Routes.documentation} displayName={'Documentation'}
                                onClick={handleOnLinkClicked}/>
                        <VaLink route={Routes.excelDocumentation} displayName={'Excel Add-In'}
                                onClick={handleOnLinkClicked}/>
                        <VaLink route={Routes.aboutUs} displayName={'About Us'} onClick={handleOnLinkClicked}/>

                        {
                            isLoggedIn && hasSubscribed ? (
                                <>
                                    <VaLink route={'/account/settings'} displayName={'Account'}
                                            collapsed={true} onClick={handleOnLinkClicked}/>
                                    <VaLink route={Routes.homePage} displayName={'Sign Out'}
                                            collapsed={true} onClick={() => Auth.signOut()}/>
                                </>
                            ) : null
                        }
                    </Nav>
                    {
                        isLoggedIn && hasSubscribed ? (
                            <Portrait onClick={props.handlePortraitClicked}/>
                        ) : (
                            <Nav className={'ms-auto roboto-thin-16'} navbar fill={false} justified={true}>
                                <VaLink className={'login'} route={Routes.login} displayName={'Login'}
                                        onClick={handleOnLinkClicked}/>
                                <VaLink className={'signup nav-round-button'} route={Routes.createAccount} displayName={'Sign Up'}
                                        onClick={handleOnLinkClicked}/>
                            </Nav>
                        )
                    }
                </Collapse>
            </Navbar>
        </div>
    )
}

export default VaNavBar;
