import React from "react"


interface boldItalicsProps {
    text: String,
    color: "#00B050" | "#0070C0" | "#C00000" | "#7030A0" | "#000000"
}

export function Bc(props:boldItalicsProps){

    let weight: "bold" | "-moz-initial" | "inherit" | "initial" | "revert" |
        "unset" | "normal" | (number & {}) | "bolder" | "lighter" | undefined = "bold"

    let style = {
        fontWeight: weight,
        color: props.color
    }

    return (
        <span style={style}>{props.text}</span>
    )
}
