import React, {useEffect, useState} from "react";
import './FilterPage.scss'
import SearchCriteriaContainer from "../SearchCriteriaContainer/SearchCriteriaContainer";
import {useDispatch, useSelector} from "react-redux";
import {SavedFilterContainer} from "../SavedFilterContainer/SavedFilterContainer";
import SelectedFiltersContainer from "../FilterContainer/SelectedFiltersContainer";
import {fetchFilters} from "../../filtering/filterSlice";
import {fetchUserFilters} from "../../filtering/savedFilterSlice";
import {EditableFilterTableContainer} from "../FilterTable/FilterTable";
import CompsTab from "../CompsTab/CompsTab";
import {Modal} from "../Modal/Modal";
import {TableColumnEditor} from "../TableColumnEditor/TableColumnEditor";
import classNames from "classnames";
import {
    addColumn,
    moveDownColumn,
    moveUpColumn,
    removeColumn,
    selectAllColumns,
    selectFilterTableHeaders,
    selectFilterTableRows
} from "../../filtering/filterTableSlice";
import SmallScreenAlert from "../Utility/SmallScreenAlert";
import {addCompsToGroup} from "../../comps/compsGroupSlice";
import {getCompany} from "../../table/filterTable";
import {FilterResultRow} from "../../types";

function useColumnEditor() {
    const [showColumnEditor, setShowColumnEditor] = useState(false);

    function handleShowColumnEditor() {
        return setShowColumnEditor(!showColumnEditor);
    }

    return {showColumnEditor, toggleShowColumnEditor: handleShowColumnEditor};
}

function FilterPage() {
    const dispatch = useDispatch();
    const {showColumnEditor, toggleShowColumnEditor} = useColumnEditor();
    const [isCompsTabExpanded, setIsCompsTabExpanded] = useState(false);
    const columns = useSelector(selectAllColumns);
    const headers = useSelector(selectFilterTableHeaders);
    const rows = useSelector(selectFilterTableRows);

    useEffect(() => {
        dispatch(fetchFilters());
        dispatch(fetchUserFilters());
    }, []);

    const handleOnAddToCompsGroup = (row: FilterResultRow) => () => {
        const company = getCompany(row);
        if (company === null) {
            return;
        }
        dispatch(addCompsToGroup(company));
        setIsCompsTabExpanded(true);
    }

    function handleOnCompsTabClicked() {
        setIsCompsTabExpanded(!isCompsTabExpanded);
    }

    const filterPageClassName =
        classNames({
            'blur': showColumnEditor
        });

    return (
        <>
            <SmallScreenAlert/>
            <div className={classNames('va-page filter-page py-5', filterPageClassName)}>
                <div className={'filter-page-search'}>
                    <div className={"filter-page-search-bar"}>
                        <SearchCriteriaContainer/>
                        <p className={'welcome-link'}>New to Value Analytics? Watch our
                            <a className={'youtube-link'} target={"_blank"}
                               href={'https://www.youtube.com/watch?v=PXGrwGQhXns'}
                            > tutorial video!</a>
                        </p>
                    </div>
                    <div className={'filter-page-filters'}>
                        <SelectedFiltersContainer/>
                    </div>
                    <div className={'filter-page-saved-filters'}>
                        <SavedFilterContainer/>
                    </div>
                    <div className="h_line"></div>
                </div>
                <div className={'filter-page-after-search'}>
                    <CompsTab isExpanded={isCompsTabExpanded} onClick={handleOnCompsTabClicked}/>
                    <div className={'filter-page-table'}>
                        <EditableFilterTableContainer
                            onAddToCompsGroup={handleOnAddToCompsGroup}
                            onEditColumns={toggleShowColumnEditor}
                            headers={headers}
                            rows={rows}
                        />
                    </div>
                </div>
            </div>
            {
                showColumnEditor
                    ? (
                        <Modal onCloseModal={toggleShowColumnEditor}>
                            <TableColumnEditor
                                columns={columns}
                                selectedColumns={headers}
                                onAddColumn={(column) => dispatch(addColumn(column))}
                                onRemoveColumn={(column) => dispatch(removeColumn(column))}
                                onMoveColumnDown={(column) => dispatch(moveDownColumn(column))}
                                onMoveColumnUp={(column) => dispatch(moveUpColumn(column))}
                            />
                        </Modal>
                    )
                    : null
            }
        </>
    );
}

export default FilterPage;
