import {Dictionary} from "../utility/utility";
import * as _ from "lodash";
import {ICompany} from "../comps/compsGroupSlice";
import {FilterResultColumn, FilterResultRow} from "../types";

function toDictionary(row: FilterResultColumn[]): { [index: string]: FilterResultColumn } {
    return _.keyBy(row, 'column');
}

function emptyFilterResultColumn(): FilterResultColumn {
    return {
        column: "",
        name: "",
        value: "",
        type: ""
    };
}

function getValueFromDictionary(key: string, dictionary: Dictionary<FilterResultColumn>): FilterResultColumn {
    let value = dictionary[key];
    if (value === undefined) {
        return emptyFilterResultColumn()
    } else {
        return value;
    }
}

function getNumberFromDictionary(key: string, dictionary: Dictionary<FilterResultColumn>): number {
    let result = parseInt(getValueFromDictionary('uid', dictionary).value);
    return result === undefined ? 0 : result;
}

export function getCompany(row: FilterResultRow): ICompany | null {
    const data = toDictionary(row);

    if (data === undefined) {
        return null;
    }

    return {
        ticker: getValueFromDictionary('ticker', data).value,
        uid: getNumberFromDictionary('uid', data)
    };
}

export interface IColumnData {
    id: string;
    name: string;
}

