import React from 'react-dom';
import './SavedFilterContainer.scss';
import '../../style/style.scss';
import {useState} from "react";
import {SecondaryButton} from "../Button/Button";
import {useDispatch, useSelector} from "react-redux";
import {
    deleteFilter,
    fetchUserFilters, selectIsFetchingUserFilters,
    selectIsRemovingFilter,
    selectSavedFilters
} from "../../filtering/savedFilterSlice";
import {ISavedFilter} from "../../api/requestsTypes";
import {
    clearSelectedFilterState,
    getSelectedFilterCounts,
    loadSavedFilter,
    selectSelectedFilterId
} from '../../filtering/selectedFilterSlice';
import {Action, ThunkDispatch, unwrapResult} from "@reduxjs/toolkit";
import {RootState} from "../../store";
import {getLocalMonthDayYear} from "../../utility/dates";
import {Loader} from '../Loader/Loader';
import {clearSectors} from '../../filtering/sectorSlice';

export function SavedFilterContainer() {
    const filters = useSelector(selectSavedFilters);
    const isFetchingUserFilters = useSelector(selectIsFetchingUserFilters);
    const [isExpanded] = useState(true);

    return (
        <div className={'saved-filter-container'}>
            <div className={'saved-filter-header'}>
                <span className={'font-blue-modal-header font-valign'}>Saved Filters</span>
            </div>
            {
                isExpanded ?
                    <div className={'saved-filters'}>
                        <span className={'filter-date roboto-regular-14'}>Last Updated</span>
                        <span className={'filter-name roboto-regular-14'}>Name</span>
                        <span className={'filter-actions roboto-regular-14'}>Actions</span>
                        {filters.map(i => <SavedFilter key={i.filterId} savedFilter={i}/>)}
                    </div>
                    : null
            }
            <Loader isLoading={isFetchingUserFilters}/>
        </div>
    );
}

export function SavedFilter(props: { savedFilter: ISavedFilter }) {
    const dispatch = useDispatch<ThunkDispatch<RootState, any, Action>>();
    const selectedFilterId = useSelector(selectSelectedFilterId);
    const isDeleting = useSelector(selectIsRemovingFilter);

    function handleOnLoad() {
        dispatch(clearSectors());
        dispatch(clearSelectedFilterState());
        dispatch(loadSavedFilter(props.savedFilter));
        dispatch(getSelectedFilterCounts());
    }

    function handleOnDelete() {
        dispatch(deleteFilter(props.savedFilter))
            .then(unwrapResult)
            .then(i => {
                if (i.status === 'success' &&
                    props.savedFilter.filterId === selectedFilterId) {
                    dispatch(clearSelectedFilterState());
                }
            })
            .then(i => dispatch(fetchUserFilters()));
    }

    return (
        <>
            <div className={'filter-date roboto-regular-12'}>
                {getLocalMonthDayYear(props.savedFilter.isoString)}
            </div>
            <div className={'filter-name roboto-regular-12'}>{props.savedFilter.filterName}</div>
            <div className={'filter-actions'}>
                <div className={'saved-filter-buttons'}>
                    <SecondaryButton
                        text={'Load'}
                        onClick={handleOnLoad}
                        isEnabled={true}
                    />
                    <SecondaryButton
                        text={'Delete'}
                        onClick={handleOnDelete}
                        isEnabled={!isDeleting}
                        isLoading={isDeleting}
                    />
                </div>
            </div>
        </>
    );
}
