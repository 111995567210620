import './PadLockModal.scss'
import {useSelector} from "react-redux";
import {selectClaims, selectHasSubscribed, selectIsLoggedIn, selectIsTrialing} from "../../auth/authSlice";
import {Link} from "react-router-dom";
import {Routes} from "../../routes/routes";
import {Modal} from "../Modal/Modal";
import {PadLock} from "../Svg/Svg";
import React from "react";
import classNames from "classnames";
import {BVR_ROLE} from "../../constants";

export function PadLockModal(props: { onCloseModal: () => null, children?: React.ReactNode, fullscreen: boolean }) {
    const isLoggedIn = useSelector(selectIsLoggedIn);
    const hasSubscribedBefore = useSelector(selectHasSubscribed)
    const isTrialing = useSelector(selectIsTrialing)
    const claims = useSelector(selectClaims);

    function getLinks() {
        if (!isLoggedIn) {
            return (
                <>
                    <Link key={1} to={Routes.createAccount} className={"subscribe-link"}><b>Sign Up</b></Link>
                    <span key={2} className={"subscribe-text"}> or </span>
                    <Link key={3} to={Routes.login} className={"subscribe-link"}><b>Login</b></Link>
                </>
            )
        }
          else if (claims.role == BVR_ROLE) { // this means they are a bvr user and we block everything
              return <Link to={'/account/settings'} className={"subscribe-link"}><b>Manage Account</b></Link>
        } else if (!hasSubscribedBefore) { // this means the user has not selected a plan
            return <Link to={'/create-account'} className={"subscribe-link"}><b>Select Plan</b></Link>
        } else if (isTrialing) { // this means the user is trialing and their trial is expired. Redirect to select plan page
            return <Link to={'/upgrade-account'} className={"subscribe-link"}><b>Upgrade Plan</b></Link>
        } else { // if we make it here it means the user's previous subscription has expired
            return <Link to={'/account/billing'} className={"subscribe-link"}><b>Resubscribe</b></Link>
        }
    }

    const className = classNames({
            'fullscreen': props.fullscreen
        }
    )

    function getText() {
        if (claims.role == BVR_ROLE) {
            return <span className={"subscribe-text"}>
                           Your plan only allows access to the Excel Add-in. Please contact your
                           account administrator if you are interested in additional features.
                    </span>
        } else {
            return <span className={"subscribe-text"}>Subscribe to unlock data</span>
        }
    }

    return (
        <div className={'pad-lock-wrap ' + className}>
            <div className={'blur'}>
                {props.children}
            </div>
            <div className={"pad-lock-modal"}>
                <Modal onCloseModal={props.onCloseModal} hideCloseButton={true}>
                    <div className={"pad-lock"}>
                        <PadLock/>
                    </div>
                    {
                        getText()
                    }
                    <div>
                        {
                            getLinks()
                        }
                    </div>
                </Modal>
            </div>
        </div>
    );
}
