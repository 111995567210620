import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {DeleteFilterResponse, GetUserFiltersResponse, ISavedFilter} from "../api/requestsTypes";
import {deleteUserFilter, getUserFilters} from "../api/requests";
import {resetStateOnSignOut} from "../auth/signOut";

interface ISavedFilterSlice {
    savedFilters: ISavedFilter[];
    isRemovingFilter: boolean;
    isFetchingUserFilters: boolean;
}

interface ISavedFilterSliceState {
    savedFilterSlice: ISavedFilterSlice;
}

export const fetchUserFilters = createAsyncThunk<GetUserFiltersResponse, void>(
    'savedFilterSlice/getUserFilters',
    async (arg, thunkAPI) => {
        return await getUserFilters();
    }
);

export const deleteFilter = createAsyncThunk<DeleteFilterResponse, ISavedFilter>(
    'savedFilterSlice/deleteUserFilter',
    async (filter, thunkAPI) => {
        return await deleteUserFilter(filter);
    }
);

const initialState: ISavedFilterSlice = {
    isRemovingFilter: false,
    savedFilters: [] as ISavedFilter[],
    isFetchingUserFilters: false
};

export const savedFilterSlice = createSlice({
    name: 'savedFilterSlice',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(fetchUserFilters.fulfilled, (state, action) => {
            state.isFetchingUserFilters = false;
            switch (action.payload.status) {
                case 'success':
                    state.savedFilters = action.payload.filters;
                    break;
                case 'error':
                    break;
            }
        });

        builder.addCase(fetchUserFilters.pending, (state, action) => {
            state.isFetchingUserFilters = true;
        });

        builder.addCase(fetchUserFilters.rejected, (state, action) => {
            state.isFetchingUserFilters = false;
        });

        builder.addCase(deleteFilter.pending, (state, action) => {
            state.isRemovingFilter = true;
        });
        builder.addCase(deleteFilter.fulfilled, (state, action) => {
            state.isRemovingFilter = false;
        });
        builder.addCase(deleteFilter.rejected, (state, action) => {
            state.isRemovingFilter = false;
        });

        resetStateOnSignOut(builder, initialState);
    }
});


export const selectSavedFilters = (state: ISavedFilterSliceState): ISavedFilter[] =>
    state.savedFilterSlice.savedFilters;

export const selectIsRemovingFilter = (state: ISavedFilterSliceState): boolean =>
    state.savedFilterSlice.isRemovingFilter;

export const selectIsFetchingUserFilters = (state: ISavedFilterSliceState): boolean =>
    state.savedFilterSlice.isFetchingUserFilters;

export default savedFilterSlice.reducer;
