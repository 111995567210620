import {getRequest} from "./requestUtility";

type PreSignedUrlResponse = {url: string}

export function getPreSignedUrl(file: string): Promise<PreSignedUrlResponse>{
    const filePath = encodeURIComponent(file)
    return getRequest('Api', `/sub/presigned/documentation?file=${filePath}`)
}

export async function isPreSignedUrlExpired(url: string): Promise<boolean>{
    if(!url.length){
       return false;
    }

    try{
        const response = await fetch(url);
        return response.status === 403;
    }
    catch (error){
        console.error('Error checking presigned url', error);
        return false;
    }
}


