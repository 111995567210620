import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {selectClaims, selectIsLoggedIn} from "../../auth/authSlice";
import {PadLockModal} from "./PadLockModal";
import {BVR_ROLE} from "../../constants";

function WithActiveSubscription(props: { children: React.ReactNode, fullscreen: boolean }) {
    const isLoggedIn = useSelector(selectIsLoggedIn);
    const claims = useSelector(selectClaims);
    const [showPadLock, setShowPadLock] = useState(false);

    function hasActiveSubscription() {
        return isLoggedIn && claims.active_subscription && claims.role != BVR_ROLE;
    }

    useEffect(() => {
        setShowPadLock(!hasActiveSubscription());
    }, [isLoggedIn, claims]);

    return (
        showPadLock ?
            <PadLockModal onCloseModal={() => null} fullscreen={props.fullscreen}>
                {props.children}
            </PadLockModal>
            : <>{props.children}</>
    )
}

export default WithActiveSubscription;
