import './FilterTable.scss'
import React, {ReactNode, useState} from 'react';
import {formatNumber} from "../../utility/numberFormatter";
import {TableSortDownSvg, TableSortUpSvg} from '../Table/Table';
import {EditColumnsButton, CircleButton} from "../Button/Button";
import {Tooltip} from "reactstrap";

import {FilterResultColumn, FilterResultRow, FilterResults, IHighlightable, ITableHeader} from "../../types";
import classNames from "classnames";

interface TableValueProps {
    value: string;
}

interface TableSortableHeaderProps extends IHighlightable{
    value: string;
    onSort?: () => void;
    ascending: boolean;
}

interface TableHeaderProps extends IHighlightable{
    children: ReactNode;
    value: string;
}

function TableSortableHeader(props: TableSortableHeaderProps) {
    return (
        <TableHeader value={props.value} highlight={props.highlight}>
            {
                props.ascending
                    ? <TableSortDownSvg onSort={props.onSort}/>
                    : <TableSortUpSvg onSort={props.onSort}/>
            }
        </TableHeader>
    )
}

function TableHeader(props: TableHeaderProps) {
    const thStyle = classNames({
        'highlight': props.highlight
    })

    return (
        <th className={thStyle}>
            {props.value}
            {props.children}
        </th>
    );
}

function TableCell(props: TableValueProps) {
    return (
        <td>{props.value}</td>
    );
}

function FilterTableToolbar(props: { title: string, children?: React.ReactNode | React.ReactNodeArray}) {
    return (
        <div className={"filter-table-toolbar"}>
            <div className={"filter-table-toolbar-label roboto-regular-24"}>
                {props.title}
            </div>
            {props.children}
        </div>
    );
}

interface FilterTableContainerParams {
    onAddToCompsGroup: (row: FilterResultRow) => () => void;
    headers: ITableHeader[];
    rows: FilterResults;
    subHeaders?: ITableHeader[][]
}

interface EditableFilterTableContainerParams extends FilterTableContainerParams {
    onEditColumns: () => void;
}

export function FilterTableContainer(props: FilterTableContainerParams) {
    const hasRows = props.rows.length > 0;


    return hasRows ? (
            <div className={'filter-table-container'}>
                <FilterTableToolbar title={'Results'}/>
                <FilterTable
                    headers={props.headers}
                    rows={props.rows}
                    subHeaders={props.subHeaders}
                    onAddToCompsGroup={props.onAddToCompsGroup}/>
            </div>
        )
        : null;
}

export function EditableFilterTableContainer(props: EditableFilterTableContainerParams) {
    const hasRows = props.rows.length > 0;


    return hasRows ? (
            <div className={'filter-table-container'}>
                <FilterTableToolbar title={'Results'}>
                    <EditColumnsButton text={'Edit Columns'} onClick={props.onEditColumns} isEnabled={true}/>
                </FilterTableToolbar>
                <FilterTable
                    headers={props.headers}
                    rows={props.rows}
                    onAddToCompsGroup={props.onAddToCompsGroup}/>
            </div>
        )
        : null;
}

const sortByColumn = (column: string) =>
    (ascending: boolean) =>
        (row1: FilterResultColumn[], row2: FilterResultColumn[]) => {
            const row1Value = row1.filter(i => i.column === column)[0]?.value;
            const row2Value = row2.filter(i => i.column === column)[0]?.value;

            if(row1Value === row2Value) {
               return 0;
            }

            const isBigger = row1Value > row2Value;

            if (ascending) {
                return isBigger ? 1 : -1;
            }
            return isBigger ? -1 : 1;
        }

function AddToCompsButton(props: { onClick: () => void, index: number }) {
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const toggle = () => setTooltipOpen(!tooltipOpen);

    return <>
        <CircleButton
            text={"+"}
            isEnabled={true}
            onClick={props.onClick}
            id={`add-to-comps-${props.index}`}
        />
        <Tooltip placement={"right"}
                 target={`add-to-comps-${props.index}`}
                 trigger={"hover"}
                 toggle={toggle}
                 isOpen={tooltipOpen}>
            Add to comps group
        </Tooltip>
    </>;
}

interface FilterTableParams {
    subHeaders?: ITableHeader[][];
    headers: ITableHeader[];
    rows: FilterResults;
    onAddToCompsGroup: (row: FilterResultRow) => () => void;
}

function FilterTable(props: FilterTableParams) {
    const [sortOptions, setSortOptions] = useState<{ id: string, ascending: boolean }>({
        id: '',
        ascending: true
    });


    function handleOnSort(header: string) {
        setSortOptions((prevState) => {
            if (prevState.id === header) {
                return {
                    id: header,
                    ascending: !prevState.ascending
                }
            }
            return {
                id: header,
                ascending: true
            }
        });
    }

    return (
        <table className={'filter-table'}>
            <thead>
                <tr>
                    <th className={'add-to-comps'}></th>
                    {
                        props.headers
                            .filter(header => header.id !== 'uid')
                            .map(header => <TableSortableHeader
                                key={header.id}
                                value={header.name}
                                onSort={() => handleOnSort(header.id)}
                                ascending={header.id === sortOptions.id ? sortOptions.ascending : true}
                                highlight={header.highlight}
                            />)
                    }
                </tr>
                {
                 props.subHeaders
                     ?(
                         <tr>
                            <th className={'add-to-comps'}></th>
                             {
                                props.subHeaders ?

                                    props.subHeaders
                                        .map(headers =>
                                        headers
                                            .filter(header => header.id !== 'uid')
                                            .map(header =>
                                                <TableHeader key={header.id} value={header.name} highlight={header.highlight}>
                                                    <></>
                                                </TableHeader>))
                                    : null
                             }
                        </tr>
                     )
                     : null
                }

            </thead>
            <tbody className={'filter-table-body'}>
            {
                props.rows
                    .sort(sortByColumn(sortOptions.id)(sortOptions.ascending))
                    .map((row, index) =>
                        <tr key={index}>
                            <td className={'add-to-comps'}>
                                <div>
                                    <AddToCompsButton
                                        onClick={props.onAddToCompsGroup(row)}
                                        index={index}
                                    />
                                </div>
                            </td>
                            {
                                row
                                    .filter(column => column.name !== 'uid')
                                    .map(column =>
                                        <TableCell
                                            key={column.column}
                                            value={formatNumber(column.value,
                                                column.type === 'integer_m'? 'integer_m_f': column.type)} />
                                    )
                            }
                        </tr>
                    )
            }
            </tbody>
        </table>
    )
}

export default EditableFilterTableContainer;


