import {FilterType, IFilter, IFilterCategoryNonExpandable} from "../../filtering/filter";
import Criterion from "../Criterion/Criterion";
import React from "react";
import "./CriteriaCategory.scss"

interface FilterCategoryNonExpandableProps {
    category: IFilterCategoryNonExpandable
}

function FilterCategoryNonExpandable(props: FilterCategoryNonExpandableProps) {
    return (
        <div className={"category-criteria"}>
            <Criterion bold={true} {...props.category.filter}/>
        </div>
    );
}

export default FilterCategoryNonExpandable;
