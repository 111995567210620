import './RanksTable.scss';
import React from 'react';
import {useSelector} from "react-redux";
import {selectRanks} from "../../comps/compsTableSlice";
import {formatNumber} from "../../utility/numberFormatter";
import {CompsTable} from "./CompsTableInput";

export default RanksTable;

interface IRanksCardProps {
    title: string,
    type: string,
    values: [string, number][],
}

function RanksCard(props: IRanksCardProps) {
    return (
        <div className={"ranks-table-card"}>
            <table className={"va-table"}>
                <thead>
                <tr>
                    <th colSpan={2}>{props.title}</th>
                </tr>
                </thead>
                <tbody>
                {
                    props.values.map(i =>
                        <tr key={i[0]}>
                            <td>{i[0]}</td>
                            <td>{formatNumber(i[1], props.type)}</td>
                        </tr>
                    )
                }
                </tbody>
            </table>
        </div>
    );
}

function RanksTable() {
    const ranks = useSelector(selectRanks);
    const mapToCard = Object.keys(ranks).map(key =>
        <RanksCard key={key} title={key} values={ranks[key].values} type={ranks[key].type}/>
    );

    return (
        <CompsTable className={'ranks-table'}>
            <div className={'ranks-table-cards'}>
                {mapToCard}
            </div>
        </CompsTable>
    )
}
