export default class RouteService {

    public static getChildRoutes(parentRoute: string | object): string[] {
        if (typeof (parentRoute) === 'string') {
            return []
        }

        const values: (string | object)[] = Object.values(parentRoute)

        if (values.length === 0) {
            return []
        }

        let result: string[] = []
        for (let value of values) {
            if (typeof (value) === 'string') {
                result.push(value)
            } else {
                const childRoutes = this.getChildRoutes(value)
                result = result.concat(childRoutes)
            }
        }
        return result
    }
}
