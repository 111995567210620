import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {IColumnData} from "./filterTable";



interface IColumnEditorSlice {
    columns: IColumnData[];
}

interface IColumnEditorSliceState {
    columnEditorSlice: IColumnEditorSlice;
}

export const columnEditorSlice = createSlice({
    name: 'columnEditorSlice',
    initialState: {
        columns: []
    } as IColumnEditorSlice,
    reducers: {
        setColumns: (state, action: PayloadAction<IColumnData[]>) => {
            state.columns = action.payload
        },
        addColumn: (state, action: PayloadAction<IColumnData>) => {
            const {id} = action.payload;
            const isSelected = state.columns
                .filter(i => i.id=== id).length > 0;

            if (isSelected) {
                return;
            }

            state.columns.push(action.payload);
        },
        removeColumn: (state, action) => {
            const {column} = action.payload;
            state.columns = state.columns
                .filter(i => i.id !== column);
        }
    }
});

export const {addColumn, removeColumn, setColumns} = columnEditorSlice.actions;

export const selectSelectedColumns = (state: IColumnEditorSliceState): IColumnData[] =>
    state.columnEditorSlice.columns;

export default columnEditorSlice.reducer;
