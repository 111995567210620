import './Modal.scss';
import React from 'react';
import {PrimaryButton, SecondaryButton} from "../Button/Button";
import {Container} from "../Container/Container";

export function Modal(props: { children: React.ReactNode, onCloseModal: () => void, hideCloseButton?: boolean }) {
    return (
        <div className={'va-modal'}>
            <Container>
                <div className={'container-wrap'}>
                    <div className={'close-button-wrap'} hidden={props.hideCloseButton}>
                        <PrimaryButton className={'x-button'} onClick={props.onCloseModal} isEnabled={true} text={'X'}/>
                    </div>
                    <div className={'children-wrap'}>
                        {props.children}
                    </div>
                </div>
            </Container>
        </div>
    );
}
