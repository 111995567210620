import './InputBox.scss';
import React, {ReactNode} from 'react';

export function InputBox(props: { children: ReactNode }) {
    return (
        <div className={'input-box'}>
            <div className={'picker-wrap'}>
                {props.children}
            </div>
        </div>
    )
}
