import {useEffect, useRef} from "react";

export function useFixElementOnDocumentScrollLeft() {
    const navRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        document.addEventListener('scroll', handleScroll, {passive: true});
        return () => document.removeEventListener('scroll', handleScroll);
    }, []);

    function handleScroll(e: Event) {
        const scrollLeft = document.documentElement.scrollLeft;
        if (navRef.current === null) {
            return;
        }

        navRef.current.style.left = `${scrollLeft}px`;
    }

    return {navRef}
}
