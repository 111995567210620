import searchIcon from "../../img/search-bar-icon.svg";
import React from "react-dom";
import './CriteriaSearchBar.scss';

function CriteriaSearchBar(props: { handleOnSearchForText: (searchText: string) => void }) {
    return (
        <div className={'search-criteria-bar'}>
            <div className={"search-criteria-bar-textarea"}>
                <img src={searchIcon}></img>
                <input
                    className={'roboto-regular-12'}
                    type={"text"}
                    id={"search-category-input"}
                    placeholder={"Filter businesses by ... "}
                    onChange={(e) => props.handleOnSearchForText(e.target.value)}
                />
            </div>
        </div>
    )
}

export default CriteriaSearchBar;
