import {InvoicePreview, lineItem} from "../../../api/requestsTypes";
import {useInvoiceLogic} from "./UseInvoiceLogic";
import {reSubscribe} from "../../../api/requests";
import {fetchSubMgmtData} from "../../../billing/billingSlice";
import {OrderSummary} from "./OrderSummary";
import {PrimaryButton} from "../../Button/Button";
import {OrderInfo} from "./OrderInfo";
import React from "react";

interface ReSubscribeUserProps {
    invoice: InvoicePreview
    users: any[]
    setShowInvoicePreview: (show: boolean) => void
    line_items: lineItem[]
    errors: string

}

export function ReSubscribeUserInvoice(props: ReSubscribeUserProps) {
    const {
        error,
        isLoading, isEnabled,
        performAction
    } = useInvoiceLogic(props)
    const user_id = props.invoice.invoice_preview_params.user_id
    const user_info = props.users.filter(user => {
        return user.id === user_id
    })[0]
    const button_text = 'Subscribe'
    const desc_text_1 = `Renewing ${user_info.name}'s subscription does not generate an immediate invoice. Your monthly subscription ` +
        ' total will be adjusted on the next billing cycle.'
    const desc_text_2 = `Press "Subscribe" to adjust your monthly total and renew ${user_info.name}'s subscription!` +
        ` ${user_info.name} can continue to use ${user_info.email} to access their account.`
    const due_text = 'Total due on next Invoice'
    const resubscribeUser = performAction(resubscribeUserAction)

    function resubscribeUserAction() {
        let payload = {
            ...props.invoice.invoice_preview_params,    // important to use the same params we sent to preview
            proration_date: props.invoice.proration_date // add the proration date to payload
        }
        return reSubscribe(payload)
    }

    return (
        <OrderSummary
            errors={props.errors}
            updateError={error}
            descText1={desc_text_1}
            descText2={desc_text_2}
            actionButton={
                <PrimaryButton className={'right-inline-button'}
                               text={button_text}
                               onClick={resubscribeUser}
                               isEnabled={isEnabled}
                               isLoading={isLoading}/>
            }
            orderInfo={
                <OrderInfo
                    lineItems={props.line_items}
                    dueText={due_text}
                    total={props.invoice.next_invoice.total}/>
            }
        />
    )
}
