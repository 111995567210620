import React from "react-dom";
import './FilterFlap.scss';
import '../../style/style.scss';
import '../../components/SearchCriteriaContainer/SearchCriteriaContainer.scss'
import CollapsibleArrow from "../CollapsibleArrow/CollapsibleArrow";
import {useSelector} from "react-redux";
import {selectIsFetchingFilters} from "../../filtering/filterSlice";
import {Loader} from "../Loader/Loader";

interface FilterFlapProps {
    handleFlapClicked: () => void;
    hasShadow: boolean;
    isFilterFlapOpen: boolean;
}

function FilterFlap(props: FilterFlapProps) {
    const isFetchingFilters = useSelector(selectIsFetchingFilters);
    const backgroundCssClass = props.hasShadow ? 'with-background' : '';

    return (
        <button className={'filter-flap'}
                onClick={() => props.handleFlapClicked()}>
            <CollapsibleArrow isExpanded={props.isFilterFlapOpen}/>
            <span className={'roboto-regular-14-padding'}> Filter List</span>
            {/*<Loader isLoading={isFetchingFilters}/>*/}
        </button>
    );
}

export default FilterFlap;
