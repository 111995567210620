export interface ISizePremium {
    title: string,
    data: string[][],
}

export interface IPremium extends ISizePremium {
    type: "premium"
}

export interface IMethodology extends ISizePremium {
    type: "methodology",
    description: string,
    whitePaperLink: string
}

export interface ISizePremiums {
    normalizedRiskFreeRates: ISizePremium
    fortyYearEstimationWindow: ISizePremium
    fiveYearTreasuryRateAsRiskFreeRate: ISizePremium
    baselineSizePremium: ISizePremium
    equityRiskPremium: ISizePremium
    equallyWeightedReturns: ISizePremium
    geometricAverageReturns: ISizePremium
    historicalERP: ISizePremium
    blumeAdjustedBeta: ISizePremium
}

const createEmptyPremium = (title: string): IPremium => {
    return {
        data: [],
        title: title,
        type: "premium"
    }
}

export const createMethodology = (): IMethodology => {
    return {
        type: "methodology",
        title: "Methodology",
        whitePaperLink: "",
        description: "The embedded white paper outlines the methodology used to calculate the size premiums, equity risk premiums, and risk-free rates below. Summary information is provided here.",
        data: [
            [
                "Title ",
                "Description"
            ],
            [
                "Normalized Risk-free Rate",
                "This table represents the average risk-free rate over the full historical window."
            ],
            [
                "Equity Risk Premium",
                "This table represents the excess return of equities above the normalized risk-free rate over the full historical window. The supply-side equity risk premium represents the historical equity risk premium less the portion of the premium attributable to multiples expansion.\n"
            ],
            [
                "Baseline Size Premium",
                "This table details size premiums following conventional calculation methodologies. Specifically, baseline size premiums reflect the normalized 20-year risk-free rate and the supply-side equity risk premium."
            ],
            [
                "Historical ERP",
                "This table follows all baseline calculation methodologies, except it uses the historical equity risk premium rather than the supply-side equity risk premium. This assumes that expansion of pricing multiples experienced historically will continue into the future."
            ],
            [
                "Equally-Weighted Returns",
                "This table follows all baseline calculation methodologies, except it assigns equal weight to all companies in each decile (rather than market capitalization weighting. Using equally-weighted returns, the largest firms in each decile will have reduced influence over the associated premiums. If valuing a firm whose size is not near the top of their corresponding decile, one should consider using the size premiums from this table."
            ],
            [
                "40 Year Estimation Window",
                "This table follows all baseline calculation methodologies, except the historical window is limited to the prior 40 years. A shorter estimation window emphasizes more recent market return behavior when estimating size premiums. Events in the distant past may convey more relevant information for highly mature industries whose operating environment has been unaffected by changing economic regimes. For budding or relatively new industries, the size premiums from this table may be more appropriate."
            ],
            [
                "Geometric Average Returns",
                "This table follows all baseline calculation methodologies, except size premiums now reflect the geometric average rather than the arithmetic average. The size premiums calculated using the geometric average may be more appropriate for multi-period discounted cash flow models wherein the discount rate compounds from one period to the next, though it is common to still use the baseline size premiums for this purpose."
            ],
            [
                "5 Year Treasury Rate as Risk Free Rate",
                "This table follows all baseline calculation methodologies, except the 20-year normalized risk-free rate is replaced with the 5-year normalized risk-free rate. The choice of risk-free rate should consider the holding period of the company being valued. If the willing buyer pool consists primarily of financial buyers, rather than strategic buyers, one should consider using the size premiums in this table.\n"
            ],
            [
                "Blume-Adjusted Beta",
                "This table follows all baseline calculation methodologies, except the beta calculation is replaced with the Bloom-adjusted beta. If using the Bloom-adjusted beta when building up the modified capital asset pricing model, one should consider using the size premiums in this table."
            ]
        ]
    }
}

export const createEmptySizePremiums = (): ISizePremiums => {
    return {
        fortyYearEstimationWindow: createEmptyPremium("40 Year Estimation Window"),
        fiveYearTreasuryRateAsRiskFreeRate: createEmptyPremium("5 Year Treasury Rate as Risk Free Rate"),
        baselineSizePremium: createEmptyPremium("Baseline Size Premium"),
        normalizedRiskFreeRates: createEmptyPremium("Normalized Risk Free Rates"),
        equityRiskPremium: createEmptyPremium("Equity Risk Premium"),
        historicalERP: createEmptyPremium("Historical ERP"),
        equallyWeightedReturns: createEmptyPremium("Equally-Weighted Returns"),
        geometricAverageReturns: createEmptyPremium("Geometric Average Returns"),
        blumeAdjustedBeta: createEmptyPremium("Blume-Adjusted Beta")
    }
}

