export const MANAGER_ROLE = "Account Manager"
export const USER_ROLE = "Team Member"
export const OWNER_ROLE = "Owner"
export const BVR_ROLE = "BVR_USER"
export const DEMO_ROLE = "DEMO_USER"

export const WEEKLY_NAME = "Week Pass"
export const MONTHLY_NAME = "Monthly Plan"
export const TRIAL_NAME = "One Week Trial"

export const EXPIRED_TERM = "Expired"