import SmallScreenAlert from "../Utility/SmallScreenAlert";
import React, {useState, useEffect} from "react";
import {Col, Container, Input, InputGroup, Label, Row} from "reactstrap";
import {Modal} from "../Modal/Modal";
import {TickerPicker} from "../TickerPicker/TickerPicker";
import {PrimaryButton} from "../Button/Button";
import CompsTab from "../CompsTab/CompsTab";
import {InputCheckbox} from "../Checkbox/InputCheckbox";
import './CompAssist.scss'
import {FilterTableContainer} from "../FilterTable/FilterTable";
import {getCompany} from "../../table/filterTable";
import {useDispatch, useSelector} from "react-redux";
import {
    fetchDistancingOptions,
    fetchNearestBussiness,
    ICompAssistColumn,
    resetSelections, selectAvailableTickers, selectCanSearchForCompanies,
    selectIsFetchingDistancingOptions, selectIsFetchingNearestBusinesses,
    selectSelectableColumnGroups,
    selectTableData,
    selectTicker,
    setColumn,
    setTicker
} from "../../compAssist/compAssitSlice";
import {FilterResultRow, TickerItem} from "../../types";
import {addCompsToGroup} from "../../comps/compsGroupSlice";
import {Loader} from "../Loader/Loader";

function CompAssistColumnCheckbox(props: { column: ICompAssistColumn }) {
    const dispatch = useDispatch();

    function handleOnChange(event: React.ChangeEvent<HTMLInputElement>) {
        dispatch(setColumn({
            ...props.column,
            selected: event.target.checked
        }));
    }

    return (
        <InputCheckbox onChange={handleOnChange} label={props.column.display_name} isChecked={props.column.selected}/>
    )
}

function CompAssistColumnGroup(props: { groupName: string, columns: ICompAssistColumn[] }): JSX.Element {
    return (
        <Col sm={6} md={4}>
            <Label className={'roboto-bold-18'}>{props.groupName}</Label>
            <hr className={'checkbox-group-line'}/>
            {props.columns.map((column) => <CompAssistColumnCheckbox key={column.column_name} column={column}/>)}
        </Col>
    )
}

function CompAssist() {
    const [showTickerPicker, setShowTickerPicker] = useState<boolean>(false);
    const [isCompsTabExpanded, setIsCompsTabExpanded] = useState(false);
    const dispatch = useDispatch();
    const columnGroups = useSelector(selectSelectableColumnGroups);
    const ticker = useSelector(selectTicker);
    const tableData = useSelector(selectTableData);
    const isFetchingDistancingOptions= useSelector(selectIsFetchingDistancingOptions);
    const isFetchingNearestBusiness = useSelector(selectIsFetchingNearestBusinesses);
    const canFindCompanies = useSelector(selectCanSearchForCompanies);
    const availableTickers = useSelector(selectAvailableTickers);

    useEffect(() => {
        dispatch(fetchDistancingOptions());
    }, []);

    function displayTickerPicker() {
        setShowTickerPicker(true);
    }

    function handleOnCloseTickerPicker() {
        setShowTickerPicker(false);
    }

    const handleOnAddToCompsGroup = (row: FilterResultRow) => () => {
        const company = getCompany(row);
        if (company === null) {
            return;
        }
        dispatch(addCompsToGroup(company));
        setIsCompsTabExpanded(true);
    }


    function handleOnCompsTabClicked() {
        setIsCompsTabExpanded(!isCompsTabExpanded);
    }


    function handleOnTickerPicked(ticker: TickerItem) {
        setShowTickerPicker(false);
        dispatch(setTicker(ticker))
    }

    return (
        <>
            <SmallScreenAlert/>
            <div className={'va-page comp-assist py-5'}>
            <Container fluid={true} >
                <Row className={'gy-5'} sm={12}>
                    <Col sm={12}>
                        <Row sm={12}>
                            <p className={'roboto-regular-14'}>Choose a ticker and find comparable companies based on
                                selected attributes.</p>
                        </Row>
                        <Row sm={12}>
                            <InputGroup>
                                <PrimaryButton
                                    text={'Select Ticker'}
                                    onClick={displayTickerPicker}
                                    isEnabled={!showTickerPicker}
                                />
                                <Input type={'text'}
                                       readOnly={true}
                                       value={ticker.ticker.length ? `${ticker.company_name} (${ticker.ticker})`: ''}/>
                            </InputGroup>
                        </Row>
                    </Col>
                    {
                    isFetchingDistancingOptions
                            ?(
                                <div><Loader isLoading={true}/></div>
                            )
                            :(
                                <>
                                     <Col sm={12}>
                                        <Row className={'gy-5 gx-3'}>
                                            {
                                                Object
                                                    .keys(columnGroups)
                                                    .map(groupName =>
                                                        <CompAssistColumnGroup
                                                            key={groupName}
                                                            groupName={groupName}
                                                            columns={columnGroups[groupName]}/>)
                                            }
                                        </Row>
                                    </Col>
                                   <Col sm={12}>
                                        <Row sm={12} className={'gy-5 gx-5'}>
                                            <Col md={3} lg={2}>
                                                <PrimaryButton
                                                    text={"Find Companies"}
                                                    onClick={() => dispatch(fetchNearestBussiness())}
                                                    isEnabled={canFindCompanies}
                                                    className={'form-button'}
                                                    isLoading={isFetchingNearestBusiness}
                                                />
                                            </Col>
                                            <Col md={3} lg={2}>
                                                <PrimaryButton text={"Reset"} onClick={() => dispatch(resetSelections())}
                                                               isEnabled={true} className={'form-button'}/>
                                            </Col>
                                        </Row>
                                    </Col>
                                </>
                        )
                    }
                    {
                        isFetchingNearestBusiness
                            ?(
                                <div><Loader isLoading={true}/></div>
                            )
                            :(
                                 <Col sm={12}>
                                    <FilterTableContainer
                                        onAddToCompsGroup={handleOnAddToCompsGroup}
                                        headers={tableData.headers}
                                        rows={tableData.rows}
                                        subHeaders={[tableData.subHeaders]}
                                    />
                                </Col>
                            )
                    }
                </Row>
            </Container>
            <CompsTab isExpanded={isCompsTabExpanded} onClick={handleOnCompsTabClicked}/>
            {
                showTickerPicker
                    ? (
                        <Modal onCloseModal={handleOnCloseTickerPicker}>
                            <TickerPicker
                                onTickerPicked={handleOnTickerPicked}
                                isLoading={isFetchingDistancingOptions}
                                tickers={availableTickers}
                            />
                        </Modal>
                    )
                    : null
            }
            </div>
        </>
    )
}

export default CompAssist;
