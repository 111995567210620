import {IIndustry, IIndustryGroupsResponse, ISicCodesData} from "./filterPageData";
import * as _ from "lodash";
import {ISelectableState} from "../filterPicker/filterPickerUtility";
import {ISicCodePicker} from "./filterStateTypes";

export interface ISectorState extends ISelectableState {
    sector_name: string,
    sector_id: string,
    industry_groups: IIndustryGroupState[]
}

export interface IIndustryGroupState extends ISelectableState {
    sector_name: string,
    sector_id: string
    industry_group_name: string,
    industry_group_id: string,
    industries: IIndustryState[]
}

export interface IIndustryState extends ISelectableState {
    sector_name: string,
    sector_id: string
    industry_group_name: string,
    industry_group_id: string,
    sic_code: string,
    industry_name: string
}

export function getSectorsFromSicCodes(filterState: ISicCodePicker, sicCodes: ISicCodesData[]): ISectorState[] {
    return sicCodes.map(sector => createSectorState(filterState, sector)) as ISectorState[];
}

export function getSectorFromSectors(sicCodeState: ISectorState[], sector_id: string) {
    return sicCodeState.find(i => i.sector_id === sector_id);
}

export function getIndustryGroupFromIndustryGroups(industryGroups: IIndustryGroupState[], industry_id: string) {
    return industryGroups.find(i => i.industry_group_id === industry_id);
}

export function getIndustryFromIndustries(industries: IIndustryState[], sic_codes: string, industry_name: string) {
    return industries.find(i => i.sic_code === sic_codes && i.industry_name === industry_name);
}

export function isAnyIndustrySelected(industryGroup: IIndustryGroupState) {
    return _.some(industryGroup.industries,
        industry => industry.isSelected);
}

export function isAnyIndustryGroupOrChildrenSelected(sector: ISectorState) {
    return _.some(sector.industry_groups,
        industryGroup => industryGroup.isSelected ||
            industryGroup.isChildSelected);
}

function createSectorState(filterState: ISicCodePicker, sector: ISicCodesData): ISectorState {
    const isSelected = filterState.sector_id.includes(sector.sector_id);
    const isChildSelected = isAnySectorChildrenSelected(sector, filterState);

    return {
        isSelected: isSelected,
        isChildSelected: isChildSelected,
        ...sector,
        industry_groups: createIndustryGroups(isSelected, filterState, sector),
    };
}

function isAnySectorChildrenSelected(sector: ISicCodesData, filterState: ISicCodePicker) {
    return getAllSectorsSelectedIndustryGroupIds(sector, filterState).length > 0 ||
        getAllSectorsSelectedSicCodes(sector, filterState).length > 0;
}

function getAllSectorsSelectedIndustryGroupIds(sector: ISicCodesData, filterState: ISicCodePicker): string[] {
    return _.intersection(
        sector.industry_groups
            .map(i =>
                i.industry_group_id),
        filterState.industry_group_id
    );
}

function getAllSectorsSelectedSicCodes(sector: ISicCodesData, filterState: ISicCodePicker): string[] {
    return _.intersection(
        filterState.sic_code,
        sector.industry_groups.flatMap(i => i.industries.map(i => i.sic_code))
    );
}

function createIndustryGroups(isParentSelected: boolean, filterState: ISicCodePicker, sector: ISicCodesData) {
    return sector.industry_groups
        .map(industryGroup => createIndustryGroupState(isParentSelected, filterState, sector, industryGroup));
}

function createIndustryGroupState(isParentSelected: boolean, filterState: ISicCodePicker, sector: ISicCodesData, industryGroup: IIndustryGroupsResponse): IIndustryGroupState {
    const isSelected = isParentSelected ||
        filterState.industry_group_id
            .includes(industryGroup.industry_group_id);
    const isChildSelected = getAllIndustryGroupSelectedSicCodes(filterState, industryGroup).length > 0;

    return {
        sector_name: sector.sector_name,
        sector_id: sector.sector_id,
        isSelected: isSelected,
        isChildSelected: isChildSelected,
        ...industryGroup,
        industries: createIndustryStates(isSelected, filterState, industryGroup, sector),
    };
}

function getAllIndustryGroupSelectedSicCodes(filterState: ISicCodePicker, industryGroup: IIndustryGroupsResponse) {
    return _.intersection(
        filterState.sic_code,
        industryGroup.industries.flatMap(i => i.sic_code)
    );
}

function createIndustryStates(isParentSelected: boolean, filterState: ISicCodePicker, industryGroup: IIndustryGroupsResponse, sector: ISicCodesData) {
    return industryGroup.industries
        .map(industry => createIndustryState(isParentSelected, filterState, sector, industryGroup, industry)) as IIndustryState[];
}

function createIndustryState(
    isParentSelected: boolean,
    filterState: ISicCodePicker,
    sector: ISicCodesData,
    industryGroup: IIndustryGroupsResponse,
    industry: IIndustry): IIndustryState {
    const isSelected = isParentSelected ||
        filterState.sic_code.includes(industry.sic_code);

    return {
        sector_name: sector.sector_name,
        sector_id: sector.sector_id,
        industry_group_name: industryGroup.industry_group_name,
        industry_group_id: industryGroup.industry_group_id,
        isSelected: isSelected,
        isChildSelected: false,
        ...industry
    };
}
