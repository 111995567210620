import awsmobile from "./aws-exports";
import {config} from './config';

const currentConfig = {
    ...awsmobile,
    API: {
        endpoints: [
            {
                name: "Api",
                endpoint:config.api_endpoint
            },
        ]
    }
};

export default currentConfig;
