import {configureStore} from '@reduxjs/toolkit'
import {selectedFilterSlice} from "./filtering/selectedFilterSlice";
import {authSlice} from "./auth/authSlice";
import {sectorSlice} from "./filtering/sectorSlice";
import pinnedFilterSlice from "./filtering/pinnedFilterSlice";
import {filterSlice} from "./filtering/filterSlice";
import {savedFilterSlice} from "./filtering/savedFilterSlice";
import {signUpSlice} from "./signup/signUpSlice";
import {filterTableSlice} from './filtering/filterTableSlice';
import {selectedCompsGroupSlice} from "./comps/compsGroupSlice";
import {compsTableSlice} from "./comps/compsTableSlice";
import {columnEditorSlice} from "./table/columnEditor";
import {compsTickerPickerSlice} from "./comps/compsTickerPickerSlice";
import {savedUserCompSetSlice} from "./comps/savedCompsSets";
import {billingSlice} from './billing/billingSlice';
import {compAssistSlice} from "./compAssist/compAssitSlice";
import {sizePremiumsSlice} from "./sizePremiums/sizePremiumsSlice";
import {documentationSlice} from "./documentationSlice/documentationSlice";


export const store = configureStore({
    reducer: {
        compsTickerPickerSlice: compsTickerPickerSlice.reducer,
        filterSlice: filterSlice.reducer,
        selectedFilterSlice: selectedFilterSlice.reducer,
        authSlice: authSlice.reducer,
        sectorSlice: sectorSlice.reducer,
        pinnedFilterSlice: pinnedFilterSlice.reducer,
        savedFilterSlice: savedFilterSlice.reducer,
        filterTableSlice: filterTableSlice.reducer,
        selectedCompsGroupSlice: selectedCompsGroupSlice.reducer,
        compsTableSlice: compsTableSlice.reducer,
        columnEditorSlice: columnEditorSlice.reducer,
        signUpSlice: signUpSlice.reducer,
        savedUserCompSetSlice: savedUserCompSetSlice.reducer,
        billingSlice: billingSlice.reducer,
        compAssistSlice: compAssistSlice.reducer,
        sizePremiumSlice: sizePremiumsSlice.reducer,
        documentationSlice: documentationSlice.reducer
    }
});

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch




