import './TableColumnEditorSelectedColumn.scss'
import {List} from "../List/List";
import {SortArrows} from "../SortArrows/SortArrows";
import {SecondaryButton} from "../Button/Button";
import {IColumnData} from "../../table/filterTable";

export default TableColumnEditorSelectedColumn;

interface ListItemProps {
    id: number | string;
    value: string;
    onClick?: () => void;
    onMoveColumnDown: () => void;
    onMoveColumnUp: () => void;
}

function ListItem(props: ListItemProps) {
    return (
        <div className={'list-item'} >
            <div className={'arrows-wrap'}>
                <SortArrows
                    onMoveUp={props.onMoveColumnUp}
                    onMoveDown={props.onMoveColumnDown}
                />
            </div>
            <span className={'text-wrap'}>
                {props.value}
            </span>
            <div className={'delete-button'} onClick={props.onClick}>
                <SecondaryButton
                    text={'X'}
                    onClick={() => null}
                    isEnabled={true}
                />
            </div>
        </div>
    );
}

interface TableColumnEditorSelectedColumnProps {
    columns: IColumnData[]
    onColumnRemoved: (data: IColumnData) => void;
    onMoveColumnDown: (data: IColumnData) => void;
    onMoveColumnUp: (data: IColumnData) => void;
}

function TableColumnEditorSelectedColumn(props: TableColumnEditorSelectedColumnProps) {
    const items = props.columns
        .map(column => (
            {
                id: column.id,
                name: column.name
            }
        ));

    const mapper = (item: { id: string, name: string }) =>
        <ListItem
            id={item.id}
            value={item.name}
            onClick={() => props.onColumnRemoved({
                id: item.id,
                name: item.name
            })}
            onMoveColumnDown={() => props.onMoveColumnDown(item)}
            onMoveColumnUp={() => props.onMoveColumnUp(item)}
        />


    return <List headers={[]} items={items} children={mapper}/>
}
