import {useState} from "react";

export function useSignOutButtonLogic() {
    const [showSignOut, setShowSignOut] = useState(false);

    function handlePortraitClicked() {
        if (showSignOut) {
            setShowSignOut(false);
        } else {
            setShowSignOut(true);
        }
    }

    return {showSignOut, setShowSignOut, handlePortraitClicked};
}
