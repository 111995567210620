import './TableTab.scss';

import React from 'react';
import { Loader } from '../Loader/Loader';

interface TableTabProps {
    name: string;
    isActive: boolean;
    isLoading: boolean;
}

export function TableTab(props: TableTabProps) {
    const className = props.isActive
        ? 'table-tab tab-active'
        : 'table-tab tab-inactive';

    return (
        <div className={className}>
            <div className={'table-tab-text'}>
                { props.isLoading ? <Loader isLoading={true}/> : props.name    }

            </div>
        </div>
    )
}
