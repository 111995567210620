import './List.scss';
import React from 'react';

import {IdObj} from "../../types";

export interface IListProps<T> {
    children: (item: T) => React.ReactNode;
    items: T[];
    headers: string[];
}

export function List<T extends IdObj>(props: IListProps<T>) {
    return (
        <div className={'list'}>
            <table>
                <thead>
                    <tr>
                        {props.headers.map(i => <th key={i}>{i}</th>)}
                    </tr>
                </thead>
                <tbody>
                    {props.items.map(i =>
                            props.children(i)
                    )}
                </tbody>
            </table>
        </div>
    )
}
