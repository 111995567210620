import searchBarIcon from "../../img/search-bar-icon.svg";
import React from "react";
import './FilterSearchBar.scss'

interface IFilterSearchBarProps {
    handleSearch: (text: string) => void;
    searchText?: string;
    placeholder?: string;
}

function FilterSearchBar(props: IFilterSearchBarProps) {
    return (
        <div className={"filter-search-bar"}>
            <img src={searchBarIcon}/>
            <input
                type={"text"}
                placeholder={props.placeholder == undefined? 'search': props.placeholder}
                value={props.searchText}
                onChange={(e) => props.handleSearch(e.target.value)}
            />
        </div>
    );
}

export default FilterSearchBar;
