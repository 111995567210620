import {UncontrolledTooltip} from "reactstrap"
import React from "react";
import {ImInfo} from "react-icons/im"


interface InfoTipProps{
    message: string;
    placement: "auto-start" | "auto" |
        "auto-end" | "top-start" | "top" | "top-end"
        | "right-start" | "right" | "right-end" | "bottom-end"
        | "bottom" | "bottom-start" | "left-end" | "left" | "left-start" | undefined,
    size: number;
    id: string
}

export function InfoTip(props:InfoTipProps) {

    return (
        <div className={'tool-tip-container'}>
            <ImInfo
            className={'info-image'}
            size={props.size}
            id = {`info-image-${props.id}`}
            ></ImInfo>
            <UncontrolledTooltip
                className={'tool-tip'}
                innerClassName={"tool-tip roboto-regular-12"}
                placement={props.placement}
                target={`info-image-${props.id}`}>
                {props.message}

            </UncontrolledTooltip>
        </div>
    )
}