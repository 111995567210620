import './CompsTableContainer.scss'
import React, {useState} from 'react';
import {TableTabContainer} from "../Table/TableTabContainer";
import {Container} from "../Container/Container";
import {TableTitle, TableToolBar} from "../Table/TableToolBar";
import {ExportButton} from "../Button/Button";
import {selectCompsetTableResults, selectIsFetchingCompset} from "../../comps/compsTableSlice";
import {useSelector} from "react-redux";

function LeftToolBar(props: {
    title: string;
}) {
    return <TableTitle title={`Guideline Public Companies - ${props.title}`}/>;
}

interface RightToolBarProps {
    onEditColumnsButtonClicked: () => void;
    onExportButtonClicked: () => void;
    isEnabled: boolean;
    isExporting: boolean;
}

function RightToolBar(props: RightToolBarProps) {
    return (
        <ExportButton
            text={"Export"}
            onClick={props.onExportButtonClicked}
            isEnabled={props.isEnabled}
            isLoading={props.isExporting}
        />
    )
}

interface CompsTableContainerProps {
    tabs: {
        [key: string]: {
            title: string,
            table: () => JSX.Element
        }
    };
    onShowColumnEditor: () => void;
    onExportButtonClicked: () => void;
    isExporting: boolean;
}

export function CompsTableContainer(props: CompsTableContainerProps) {
    const isFetchingCompset = useSelector(selectIsFetchingCompset)
    const compsetResults = useSelector(selectCompsetTableResults)
    const tabNames = Object.keys(props.tabs);
    const [currentTab, setCurrentTab] = useState(tabNames[0]);

    function handleOnTabChange(name: string) {
        setCurrentTab(name);
    }

    function handleOnColumnEditorButtonClicked() {
        props.onShowColumnEditor();
    }

    function handleOnExportButtonClicked() {
        props.onExportButtonClicked();
    }

    function isExportButtonEnabled() {
        return !isFetchingCompset && compsetResults.data_found !== 'none'
    }

    return (
        <div className={'comps-table-container'}>
            <div className={'tabs'}>
                <TableTabContainer tabNames={tabNames} onTabChange={handleOnTabChange} isLoading={isFetchingCompset}/>
            </div>
            <Container className={'toolbar-table-wrap'}>
                <div>
                    <TableToolBar
                        left={<LeftToolBar title={props.tabs[currentTab].title}/>}
                        right={<RightToolBar
                            onEditColumnsButtonClicked={handleOnColumnEditorButtonClicked}
                            onExportButtonClicked={handleOnExportButtonClicked}
                            isEnabled={isExportButtonEnabled()}
                            isExporting={props.isExporting}
                        />
                        }
                    />
                </div>
                <div>
                    {props.tabs[currentTab].table()}
                </div>
            </Container>
        </div>

    )

}

