export const Routes = {
    main: '/main',
    comps: '/comps',
    filtering: '/filtering',
    documentation: '/documentation',
    aboutUs: '/about-us',
    login: '/login',
    signUp: '/signup',
    createAccount: '/create-account',
    account: '/account',
    excelDocumentation: '/excel',
    privacy: '/privacy',
    terms: '/terms',
    upgradeAccount: '/upgrade-account',
    homePage: '/home',
    compAssist: '/comp-assist',
    sizePremiums:{
        methodology: '/size-premiums/methodology',
        baselineSizePremiums: '/size-premiums/baseline-size-premiums',
        equityRiskPremium: '/size-premiums/equity-risk-premiums',
        normalizedRiskFreeRates: '/size-premiums/normalized-risk-free-rates',
        equallyWeightedReturns: '/size-premiums/equally-weighted-returns',
        geometricAverageReturns: '/size-premiums/geometric-average-returns',
        fiveYearTreasuryRateAsRiskFreeRate : '/size-premiums/five-year-treasury-rate-as-risk-free-rate',
        historicalERP: '/size-premiums/historical-erp',
        fortyYearEstimationWindow: '/size-premiums/forty-year-estimation-window',
        bloomAdjustedBeta: '/size-premiums/bloom-adjusted-beta',
    }
}

export const ActiveSubscriptionRequiredRoutes = [
    Routes.filtering,
    Routes.comps,
    Routes.compAssist
]
