import React from "react"
import "./InvoiceForm.scss"
import {useSelector} from 'react-redux'
import {getInvoice, getLineItems, getPlanType, getUsers} from '../../../billing/billingSlice';
import {InvoiceAction} from "../../../billing/invoice";
import {ReSubscribeManager} from "./ReSubscribeManager";
import {ReSubscribeManagerInvoice} from "./ReSubscribeManagerInvoice";
import {OwnerSubscribeUserInvoice, SubscribeUserInvoice} from "./SubscribeUserInvoice";
import {ReSubscribeUserInvoice} from "./ReSubscribeUserInvoice";
import {CancelManagerInvoice} from "./CancelManagerInvoice";
import {CancelUserInvoice} from "./CancelUserInvoice";
import {DeleteUserInvoice} from "./DeleteUserInvoice";
import {MonthlyUpgradeInvoice} from "./MonthlyUpgradeInvoice";


interface InvoicePreviewProps {
    invoiceAction: InvoiceAction
    setShowInvoicePreview: (state: boolean) => void
    newUserEmail: string
    newUserName: string
}


function InvoiceForm(props: InvoicePreviewProps) {
    const invoice = useSelector(getInvoice)
    const line_items = useSelector(getLineItems)
    const plan = useSelector(getPlanType)
    const users = useSelector(getUsers)

    let errors = ''
    if (!('next_invoice' in invoice) && props.invoiceAction !== 'subscribe_owner_user') {
        errors = 'Error retrieving invoice preview. Please contact support@valueanalytics.'
    }

    // set the invoice based on the invoice type
    let showInvoice = () => {
        switch (props.invoiceAction) {
            case 'monthly_upgrade':
                return (
                    <MonthlyUpgradeInvoice
                        invoice={invoice}
                        setShowInvoicePreview={props.setShowInvoicePreview}
                        line_items={line_items}
                        errors={errors}
                    />
                )
            case 'subscribe_user':
                return (
                    <SubscribeUserInvoice
                        newUserEmail={props.newUserEmail}
                        newUserName={props.newUserName}
                        invoice={invoice}
                        setShowInvoicePreview={props.setShowInvoicePreview}
                        line_items={line_items}
                        errors={errors}
                    />
                )
            case 'subscribe_owner_user':
                return (
                    <OwnerSubscribeUserInvoice
                        newUserEmail={props.newUserEmail}
                        newUserName={props.newUserName}
                        invoice={invoice}
                        setShowInvoicePreview={props.setShowInvoicePreview}
                        line_items={line_items}
                        errors={errors}
                    />
                )
            case 'cancel_with_active_users':
            case 'cancel_sole_manager':
                return (
                    <CancelManagerInvoice
                        invoice={invoice}
                        setShowInvoicePreview={props.setShowInvoicePreview}
                        line_items={line_items}
                        users={users}
                        plan={plan}
                        errors={errors}
                        invoiceAction={props.invoiceAction}
                    />
                )
            case 're_subscribe_manager_monthly':
                return <ReSubscribeManagerInvoice
                    invoice={invoice}
                    setShowInvoicePreview={props.setShowInvoicePreview}
                    line_items={line_items}
                    errors={errors}
                />
            case 're_subscribe_user':
                return <ReSubscribeUserInvoice
                    users={users}
                    invoice={invoice}
                    setShowInvoicePreview={props.setShowInvoicePreview}
                    line_items={line_items}
                    errors={errors}
                />
            case 'cancel_user':
                return <CancelUserInvoice
                    invoice={invoice}
                    setShowInvoicePreview={props.setShowInvoicePreview}
                    line_items={line_items}
                    users={users}
                    plan={plan}
                    errors={errors}
                />
            case 'delete_user':
                return <DeleteUserInvoice
                    invoice={invoice}
                    setShowInvoicePreview={props.setShowInvoicePreview}
                    line_items={line_items}
                    users={users}
                    plan={plan}
                    errors={errors}
                />
            case 're_subscribe_manager':
                return <ReSubscribeManager
                    invoice={invoice}
                    setShowInvoicePreview={props.setShowInvoicePreview}
                    line_items={line_items}
                    errors={errors}
                />
            case 'nothing':
            default:
                return <p>Not Implemented</p>
        }
    }

    return (
        showInvoice()
    )

}


export default InvoiceForm
