import React from 'react';
import documentIcon from '../../img/document.svg';
import videoIcon from '../../img/youtube-play.png'

function Document(props: any) {
  return (
    <div className='document'>
      <img src={props.video ? videoIcon: documentIcon} alt=''></img>
      <p><span className='name'><a href={props.url} target={props.new_tab ? '_blank' : ''}>{props.name}</a></span> {props.desc}</p>
    </div>
  )
}

export default Document;