import React, {useEffect, useState} from "react";
import {CategoryLabel} from "../CategoryLabel/CategoryLabel";
import CategoryExpansionToggle from "../CategoryExpansionToggle/CategoryExpansionToggle";
import {CriteriaList} from "../CriteriaList/CriteriaList";
import "./CriteriaCategory.scss"
import {IFilterCategoryExpandable} from "../../filtering/filter";

export interface FilterCategoryProps {
    category: IFilterCategoryExpandable;
    isSearchingForText: boolean;
}

export function FilterCategoryExpandable(props: FilterCategoryProps) {
    const [isExpanded, setIsExpanded] = useState(false);

    function handleIsExpanded(expand: boolean) {
        setIsExpanded(props.isSearchingForText ? true : expand);
    }

    useEffect(() => {
        handleIsExpanded(isExpanded);
    }, [props.isSearchingForText])

    return (
        <div className={"category-criteria"}>
            <div className={"category-toggle"}>
                <CategoryExpansionToggle handleIsExpanded={handleIsExpanded} isExpanded={isExpanded}/>
            </div>
            <div className={'roboto-bold-16 category-name'}>
                <CategoryLabel category={props.category.table}></CategoryLabel>
            </div>
            {
                isExpanded ?
                    <CriteriaList criteria={props.category.filters}></CriteriaList>
                    : null
            }
        </div>
    );
}

