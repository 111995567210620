import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {RootState} from "../store";
import {getPreSignedUrl, isPreSignedUrlExpired} from "../api/preSignedUrlRequest";
import {resetStateOnSignOut} from "../auth/signOut";

type Documents = { [s3Prefix: string]: string }

interface DocumentationSlice {
    documents: Documents
}

export const fetchPreSignedDocumentationLink = createAsyncThunk<{ s3Prefix: string, url: string }, string, {
    state: RootState
}>(
    'sizePremiumsSlice/fetchWhitePaperLink',
    async (s3Prefix, {getState, rejectWithValue}) => {
        const documents = getState().documentationSlice.documents
        let url = ''

        if (s3Prefix in documents) {
            url = getState().documentationSlice.documents[s3Prefix]
        }

        if (url === '' || await isPreSignedUrlExpired(url)) {
            try {
                const response = await getPreSignedUrl(s3Prefix)
                return {
                    s3Prefix: s3Prefix,
                    url: response.url
                }
            } catch (e) {
                rejectWithValue({
                    s3Prefix: s3Prefix,
                    url: ''
                })
            }

        }

        return {
            s3Prefix: s3Prefix,
            url: url
        }
    })

const initialState: DocumentationSlice = {
    documents: {}
};

export const documentationSlice = createSlice({
    name: "documentationSlice",
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(fetchPreSignedDocumentationLink.fulfilled, (state, action) => {
            const {s3Prefix, url} = action.payload
            state.documents[s3Prefix] = url
        })

        resetStateOnSignOut(builder, initialState)
    }
})

export const selectDocuments = (state: RootState): Documents => {
    return state.documentationSlice.documents
}
