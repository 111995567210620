import {Table} from 'reactstrap'

export default function Premiums(props: { title: string, data: string[][] }) {
    const headers = props.data.length > 0 ? props.data[0] : []
    const rows = props.data.slice(1);

    return (
        <div>
            <h1 className={'font-blue-modal-header'}>{props.title}</h1>
            <Table className={'my-3'}>
                <thead>
                <tr>
                    {headers.map(i => <td key={i}>{i}</td>)}
                </tr>
                </thead>
                <tbody>
                {rows.map((columns, index) =>
                    <tr key={index}>
                        {columns.map((i, index) =>
                            <td key={index}>{i}</td>
                        )}
                    </tr>
                )}
                </tbody>
            </Table>
        </div>
    )
}
