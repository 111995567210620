import {IFilter} from "./filter";
import {ISectorState} from "./sicCodeState";
import {
    FilterState,
    IKeywordSearchFilter,
    INumericFilter,
    IPickerFilter,
    ISicCodePicker,
    KeywordSearchFilters,
    RegularFilterPickers
} from "./filterStateTypes";
import * as _ from 'lodash';
import {FilterKeywordSearchProps} from "../components/FilterContainer/FilterKeywordSearch";
import numeral from "numeral";

function initNumericFilter(index: number, filter: IFilter): INumericFilter {
    return {
        type: "numeric",
        column: filter.category,
        filterType: filter.value,
        range: [0, Infinity],
        displayName: filter.name,
        filterCount: 0,
        ordinal: index,
        displayType: filter.displayType
    };
}

function initSicCodePicker(index: number, filter: IFilter): ISicCodePicker {
    return {
        type: "sicCodePicker",
        sic_code: [],
        industry_group_id: [],
        sector_id: [],
        displayName: filter.name,
        filterCount: 0,
        ordinal: index,
        displayType: filter.displayType
    };
}

function initPickerFilter(index: number, filter: IFilter): IPickerFilter {
    return {
        type: "picker",
        displayName: filter.name,
        filterType: filter.category as RegularFilterPickers,
        value: [],
        filterCount: 0,
        ordinal: index,
        displayType: filter.displayType
    }
}

function initKeywordSearch(index: number, filter: IFilter): IKeywordSearchFilter {
    return {
        type: "keywordsearch",
        displayName: filter.name,
        filterType: filter.category as KeywordSearchFilters,
        value: "",
        filterCount: 0,
        ordinal: index,
        displayType: filter.displayType
    }
}

export function initFilterState(index: number, filter: IFilter): FilterState {
    switch (filter.type) {
        case "numeric":
            return initNumericFilter(index, filter);
        case "picker":
            return initPickerFilter(index, filter);
        case "sicCodePicker":
            return initSicCodePicker(index, filter);
        case "keywordsearch":
            return initKeywordSearch(index, filter);
    }
}

export function getUid(filter: FilterState): string {
    switch (filter.type) {
        case "numeric":
        case "keywordsearch":
        case "picker":
            return filter.filterType;
        case "sicCodePicker":
            return filter.type;
    }
}

export function getSicCodePickerState(
    filterState: ISicCodePicker,
    sicCodeStates: ISectorState[]
): FilterState {
    let sectors: string[] = [];
    let industryGroups: string[] = [];
    let industries: string[] = [];

    for (let sector of sicCodeStates) {
        if (sector.isSelected) {
            sectors.push(sector.sector_id);
            continue;
        }
        if (!sector.isChildSelected) {
            continue;
        }
        for (let industryGroup of sector.industry_groups) {
            if (industryGroup.isSelected) {
                industryGroups.push(industryGroup.industry_group_id);
                continue;
            }
            if (!industryGroup.isChildSelected) {
                continue;
            }
            for (let industry of industryGroup.industries) {
                if (industry.isSelected) {
                    industries.push(industry.sic_code);
                }
            }
        }
    }

    return {
        ...filterState,
        sic_code: industries,
        industry_group_id: industryGroups,
        sector_id: sectors,
    };
}

export function getNumericFilterStateWithMin(min: string, filter: INumericFilter): INumericFilter {
    const value = numeral(min).value();
    return {
        ...filter,
        range: [value === null ? 0 : value, filter.range[1]]
    }
}

export function getNumericFilterStateWithMax(max: string, filter: INumericFilter): INumericFilter {
    const value = numeral(max).value();
    return {
        ...filter,
        range: [filter.range[0], value === null ? Infinity : value]
    }
}

export function removePickerValues(filter: IPickerFilter, values: string[]): IPickerFilter {
    return {
        ...filter,
        value: _.without(filter.value, ...values)
    };
}

export function removePickerValue(filter: IPickerFilter, value: string): IPickerFilter {
    return {
        ...filter,
        value: _.without(filter.value, value)
    };
}

export function addPickerValues(filter: IPickerFilter, values: string[]): IPickerFilter {
    return {
        ...filter,
        value: _.union(filter.value, values)
    };
}

export function addPickerValue(filter: IPickerFilter, value: string): IPickerFilter {
    return {
        ...filter,
        value: _.union(filter.value, [value])
    };
}

export function getKeywordSearchState(props: FilterKeywordSearchProps, value: string) {
    return {
        ...props.filter,
        value: value
    };
}
