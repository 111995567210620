import React from 'react';
import './Footer.scss';
import logo from '../../img/va-2.svg';
import {Col, Container, Row} from "reactstrap";
import {Image} from "react-bootstrap";
import {Link} from "react-router-dom";
import {Routes} from "../../routes/routes";

function Footer() {
    const currentYear = new Date().getFullYear()
    return (
        <div className="footer">
            <Container fluid={true}>
                <Row className={'gy-3'}>
                    <Col lg={2} md={3} xs={6}>
                        <div className={'footer-logo'}>
                            <Image className="footer-image" alt="logo" src={logo}/>
                            <p>&#169; {currentYear} Value Analytics LLC</p>
                        </div>
                    </Col>
                    <Col lg={2} md={3} xs={6}>
                        <div>
                            <h3>Legal</h3>
                            <Link to={Routes.privacy}>Privacy Policy</Link>
                            <Link to={Routes.terms}>User Agreement</Link>
                        </div>
                    </Col>
                    <Col lg={2} md={3} xs={6}>
                        <div>
                            <h3>Customer Care</h3>
                            <p>support@valueanalytics.org</p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default Footer;
