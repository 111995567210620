import {IFilterPageData, IFilterData} from "./filterPageData";
import * as _ from 'lodash';

export interface IFilterDictionary {
    [column: string]: IFilter
}

export interface IFilterCategoryExpandable {
    table: string;
    filters: IFilter[];
}

export interface IFilterCategoryNonExpandable {
    filter: IFilter;
}

export interface IFilter {
    type: FilterType;
    category: string;
    value: string;
    name: string;
    displayType: string;
    bold?: boolean;
}

export type FilterType = "numeric" | "picker" | "sicCodePicker" | "keywordsearch";

export function getExpandableFilterCategoriesFrom(filters: IFilter[]): IFilterCategoryExpandable[] {
    return _
        .chain(filters)
        .filter(isExpandableFilter)
        .groupBy(filter => filter.category)
        .map((filterList, table) => ({table: table, filters: filterList}))
        .value();
}

export function getNonExpandableFilterCategoriesFrom(filters: IFilter[]): IFilterCategoryNonExpandable[] {
    return _
        .chain(filters)
        .filter(isNonExpandableFilter)
        .map(filter => ({
            filter: filter
        }))
        .value();
}

function isNonExpandableFilter(filter: IFilter) {
    return filter.type === "sicCodePicker" ||
        filter.type === "picker" ||
        filter.type === "keywordsearch";
}

function isExpandableFilter(filter: IFilter) {
    return filter.type === "numeric";
}

export interface IFilterCounter {
    filterCount: number;
}

export function getSearchableFilters(filterResponse: IFilterPageData): IFilter[] {
    const sectorIndustryFilter: IFilter = {
        type: "sicCodePicker",
        value: "sic_codes",
        name: "SIC Codes",
        category: "sic_codes_table",
        displayType: 'text'
    };

    const exchangesFilter: IFilter = {
        category: 'exchanges',
        name: 'Exchange',
        type: 'picker',
        value: 'exchanges',
        displayType: 'text'
    }

    const yearsOfDataFilter: IFilter = {
        category: 'years_of_data',
        name: 'Years of Financial Data',
        type: 'picker',
        value: 'years_of_data',
        displayType: 'integer'
    }

    const stateHqFilter: IFilter = {
        category: 'state_hq',
        name: 'Corporate HQ',
        type: 'picker',
        value: 'state_hq',
        displayType: 'text'
    }

    const businessDescriptionFilter: IFilter = {
        category: 'business_description',
        name: 'Business Description',
        type: 'keywordsearch',
        value: 'business_description',
        displayType: 'text'
    }

    const filters: { name: string, value: IFilterData[] }[] = [
        {name: "numeric_filters", value: filterResponse['Numeric Filters']}
    ];

    return filters
        .flatMap(i => i.value
            .flatMap(
                j => getPageDataFilter(i.name, j)
            )
        )
        .concat(sectorIndustryFilter)
        .concat(exchangesFilter)
        .concat(yearsOfDataFilter)
        .concat(stateHqFilter)
        .concat(businessDescriptionFilter)
}

function getNumericFilters(pageData: IFilterData): IFilter[] {
    return pageData
        .filter_values
        .map(i => ({
            type: "numeric",
            value: i.value,
            name: i.name,
            category: pageData.filter_category,
            displayType: i.type
        }));
}

function getPickerFilter(name: string, pageData: IFilterData): IFilter {
    return {
        type: "picker",
        category: name,
        name: pageData.filter_category,
        value: pageData.filter_category,
        displayType: 'text'
    }
}

function getKeywordSearchFilter(name: string, pageData: IFilterData): IFilter {
    return {
        type: "keywordsearch",
        category: pageData.filter_category,
        name: name,
        value: pageData.filter_category,
        displayType: 'text'
    };
}

export function getPageDataFilter(name: string, pageData: IFilterData): IFilter[] {
    switch (pageData.filter_type) {
        case "numeric":
            return getNumericFilters(pageData)
        case "picker":
            return [getPickerFilter(name, pageData)]
        case "keywordsearch":
            return [getKeywordSearchFilter(name, pageData)]
        default:
            return []
    }
}

export function getFiltersWithName(filterList: IFilter[], name: string) {
    if (name === "") {
        return filterList;
    }

    return filterList
        .filter(i => i.name
            .toLowerCase()
            .includes(name.toLowerCase())
        );
}
