import './SortArrows.scss';
import React from 'react';
import {BxsMoveFilterDownArrow, BxsMoveFilterUpArrow} from "../Svg/Svg";

export interface ISortArrowProps {
    onMoveUp: () => void;
    onMoveDown: () => void;
}

export function SortArrows(props: ISortArrowProps) {
    return (
        <div className={'sort-arrows'}>
            <div className={'sort-arrow'}>
                <button className={'clickable'} onClick={props.onMoveUp}>
                    <BxsMoveFilterUpArrow/>
                </button>
            </div>
            <div className={'sort-arrow'}>
                <button className={'clickable'} onClick={props.onMoveDown}>
                    <BxsMoveFilterDownArrow/>
                </button>
            </div>
        </div>
    )
}
