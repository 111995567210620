import React from 'react'

function ConditionalWrapper(props: {
    condition: boolean,
    wrapper: (children: JSX.Element) => JSX.Element,
    children: JSX.Element}
): JSX.Element {
    return props.condition
        ? props.wrapper(props.children)
        : props.children
}

export default ConditionalWrapper
