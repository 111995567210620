import React from 'react'
import {FilterPicker} from '../FilterPicker/FilterPicker';
import {useDispatch, useSelector} from "react-redux";
import {IFilterSliceState} from "../../filtering/filterSlice";
import {
    isAllValuesSelected,
    isAnyValueSelected,
    ISelectableState,
    isValueSelected
} from "../../filterPicker/filterPickerUtility";
import {FilterPickerChild} from "../FilterPicker/FilterPickerChild";
import {updateSelectedFilterState} from "../../filtering/selectedFilterSlice";
import {IFilterData, IFilterValues} from "../../filtering/filterPageData";
import {IPickerFilter} from "../../filtering/filterStateTypes";
import {addPickerValue, addPickerValues, removePickerValue, removePickerValues} from "../../filtering/filterStateLogic";
import {FilterPickerParent} from "../FilterPicker/FilterPickerParent";
import {CheckboxState} from "../Checkbox/Checkbox";
import {YF_LABEL} from "../../utility/constants";

interface FilterPickerRegularProps {
    filter: IPickerFilter;
    stateSelector: (state: IFilterSliceState) => IFilterData[];
}

interface ParentPickerState extends ISelectableState {
    name: string;
    filter_values: string[];
}

interface ChildPickerState extends ISelectableState {
    name: string;
    value: string;
}

interface FilterPickerRegularState2 {
    parent: ParentPickerState;
    children: ChildPickerState[];
}

function getParent(
    filter: IPickerFilter,
    parent: IFilterData
) {
    const values = parent.filter_values.map(i => i.value);
    const isAnySelected = isAnyValueSelected(filter, values);
    const isAllSelected = isAllValuesSelected(filter, values);

    return {
        isChildSelected: isAnySelected,
        isSelected: isAllSelected,
        name: parent.filter_category,
        filter_values: values
    };
}

function getChild(
    filter: IPickerFilter,
    child: IFilterValues
) {
    return {
        isChildSelected: false,
        isSelected: isValueSelected(filter, child.value),
        name: child.name,
        value: child.value
    };
}

function getPickerCheckboxState(state: ISelectableState, name:string): CheckboxState {
    const unclickable_parents = [YF_LABEL]

    if (unclickable_parents.includes(name)) {
        return CheckboxState.NotClickable;
    }
    if (state.isSelected) {
        return CheckboxState.Checked;
    }

    return state.isChildSelected
        ? CheckboxState.ChildChecked
        : CheckboxState.Unchecked;
}

export function FilterPickerRegular(props: FilterPickerRegularProps) {
    const data = useSelector(props.stateSelector)
    const dispatch = useDispatch()
    const pickerState = getPickerState(data);

    function getPickerState(data: IFilterData[]): FilterPickerRegularState2[] {
        let result: FilterPickerRegularState2[] = [];

        for (let parent of data) {
            let state = {} as FilterPickerRegularState2;
            state.parent = getParent(props.filter, parent);
            state.children = [];
            for (let child of parent.filter_values) {
                state.children.push(getChild(props.filter, child))
            }
            result.push(state);
        }

        return result;
    }

    function handleOnParentCheckBoxClicked(parent: ParentPickerState) {
        const filterState = parent.isSelected
            ? removePickerValues(props.filter, parent.filter_values)
            : addPickerValues(props.filter, parent.filter_values);
        dispatch(updateSelectedFilterState(filterState))
    }

    function handleOnChildCheckBoxClicked(child: ChildPickerState, pickerState: FilterPickerRegularState2) {
        const exclusive_pickers = ['Years of Financial Data']
        // logic for filters that can only select one child at a time
        if (exclusive_pickers.includes(pickerState.parent.name)) {
            let new_state = {...props.filter}

            if (child.isSelected) {
                new_state.value = []
            } else {
                new_state.value = [child.value]
            }

            dispatch(updateSelectedFilterState(new_state))
        }
        else {
            const filterState = child.isSelected
                ? removePickerValue(props.filter, child.value)
                : addPickerValue(props.filter, child.value);
            dispatch(updateSelectedFilterState(filterState));
        }
    }

    return (
        <FilterPicker
            filter={props.filter}
            onDeleteFilter={() => null}
        >
            {
                pickerState
                    .map(pickerState =>
                        <FilterPickerParent
                            key={pickerState.parent.name}
                            className={'picker-level-1'}
                            onCheckBoxClicked={() => handleOnParentCheckBoxClicked(pickerState.parent)}
                            searchText={''}
                            checkboxState={getPickerCheckboxState(pickerState.parent, pickerState.parent.name)}
                            label={pickerState.parent.name}
                        >
                            {
                                pickerState.children.map(child =>
                                    <FilterPickerChild
                                        key={child.name}
                                        className={'picker-level-2'}
                                        checkboxState={getPickerCheckboxState(child, child.name)}
                                        handleOnClick={() => handleOnChildCheckBoxClicked(child, pickerState)}
                                        label={child.name}
                                    />
                                )
                            }
                        </FilterPickerParent>
                    )
            }
        </FilterPicker>
    )
}
