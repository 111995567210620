import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {getBillingPageData,
        getSubMgmtData,
        postDefaultPayment,
        getInvoicePreview,
        IPlanOption,
        ISetDefault} from '../api/requests';
import {
    IGetBillingPageData,
    SetDefaultResponse,
    IUser,
    CurrentSubscription,
    SubMgmtData,
    InvoicePreview,
    IGetInvoicePreview, lineItem
} from "../api/requestsTypes";
import { RootState } from "../store";
import {MANAGER_ROLE, USER_ROLE, OWNER_ROLE} from "../constants";
import {resetStateOnSignOut} from "../auth/signOut";




interface IBillingSlice {
  isLoading: boolean,
  invoicePreview: InvoicePreview
  planType: CurrentSubscription,
  planOptions: IPlanOption[],
  users: IUser[],
  billingPageData: IGetBillingPageData;
}


export interface IBillingSliceState {
  billingSlice: IBillingSlice
}

export const fetchInvoicePreview = createAsyncThunk<InvoicePreview, IGetInvoicePreview>(
    'billing/fetchInvoicePreview',
    async (data, thunkAPI) => {
      return getInvoicePreview(data)
    }
);

export const fetchBillingData = createAsyncThunk<IGetBillingPageData, void>(
    'billing/fetchData',
    async (thunkAPI) => {
      return getBillingPageData()
    }
);

export const fetchSubMgmtData = createAsyncThunk<SubMgmtData, void, { state: RootState }>(
  'billing/fetchSubMgmtData',
  async (thunkAPI) => {
    return getSubMgmtData()
  }
);


export const setDefaultPayment = createAsyncThunk<SetDefaultResponse, ISetDefault>(
    'billing/setDefaultPayment',
    async (data, thunkAPI) => {
        return await postDefaultPayment(data)
    }
)

const initialState: IBillingSlice= {
  isLoading: true,
  invoicePreview: {} as InvoicePreview,
  planType: {} as CurrentSubscription,
  planOptions: [] as IPlanOption[],
  users: [] as IUser[],
  billingPageData: {
      next_payment: {},
      billing_history: [],
      card_details: {}
  }
}

export const billingSlice = createSlice({
  name: 'billing',
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.users.push(action.payload);
    },
    setInvoicePreview: (state, action) => {
       state.invoicePreview = action.payload
    }
  },
  extraReducers: builder => {

      builder.addCase(fetchSubMgmtData.fulfilled, (state, action) => {
          state.planType = action.payload.sub_details
          state.planOptions = action.payload.sub_options
          state.users = action.payload.account_rows
          state.isLoading = false;
      });

      builder.addCase(fetchSubMgmtData.pending, (state, action) => {
          state.isLoading = true;
      });

      builder.addCase(fetchSubMgmtData.rejected, (state, action) => {
         state.isLoading = false;
      });

      builder.addCase(fetchInvoicePreview.fulfilled, (state, action) => {
          if (!('errors' in action.payload)) {
            state.invoicePreview = action.payload
          }
          state.isLoading = false;
      });

      builder.addCase(fetchInvoicePreview.rejected, (state, action) => {
          state.isLoading = false
      });


      builder.addCase(fetchBillingData.fulfilled, (state, action) => {
          state.billingPageData = action.payload
          state.isLoading = false;
      });

      builder.addCase(fetchBillingData.pending, (state, action) => {
          state.isLoading = true;
      });

      builder.addCase(fetchBillingData.rejected, (state, action) => {
         state.isLoading = false;
      });

      builder.addCase(setDefaultPayment.fulfilled, (state, action) => {
          state.billingPageData.card_details = action.payload.card
      });

      resetStateOnSignOut(builder, initialState);
    }
  });



export const inviteUser = createAsyncThunk<void, { name: string, email: string }>(
  'billing/inviteUser',
  async (arg) => {
  }
);

function format_date(utc_date:string|undefined, hour_minute:boolean) {

    if (typeof utc_date == 'string' && utc_date != 'N/A' && utc_date != 'Expired') {

        let proper_date = utc_date[utc_date.length - 1] == 'Z'
                          ? utc_date : utc_date + 'Z'

        let localDate = new Date(proper_date)
        let options;
        if (hour_minute) {
            options = {
                month: 'short',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                year: 'numeric'
            }
        } else {
            options = {
                month: 'short',
                day: 'numeric',
                year: 'numeric'
            }
        }

        let fmt_date = localDate.toLocaleString(
            'default',
            options
        )

        return fmt_date
    }
    else if (utc_date == 'N/A' || utc_date == 'Expired' ) {
        return utc_date
    }
    else {
        return ''
    }
}

function format_amount_dollars(amount:number|undefined){
    if (typeof amount == 'number') {
        return '$' + amount.toFixed(2)
    }
    else {
        return ''
    }
}

function format_user_type(role:number) : string {

    if (role == 1){
        return USER_ROLE
    }
    else if (role == 2){
        return MANAGER_ROLE
    }
    else {
        return OWNER_ROLE
    }

}


export function getNextPayment(state: IBillingSliceState) {
    let fmt_payment_date = format_date(state.billingSlice.billingPageData.next_payment.next_payment_date, false)
    let fmt_amount_due = format_amount_dollars(state.billingSlice.billingPageData.next_payment.amount_due)

    if (fmt_amount_due && fmt_payment_date) {
        return {
            next_payment_date: fmt_payment_date,
            amount_due: fmt_amount_due
        }

    } else {
        return {}
    }

}

export function getHistory(state: IBillingSliceState) {
    let display_items = state.billingSlice.billingPageData.billing_history.filter(
        item => item.amount > 0
    )
    let format_history = display_items.map(
                        item=>{
                            return {
                                payment_date: format_date(item.payment_date, true),
                                amount: format_amount_dollars(item.amount),
                                invoice_url: item.invoice_url

                            }
                        })
  return format_history
}

export function getCardDetails(state:IBillingSliceState ){
    return state.billingSlice.billingPageData.card_details
}

export function getIsLoading(state: IBillingSliceState) {
  return state.billingSlice.isLoading
}

export function getPlanType(state: IBillingSliceState) {
  return state.billingSlice.planType;
}

export function getTrialing(state: IBillingSliceState) {
  return state.billingSlice.planType.trialing;
}

export function getPlanOptions(state: IBillingSliceState){
    return state.billingSlice.planOptions
}

export function getInvoice(state: IBillingSliceState){
    return state.billingSlice.invoicePreview
}



export function getLineItems(state: IBillingSliceState): lineItem[]{

    if (state.billingSlice.invoicePreview.next_invoice != undefined) {

        const preview = state.billingSlice.invoicePreview
        let line_items;
        if (preview.next_invoice.discounts != '') {

            line_items = [...preview.next_invoice.line_items, {
                'amount': preview.next_invoice.discounts,
                'description': preview.next_invoice.discount_description
            }]

        } else {
            line_items = preview.next_invoice.line_items
        }
        return line_items
    } else {
        return []
    }

}

export function getUsers(state: IBillingSliceState) {
    let formatted_users = state.billingSlice.users.map(
        user => {
            return {
                id: user.id,
                name: user.name,
                email: user.email,
                role: format_user_type(user.role),
                renew_date: format_date(user.renew_date, false),
                expires_date: format_date(user.expires_date, false),
                active: user.active,
                sub_name: user.sub_name
            }
        }
    )
  return formatted_users;
}

export const { setUser, setInvoicePreview } = billingSlice.actions;

export default billingSlice.reducer;
