import "../SignupForm/SignupForm.scss";
import React, {ChangeEvent} from "react";

interface FormInputProps {
    for: string;
    value: string;
    onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
    children?: React.ReactNode;
    className?: string;
    type?: string;
    placeholder?: string;
    name?: string;
    id?: string;
    onError?: React.ReactNode;
}

export function FormInputTyped(props: FormInputProps) {
    return (
        <label htmlFor={props.for} className="form-input">
            {props.children}
            <input
                className={props.className}
                type={props.type}
                id={props.id}
                placeholder={props.placeholder}
                name={props.name}
                defaultValue={props.value}
                onChange={(e) =>
                    props.onChange ? props.onChange(e) : null}
            />
            {props.onError ? props.onError : null}
        </label>
    );
}
