import React, {ReactNode, useEffect, useState} from "react";
import {BxsDownArrow, BxsRightArrow} from "./BxsArrow";
import Checkbox, {CheckboxState} from "../Checkbox/Checkbox";
import {ME_LABEL, HQ_LABEL, YF_LABEL} from "../../utility/constants";
import './FilterPicker.scss';
import {PickerLevel} from "../../filterPicker/filterPickerUtility";

interface FilterPickerParentProps {
    className: PickerLevel
    onCheckBoxClicked: () => void
    searchText: string
    checkboxState: CheckboxState
    label: string
    children: ReactNode
}

export function FilterPickerParent(props: FilterPickerParentProps) {
    const [isExpanded, setIsExpanded] = useState(false);
    const expand_on_init = [ // these filters are expanded initially
        ME_LABEL,
        YF_LABEL,
        HQ_LABEL
    ]
    useEffect(() => {
        if (props.searchText !== "" || expand_on_init.includes(props.label) ) {
            setIsExpanded(true);
        } else {
            setIsExpanded(false);
        }
    }, [props.searchText])

    return (
        <>
            <div className={props.className}>
                <div className={"picker"}>
                    <div onClick={() => setIsExpanded(!isExpanded)} className={"picker-arrow clickable"}>
                        {
                            isExpanded
                                ? <BxsDownArrow/>
                                : <BxsRightArrow/>
                        }
                    </div>
                    <Checkbox
                        onClick={props.onCheckBoxClicked}
                        className={"picker-checkbox clickable"}
                        checkboxType={props.checkboxState}
                    />
                    <span className={"picker-label roboto-regular-12"}>{props.label}</span>
                </div>
            </div>
            {
                isExpanded
                    ? props.children
                    : null
            }
        </>
    )
}
