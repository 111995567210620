import '../../style/style.scss';
import '../LoginPage/LoginPage.scss'
import './SignupPage.scss';
import './SignUpWithCoupon.scss';
import Feature from "./Feature";
import {FormInputTyped} from "../FormInput/FormInputTyped";
import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {IUserClaims, selectClaims} from "../../auth/authSlice";
import {Auth} from "aws-amplify";
import {useHistory} from "react-router-dom";
import {Routes} from "../../routes/routes";
import logo from '../../img/va-logo.svg';
import greeting_logo from '../../img/valueanalytics.svg';
import {ValueAnalyticsDocuments} from "../../data/va-documents";
import {PrimaryButton} from "../Button/Button";
import {
    AmplifyAuthenticator, AmplifySignUp, AmplifyConfirmSignUp,
    AmplifySignIn, AmplifyForgotPassword
} from "@aws-amplify/ui-react";
import {AuthState} from '@aws-amplify/ui-components';
import {Container, Row, Col} from 'react-bootstrap';
import icon1 from "../../img/companyfilteringicon.svg";
import icon2 from "../../img/smartsearchicon.svg";
import icon3 from "../../img/templateicon.svg";
import icon4 from "../../img/statisticsicon.svg";
import icon5 from "../../img/piecharticon.svg";

function SignUpPage() {

    const history = useHistory();

    function routeChange() {
        let path = '/create-account';
        history.push(path);
    }

    return (
        <div className={'signup-style'}>
            <Container>
                <Row className={'mt-5'}>
                    <Col md={8} className={'aside-style'}>
                        <Row className={'justify-content-center'}>
                            <h2>The most affordable platform for high quality market data!</h2>
                        </Row>
                        <Row className={'mt-5 justify-content-center'}>
                            <p className={'pay-text'}>
                                Stop over paying for data! Join Value Analytics today
                            </p>
                        </Row>
                        <Row className={'mt-5 justify-content-center'}>
                            <PrimaryButton
                                isEnabled={true}
                                text={'CREATE AN ACCOUNT'}
                                onClick={routeChange}
                                className={'create-account-button'}/>
                        </Row>
                    </Col>
                    <Col md={4}>
                        <div className={'image-wrap'}>
                            <span></span>
                            <img alt='' src={logo}></img>
                        </div>
                    </Col>
                </Row>
                <Row className={'mt-5'}>
                    <h3 className={'subscription-access-text'}>Your Subscription Access</h3>
                </Row>
                <Row className={'mt-5'}>
                    <Row className={'gy-5'}>
                        <Col md={4} className={'d-flex justify-content-center'}>
                            <Feature src={icon1} heading={'Public Company Filtering'}>
                                Filter public companies using financial characteristics,
                                equity values, and company profiles
                            </Feature>
                        </Col>
                        <Col md={4} className={'d-flex justify-content-center'}>
                            <Feature src={icon2} heading={'Excel Add-In'}>
                                Access financial data directly from Microsoft Excel to support
                                custom analysis and financial modeling
                            </Feature>
                        </Col>
                        <Col md={4} className={'d-flex justify-content-center'}>
                            <Feature src={icon3} heading={'Reporting Templates'}>
                                Summarize performance and pricing analysis using guided reporting templates that
                                are directly exportable to Microsoft Excel
                            </Feature>
                        </Col>
                        <Col md={2}></Col>
                        <Col md={4} className={'d-flex justify-content-center'}>
                            <Feature src={icon4} heading={'Performance Analysis'}>
                                Compare performance across companies using industry standard financial metrics
                                and pricing multiples
                            </Feature>
                        </Col>
                        <Col md={4} className={'d-flex justify-content-center'}>
                            <Feature src={icon5} heading={'Data Accessibility'}>
                                Gain access to financial, equity, and company profile data for U.S.
                                exchange-traded companies
                            </Feature>
                        </Col>
                        <Col md={2}></Col>
                    </Row>
                </Row>
            </Container>
        </div>

    )
}


interface ICreateNewUserInfo {
    email: string;
    fullName: string;
    password: string;
}


interface ISignUpSuccess {
    status: "success";
    message: string;
}

interface ISignUpError {
    status: "error";
    message: string;
}

type SignUpResult = ISignUpSuccess | ISignUpError;

function handleAuthError(message: any) {
}

function LoginPage() {


    return (
        <div className={'login-form'}>
            <Container className={'h-100'}>
                    <Row className={'h-100 align-items-center justify-content-center'}>
                        <AmplifyAuthenticator federated={{}}
                                              initialAuthState={AuthState.SignIn}
                                              usernameAlias="email"
                                              handleAuthStateChange={handleAuthError}>
                            <AmplifySignUp
                                headerText=""
                                usernameAlias="email"
                                slot="sign-up"
                                formFields={[{
                                    type: "custom:fullname",
                                    label: "Full Name*",
                                    placeholder: "Enter your full name",
                                    inputProps: {required: true},
                                },
                                    {
                                        type: "email",
                                        label: "Email Address*",
                                        placeholder: "Enter your email address",
                                        inputProps: {required: true, autocomplete: "username"},
                                    },
                                    {
                                        type: "password",
                                        label: "Password*",
                                        placeholder: "Enter your password",
                                        inputProps: {required: true, autocomplete: "new-password"},
                                    }
                                ]}
                            >
                                <div slot="header-subtitle" className={'amplify-header'}>
                                    <img className={"signup-logo"} src={greeting_logo}/>
                                    <br/>
                                    <span className={'welcome-message'}>{"Create Account"}</span>
                                </div>
                            </AmplifySignUp>

                            <AmplifySignIn className={'login-element'}
                                           hideSignUp
                                           headerText=""
                                           slot="sign-in"
                                           submitButtonText={'Login'}
                                           usernameAlias={'email'}>
                                <div slot="greetings"></div>
                                <div slot="federated-buttons"/>
                                <div slot="header-subtitle" className={'amplify-header'}>
                                    <img className={"signup-logo"} src={greeting_logo}/>
                                    <br/>
                                    <span className={'welcome-message'}>{"Welcome!"}</span>
                                </div>
                            </AmplifySignIn>

                            <AmplifyForgotPassword className={'login-element'}
                                                   headerText="Password Reset"
                                                   slot="forgot-password"
                                                   usernameAlias="email"
                            >
                            </AmplifyForgotPassword>
                        </AmplifyAuthenticator>
                    </Row>
            </Container>
        </div>
    )
}

function signUpUser(newUser: ICreateNewUserInfo, claims: IUserClaims): Promise<SignUpResult> {
    const params: any = {
        attributes: {
            email: newUser.email,
            'custom:fullname': newUser.fullName
        },
        clientMetadata: {
            manager: claims.cognito_username
        },
        password: newUser.password,
        username: newUser.email
    }

    return Auth
        .signUp(params)
        .then(response => {
            return {
                status: "success",
                message: 'You account has been created. You can now login.'
            } as ISignUpSuccess
        })
        .catch(error => {
            return {
                status: "error",
                message: error.message
            } as ISignUpError
        });
}

function useCreateAccountLogic() {
    const [fullName, setFullName] = useState('');
    const [emailAddress, setEmailAddress] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [formSubmitted, setFormSubmitted] = useState(false);
    const claims = useSelector(selectClaims);
    const [errorMessage, setErrorMessage] = useState('');
    const [redirectToLogin, setRedirectToLogin] = useState(false);
    const [acceptedTerms, setAcceptedTerms] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const history = useHistory();

    const handleChange = (dispatch: React.Dispatch<React.SetStateAction<string>>) =>
        (event: React.ChangeEvent<HTMLInputElement>) =>
            dispatch(event.target.value);

    const handleCheckBoxChange = (dispatch: React.Dispatch<React.SetStateAction<boolean>>) =>
        (event: React.ChangeEvent<HTMLInputElement>) =>
            dispatch(event.target.checked);

    function handleFormSubmitted(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();
        if (!acceptedTerms) {
            setErrorMessage('You must agree to terms of service');
            return;
        }
        if (password !== confirmPassword) {
            setErrorMessage('The passwords you entered do not match.');
            return;
        }
        if (password.length < 8) {
            setErrorMessage('Your password must be at least 8 characters.');
            return;
        }

        setFormSubmitted(true);
    }

    useEffect(() => {
        if (formSubmitted) {
            const newUser: ICreateNewUserInfo = {
                email: emailAddress,
                fullName: fullName,
                password: password
            }
            setIsLoading(true);
            signUpUser(newUser, claims)
                .then(result => {
                    switch (result.status) {
                        case "error":
                            setErrorMessage(result.message)
                            break;
                        case "success":
                            setRedirectToLogin(true)
                            break;
                    }
                })
                .finally(() => setIsLoading(false));
        }

        setFormSubmitted(false);
    }, [formSubmitted]);

    useEffect(() => {
        if (redirectToLogin) {
            history.push(Routes.login);
        }

        setRedirectToLogin(false);
    }, [redirectToLogin]);

    return {
        fullName,
        setFullName,
        emailAddress,
        setEmailAddress,
        password,
        setPassword,
        confirmPassword,
        setConfirmPassword,
        errorMessage,
        acceptedTerms,
        setAcceptedTerms,
        handleChange,
        handleFormSubmitted,
        handleCheckBoxChange,
        isLoading
    };
}

function CreateAccount() {
    const {
        fullName,
        setFullName,
        emailAddress,
        setEmailAddress,
        password,
        setPassword,
        confirmPassword,
        setConfirmPassword,
        handleFormSubmitted,
        errorMessage,
        acceptedTerms,
        setAcceptedTerms,
        handleChange,
        handleCheckBoxChange,
        isLoading
    } = useCreateAccountLogic();

    return (
        <main className="create-account">
            <div className="headline">
                <h1>Create Account</h1>
            </div>
            <div className={'error-message'}>
                {errorMessage}
            </div>
            <form className={'create-account-form'} onSubmit={(event) => handleFormSubmitted(event)}>
                <FormInputTyped
                    for={'fullName'}
                    id={'fullName'}
                    value={fullName}
                    onChange={handleChange(setFullName)}
                >
                    Full Name*
                </FormInputTyped>
                <FormInputTyped
                    for={'emailAddress'}
                    id={'emailAddress'}
                    value={emailAddress}
                    onChange={handleChange(setEmailAddress)}
                >
                    Email Address*
                </FormInputTyped>
                <FormInputTyped
                    for={'password'}
                    id={'password'}
                    type={'password'}
                    value={password}
                    onChange={handleChange(setPassword)}
                >
                    Password
                </FormInputTyped>
                <FormInputTyped
                    for={'confirmPassword'}
                    id={'confirmPassword'}
                    type={'password'}
                    value={confirmPassword}
                    onChange={handleChange(setConfirmPassword)}
                >
                    Confirm Password
                </FormInputTyped>
                <PrimaryButton
                    className={'get-started'}
                    text={'Apply'}
                    onClick={() => null}
                    type={'submit'}
                    isLoading={isLoading}
                    isEnabled={!isLoading && acceptedTerms}
                />

                <div className="privacy-policy">
                    <h3>TERMS & CONDITIONS</h3>
                    <br/>
                    <h4>Last modified 3/31/2021</h4>
                    <br/>
                    <h4>PLEASE READ THESE TERMS CAREFULLY.</h4>
                    <p>
                        These Terms and Conditions of Use (the “Terms”) govern your use of and access to
                        ValueAnalytics.org
                        and its sub-domains and affiliated sites, as well as Value Analytics, LLC’s (“Value Analytics”)
                        pages and accounts
                        on Facebook®, Twitter®, LinkedIn®, Google Plus®, YouTube®, and any other social media website
                        (collectively the “Sites)
                        and its Services (as hereinafter defined). Please read both these Terms and Value Analytics’
                        Privacy Policy carefully, which is
                        incorporated into these Terms. You may not use the Sites or the Services until you have read the
                        Terms. By using any or all
                        of the Sites or the Services, you accept and agree to be bound by these Terms. If you do not
                        want to agree to be bound by these
                        Terms, do not use the Sites or the Services. Value Analytics may modify these Terms at any time,
                        and any modifications
                        will be effective immediately unless otherwise stated. You are responsible for reviewing any
                        modified terms.
                        All modifications will be posted at ValueAnalytics.org/documentation. Your continued use of a
                        Site or the
                        Services following any changes means you accept and agree to any changes. For your convenience
                        and future reference,
                        the date of the most recent revision of these Terms is listed above so that you may compare
                        different versions
                        to determine what, if any, changes have been made. As used in these Terms, the terms "you",
                        "your" or
                        "user" all refer to both the person using the Sites or the Services in any way, including those
                        registered as, for,
                        or on behalf of a corporate entity, such as a company, business, affiliated entities,
                        corporation, government agency,
                        or other business entity and its employees, subcontractors, affiliates, and all other persons or
                        entities permitted
                        to access and use the Sites or the Services.
                    </p>
                    <br/>
                    <h4>SITE CONTENT.</h4>
                    <p>
                        Value Analytics exclusively owns and controls the Sites, which, among other things, provides
                        information about
                        products and services and access to a variety of materials and content (the “Services”). You
                        agree that use or
                        access to any or all of the Sites or the Services does not create any sort of representation or
                        future promise.
                        Value Analytics does not warrant or make any representation of accuracy regarding the
                        information or data contained,
                        described in, or related to the Services. Value Analytics shall own any feedback,
                        recommendations,
                        or suggestions that you may provide to Value Analytics and may use the results of such feedback
                        for any purpose,
                        including but not limited to in its marketing and promotional materials. The unauthorized
                        reproduction, sublicensing,
                        sharing, use of, or theft of any content, written, photographic, video or otherwise, is
                        expressly prohibited.
                        Any use of the Sites or Services in violation of these terms will constitute infringement of
                        Value Analytics’ intellectual property rights.
                    </p>
                    <br/>
                    <h4>FEES.</h4>
                    <p>
                        You agree to pay all fees for the Services you select. Fees shall be due and payable to Value
                        Analytics by credit card,
                        via a third-party payment processor, prior to Services being rendered by Value Analytics.
                        Services can be cancelled by
                        you at any time and will be effective at the end of the paid period in which Value Analytics
                        receives notice of cancellation.
                        In the event a payment is not received for any reason, Value Analytics may suspend your access
                        to the applicable Services or
                        terminate your Services. Refunds shall not be given in the event any or all of the Sites become
                        non-operational, in the event
                        information in the Services is incorrect or inaccurate, or for any other reason. All payments
                        are non-refundable.
                        Value Analytics reserves the right to adjust fees at any time at its discretion.
                    </p>
                    <br/>
                    <h4>INTELLECTUAL PROPERTY.</h4>
                    <p>
                        Unless explicitly stated otherwise, as between you and Value Analytics, Value Analytics owns and
                        retains all right,
                        title, and interest in and to (a) the Services; (b) its Sites; and (c) all associated content,
                        materials, graphics,
                        logos, slogans, product and service names, information, and other intellectual property. All
                        other names, logos,
                        product and service names, designs and slogans on the Sites are the trademarks of their
                        respective owners and should
                        not be used without those respective owners’ permission. You are granted a limited,
                        non-exclusive, non-transferable,
                        revocable license to access and use the Sites and the Services therein, including the resources
                        available for download
                        or streaming strictly in accordance with these Terms and solely for your personal purposes.
                    </p>
                    <br/>
                    <h4>THIRD PARTY RIGHTS.</h4>
                    <p>
                        Content and materials posted to the Sites may be the copyrighted content of others (“Third Party
                        Content”) that
                        is used by Value Analytics either by permission or under Section 107 of the Copyright Act as
                        “fair use” for
                        purposes such as education and research. Users must obtain permission from the owners of any
                        Third-Party Content before
                        copying, distributing or otherwise using those materials. Except as otherwise expressly
                        permitted under copyright law,
                        no copying, redistribution, retransmission, publication, or commercial exploitation of
                        downloaded material will be
                        permitted without the express permission of the copyright owner.
                    </p>
                    <br/>
                    <p>
                        If you believe that your work has been copied on one or more of the Sites in a way that
                        constitutes copyright
                        infringement or otherwise violates your intellectual property rights, please contact us via
                        email at the contact
                        information listed below and provide the following: (i) identification of what is claimed to
                        have been infringed;
                        (ii) identification of what is claimed to be infringing; (iii) your contact information (or the
                        contact information
                        of the person we need to contact about the infringement); (iv) a statement that the person
                        submitting the complaint
                        is doing so with a good faith belief that use of the material in the manner complained of is not
                        authorized by the
                        owner, its agent, or the law; (v) a statement that the information provided is accurate, and
                        under penalty of perjury;
                        (vi) a physical or electronic signature of the person submitting the complaint; and (vii) if
                        that person is not the
                        owner of the content at issue, a statement that the person submitting the complaint is
                        authorized to act on the owner’s behalf.
                    </p>
                    <br/>
                    <h4>LINKING TO OUR SITES.</h4>
                    <p>
                        Anyone linking to the Sites must comply with all applicable laws and must not: (i) misrepresent
                        its relationship
                        with Value Analytics; (ii) present false or misleading information about Value Analytics; or
                        (iii) contain content
                        that is reasonably considered profanity, offensive, defamatory, vulgar, or unlawful.
                    </p>
                    <br/>
                    <h4>ADVERTISEMENTS AND LINKS.</h4>
                    <p>
                        Value Analytics may at times include advertisements on the Sites. Your correspondence or
                        business dealings with,
                        or participation in promotions of, advertisers found on or through the Sites are solely between
                        you and such
                        advertiser. Any opinions, advice, statements, services, offers, or other information or content
                        expressed or
                        made available by advertisers, including information providers, or any other end users are those
                        of the
                        respective author(s) and not our own. You agree that Value Analytics shall not be responsible or
                        liable
                        for any loss or damage of any sort incurred as a result of any such dealings or as the result of
                        the presence of
                        such advertisers. Any affiliate links on the Sites will be clearly marked; however, you may
                        reach out to Value Analytics
                        using the contact information listed below with any questions you may have regarding affiliate
                        links.
                    </p>
                    <br/>
                    <p>
                        You may find links to other websites on a Site. These links are provided solely as a convenience
                        to you and
                        not as an endorsement by Value Analytics of the contents on such third-party sites, and Value
                        Analytics expressly
                        disclaims any representations regarding the content or accuracy of materials on such third-party
                        websites.
                        You acknowledge and agree that Value Analytics shall not be responsible or liable, directly or
                        indirectly,
                        for any damage or loss caused or alleged to be caused by or in connection with use of or
                        reliance on any such content,
                        goods or products available on or through any such linked site. You agree that it is your
                        responsibility to evaluate
                        the accuracy, completeness, or usefulness of any information, opinion, advice, etc., or other
                        content available
                        through such third-party sites.
                    </p>
                    <br/>
                    <h4>DISCLAIMER AND LIMITATION OF LIABILITY.</h4>
                    <p>
                        THE SITES AND THE SERVICES ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS, WITHOUT WARRANTY
                        OF ANY KIND.
                        VALUE ANALYTICS, TOGETHER WITH ITS AFFILIATES, LICENSORS, SERVICE PROVIDERS, EMPLOYEES, AGENTS,
                        OFFICERS OR DIRECTORS
                        (THE “RELEASED PARTIES”), SPECIFICALLY DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING,
                        BUT NOT LIMITED TO,
                        WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT AND
                        WARRANTIES THAT
                        MAY ARISE OUT OF COURSE OF DEALING, COURSE OF PERFORMANCE, USAGE OR TRADE PRACTICE. THE RELEASED
                        PARTIES DO NOT GUARANTEE
                        THE RELIABILITY, ACCURACY, COMPLETENESS, SAFETY, TIMELINESS, LEGALITY, USEFULNESS, ADEQUACY OR
                        SUITABILITY OF ANY OF THE INFORMATION OR CONTENT ON THE SITES. VALUE ANALYTICS AND THE RELEASED
                        PARTIES DO NOT
                        WARRANT THAT THE SERVICE OR SITES WILL BE ERROR FREE, FREE FROM ANY VIRUS, MALWARE, OR HARMFUL
                        CODE, FREE FROM
                        INTERRUPTIONS, WILL NOT INFRINGE THE INTELLECTUAL PROPERTY RIGHTS OF ANY THIRD PARTY, OR
                        ACCOMPLISH ANY PARTICULAR RESULT.
                        ACCORDINGLY, YOU AGREE TO EXERCISE CAUTION, DISCRETION AND COMMON SENSE WHEN USING THE SITES.
                        THE ENTIRE RISK FOR
                        USE OF THE SITE AND/OR SERVICES IS BORNE BY YOU. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE
                        LAW, IN NO EVENT SHALL
                        THE RELEASED PARTIES BE LIABLE FOR ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL OR
                        PUNITIVE DAMAGES
                        ARISING OUT OF THE USE OF OR INABILITY TO ACCESS THE SITES, INCLUDING, WITHOUT LIMITATION,
                        DAMAGES FOR LOSS OF
                        GOODWILL, WORK DISRUPTIONS, COMPUTER FAILURE OR MALFUNCTION, OR ANY AND ALL OTHER PERSONAL OR
                        COMMERCIAL DAMAGES OR
                        LOSSES, EVEN IF ADVISED OF THE POSSIBILITY THEREOF, AND REGARDLESS OF THE LEGAL OR EQUITABLE
                        THEORY
                        (CONTRACT, TORT, BREACH OF WARRANTY OR OTHERWISE) UPON WHICH THE CLAIM IS BASED. THE RELEASED
                        PARTIES ARE NOT RESPONSIBLE
                        FOR ANY LIABILITY ARISING OUT OF THE POSTINGS OR ANY MATERIAL LINKED THROUGH THE SITES. YOUR
                        SOLE REMEDY WITH RESPECT
                        TO ANY CLAIM ARISING OUT OF YOUR USE OF THE SITES AND SERVICES IS TO CEASE USING THE SITES AND
                        SERVICES. IN NO EVENT SHALL
                        VALUE ANALYTICS’ TOTAL LIABILITY EXCEED AN AMOUNT EQUAL TO THE LESSOR OF ACTUAL DAMAGES INCURRED
                        OR THE FEES PAID BY YOU IN
                        HE TWELVE (12) MONTH PERIOD PROCEEDING THE EVENT GIVING RISE TO SUCH LIABILITY FOR THE SERVICES
                        GIVING RISE TO SUCH LIABILITY.
                    </p>
                    <br/>
                    <h4>INDEMNIFICATION.</h4>
                    <p>
                        YOU AGREE TO INDEMNIFY, DEFEND, AND HOLD VALUE ANALYTICS HARMLESS FROM AND AGAINST ANY DAMAGES,
                        CLAIMS OR LOSSES
                        ARISING FROM YOUR: (A) BREACH OF THESE TERMS; (B) INFRINGEMENT OF ANY THIRD-PARTY INTELLECTUAL
                        PROPERTY; AND (C)
                        NEGLIGENCE, WILLFUL MISCONDUCT, OR VIOLATION OF LAW. VALUE ANALYTICS SHALL NOT BE LIABLE FOR ANY
                        SPECIAL, INCIDENTAL,
                        CONSEQUENTIAL, PUNITIVE OR EXEMPLARY DAMAGES, ARISING OUT OF OR RELATED TO THIS AGREEMENT,
                        WHETHER OR NOT A PARTY HAS
                        BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES, AND WHETHER BASED UPON BREACH OF CONTRACT,
                        WARRANTY, OR STATUTORY DUTY,
                        NEGLIGENCE, STRICT LIABILITY OR OTHER TORT, PRINCIPLES OF INDEMNITY OR CONTRIBUTION, OR
                        OTHERWISE.
                        YOU AGREE THAT VALUE ANALYTICS WILL NOT MAKE ANY COURT APPEARANCES ON YOUR BEHALF WITHOUT A
                        SUBPOENA AND
                        IF VALUE ANALYTICS IS REQUIRED TO MAKE A COURT APPEARANCE DUE TO A SUBPOENA BY YOU, THEN YOU
                        SHALL INDEMNIFY AND
                        REIMBURSE VALUE ANALYTICS FOR ALL RELATED LOSSES AND EXPENSES.
                    </p>
                    <br/>
                    <h4>CHOICE OF LAW AND VENUE.</h4>
                    <p>
                        These Terms are governed by the laws of the Commonwealth of Pennsylvania without regard to any
                        conflict of laws.
                        For any dispute regarding these Terms, the Sites, or the Services, you agree to submit to the
                        personal and exclusive
                        jurisdiction and venue of the federal and state courts located in Butler County, PA.
                    </p>
                    <br/>
                    <h4>MISCELLANEOUS.</h4>
                    <p>
                        Should any provision of these Terms be found unenforceable, the remainder of these Terms shall
                        remain in full force and effect.
                        These Terms (and any policies linked or attached to it, including but not limited to the Privacy
                        Policy) constitute the
                        entire agreement between you and Value Analytics and supersedes any oral or written
                        communications between us.
                        In the event of conflict between these Terms and any other agreement or understanding between
                        the parties, these Terms shall govern.
                    </p>
                    <br/>
                    <h4>YOUR COMMENTS AND CONCERNS.</h4>
                    <p>
                        All feedback, comments, requests for technical support and other communications relating to the
                        Sites or the Services should be
                        directed to: Derek@ValueAnalytics.org or Adam@ValueAnalytics.org or to Value Analytics, PO Box
                        993, Mars, PA 16046.
                    </p>

                </div>
                <div className="agree">
                    <input type="checkbox" id="accepted-terms" name="agree"
                           itemID={'accepted-terms'}
                           checked={acceptedTerms}
                           onChange={handleCheckBoxChange(setAcceptedTerms)}/>
                    <label htmlFor="accepted-terms">
                        I have read and agree to the
                        <a href={ValueAnalyticsDocuments.termsAndConditions.url}> terms and conditions </a>
                        and
                        <a href={ValueAnalyticsDocuments.privacyPolicy.url}> privacy policy </a>
                    </label>
                </div>
            </form>
        </main>
    );
}

export {
    SignUpPage,
    LoginPage,
}



