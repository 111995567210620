import './FilterContainer.scss';
import './FilterStateContainer.scss';
import './FilterStateNumericRange.scss';
import '../../style/style.scss';
import React, {ReactNode} from "react";
import '../SaveWithNameButton/SaveWithNameButton.scss';
import {PrimaryButton, SecondaryButton} from "../Button/Button";
import {useDispatch, useSelector} from "react-redux";
import {Action, ThunkDispatch} from "@reduxjs/toolkit";
import {
    clearSelectedFilterState,
    saveSelectedFilter,
    selectErrors,
    selectFilterName,
    selectIsSaving, selectSelectedFilterId,
    updateSelectedFilterName
} from "../../filtering/selectedFilterSlice";
import {fetchUserFilters} from "../../filtering/savedFilterSlice";
import {RootState} from "../../store";
import {
    clearErrorMessage,
    getSelectedFilterResults,
    selectFilterTableError,
    selectFilterTableLoading
} from "../../filtering/filterTableSlice";
import {SaveWithNameButton} from "../SaveWithNameButton/SaveWithNameButton";
import {Container} from "../Container/Container";
import { clearSectors } from '../../filtering/sectorSlice';

interface FilterContainerProps {
    children?: ReactNode
    isActive: boolean
}


export function FilterContainer(props: FilterContainerProps) {
    const errors = useSelector(selectErrors)
    const isSaving = useSelector(selectIsSaving)
    const filterName = useSelector(selectFilterName)
    const isGeneratingData = useSelector(selectFilterTableLoading);
    const selectedFilterId = useSelector(selectSelectedFilterId);
    const dispatch = useDispatch<ThunkDispatch<RootState, any, Action>>();
    const filterTableError = useSelector(selectFilterTableError);


    function handleOnNameChange(name: string) {
        if (!props.isActive) {
            return;
        }

        dispatch(updateSelectedFilterName(name));
    }

    function handleOnSaveFilter() {
        if (!props.isActive) {
            return;
        }

        if (filterName === "") {
            return;
        }

        dispatch(saveSelectedFilter(filterName))
            .then(i => dispatch(fetchUserFilters()))
    }

    function handleOnClearFilter() {
        if (!props.isActive) {
            return;
        }

        dispatch(clearSectors());
        dispatch(clearSelectedFilterState());
        dispatch(clearErrorMessage());
    }

    function handleOnGenerateData() {
        if (!props.isActive ||
            isGeneratingData) {
            return;
        }

        dispatch(getSelectedFilterResults())
    }

    function getErrors() {
       if(errors.length) {
           return errors.map(error => <li key={error}>{error}</li>)
       }

       if(filterTableError.length) {
           return <li >{filterTableError}</li>
       }

       return null;
    }

    return (
        <Container className={'filter-container'}>
            <div className={'filter-container-header'}>
                <div className={'filter-container-status'}>
                    {isSaving ? 'Saving Your Filter...' : ''}
                </div>
                <div className={'filter-container-errors'}>
                    <ul>
                        {getErrors()}
                    </ul>
                </div>
                <SaveWithNameButton
                    text={filterName}
                    onNameChange={handleOnNameChange}
                    onSave={handleOnSaveFilter}
                    isEnabled={props.isActive}
                    placeHolder={'give your filter a name'}
                    isLoading={isSaving}
                    isEditing={selectedFilterId > 0}
                />
            </div>
            <div className={'filter-container-filters'}>
                {props.children}
            </div>
            <div className={'filter-container-buttons'}>
                <div className={'container-buttons'}>
                    <PrimaryButton
                        isEnabled={props.isActive && !isGeneratingData}
                        text={"Generate Data"}
                        onClick={handleOnGenerateData}
                        isLoading={isGeneratingData}
                    />
                    <SecondaryButton
                        text={"Clear"}
                        onClick={handleOnClearFilter}
                        isEnabled={props.isActive}
                    />
                </div>
            </div>
        </Container>
    );
}



