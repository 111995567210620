import "./CriteriaList.scss"
import Criterion from "../Criterion/Criterion";
import {IFilter} from "../../filtering/filter";

export interface CriteriaListProps {
    criteria: IFilter[]
}

export function CriteriaList(props: CriteriaListProps) {
    return (
        <>
            {
                props.criteria
                    .map(prop => <Criterion key={prop.name}{...prop}/>)
            }
        </>
    )
}

