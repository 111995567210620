import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {
    deleteCompSet,
    getUserCompSets,
    IDeleteUserCompSetResponse,
    IUserCompSet,
    IUserCompSetResponse
} from "../api/requests";
import {resetStateOnSignOut} from "../auth/signOut";

interface ISavedUserCompSetSlice {
    savedUserCompSets: IUserCompSet[];
    isFetchingUserCompSets: boolean;
    isDeletingUserCompSet: boolean;
}

interface ISavedUserCompSetSliceState {
    savedUserCompSetSlice: ISavedUserCompSetSlice
}

export const fetchUserCompSets = createAsyncThunk<IUserCompSetResponse, void>(
    'savedUserCompSetSlice/getUserCompSet',
    async (arg, thunkAPI) => {
        return await getUserCompSets();
    }
)

export const deleteUserCompSet = createAsyncThunk<IDeleteUserCompSetResponse, IUserCompSet>(
    'savedUserCompSetSLice/deleteUserCompSet',
    async(arg, thunkAPI) => {
        const data = {
           id: arg.id
        };

        return await deleteCompSet(data);
    }
)

const initialState: ISavedUserCompSetSlice = {
    isFetchingUserCompSets: false,
    isDeletingUserCompSet: false,
    savedUserCompSets: [] as IUserCompSet[]
}

export const savedUserCompSetSlice = createSlice({
    name: 'savedUserCompSetSlice',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(fetchUserCompSets.pending, (state, action) => {
            state.isFetchingUserCompSets = true;
        });

        builder.addCase(fetchUserCompSets.fulfilled, (state, action) => {
            const {compsets, status} = action.payload;
            state.isFetchingUserCompSets = false;

            if(status === 'error') {
                return;
            }

            state.savedUserCompSets = compsets;
        });

        builder.addCase(fetchUserCompSets.rejected, (state, action) => {
            state.isFetchingUserCompSets = false;
        });

        builder.addCase(deleteUserCompSet.fulfilled, (state, action) => {
            state.isDeletingUserCompSet = false;
        });

        builder.addCase(deleteUserCompSet.pending, (state, action) => {
            state.isDeletingUserCompSet = true;
        });

        builder.addCase(deleteUserCompSet.rejected, (state, action) => {
            state.isDeletingUserCompSet = false;
        });

        resetStateOnSignOut(builder, initialState);
    }
});

export default savedUserCompSetSlice.reducer;


export const selectIsFetchingUserCompSets = (state: ISavedUserCompSetSliceState): boolean =>
    state.savedUserCompSetSlice.isFetchingUserCompSets;

export const selectSavedUserCompSets = (state: ISavedUserCompSetSliceState): IUserCompSet[] =>
    state.savedUserCompSetSlice.savedUserCompSets;

export const selectIsDeleteingUserCompSet = (state: ISavedUserCompSetSliceState): boolean =>
    state.savedUserCompSetSlice.isDeletingUserCompSet;
