import React from 'react'
import {Alert} from 'reactstrap'

function SmallScreenAlert() {
    return (
        <Alert color={'danger'} className={'d-md-none'}>
            This page is intended for a larger screen.
        </Alert>
    )
}

export default SmallScreenAlert
