import React from 'react-dom'
import './CategoryExpansionToggle.scss'
import minus from '../../img/minus.svg'
import plus from '../../img/plus.svg'
import '../../style/style.scss'
import searchFilterUpArrow from "../../img/search-filter-arrow-up.svg";
import searchFilterDownArrow from "../../img/search-filter-arrow-down.svg";

export interface CategoryExpansionToggleProps {
    isExpanded: boolean;
    handleIsExpanded: (isExpanded: boolean) => void;
}

function CategoryExpansionToggle(props: CategoryExpansionToggleProps) {
    const isExpanded = props.isExpanded;
    const handleIsExpanded = props.handleIsExpanded;

    return (
        <div
            className={"font-default category-expansion-toggle"}
            onClick={() => handleIsExpanded(!isExpanded)}>
            <img src={isExpanded ? searchFilterUpArrow : searchFilterDownArrow } alt={"image"}></img>
        </div>
    )
}

export default CategoryExpansionToggle;
