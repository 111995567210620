import './TableToolBar.scss';
import React from 'react';

export function TableTitle(props: {
    title: string;
}) {
    function add_subtitle(title:string){
        if (title.toLowerCase().includes('highlights')){
            return 'Balance Sheet and Income Statement items from most recent annual report prior to Valuation Date'
        } else if (title.toLowerCase().includes('ranking')){
            return 'Rankings based on LFY performance, unless otherwise specified'
        } else {
            return ''
        }
    }


    return (
        <div className={'roboto-regular-14 table-title'}>
            <div>
                {props.title}
                <br/>
                {add_subtitle(props.title)}
            </div>

        </div>
    );
}

export function TableToolBar(props: {
    left: React.ReactNode,
    right: React.ReactNode
}) {
    return (
        <div className={'table-toolbar'}>
            <div className={'left'}>
                {props.left}
            </div>
            <div className={'right'}>
                {props.right}
            </div>
        </div>
    );
}
