export function swapIndexes<T>(list: T[], index1: number, index2: number) {
    const swap = list[index1];
    list[index1] = list[index2];
    list[index2] = swap;
}

export type Dictionary<T> = { [index: string]: T };

export function filterList<T>(list: T[], filterList: T[]): T[] {
    let result: T[] = [];
    for(let item of list) {
        let index = filterList.indexOf(item);
        if(index < 0) {
            result.push(item);
        }
    }
    return result;
}
