import {useInvoiceLogic} from "./UseInvoiceLogic";
import {reSubscribe} from "../../../api/requests";
import {fetchSubMgmtData} from "../../../billing/billingSlice";
import {OrderSummary} from "./OrderSummary";
import {PrimaryButton} from "../../Button/Button";
import {OrderInfo} from "./OrderInfo";
import React from "react";
import {InvoicePreview, lineItem} from "../../../api/requestsTypes";

interface ReSubscribeManagerProps {
    invoice: InvoicePreview
    setShowInvoicePreview: (show: boolean) => void
    line_items: lineItem[]
    errors: string

}

export function ReSubscribeManagerInvoice(props: ReSubscribeManagerProps) {
    const {
        error,
        isLoading, isEnabled,
        performAction
    } = useInvoiceLogic(props)
    const button_text = 'Subscribe'
    const desc_text_1 = 'Renewing your Account Admin subscription will charge your card on file at the beginning of the next billing cycle.'
        + ' Once your Account Admin subscription is renewed, you will be able to renew any Team Member subscriptions'
        + ' that were previously cancelled.'
    const desc_text_2 = 'Press "Subscribe" to renew your Account Admin subscription!'
    const due_text = 'Total due on next Invoice'
    const upgradeAccount = performAction(upgradeAccountAction)

    async function upgradeAccountAction() {
        let payload = {
            ...props.invoice.invoice_preview_params,    // important to use the same params we sent to preview
            proration_date: props.invoice.proration_date // add the proration date to payload
        }

        return reSubscribe(payload)
    }

    return (
        <OrderSummary errors={props.errors}
                      updateError={error}
                      descText1={desc_text_1}
                      descText2={desc_text_2}
                      actionButton={
                          <PrimaryButton className={'right-inline-button'}
                                         text={button_text}
                                         onClick={upgradeAccount}
                                         isEnabled={isEnabled}
                                         isLoading={isLoading}/>
                      }
                      orderInfo={
                          <OrderInfo
                              lineItems={props.line_items}
                              dueText={due_text}
                              total={props.invoice.next_invoice.total}
                          />
                      }
        />
    )
}
