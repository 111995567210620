import './ValuationDate.scss';
import {useDispatch, useSelector} from "react-redux";
import {selectValuationDate, setValuationDate} from "../../comps/compsGroupSlice";
import {getDateFromIsoString} from "../../utility/dates";
import {DateSelector} from "../DateSelector/DateSelector";
import React from "react";
import {fetchCompSet} from "../../comps/compsTableSlice";

export function useValuationDate(): [date: Date, updateDateFunc: (date: Date | null) => void] {
    const valuationDateIsoString = useSelector(selectValuationDate);
    const valuationDate = getDateFromIsoString(valuationDateIsoString);
    const dispatch = useDispatch();

    function handleValuationDateChange(date: Date | null): void {
        if (date === null) {
            return;
        }


        dispatch(setValuationDate(date.toISOString()))
        dispatch(fetchCompSet());
    }

    return [valuationDate, handleValuationDateChange];
}

export function ValuationDate() {
    const [valuationDate, handleValuationDate] = useValuationDate();
    return (
        <div className={"input-wrap"}>
            Enter Valuation Date
            <DateSelector date={valuationDate} onDateChange={handleValuationDate}/>
        </div>
    );
}
