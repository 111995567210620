import './CompsTableInput.scss';
import React, {ReactNode, ReactNodeArray} from "react";
import {ValuationDate} from "./ValuationDate";
import {useSelector} from "react-redux";
import {selectCompsetTableResults} from "../../comps/compsTableSlice";
import classNames from "classnames";

interface CompsTableParams {
    className?: string;
    tableInputChildren?: ReactNode | ReactNodeArray;
    children?: ReactNode | ReactNodeArray;
}

export function CompsTable(props: CompsTableParams) {
    const results = useSelector(selectCompsetTableResults);
    const className = classNames('comps-table', props.className);

    return (
        <div className={className}>
            <div className={'roboto-regular-12 comps-table-input'}>
                <ValuationDate/>
                {props.tableInputChildren}
            </div>
            <>
                {
                    results.data_found !== 'all'
                        ? <p className={'warning-message'}>{results.message}</p>
                        : null
                }
                {results.data_found !== 'none' ? props.children : null}
            </>
        </div>
    )
}
