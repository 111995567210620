import numeral from 'numeral';

type NumberFormat
    = 'integer'
    | 'integer_m'
    | 'text'
    | 'decimal'
    | 'percentage'
    | 'Infinity'
    | 'NYSE MKT'
    | 'blank'
    | 'integer_m_f'
    | 'money'
    | 'decimal_2'
    | any;

export function formatNumber(value: string | number, type: NumberFormat): string {
    if (value === 'Infinity') {
        return 'Inf';
    }

    if (value === 'NYSE MKT') {
        return 'AMEX'
    }

    if(type === 'text') {
        return value.toString();
    }

    if (value === 'blank') {
        return ''
    }

    if (type === "integer_m_f") {

        if (value < 0.05 && value > -0.05){
            return "< 0.05"
        } else {
            const number = numeral(value);
            return number.format('0,0.0')
        }

    }

    if (!value || value === '-' ) {
        return '-';
    }

    const number = numeral(value);

    if(number.value() === null){
        return '-';
    }

    switch (type) {
        case "integer_m":
            return number.format('0,0.0')
        case "integer":
            return number.format('0,0');
        case "decimal":
            return number.format('0,0.0');
        case "decimal_2":
            return number.format('0,0.00');
        case "percentage":
            return number.multiply(.01).format('0.0%');
        case "money":
            return number.format('$0,0');
    }

    return value.toString();
}

export function normalizeNumber(value: string | number | null, type: NumberFormat): number {
    let result: number | null = 0;
    switch (type) {
        case "integer_m":
            result = numeral(value).multiply(1000000).value();
            break;
        case "percentage":
            result = numeral(value).divide(100).value();
            break;
        case "decimal":
        case "integer":
            result = numeral(value).value();
            break;
        default:
            result = 0;
    }

    if (result === null) {
        return 0;
    }

    return result;
}


