import React from "react";
import './Button.scss';
import '../../style/style.scss';
import {
    EditColumnsSvg, EditIconSvg,
    ExportIconSvg,
    SaveIconSvg,
} from "../Svg/Svg";
import {Loader} from "../Loader/Loader";
import classNames from 'classnames';
import {ReactComponent as DocumentSvg} from '../../img/document.svg'

interface ButtonInputs {
    text: string;
    onClick: (e:any | null) => void;
    isEnabled: boolean;
    isLoading?: boolean;
    type?: 'button' | 'submit' | 'reset' | undefined;
    className?: string;
    id?: string;
}

interface IButtonProps {
    buttonProps: ButtonInputs;
    isPrimary: boolean;
    isLoading?: boolean;
    type?: 'button' | 'submit' | 'reset' | undefined;
    children?: React.ReactNode;
    id?: string;
}

function Button(props: IButtonProps) {

    function handleClick(e:any) {
        if (props.buttonProps.isEnabled) {
            props.buttonProps.onClick(e);
        }
    }

    const className = classNames(props.buttonProps.className, {
        'disabled': !props.buttonProps.isEnabled,
        'primary': props.isPrimary,
        'secondary': !props.isPrimary,
        'button': true,
        'robot-regular-12': true,
    });

    return (
        <button
            onClick={handleClick}
            type={props.type}
            className={className}
            disabled={props.buttonProps.isLoading}
            id={props.buttonProps.id}
        >
            {props.buttonProps.isLoading ?
                <Loader isLoading={props.buttonProps.isLoading}/> : props.buttonProps.text}
            {props.children}
        </button>
    );
}

function PrimaryButton(props: ButtonInputs) {
    return <Button buttonProps={{...props}} isPrimary={true}/>
}

function SecondaryButton(props: ButtonInputs) {
    return <Button buttonProps={{...props}} isPrimary={false}/>
}



function MinusButton(props: ButtonInputs) {
    return (
        <Button buttonProps={{...props}} isPrimary={false}>
            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M10.1111 6.61108H3.88889C3.78575 6.61108 3.68683 6.65206 3.6139 6.72499C3.54097 6.79792 3.5 6.89683 3.5 6.99997C3.5 7.10311 3.54097 7.20203 3.6139 7.27496C3.68683 7.34789 3.78575 7.38886 3.88889 7.38886H10.1111C10.2143 7.38886 10.3132 7.34789 10.3861 7.27496C10.459 7.20203 10.5 7.10311 10.5 6.99997C10.5 6.89683 10.459 6.79792 10.3861 6.72499C10.3132 6.65206 10.2143 6.61108 10.1111 6.61108Z"
                    fill="black"/>
            </svg>
        </Button>
    )

}

function IconLeftButton(props: {
    outlined?: boolean;
    icon: React.ReactNode, button: React.ReactNode }) {
    const className= classNames('icon-left-button', {
        outlined: props.outlined
    })

    return (
        <div className={className}>
            {props.icon}
            {props.button}
        </div>
    );
}

function IconButton(props: { icon: React.ReactNode, inputs: ButtonInputs }) {
    const className = classNames(props.inputs.className, {
        'icon-button': true
    });

    return (
        <Button isPrimary={false} buttonProps={{...props.inputs, className: className}}>
            {props.icon}
        </Button>
    )
}

export function CircleButton(props: ButtonInputs) {
    const className =classNames(props.className, {
        'circle-button': true
    });

    return (
        <Button isPrimary={true} buttonProps={{...props, className: className}}/>
    )
}

function ExportButton(props: ButtonInputs) {
    return (
        <IconLeftButton
            icon={<ExportIconSvg/>}
            button={<SecondaryButton {...props}/>}
        />
    );
}

function EditColumnsButton(props: ButtonInputs) {
    return (
        <IconLeftButton
            icon={<EditColumnsSvg/>}
            button={<SecondaryButton {...props}/>}
        />
    )
}

function SaveButton(props: ButtonInputs & {isEditing: boolean}) {
    const className = classNames(props.className, {
        'save-button': true,
        'disabled': !props.isEnabled
    });

    return (
        <IconButton
            icon={ props.isEditing ?  <EditIconSvg/> : <SaveIconSvg/>}
            inputs={{...props, className: className}}
        />
    );
}

function DocumentButton(props: ButtonInputs){
    const buttonProps = {...props}
    return (
        <IconLeftButton outlined icon={<DocumentSvg/>} button={<SecondaryButton {...buttonProps}/>}/>
    )
}

export type
{
    ButtonInputs
}

export
{
    PrimaryButton,
    SecondaryButton,
    MinusButton,
    ExportButton,
    EditColumnsButton,
    SaveButton,
    DocumentButton
}
