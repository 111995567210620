import './Methodology.scss'
import {Col, Row, Table} from "reactstrap";
import {DocumentButton} from "../Button/Button";
import {fetchPreSignedDocumentationLink, selectDocuments} from "../../documentationSlice/documentationSlice";
import {preSignedUrlDocuments} from "../../documentation/documentation";
import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";

function DownloadButton(props: { preSignedUrl: string, displayName: string }) {
    const dispatch = useDispatch();
    const documents = useSelector(selectDocuments);

    useEffect(() => {
            dispatch(fetchPreSignedDocumentationLink(props.preSignedUrl))
        }
        , [])

    async function handleDownloadButtonClick() {
        dispatch(fetchPreSignedDocumentationLink(props.preSignedUrl))
        if (documents[props.preSignedUrl]) {
            window.open(documents[props.preSignedUrl], '_blank')
        }
    }

    return (
        <div className={'download-button mb-1'}>
            <DocumentButton text={props.displayName}
                            isEnabled={documents[props.preSignedUrl] ? true : false}
                            onClick={handleDownloadButtonClick}/>
        </div>
    )
}

export default function Methodology(props: {
    title: string,
    description: string,
    data: string[][]
}) {

    return (
        <div className={'methodology'}>
            <h1 className={'font-blue-modal-header'}>{props.title}</h1>
            <Row>
                <Col>
                    <p className={'my-3'}>{props.description}</p>
                </Col>
                <Col xs={3}>
                    <DownloadButton displayName={"White Paper 2024"}
                                    preSignedUrl={preSignedUrlDocuments.sizePremiumMethodology2024}/>
                    <DownloadButton displayName={"White Paper 2023"}
                                    preSignedUrl={preSignedUrlDocuments.sizePremiumMethodology2023}/>
                </Col>
            </Row>
            <Table bordered>
                <tbody>
                {props.data.map(columns =>
                    <tr>
                        {columns.map(i =>
                            <td>{i}</td>
                        )}
                    </tr>
                )}
                </tbody>
            </Table>
        </div>
    )
}
