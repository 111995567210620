import {API} from "aws-amplify";
import {IFilterPageData} from "../filtering/filterPageData";
import {
    CreateManagedUserResponse,
    CreateStripeCustomerResponse,
    CreateStripeSubscriptionResponse,
    DeleteFilterResponse,
    FilterCountsResponse,
    GetUserFiltersResponse,
    ICancelSubscription,
    ICompSetRequest,
    ICompSetResponse,
    ICreateManagedUser,
    ICreatePaymentIntent,
    ICreateStripeCustomer,
    ICreateStripeSubscription,
    IDeleteUser,
    IDistancingOptionsResponse,
    IGetBillingPageData,
    IGetInvoicePreview,
    INearestBusinessesRequest,
    INearestBusinessesResponse,
    InvoicePreview,
    ISavedFilter,
    PaymentIntentResponse,
    SavedFilterResponse,
    SetDefaultResponse,
    SetupIntentResponse,
    SubMgmtData
} from "./requestsTypes";
import {IFilterCountsRequest} from "./filterCountRequest";
import {FilterResults, ITicker} from "../types";
import {ISizePremiums} from "../sizePremiums/sizePremium";
import {getRequest, postRequest} from "./requestUtility";

export function createManagedUser(data: ICreateManagedUser): Promise<CreateManagedUserResponse> {
    return postRequest('Api', '/create_managed_user', data)
}

export function getBillingPageData(): Promise<IGetBillingPageData> {
    return getRequest('Api', '/get_billing_information');
}

export async function getInvoicePreview(data: IGetInvoicePreview): Promise<InvoicePreview>{
    const invoice_preview = await postRequest('Api', '/get_invoice_preview', data)
    invoice_preview.invoice_preview_params = data // here we add the invoice preview params to response
    return invoice_preview
}

export function reSubscribe(data: IGetInvoicePreview){
    return postRequest('Api', '/re_subscribe', data)
}


export function deleteManagedUser(data: IDeleteUser){
    return postRequest('Api', '/delete_managed_user', data)
}

export function cancelSubscription(data: ICancelSubscription){
    return postRequest('Api', '/cancel_subscription', data)
}


export function getSubMgmtData(): Promise<SubMgmtData> {
    return getRequest('Api', '/get_sub_mgmt_data');
}

export function getFilterPageData(): Promise<IFilterPageData> {
    return getRequest('Api', '/get_filter_page_data');
}

export function getFilterCounts(data: IFilterCountsRequest): Promise<FilterCountsResponse> {
    return postRequest('Api', '/get_filter_count', data);
}

export function getFilterResults(data: IFilterCountsRequest): Promise<FilterResults> {
    return postRequest('Api', '/get_filter_results', data);
}

export function getDistancingOptions(): Promise<IDistancingOptionsResponse>{
    return postRequest('Api', '/get_distancing_options', {});
}

export function getNearestBusinesses(data: INearestBusinessesRequest): Promise<INearestBusinessesResponse>{
    return postRequest('Api', '/get_nearest_businesses', data);
}

export function getUserFilters(): Promise<GetUserFiltersResponse> {
    return getRequest('Api', '/get_user_filters');
}

export function saveUserFilter(data: ISavedFilter): Promise<SavedFilterResponse> {
    return postRequest('Api', '/save_user_filter', data);
}

export function deleteUserFilter(data: ISavedFilter): Promise<DeleteFilterResponse> {
    return postRequest('Api', '/delete_user_filter', data);
}

export function getCompSet(data: ICompSetRequest): Promise<ICompSetResponse> {
    return postRequest('Api', '/get_compset', data);
}

export function getSizePremiums(): Promise<ISizePremiums>{
    return getRequest('Api', '/sub/size-premiums');
}

export interface IUserCompSetResponse {
    status: 'success' | 'error';
    compsets: IUserCompSet[]
}

export interface IUserCompSet {
    id: number;
    compset: number[];
    valuation_date: string;
    update_date: string;
    compset_name: string;
}

export function getUserCompSets(): Promise<IUserCompSetResponse> {
    return getRequest('Api', '/get_user_compset');
}

export interface IDeleteCompSetRequest {
    id: number;
}

export interface IDeleteUserCompSetResponse {
    status: 'success' | 'error',
    deleted?: number
}

export function deleteCompSet(data: IDeleteCompSetRequest): Promise<IDeleteUserCompSetResponse> {
    return postRequest('Api', '/delete_user_compset', data);
}

export interface ISaveUserCompSetResult {
    status: string,
    compsetId: number
}

export function saveCompSet(compset: IUserCompSet): Promise<ISaveUserCompSetResult> {
    return postRequest('Api', '/save_user_compset', compset);
}

export interface ICheckTokenRequest {
    token_name: string;
    token_value: string;
}

export interface ICheckTokenSuccess {
    status: 'success';
    token_name: string;
    token_secret: string;
}

export interface ICheckTokenError {
    status: 'error';
    error: string;
}


export type CheckTokenResponse = ICheckTokenSuccess | ICheckTokenError

export function checkToken(data: ICheckTokenRequest): Promise<CheckTokenResponse> {
    return API.post('Api', '/check_token', {body: data})
        .catch(() => {
            return {
                status: 'error',
                error: 'Something went wrong'
            } as ICheckTokenError
        });
}

export function getTickers(): Promise<ITicker[]> {
    return getRequest('Api', '/get_tickers');
}

export function createPaymentIntent(data: ICreatePaymentIntent): Promise<PaymentIntentResponse> {
    return postRequest('Api', '/create_payment_intent', data);
}

export function createStripeCustomer(data: ICreateStripeCustomer): Promise<CreateStripeCustomerResponse> {
    return postRequest('Api', '/create_customer', data);
}


export function createStripeSubscription(data: ICreateStripeSubscription): Promise<CreateStripeSubscriptionResponse> {
    return postRequest('Api', '/create_subscription', data);
}

export interface IPlanOption {
    best_value: boolean
    billing_frequency: string,
    cancel_at_period_end: boolean,
    description: string,
    display_due_now: string,
    display_price: string,
    price_id: string,
    sub_period: string,
    title: string,
    trial_period: boolean,
    trial_period_days: number,
    selected_plan: boolean,
    coupon_applied: boolean,
    coupon_available: boolean,
    coupon_display_name: string,
    coupon_name: string,
    coupon_price: string,
    promo_attempt_failed: boolean,
    promo_secret: string
}

export interface ISetDefault {
    payment_id: string
}

export function postDefaultPayment(data: ISetDefault): Promise<SetDefaultResponse> {
    return postRequest('Api', '/set_default_payment', data)
}


export function getSetupIntent(): Promise<SetupIntentResponse> {
    return getRequest('Api', '/get_setup_intent')
}

export function getPlanOptions(): Promise<IPlanOption[]> {
    return getRequest('Api', '/get_plan_options');
}


