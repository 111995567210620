import './CompsConclusionTable.scss';
import '../Table/Table.scss'
import React, {useState} from 'react';
import {InputMoney} from "../Input/InputMoney";
import {
    selectCompanyNetDebt,
    selectRowDictionary,
    setSubjectCompanyNetDebt,
    updateRowsDictionary
} from "../../comps/compsTableSlice";
import {useDispatch, useSelector} from "react-redux";
import {formatNumber} from "../../utility/numberFormatter";
import * as math from 'mathjs';
import {CompsTable} from "./CompsTableInput";


interface ICalculatedCell {
    id: number;
    measureOfPerformance: string;
    companyResults: string;
    selectedMultiples: number | '-';
}

interface TableProps {
    calculationsDictionary: { [measureOfPerformance: string]: ICalculatedCell };
    headers: string[];
    subjectCompanyNetDebt: string;
    enterpriseValue: string;
    onEnterpriseValueChange: (value: string) => void;
    handleRowUpdate: (id: number, value: string) => void;
}

const netDebtHeader = "Net Debt (MM)";
const enterpriseValueHeader = "Enterprise Value (MM)";
const selectedMultiplesHeader = 'Selected Multiples';
const equityValueHeader = 'Equity Value (MM)';
const blankOperator = 'blank';

export function CompsConclusionTable() {
    const subjectCompanyNetDebt = useSelector(selectCompanyNetDebt);
    const rowsDictionary = useSelector(selectRowDictionary);
    const [inputEnterpriseValue, setInputEnterpriseValue] = useState('');
    const headers = ["Measure of Performance", "Company Results (MM)", selectedMultiplesHeader, equityValueHeader, netDebtHeader, enterpriseValueHeader];
    const dispatch = useDispatch();

    function handleOnEnterpriseValueChange(value: string) {
        setInputEnterpriseValue(value);
    }

    function handleRowUpdate(key: number, value: string) {
        dispatch(updateRowsDictionary({key: key, value: value}))
    }

    function handleOnNetDebChange(text: string) {
        dispatch(setSubjectCompanyNetDebt(text));
    }

    return (
        <CompsTable
            className={'comps-conclusion-table'}
            tableInputChildren={
                <div className={'input-wrap'}>
                    Subject Company Net Debt (MM)
                    <InputMoney value={subjectCompanyNetDebt} onChange={handleOnNetDebChange}/>
                </div>
            }
        >
            <Table
                headers={headers}
                calculationsDictionary={rowsDictionary}
                subjectCompanyNetDebt={subjectCompanyNetDebt}
                enterpriseValue={inputEnterpriseValue}
                onEnterpriseValueChange={handleOnEnterpriseValueChange}
                handleRowUpdate={handleRowUpdate}
            />
        </CompsTable>
    );
}


function Table(props: TableProps) {
    const rows = Object
        .keys(props.calculationsDictionary)
        .map(key => props.calculationsDictionary[key]);

    function getEquityValue(companyResults: string, selectedMultiples: number | '-', mop: string): number | string {
        const companyResultsNumber = getNumber(companyResults);

        if (mop.includes('EV')) {
            return 'blank'
        } else if (companyResultsNumber === '-' || selectedMultiples === '-') {
            return NaN;
        }


        return companyResultsNumber * selectedMultiples;
    }

    function getEnterpriseValue(companyResults: string, selectedMultiples: number | '-', subjectCompanyNetDebt: string, mop: string): number {
        let netDebtValue = subjectCompanyNetDebt == '' ? 0 : Number(subjectCompanyNetDebt) // default netDebt to 0
        let companyValue = companyResults == '' ? NaN : Number(companyResults) // default companyValue to NaN
        let multipleValue = selectedMultiples == '-' ? NaN : Number(selectedMultiples)

        if (isNaN(companyValue) || isNaN(multipleValue)) {
            return NaN
        } else if (mop.includes('EV')) {
            return companyValue * multipleValue
        } else {
            return companyValue * multipleValue + netDebtValue
        }
    }

    function getGoodEVValues(rows: ICalculatedCell[]): number[] {
        let values = rows.map(row =>
            getEnterpriseValue(row.companyResults, row.selectedMultiples, props.subjectCompanyNetDebt, row.measureOfPerformance)
        );
        let good_values = values.filter(value => (!isNaN(value)))
        return good_values
    }

    function getMaximum(rows: ICalculatedCell[]): number {
        let good_values = getGoodEVValues(rows)
        if (good_values.length <= 0) {
            return NaN;
        }
        return Math.max(...good_values);
    }

    function getAverage(rows: ICalculatedCell[]): number {
        let good_values = getGoodEVValues(rows)
        if (good_values.length > 0) {
            let sum = 0;
            for (let i in good_values) {
                sum += good_values[i]
            }
            return sum / good_values.length
        } else {
            return NaN
        }
    }

    function getMedian(rows: ICalculatedCell[]): number {
        let good_values = getGoodEVValues(rows)
        if (good_values.length <= 0) {
            return NaN;
        }

        return math.median(good_values)
    }

    function getMinimum(rows: ICalculatedCell[]): number {
        let good_values = getGoodEVValues(rows)
        if (good_values.length <= 0) {
            return NaN;
        }

        return math.min(good_values);
    }

    function getCoefficientOfVariation(rows: ICalculatedCell[]) {
        let good_values = getGoodEVValues(rows)
        let average = getAverage(rows)

        if (average != 0 && good_values.length > 0) {
            return math.std(good_values) / average * 100;
        } else {
            return NaN;
        }
    }

    function getUpperQuartile(rows: ICalculatedCell[]) {
        let good_values = getGoodEVValues(rows)


        if (good_values.length > 0) {
            let perc = percentile(good_values, 75)
            if (perc === undefined) {
                return NaN
            }
            return perc
        } else {
            return NaN;
        }
    }

    function getLowerQuartile(rows: ICalculatedCell[]) {
        let good_values = getGoodEVValues(rows)


        if (good_values.length > 0) {
            let perc = percentile(good_values, 25)
            if (perc === undefined) {
                return NaN
            }
            return perc
        } else {
            return NaN;
        }
    }

    function getNetDebt(debt: number | string, mop: string): number | string {

        let netDebtValue: number | string;
        if (mop.includes('EV')) {
            netDebtValue = 'blank'
        } else if (debt == '') {
            netDebtValue = 0
        } else {
            netDebtValue = Number(debt)
        }
        return netDebtValue
    }

    function getFairMarketValue(subjectCompanyNetDebt: string, rows: ICalculatedCell[]) {
        let netDebtValue = subjectCompanyNetDebt == '' ? 0 : Number(subjectCompanyNetDebt) // default netDebt to 0
        const enterprise_value = getMedian(rows);

        if (isNaN(enterprise_value)) {
            return NaN;
        }

        return enterprise_value - netDebtValue;
    }

    return (
        <table className={'va-table'}>
            <thead>
            <tr>
                {
                    props.headers.map(header =>

                        <th>{getOperator(header, 'N/A')}{header}</th>
                    )
                }
            </tr>
            </thead>
            <tbody>
            {
                rows.map((row) =>

                    <tr key={row.id}>
                        <td>{row.measureOfPerformance}</td>
                        <td><span className={'operator'}>$</span><input type={'text'} value={row.companyResults}
                                                                        onChange={(e) => props.handleRowUpdate(row.id, e.target.value)}/>
                        </td>
                        <td>{getOperator(selectedMultiplesHeader, row.measureOfPerformance)}
                            {formatNumber(row.selectedMultiples, 'decimal')}</td>
                        <td>{getOperator(equityValueHeader, row.measureOfPerformance)}
                            {formatNumber(getEquityValue(row.companyResults, row.selectedMultiples, row.measureOfPerformance),
                                'integer_m')}</td>
                        <td>{getOperator(netDebtHeader, row.measureOfPerformance)}
                            {formatNumber(getNetDebt(props.subjectCompanyNetDebt, row.measureOfPerformance),
                                'integer_m')}</td>
                        <td>{getOperator(enterpriseValueHeader, row.measureOfPerformance)}
                            {formatNumber(getEnterpriseValue(row.companyResults, row.selectedMultiples, props.subjectCompanyNetDebt, row.measureOfPerformance),
                                'integer_m')}</td>
                    </tr>
                )
            }
            </tbody>
            <tbody className={'conclusion-table-totals'}>
            <tr>
                <td colSpan={4}/>
                <td>Maximum</td>
                <td>{getOperator(enterpriseValueHeader, 'N/A')}{formatNumber(getMaximum(rows), 'integer_m')}</td>
            </tr>
            <tr>
                <td colSpan={4}/>
                <td>Upper Quartile</td>
                <td>{getOperator(enterpriseValueHeader, 'N/A')}{formatNumber(getUpperQuartile(rows), 'integer_m')}</td>
            </tr>
            <tr>
                <td colSpan={4}/>
                <td>Average</td>
                <td>{getOperator(enterpriseValueHeader, 'N/A')}{formatNumber(getAverage(rows), 'integer_m')}</td>
            </tr>
            <tr>
                <td colSpan={4}/>
                <td>Median</td>
                <td>{getOperator(enterpriseValueHeader, 'N/A')}{formatNumber(getMedian(rows), 'integer_m')}</td>
            </tr>
            <tr>
                <td colSpan={4}/>
                <td>Lower Quartile</td>
                <td>{getOperator(enterpriseValueHeader, 'N/A')}{formatNumber(getLowerQuartile(rows), 'integer_m')}</td>
            </tr>
            <tr>
                <td colSpan={4}/>
                <td>Minimum</td>
                <td>{getOperator(enterpriseValueHeader, 'N/A')}{formatNumber(getMinimum(rows), 'integer_m')}</td>
            </tr>
            <tr>
                <td colSpan={4}/>
                <td className={'divider'}>Coefficient Of Variation</td>
                <td className={'divider'}>{getOperator(enterpriseValueHeader, 'N/A')}{formatNumber(getCoefficientOfVariation(rows), 'percentage')}</td>
            </tr>
            </tbody>
            <tbody className={'conclusion-table-totals'}>
            <tr>
                <td colSpan={4}/>
                <td>Enterprise Value</td>
                <td>{getOperator(enterpriseValueHeader, 'N/A')}{formatNumber(getMedian(rows), 'integer_m')}</td>
            </tr>
            <tr>
                <td colSpan={4}/>
                <td>Less: Net Debt</td>
                <td>{getOperator(enterpriseValueHeader, 'N/A')}{'(' + formatNumber(getNetDebt(props.subjectCompanyNetDebt, 'N/A'), 'integer_m') + ')'}</td>
            </tr>
            <tr>
                <td colSpan={4}/>
                <td><b>Fair Market Value</b></td>
                <td className={'red'}>{getOperator(enterpriseValueHeader, 'N/A')}{formatNumber(getFairMarketValue(props.subjectCompanyNetDebt, rows), 'integer_m')}</td>
            </tr>
            </tbody>
        </table>
    );
}


function getOperator(header: string, mop: string): React.ReactNode {
    switch (header) {
        case equityValueHeader:
            if (mop.includes("EV")) {
                return <span className={'operator'}></span>;
            } else {
                return <span className={'operator'}>=</span>;
            }
        case selectedMultiplesHeader:
            return <span className={'operator'}>X</span>;
        case netDebtHeader:
            if (mop.includes("EV")) {
                return <span className={'operator'}></span>;
            } else {
                return <span className={'operator'}>+</span>;
            }
        case enterpriseValueHeader:
            return <span className={'operator'}>$</span>;
        case blankOperator:
        default:
            return <span className={'operator blank'}></span>;
    }
}

function getNumber(value: string | number): number | '-' {
    const float = parseFloat(value.toString());
    if (isNaN(float)) {
        return '-';
    }

    return float;
}

function swap(data: number[], i: number, j: number) {
    if (i === j) {
        return;
    }
    const tmp = data[j];
    data[j] = data[i];
    data[i] = tmp;
}

function partition(data: number[], start: number, end: number) {
    let i, j;
    for (i = start + 1, j = start; i < end; i++) {
        if (data[i] < data[start]) {
            swap(data, i, ++j);
        }
    }
    swap(data, start, j);
    return j;
}

function findK(data: number[], start: number, end: number, k: number): number | undefined {
    while (start < end) {
        const pos = partition(data, start, end);
        if (pos === k) {
            return data[k];
        }
        if (pos > k) {
            end = pos;
        } else {
            start = pos + 1;
        }
    }
}

// Calculate n-th percentile of 'data' using Nearest Rank Method
// http://en.wikipedia.org/wiki/Percentile#The_Nearest_Rank_method
function percentile(data: number[], n: number): number | undefined {
    return findK(data.concat(), 0, data.length, Math.ceil(data.length * n / 100) - 1);
}

