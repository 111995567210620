import React from 'react-dom';
import './CriteriaLabel.scss'
import '../../style/style.scss'

function FilterLabel(props: {label: string, bold?: boolean}) {

    let style_class;
    if (props.bold){
        style_class = 'roboto-gold-16'
    } else {
        style_class = 'roboto-regular-12'
    }

    return (
        <span className={`${style_class} criteria-label`}>{props.label}</span>
    );
}

export default FilterLabel;
