import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {
    getSectorsFromSicCodes,
    getIndustryFromIndustries,
    getIndustryGroupFromIndustryGroups,
    getSectorFromSectors,
    IIndustryGroupState, IIndustryState, isAnyIndustryGroupOrChildrenSelected, isAnyIndustrySelected,
    ISectorState
} from "./sicCodeState";
import {ISicCodesData} from "./filterPageData";
import {ISicCodePicker} from "./filterStateTypes";
import {resetStateOnSignOut} from "../auth/signOut";

interface ISectorSlice {
    sectors: ISectorState[]
    cleared: boolean;
}

interface ISectorSliceState {
    sectorSlice: ISectorSlice
}

const initialState: ISectorSlice = {
    cleared: false,
    sectors: [] as ISectorState[]
};

export const sectorSlice = createSlice({
    name: 'sectorSlice',
    initialState,
    reducers: {
        sectorFilterClicked: (state, action: PayloadAction<ISectorState>) => {
            let sector = getSectorFromSectors(state.sectors, action.payload.sector_id);

            if (sector === undefined) {
                return;
            }

            sector.isSelected = !sector.isSelected;
            sector.isChildSelected = false;
            if (sector.isSelected) {
                for (let industryGroup of sector.industry_groups) {
                    industryGroup.isSelected = true;
                    industryGroup.isChildSelected = false;
                    for (let industry of industryGroup.industries) {
                        industry.isSelected = true;
                    }
                }
            } else {
                for (let industryGroup of sector.industry_groups) {
                    industryGroup.isSelected = false;
                    industryGroup.isChildSelected = false;
                    for (let industry of industryGroup.industries) {
                        industry.isSelected = false;
                    }
                }
            }
        },
        industryGroupSectorClicked: (state, action: PayloadAction<IIndustryGroupState>) => {
            let sector = getSectorFromSectors(state.sectors, action.payload.sector_id)

            if (sector === undefined) {
                return;
            }

            let industryGroup = getIndustryGroupFromIndustryGroups(sector.industry_groups, action.payload.industry_group_id);

            if (industryGroup === undefined) {
                return;
            }

            industryGroup.isSelected = !industryGroup.isSelected;
            industryGroup.isChildSelected = false;

            // update children
            if (industryGroup.isSelected) {
                for (let industry of industryGroup.industries) {
                    industry.isSelected = true;
                }
            } else {
                for (let industry of industryGroup.industries) {
                    industry.isSelected = false;
                }
            }

            // update parent
            if (isAnyIndustryGroupOrChildrenSelected(sector)) {
                sector.isSelected = false;
                sector.isChildSelected = true;
            } else {
                sector.isChildSelected = false;
                sector.isSelected = false;
            }
        },
        industryClicked: (state, action: PayloadAction<IIndustryState>) => {
            let sector = getSectorFromSectors(
                state.sectors,
                action.payload.sector_id
            );

            if (sector === undefined) {
                return;
            }

            let industryGroup = getIndustryGroupFromIndustryGroups(
                sector.industry_groups,
                action.payload.industry_group_id
            );

            if (industryGroup === undefined) {
                return;
            }

            let industry = getIndustryFromIndustries(
                industryGroup.industries,
                action.payload.sic_code,
                action.payload.industry_name
            );

            if (industry === undefined) {
                return;
            }

            industry.isSelected = !industry.isSelected;

            if (industry.isSelected) {
                sector.isSelected = false;
                sector.isChildSelected = true;
            }

            // update parents
            if (isAnyIndustrySelected(industryGroup)) {
                industryGroup.isSelected = false;
                industryGroup.isChildSelected = true;
            } else {
                industryGroup.isSelected = false;
                industryGroup.isChildSelected = false;
            }
            if (isAnyIndustryGroupOrChildrenSelected(sector)) {
                sector.isSelected = false;
                sector.isChildSelected = true;
            } else {
                sector.isChildSelected = false;
                sector.isSelected = false;
            }

        },
        setSectors: (state, action: PayloadAction<{filterState: ISicCodePicker, sicCodesData: ISicCodesData[]}>) => {
            const {filterState, sicCodesData} = action.payload;
            if(state.sectors.length > 0){
              return;
            }

            state.sectors = getSectorsFromSicCodes(filterState, sicCodesData);
            state.cleared = false;
        },
        clearSectors: (state) => {
            state.sectors = [] as ISectorState[];
            state.cleared = true;
        }
    },
    extraReducers: builder => {
        resetStateOnSignOut(builder, initialState);
    }
});

export const {
    sectorFilterClicked,
    industryGroupSectorClicked,
    industryClicked,
    setSectors,
    clearSectors
} = sectorSlice.actions;

export const selectSicCodeState = (state: ISectorSliceState): ISectorState[] =>
    state.sectorSlice.sectors;

export const selectCleared = (state: ISectorSliceState): boolean =>
    state.sectorSlice.cleared;

export default sectorSlice.reducer;
