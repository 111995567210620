import './FilterPicker.scss';
import '../../style/style.scss';

import {FilterStateContainer} from "../FilterContainer/FilterStateContainer";
import FilterSearchBar from "../FilterContainer/FilterSearchBar";
import React from "react";
import {FilterPickerProps} from "./FilterPicker";


interface FilterPickerWithSearchProps extends FilterPickerProps {
    handleSearch: (searchText: string) => void;
}

export function FilterPickerWithSearch(props: FilterPickerWithSearchProps) {

    return (
        <FilterStateContainer filter={props.filter} onDeleteFilter={props.onDeleteFilter}>
            <div className={"picker-list-container"}>
                <FilterSearchBar handleSearch={props.handleSearch}/>
                <div className={"picker-list"}>
                    {props.children}
                </div>
            </div>
        </FilterStateContainer>
    );
}


