type numeric_categories = 'Income Statement' | 'Cash Flow Statement' | 'Balance Sheet' | 'Value'
                        | 'Performance' | 'Employee Count'

type qualatative_categories = 'Business Description' | 'SIC Codes' | 'Exchange' |
                          'Years of Financial Data' | 'Corporate HQ'

let IS: numeric_categories = 'Income Statement'
let CF: numeric_categories = 'Cash Flow Statement'
let BS: numeric_categories = 'Balance Sheet'
let V: numeric_categories = 'Value'
let P: numeric_categories = 'Performance'
let EC: numeric_categories = 'Employee Count'

let BD: qualatative_categories = 'Business Description'
let SC: qualatative_categories = 'SIC Codes'
let EX: qualatative_categories = 'Exchange'
let YF: qualatative_categories = 'Years of Financial Data'
let HQ: qualatative_categories = 'Corporate HQ'

let ME_LABEL = 'Major Exchanges'
let YF_LABEL = 'Years of Financial Data'
let HQ_LABEL = 'Location of Corporate HQ'

export {IS, CF, BS, V, P, EC, BD, SC, EX, YF, HQ, ME_LABEL, YF_LABEL, HQ_LABEL}