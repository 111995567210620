import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {Auth} from 'aws-amplify';
import {RootState} from "../store";
import {AuthState} from "@aws-amplify/ui-components";
import {resetStateOnSignOut} from "./signOut";

export type UserRole = 'MANAGER' | 'USER' | 'OWNER' | 'BVR_USER' | 'DEMO_USER' | undefined;

export interface IUserAttributes {
    fullName: string;
    email: string;
}

export interface IUserClaims {
    active_subscription: boolean;
    cognito_username: string;
    role: UserRole;
    customer_id: string;
    has_subscribed_before: boolean;
    is_trialing: boolean;
}

export interface IAuthentication {
    isLoggedIn: boolean;
    claims: IUserClaims;
    attributes: IUserAttributes;
}

export interface IAuthenticationState {
    authSlice: IAuthentication;
}

export const refreshUserSessionInformation = createAsyncThunk<IAuthentication, boolean>(
    'authSlice/refreshUserSessionInformation',
    async (bypassCache, thunkApi) =>
    {
        const {currentUser, attributes, decodedIdToken} = await getCurrentUserInformation(bypassCache);
        return {
            isLoggedIn: currentUser !== undefined,
            claims: getUserClaims(decodedIdToken),
            attributes: attributes
        }
    }
);

export const updateUserSession = createAsyncThunk<IAuthentication, { authState: AuthState, authData: object | undefined }>(
    'authSlice/updateUserSession',
    async (arg, thunkApi) => {
        const {attributes, decodedIdToken} = await getCurrentUserInformation(false);

        return {
            isLoggedIn: arg.authState === AuthState.SignedIn && arg.authData !== undefined,
            claims: getUserClaims(decodedIdToken),
            attributes: attributes
        }
    }
);

export const signUpUserWithStripe = createAsyncThunk<void, { stripeCustomerId: string, seats: number }, { state: RootState }>(
    'authSlice/signUpUser',
    async (arg, thunkAPI) => {
        const claims = thunkAPI.getState().authSlice.claims;
        const params: any = {
            attributes: {
                stripeCustomerId: arg.stripeCustomerId,
                seats: arg.seats
            },
            clientMetadata: {
                manager: claims.cognito_username
            },
        }
        const result = await Auth.signUp(params);
    }
);

export const updateAccountInformation = createAsyncThunk<void, { fullName: string, email: string, currentPassword: string, newPassword: string }>(
  'authSlice/updateAccountInformation',
  async (arg) => {
    // TODO API CALL
    const accountInformation: any = {
      fullName: arg.fullName,
      email: arg.email,
      currentPassword: arg.currentPassword,
      newPassword: arg.newPassword
    }
  }
)

const initClaims: IUserClaims = {
    active_subscription: false,
    cognito_username: '',
    role: undefined,
    customer_id: '',
    has_subscribed_before: false,
    is_trialing: false
};

const initAttributes: IUserAttributes = {
    email: '',
    fullName: ''
}

const initialState: IAuthentication = {
    isLoggedIn: false,
    claims: initClaims,
    attributes: initAttributes
}

export const authSlice = createSlice({
    name: 'authSlice',
    initialState,
    reducers: {
        onAuthStateChanged: (state, action) => {
            state.isLoggedIn = action.payload;
        }
    },
    extraReducers: builder => {
        builder.addCase(refreshUserSessionInformation.fulfilled, (state, action) => {
            state.isLoggedIn = action.payload.isLoggedIn;
            state.claims = action.payload.claims;
            state.attributes = action.payload.attributes;
        });
        builder.addCase(refreshUserSessionInformation.rejected, (state, action) => {
            state.isLoggedIn = false;
            state.claims = initClaims;
            state.attributes = initAttributes;
        });
        builder.addCase(updateUserSession.fulfilled, (state, action) => {
            state.isLoggedIn = true;
            state.claims = action.payload.claims
            state.attributes = action.payload.attributes;
        });
        builder.addCase(updateUserSession.rejected, (state, action) => {
            state.isLoggedIn = false;
            state.claims = initClaims;
            state.attributes = initAttributes;
        });

        resetStateOnSignOut(builder, initialState);
    }
});

export default authSlice.reducer;

export function selectIsLoggedIn(state: IAuthenticationState): boolean {
    return state.authSlice.isLoggedIn;
}

export function selectHasSubscribed(state: IAuthenticationState): boolean {
    return state.authSlice.claims.has_subscribed_before;
}

export function selectIsTrialing(state: IAuthenticationState): boolean {
    return state.authSlice.claims.is_trialing;
}

export function selectAttributes(state: IAuthenticationState): IUserAttributes {
    return state.authSlice.attributes;
}

export function selectClaims(state: IAuthenticationState): IUserClaims {
    return state.authSlice.claims;
}

function getRole(decodedIdToken: { [key: string]: any }, key: string): UserRole {
    return decodedIdToken[key];
}

function getUserClaims(decodedIdToken: { [key: string]: any }): IUserClaims {
    return {
        active_subscription: getBoolClaim(decodedIdToken, 'valueanalytics:active_subscription'),
        cognito_username: getStringClaim(decodedIdToken, 'cognito:username'),
        role: getRole(decodedIdToken, 'valueanalytics:role'),
        customer_id: getStringClaim(decodedIdToken, 'valueanalytics:customer_id'),
        has_subscribed_before: getBoolClaim(decodedIdToken, 'valueanalytics:has_subscribed_before'),
        is_trialing: getBoolClaim(decodedIdToken, 'valueanalytics:trialing')
    }
}

function getStringClaim(decodedIdToken: { [key: string]: any }, key: string): string {
    return decodedIdToken[key] === undefined
        ? ''
        : decodedIdToken[key];
}

function getBoolClaim(
    decodedIdToken: { [key: string]: any },
    key: string
): boolean {
    return decodedIdToken[key] === undefined
        ? false
        : decodedIdToken[key] === 'true'
}

function createRequestThrottleChecker() {
    let lastCheck = new Date().getMilliseconds();
    let count = 0;
    return () => {
        let currentTime = new Date().getMilliseconds();
        if (count < 5) {
            count += 1;
            return true;
        }
        if (currentTime - lastCheck > 1000) {
            lastCheck = currentTime;
            count = 0;
            return true;
        }
        return false;
    }
}



async function getCurrentUserInformation(bypassCache: boolean) {
    const currentUser = await Auth.currentAuthenticatedUser({bypassCache: bypassCache});
    const attributes = await getUserAttributes();
    const decodedIdToken = await getDecodedToken();
    return {currentUser, attributes, decodedIdToken};
}

async function getDecodedToken() {
    const session = await Auth.currentSession();
    return session.getIdToken().decodePayload();
}

async function getUserAttributes(): Promise<IUserAttributes> {
    const {attributes} = await Auth.currentAuthenticatedUser();
    if (!attributes) {
        return {
            fullName: '',
            email: ''
        }
    }
    const fullName = attributes['custom:fullname'] ? attributes['custom:fullname'] : '';
    const email = attributes['email'];
    return {
        fullName: fullName,
        email: email
    };
}
