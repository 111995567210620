import {CheckboxState} from "../components/Checkbox/Checkbox";
import {IFilterSliceState, selectExchanges, selectStateHq, selectYearsOfData} from "../filtering/filterSlice";
import {IFilterData} from "../filtering/filterPageData";
import {IPickerFilter, RegularFilterPickers} from "../filtering/filterStateTypes";
import * as _ from 'lodash';

export type PickerLevel = "picker-level-1" | "picker-level-2" | "picker-level-3";

export interface ISelectableState {
    isSelected: boolean,
    isChildSelected: boolean
}

export interface FilterPickerRegularState extends ISelectableState {
    name: string;
    value: string;
    handleOnClick: () => void;
}

export function getCheckboxState(state: ISelectableState): CheckboxState {
    if (state.isChildSelected) {
        return CheckboxState.ChildChecked;
    }

    return state.isSelected
        ? CheckboxState.Checked
        : CheckboxState.Unchecked;
}

export function includesString(aString: string, value: string) {
    if (aString === "") {
        return true;
    }

    return value
        .toLowerCase()
        .includes(aString.toLowerCase());
}

export function isAnyValueSelected(filter: IPickerFilter, values: string[]) {
    return _.intersection(filter.value, values).length > 0;
}

export function isAllValuesSelected(filter: IPickerFilter, values: string[]) {
    const result = _.intersectionWith(values, filter.value, _.isEqual);
    return result.length === values.length;
}

export function isValueSelected(filter: IPickerFilter, value: string) {
    return _.includes(filter.value, value);
}

export function isChildValueSelected(filter: IPickerFilter) {
    return filter.value.length > 0;
}

export function getPickerSelector(pickerType: RegularFilterPickers): (state: IFilterSliceState) => IFilterData[] {
    switch (pickerType) {
        case "exchanges":
            return selectExchanges
        case "state_hq":
            return selectStateHq
        case "years_of_data":
            return selectYearsOfData
    }
}
