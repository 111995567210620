import './FilterPicker.scss';
import '../../style/style.scss';

import React, {ReactNode} from "react";
import {FilterStateContainer} from "../FilterContainer/FilterStateContainer";
import {FilterState} from "../../filtering/filterStateTypes";

export interface FilterPickerProps {
    filter: FilterState;
    onDeleteFilter: () => void;
    children: ReactNode;
}

export function FilterPicker(props: FilterPickerProps) {
    return (
        <FilterStateContainer filter={props.filter} onDeleteFilter={props.onDeleteFilter}>
            <div className={"picker-list-container"}>
                <div className={"picker-list"}>
                    {props.children}
                </div>
            </div>
        </FilterStateContainer>
    );
}
