import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getNextPayment,
         getHistory,
         getIsLoading,
         fetchBillingData,
         getCardDetails } from '../../billing/billingSlice';
import {UpdateCard} from "./UpdateCard"
import {Elements} from "@stripe/react-stripe-js";
import {loadStripe} from "@stripe/stripe-js";
import {config} from "../../config";
import {Loader} from "../Loader/Loader";

const stripePromise = loadStripe(config.stripe_pk);

function BillingHistory() {
  const dispatch = useDispatch();
  const isLoading = useSelector(getIsLoading)
  const nextPayment = useSelector(getNextPayment);
  const history = useSelector(getHistory);
  const cardDetails = useSelector(getCardDetails)

  useEffect(() => {
      dispatch(fetchBillingData());
  }, []);

  const billingHistoryItems = history.map((item, index) => {
    return (
      <div className="item" key={index}>
        <p>{`${item.payment_date}`}</p>
        <p>{`${item.amount}`}</p>
        <a className="right-justify" target='_blank' href={item.invoice_url}>View Statement</a>
      </div>
    );
  });

  const cardDetailsComponent = () => {
      return (
          <div className="details-style">
              {
                  Object.keys(cardDetails).length == 0 ? 'No default payment method' :
                      <div >
                          <p className={'card-details'}>{`${typeof cardDetails.brand == 'string' ? cardDetails.brand.toUpperCase() : ""} **** **** **** ${cardDetails.last4}`}</p>
                          <p className={'card-details'}>{`Expires ${cardDetails.exp_month}/${cardDetails.exp_year}`}</p>
                      </div>
              }

          </div>
      )
  }

  const nextBillingDateComponent = () => {
      return (
          <div className="details-style">
              {
                  Object.keys(nextPayment).length == 0 ? 'No upcoming payments' :
                      <div>
                          <p>{`${nextPayment.amount_due} on ${nextPayment.next_payment_date}`}</p>
                      </div>
              }

          </div>
      )
  }

  return (
    <div className="billing-history">
      <div className="billing-date">
        <h2>Next Billing Date</h2>
          {
              isLoading? <Loader isLoading={true}></Loader>: nextBillingDateComponent()
          }
      </div>
      <div className="method">
        <h2>Payment Method</h2>
              {
                  isLoading ? <Loader isLoading={true}></Loader>: cardDetailsComponent()
              }
          <Elements stripe={stripePromise}>
                  <UpdateCard
                  ></UpdateCard>
          </Elements>
      </div>
      <div className="history">
        <h2>Billing History</h2>
        <div className="details-style">
          {
              isLoading ? <Loader isLoading={true}></Loader> : billingHistoryItems.length > 0
                        ? billingHistoryItems : <p>No Billing History</p>
          }
        </div>
      </div>
    </div>
  );
}

export default BillingHistory;
