import './FinancialHighlightsTable.scss';
import React from 'react';
import {Fragment} from "react";
import {useSelector} from "react-redux";
import {selectFhComps} from "../../comps/compsTableSlice";
import {formatNumber} from "../../utility/numberFormatter";
import _ from "lodash";
import {IFhCompSetRow} from "../../api/requestsTypes";
import {CompsTable} from './CompsTableInput';

export default FinancialHighlightsTable;

function FHtable(props: any) {

    const rows = props.rows;
    const row = props.row;
    const idx = props.idx;
    const max_idx = props.max_idx;

    if (idx == 0 || rows[idx - 1].section != row.section) {
        return <Fragment>
            <tr>
                <td className={'bottom-border'}><b>{row.section}</b></td>
                {
                    row.data.values.map((value: any, i: any) =>
                        i == max_idx ?
                            <td className={'left-border bottom-border'}></td> :
                            <td className={'bottom-border'}></td>
                    )
                }
            </tr>
            <tr>
                <td className={'align-right'}>{row.data.header}</td>
                {
                    row.data.values.map((value: any, i: any) =>
                        i == max_idx ?
                            <td className={'left-border'}>{formatNumber(value, row.type)}</td> :
                            <td>{formatNumber(value, row.type)}</td>
                    )
                }
            </tr>
        </Fragment>


    } else {
        return <tr>
            <td className={'align-right'}>{row.data.header}</td>
            {
                row.data.values.map((value: any, i: any) =>
                    i == max_idx ?
                        <td className={'left-border'}>{formatNumber(value, row.type)}</td> :
                        <td>{formatNumber(value, row.type)}</td>
                )
            }
        </tr>
    }


}

function FinancialHighlightsTable() {
    const fhComps = useSelector(selectFhComps);
    const sectionDictionary = _.groupBy(fhComps.values, i => i.section);
    const sections = Object.keys(sectionDictionary);
    const headers = fhComps.columns;
    const headerIndexes = headers.map(header => fhComps.columns.indexOf(header));
    const headersPlusEmptyCell = ['', ...headers]
    let max_idx = 0;
    headers.forEach((h, i) => {
        if (h == 'Maximum') {
            max_idx = i
        }
    })

    return (
        <CompsTable className={'financial-highlights'}>
            <table className={'va-table'}>
                <thead>
                <tr>
                    <th className={'sticky-header'}></th>
                    {
                        headers.map((header, i) =>
                            i == max_idx ? <th className={'sticker-header left-border'}>{header}</th> :
                                <th>{header}</th>
                        )
                    }
                </tr>
                </thead>
                <tbody>
                {
                    fhComps.values.map((row, idx, rows) =>
                        <FHtable row={row} idx={idx} rows={rows} max_idx={max_idx}></FHtable>
                    )
                }
                </tbody>
            </table>
        </CompsTable>
    )
}


interface ITableRows {
    headers: string[];
    rows: string[][];
}

function HeaderTables(props: { headers: string[] }) {
    return (
        <table className={'va-table'}>
            <thead>
            <tr>
                {
                    props.headers.map(i =>
                        <th colSpan={1} key={i}>
                            <b>{i}</b>
                        </th>
                    )
                }
            </tr>
            </thead>
        </table>
    )
}


function RowTables(props: ITableRows) {
    return (
        <table className={'va-table'}>
            <thead>
            <tr>
                {
                    props.headers.map((i) =>
                        <th key={i} colSpan={1}></th>
                    )
                }
            </tr>
            </thead>
            <tbody>
            {
                props.rows.map((row, index) =>
                    <tr key={index}>
                        {
                            row.map((column, i) => <td key={i}> {column} </td>)
                        }
                    </tr>
                )
            }
            </tbody>
        </table>
    )
}

interface ISingleColumnTableProps {
    sectionName: string;
    sectionRows: IFhCompSetRow[];
}


function SingleColumnTable(props: ISingleColumnTableProps) {
    return (
        <table className={'va-table'}>
            <thead className={'table-header'}>
            <tr>
                <th className={'table-section'}>{props.sectionName}</th>
            </tr>
            </thead>
            <tbody>
            {
                props.sectionRows.map(i =>
                    <tr key={i.data.header}>
                        <td><b>{i.data.header}</b></td>
                    </tr>
                )
            }
            </tbody>
        </table>
    );
}

function getRows(row: IFhCompSetRow, rowIndexes: number[]): string[] {
    let output = [row.data.header]
    rowIndexes.forEach(i => {
        output.push(formatNumber(row.data.values[i], row.type))
    })
    return output
}
