import './SubscriptionUpdaterModal.scss';
import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import {
    refreshUserSessionInformation,
    selectClaims
} from "../../auth/authSlice";
import {Modal} from "../Modal/Modal";
import {Loader} from "../Loader/Loader";

interface SubscriptionUpdaterModalProps {
    onSubscriptionActivated: () => void;
    isUpgrading: boolean
}

export function SubscriptionUpdaterModal(props: SubscriptionUpdaterModalProps) {
    const dispatch = useDispatch();
    const [shouldRefresh, setRefresh] = useState(true);
    const [refreshToggle, setRefreshToggle] = useState(false);
    const claims = useSelector(selectClaims);

    // returns true when time to break from updating status
    // when we are upgrading a trial, we check for trialing status to be false
    // when selecting plan for first time, we check for active subscription
    function get_active_flag() {
        if (props.isUpgrading){
            return !claims.is_trialing
        } else {
            return claims.active_subscription
        }
    }

    useEffect(() => {
        if (!shouldRefresh) {
            return;
        }
        dispatch(refreshUserSessionInformation(true));
        const timeout = setTimeout(() => {
            if (shouldRefresh) {
               triggerRefresh();
            }
        }, 2000);
        return () => clearTimeout(timeout);
    }, [refreshToggle]);

    useEffect(() => {

        if(get_active_flag()){
            setRefresh(false);
            props.onSubscriptionActivated();
        }
        else{
            setRefresh(true);
        }

    }, [claims]);

    function triggerRefresh() {
        setRefreshToggle(!refreshToggle);
    }

    return (
        <div className={'subscription-updater-modal'}>
            <Modal onCloseModal={() => null} hideCloseButton={true}>
                <Loader isLoading={true}/>
                <div className={'text'}>Updating Subscription...</div>
            </Modal>
        </div>
    );
}
