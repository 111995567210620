import {CardElement, Elements, ElementsConsumer, useElements, useStripe} from "@stripe/react-stripe-js";
import React, {useState} from "react";
import {Stripe, StripeCardElementChangeEvent, StripeCardElement} from "@stripe/stripe-js";
import {config} from "../../config";
import '../SignupForm/SignupForm.scss'
import '../Button/Button.scss'
import {setDefaultPayment} from "../../billing/billingSlice";
import {Action, ThunkDispatch, unwrapResult} from "@reduxjs/toolkit";
import {useDispatch} from "react-redux";
import {RootState} from "../../store";
import {SetupIntentResponse} from "../../api/requestsTypes";
import {Loader} from "../Loader/Loader";
import {getSetupIntent} from '../../api/requests';
import stripeJs from "@stripe/stripe-js";



export async function addPaymentMethod(
        stripe: Stripe,
        card: StripeCardElement,
        clientSecret: string,

    ) {

        const setupIntentResult = await stripe.confirmCardSetup(
            clientSecret,
            {
                payment_method: {
                    card: card
                }
            }
        )

        return setupIntentResult
    }


export async function handleCardSetup(
    response:SetupIntentResponse,
    stripe: Stripe | null,
    elements: stripeJs.StripeElements | null
) {
        const card = elements!.getElement(CardElement);
        // returning undefined will cause us to end up in the catch block
        if (stripe === null ||
            card === null) {
            return undefined;
        }
        return await addPaymentMethod(stripe, card, response.client_secret)

    }



export function UpdateCard(){
    const stripe = useStripe();
    const dispatch = useDispatch<ThunkDispatch<RootState, any, Action>>();
    const elements = useElements();
    const [isUpdatingCard, setIsUpdatingCard] = useState(false);
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');
    const [cardIsReady, setCardIsReady] = useState(false);


    const handleChange = async (event: StripeCardElementChangeEvent) => {
        // Listen for changes in the CardElement
        // and display any errors as the customer types their card details
        setMessage('')
        if (event.complete) {
            setCardIsReady(true);
            setError('')
            return
        }

        if (event.error) {
            setError(event.error.message);
            setIsUpdatingCard(false);
            setCardIsReady(false);
            return
        }

        setError('')
    };

    const handleCardSuccess = () => {
        setIsUpdatingCard(false)
        setMessage('Payment Method Updated!')
    }

    const handleCardFailure = () => {
        setIsUpdatingCard(false)
        setError('Error Updating Card. Please try again later or contact support@valueanalytics.org')
    }

    const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (event) => {
        event.preventDefault();
        setMessage("")

        if (!cardIsReady) {
            setError('Enter new card details.');
            return;
        }

        setIsUpdatingCard(true)
        try {
            const setupIntent = await getSetupIntent()
            const setupIntentResults = await handleCardSetup(setupIntent, stripe, elements)

            if (setupIntentResults && setupIntentResults.setupIntent?.payment_method != null) {

                dispatch(setDefaultPayment({
                    payment_id: setupIntentResults.setupIntent.payment_method
                })
                ).then(unwrapResult)
                .then(() => {
                     handleCardSuccess()
                }).catch((error) => {
                     handleCardFailure()
                })

            } else {
                throw 'SetupIntent not defined'
            }
        // this will only catch errors from getSetupIntent and handleCardSetup
        } catch (error) {
            handleCardFailure()
        }

    };



    return (

          <ElementsConsumer>
            {({stripe, elements}) => (
            <form className={'update-card-form'} onSubmit={handleSubmit}>
               <div>
                    <div className="card-element update-page">
                        <CardElement
                            onChange={handleChange}
                            options={{
                                style: {
                                    base: {
                                        padding: '14px 18px',
                                        fontSize: '12px',
                                    }
                                }
                            }}
                        />
                    </div>
                    {error? <p className={'update-error'}>{error}</p> : ''}
                    {message? <p className={'done-message'}>{message}</p> : ''}
                </div>
                <button className={'button primary va-font update-button'} type="submit" disabled={!stripe || isUpdatingCard}>
                    <span>Update Payment Method</span>
                    <Loader isLoading={isUpdatingCard} className={'inline'}></Loader>
                </button>
            </form>
        )}
        </ElementsConsumer>
    )

}