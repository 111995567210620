import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {getSearchableFilters, IFilter} from "./filter";
import {IFilterPageData, IFilterData} from "./filterPageData";
import {getFilterPageData} from "../api/requests";
import {resetStateOnSignOut} from "../auth/signOut";

interface IFilterSlice {
    searchableFilters: IFilter[];
    filterPageData: IFilterPageData;
    isFetchingFilters: boolean;
}

export interface IFilterSliceState {
    filterSlice: IFilterSlice
}

export const fetchFilters = createAsyncThunk<IFilterPageData, void>(
    'filter/fetchFilters',
    async (thunkAPI) => {
        return await getFilterPageData();
    }
);

const initialState: IFilterSlice = {
    searchableFilters: [] as IFilter[],
    filterPageData: {} as IFilterPageData,
    isFetchingFilters: false
};

export const filterSlice = createSlice({
    name: 'filterSlice',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(fetchFilters.fulfilled, (state, action) => {
            state.filterPageData = action.payload;
            state.searchableFilters = getSearchableFilters(action.payload);
            state.isFetchingFilters = false;
        });

        builder.addCase(fetchFilters.pending, (state, action) => {
            state.isFetchingFilters = true;
        });

        builder.addCase(fetchFilters.rejected, (state, action) => {
           state.isFetchingFilters = false;
        });

        resetStateOnSignOut(builder, initialState);
    }
});


export const selectFilterList = (state: IFilterSliceState) =>
    state.filterSlice.searchableFilters;

export const selectSicCodes = (state: IFilterSliceState) =>
    state.filterSlice.filterPageData['SIC Codes'];

export const selectStateHq = (state: IFilterSliceState): IFilterData[]  =>
    state.filterSlice.filterPageData['Corporate HQ']

export const selectYearsOfData = (state: IFilterSliceState) =>
    state.filterSlice.filterPageData['Years of Financial Data']

export const selectExchanges = (state: IFilterSliceState) =>
    state.filterSlice.filterPageData['Exchange']

export const selectBusinessDescription = (state: IFilterSliceState) =>
    state.filterSlice.filterPageData["Business Description"]

export const selectIsFetchingFilters = (state: IFilterSliceState) =>
    state.filterSlice.isFetchingFilters
