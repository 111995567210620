import React from "react";
import './CategoryLabel.scss'
import '../../style/style.scss'

export interface CategoryLabelProps {
    category: string;
}

export function CategoryLabel(props: CategoryLabelProps) {
    return (
        <span className={"font-default category-label"}>{props.category}</span>
    );
}
