import React, {ChangeEvent, useState} from "react";
import {FilterStateContainer} from "./FilterStateContainer";
import './FilterStateNumericRange.scss';
import {
    getNumericFilterStateWithMax,
    getNumericFilterStateWithMin
} from "../../filtering/filterStateLogic";
import {useDispatch} from "react-redux";
import {updateSelectedFilterState} from "../../filtering/selectedFilterSlice";
import {INumericFilter} from "../../filtering/filterStateTypes";
import {formatNumber} from "../../utility/numberFormatter";
import numeral from "numeral";
import {InfoTip} from "../Utility/InfoTip";


function useNumericRageLogic(filter: INumericFilter) {
    const dispatch = useDispatch();
    const[min, max] = filter.range;
    const [minInput, setMinInput] = useState(
        isNaN(min)
            ? '0'
            : filter.range[0].toString()
    );
    const [maxInput, setMaxInput] = useState(
        max === null || isNaN(max)
            ? Infinity.toString()
            : max.toString()
    );

    function handleOnMinChange(min: string) {
        setMinInput(min);
        dispatch(
            updateSelectedFilterState(
                getNumericFilterStateWithMin(min, filter)))
    }

    function handleOnMaxChange(max: string) {
        setMaxInput(max);
        dispatch(
            updateSelectedFilterState(
                getNumericFilterStateWithMax(max, filter)))
    }

    function handleOnMinBlur(min: string) {
        if(numeral(min).value() === null) {
            setMinInput('0');
        }
    }

    function handleOnMaxBlur(max: string) {
        if(numeral(max).value() === null) {
            setMaxInput('Infinity');
        }
    }




    return {
        handleOnMinChange,
        handleOnMaxChange,
        handleOnMinBlur,
        handleOnMaxBlur,
        minInput,
        maxInput
    }
}

interface NumericInputProps {
    value: string;
    onChange: (e: ChangeEvent<HTMLInputElement>) => void;
    formatType: string;
    onBlur: (e: React.FocusEvent<HTMLInputElement>) => void;
}

function NumericInput(props: NumericInputProps) {
    const [isFocused, setIsFocused] = useState(false);

    function handleOnBlur(e: React.FocusEvent<HTMLInputElement>){
       setIsFocused(false);
       props.onBlur(e);
    }

    return <>
        {
            isFocused ?
                <input
                    className={'numeric-input'}
                    name={"min"}
                    type={"number"}
                    value={props.value}
                    onChange={(e) => props.onChange(e)}
                    onBlur={(e) => handleOnBlur(e)}
                />
                : <input
                    className={'numeric-input'}
                    name={"min"}
                    type={"text"}
                    value={formatNumber(props.value, props.formatType)}
                    onFocus={() => setIsFocused(true)}
                    readOnly
                />
        }
    </>;
}

function NumericRangeInputs(props: { filter: INumericFilter, formatLeft: string, formatRight: string }) {
    const {
        handleOnMinChange,
        handleOnMaxChange,
        handleOnMinBlur,
        handleOnMaxBlur,
        minInput,
        maxInput
    } = useNumericRageLogic(props.filter);

    return (
        <>
            <span>
                <div className={'input-label'}>
                    {props.formatLeft}
                </div>
                <NumericInput
                    value={minInput}
                    onChange={e => handleOnMinChange(e.target.value)}
                    formatType={props.filter.displayType}
                    onBlur={e => handleOnMinBlur(e.target.value)}
                />
                <div className={'input-label'}>
                {props.formatRight}
                </div>
            </span>
            <span>
                <div className={'input-label'}>
                {props.formatLeft}
                </div>
                <NumericInput
                    value={maxInput}
                    onChange={e => handleOnMaxChange(e.target.value)}
                    formatType={props.filter.displayType}
                    onBlur={e => handleOnMaxBlur(e.target.value)}
                />
                <div className={'input-label'}>
                {props.formatRight}
                </div>
            </span>
        </>
    );
}

function NumericRangeFactory(props: { filter: INumericFilter }) {

    switch (props.filter.displayType) {
        case 'integer':
            return <NumericRangeInputs filter={props.filter} formatLeft={''} formatRight={''} />
        case 'integer_m':
            return <NumericRangeInputs filter={props.filter} formatLeft={'$'} formatRight={'(MM)'}/>
        case 'percentage':
            return <NumericRangeInputs filter={props.filter} formatLeft={''} formatRight={''}/>
        case 'decimal':
            return <NumericRangeInputs filter={props.filter} formatLeft={''} formatRight={''}/>
        default:
            return null;
    }
}

export function FilterStateNumericRange(props: { filter: INumericFilter }) {
    return (
        <FilterStateContainer filter={props.filter} onDeleteFilter={() => null}>
            <div className={'numeric-range'}>
                <span className={'range-header'}>Min</span>
                <div>
                    <span className={'range-header-max'}>Max</span>
                    <InfoTip message={
                                "For no upper bound, leave the max field blank."
                            } placement={"right"} size={15} id={'2'}></InfoTip>
                </div>
                <NumericRangeFactory filter={props.filter}/>
            </div>
        </FilterStateContainer>
    )
}
