import React from 'react';
import {Route, NavLink, Redirect} from 'react-router-dom';
import BillingHistory from './BillingHistory';
import TeamManagement from './TeamManagement';
import {useSelector} from "react-redux";
import {selectClaims} from "../../auth/authSlice";
import {OwnerTeamManagement} from "./OwnerTeamManagement";
import './Billing.scss';

function Billing(props: any) {
    const claims = useSelector(selectClaims)
  return (
    <div className="billing-container">
      <div className="billing-nav">
        <nav>
          <ul>
            <li>
                <NavLink to="/account/billing/team" activeClassName="selected">
                    Manage Subscription
                </NavLink>
            </li>
              {
                  claims.role === 'MANAGER'?
                  <li>
                      <NavLink to="/account/billing/history" activeClassName="selected">
                          Billing Details
                      </NavLink>
                  </li>
                      : null
              }
          </ul>
        </nav>
      </div>
      <Route path="/account/billing/history">
          {  claims.role === 'MANAGER' ?
              <BillingHistory/>:
              <Redirect to="/account/settings" />
          }
      </Route>
      <Route path="/account/billing/team">
          {  claims.role === 'MANAGER' ?
              <TeamManagement handleInviteUser={props.handleInviteUser}
                              setShowInvoicePreview={props.setShowInvoicePreview}
                              setInvoiceAction={props.setInvoiceAction}
                              teamMgmtError={props.teamMgmtError}/>:
              claims.role === 'OWNER' ?
                  <OwnerTeamManagement handleInviteUser={props.handleInviteUser}
                                  setShowInvoicePreview={props.setShowInvoicePreview}
                                  setInvoiceAction={props.setInvoiceAction}
                                  teamMgmtError={props.teamMgmtError}/>
                  :
              <Redirect to="/account/settings" />
          }
      </Route>
      <div>

      </div>
    </div>
  );
}

export default Billing;
