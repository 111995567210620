import {CurrentSubscription, InvoicePreview, lineItem} from "../../../api/requestsTypes";
import {useInvoiceLogic} from "./UseInvoiceLogic";
import {cancelSubscription} from "../../../api/requests";
import {fetchSubMgmtData} from "../../../billing/billingSlice";
import {OrderSummary} from "./OrderSummary";
import {PrimaryButton} from "../../Button/Button";
import {OrderInfo} from "./OrderInfo";
import React from "react";

interface CancelAccountAdmin {
    invoice: InvoicePreview
    setShowInvoicePreview: (show: boolean) => void
    line_items: lineItem[]
    users: any[]
    plan: CurrentSubscription
    errors: string
    invoiceAction: string
}

export function CancelManagerInvoice(props: CancelAccountAdmin) {
    const {
        error,
        isLoading, isEnabled,
        performAction
    } = useInvoiceLogic(props)
    const button_text = 'Cancel Subscription'
    let desc_text_1 = ''
    if (props.invoiceAction === 'cancel_with_active_users') {
        desc_text_1 = 'Cancelling your Account Manager subscription will also cancel the subscription for all of your Team Members.  You and your team members ' +
            'will still have access until the end of the current subscription period.'
    }
    const desc_text_2 = 'Press "Cancel" to terminate access at the end of the current subscription period and end future invoicing.'
    const due_text = 'Total due on next Invoice'
    const cancelManagerSubscriptionWithUsers = performAction(cancelManagerSubscriptionWithUsersAction)

    function cancelManagerSubscriptionWithUsersAction() {
        const cancelled_users = props.users.filter(user => user.active)
        const cancelled_user_ids = cancelled_users.map(user => user.id)

        let payload = {
            'cancelled_ids': cancelled_user_ids,
            'type': 'cancel_manager',
            'subscription_id': props.plan.sub_id
        }

        return cancelSubscription(payload)
    }

    return (
        <OrderSummary
            errors={props.errors}
            updateError={error}
            descText1={desc_text_1}
            descText2={desc_text_2}
            actionButton={
                <PrimaryButton className={'right-inline-button'}
                               text={button_text}
                               onClick={cancelManagerSubscriptionWithUsers}
                               isEnabled={isEnabled}
                               isLoading={isLoading}/>
            }
            orderInfo={
                <OrderInfo
                    lineItems={props.line_items}
                    dueText={due_text}
                    total={props.invoice.next_invoice.total}>
                </OrderInfo>
            }
        />
    )
}
