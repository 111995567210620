import {API, Auth} from "aws-amplify";
import {IAuthHeaders} from "./requestsTypes";

function addBody<T>(data: T): (initialData: any) => any {
    return (initialData: any) => ({
        ...initialData,
        body: data
    });
}

export function postRequest<T>(apiName: string, path: string, data: T) {
    return getHeaders()
        .then(addBody(data))
        .then(initData => API.post(apiName, path, initData))
}

export function getRequest(apiName: string, path: string) {
    return getHeaders()
        .then(headers => API.get(apiName, path, headers))
}

function getHeaders(): Promise<IAuthHeaders> {
    return getJwtToken()
        .then(getAuthorizationHeader)
        .then(headers => ({
            "headers": {...headers}
        }));
}

function getJwtToken(): Promise<string> {
    return Auth.currentSession()
        .then(session => session.getIdToken().getJwtToken())
}

function getAuthorizationHeader(token: string) {
    return {
        "Authorization": `Bearer ${token}`
    }
}
