import {Auth, Hub} from 'aws-amplify';
import type {HubCapsule} from '@aws-amplify/core';
import React, {useEffect, useState} from 'react';
import {useAppDispatch} from "../hooks/hooks";
import {signOutAction} from "../auth/signOut";
import {refreshUserSessionInformation} from "../auth/authSlice";
import {useHistory} from "react-router-dom";
import {Routes} from "../routes/routes";

export default function AuthEventListener() {
    const [authEvent, setAuthEvent] = useState<HubCapsule | undefined>();
    const dispatch = useAppDispatch()
    const history = useHistory();

    function authCallback(data: HubCapsule) {
        setAuthEvent(data);

        switch (data.payload.event) {
            case 'tokenRefresh_failure':
                Auth.signOut()
                break
            case 'signOut':
                dispatch(signOutAction())
                history.push(Routes.homePage)
                break
        }

    }

    useEffect(() => {
        dispatch(refreshUserSessionInformation(false));
        Hub.listen('auth', authCallback)
        return () => Hub.remove('auth', authCallback)

    }, [authEvent])

    return <></>
}



