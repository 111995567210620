import React, {useEffect} from "react";
import {useLocation} from "react-router";

function ScrollToTop(props: { children: React.ReactNode }) {
    const location = useLocation();
    const anchor = window.location.hash.split('#')[1];
    useEffect(() => {
        if (anchor == null) {
            window.scrollTo(0, 0);
        }
    }, [location]);

    return <>{props.children}</>
};

export default ScrollToTop;
