import React from "react";

interface CheckboxImgProps {
    className: string;
    onClick: () => void;
}

function CheckboxChecked(props: CheckboxImgProps) {
    return (
        <svg className={props.className} onClick={props.onClick} width="20" height="20" viewBox="0 0 20 20" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <rect width="20" height="20" fill="white"/>
            <path
                d="M15.8333 4.16667V15.8333H4.16667V4.16667H15.8333ZM15.8333 2.5H4.16667C3.25 2.5 2.5 3.25 2.5 4.16667V15.8333C2.5 16.75 3.25 17.5 4.16667 17.5H15.8333C16.75 17.5 17.5 16.75 17.5 15.8333V4.16667C17.5 3.25 16.75 2.5 15.8333 2.5Z"
                fill="#59C2E3"/>
            <rect width="14" height="14" transform="translate(3 3)" fill="#59C2E3"/>
            <path
                  d="M14.6947 6.70495C14.8259 6.83622 14.8996 7.01424 14.8996 7.19985C14.8996 7.38547 14.8259 7.56348 14.6947 7.69475L9.0947 13.2948C8.96343 13.426 8.78541 13.4997 8.5998 13.4997C8.41418 13.4997 8.23617 13.426 8.1049 13.2948L5.3049 10.4948C5.17739 10.3627 5.10683 10.1859 5.10842 10.0024C5.11002 9.81883 5.18364 9.64326 5.31342 9.51348C5.44321 9.38369 5.61878 9.31007 5.80232 9.30848C5.98585 9.30688 6.16268 9.37744 6.2947 9.50495L8.5998 11.8101L13.7049 6.70495C13.8362 6.57372 14.0142 6.5 14.1998 6.5C14.3854 6.5 14.5634 6.57372 14.6947 6.70495Z"
                  fill="white"/>
        </svg>
    );
}

function CheckboxUnchecked(props: CheckboxImgProps) {
    return (
        <svg className={props.className} onClick={props.onClick} width="20" height="20" viewBox="0 0 20 20" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <rect width="20" height="20" fill="white"/>
            <path
                d="M15.8333 4.16667V15.8333H4.16667V4.16667H15.8333ZM15.8333 2.5H4.16667C3.25 2.5 2.5 3.25 2.5 4.16667V15.8333C2.5 16.75 3.25 17.5 4.16667 17.5H15.8333C16.75 17.5 17.5 16.75 17.5 15.8333V4.16667C17.5 3.25 16.75 2.5 15.8333 2.5Z"
                fill="#969696"/>
        </svg>
    );
}

function CheckboxChildChecked(props: CheckboxImgProps) {
    return (
        <svg className={props.className} onClick={props.onClick} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M15.8333 4.16667V15.8333H4.16667V4.16667H15.8333ZM15.8333 2.5H4.16667C3.25 2.5 2.5 3.25 2.5 4.16667V15.8333C2.5 16.75 3.25 17.5 4.16667 17.5H15.8333C16.75 17.5 17.5 16.75 17.5 15.8333V4.16667C17.5 3.25 16.75 2.5 15.8333 2.5Z"
                fill="#969696"/>
            <svg width="12" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path transform={"translate(10, 2)"} d="M12.667 8.66634H3.33366C3.15685 8.66634 2.98728 8.5961 2.86225 8.47108C2.73723 8.34605 2.66699 8.17649 2.66699 7.99967C2.66699 7.82286 2.73723 7.65329 2.86225 7.52827C2.98728 7.40325 3.15685 7.33301 3.33366 7.33301H12.667C12.8438 7.33301 13.0134 7.40325 13.1384 7.52827C13.2634 7.65329 13.3337 7.82286 13.3337 7.99967C13.3337 8.17649 13.2634 8.34605 13.1384 8.47108C13.0134 8.5961 12.8438 8.66634 12.667 8.66634Z" fill="black"/>
            </svg>
        </svg>
    )
}

function CheckboxNotClickable(props: CheckboxImgProps) {
    return (
        <svg className={props.className} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M15.8333 4.16667V15.8333H4.16667V4.16667H15.8333ZM15.8333 2.5H4.16667C3.25 2.5 2.5 3.25 2.5 4.16667V15.8333C2.5 16.75 3.25 17.5 4.16667 17.5H15.8333C16.75 17.5 17.5 16.75 17.5 15.8333V4.16667C17.5 3.25 16.75 2.5 15.8333 2.5Z"
                fill="#969696"/>
            <svg width="12" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path transform={"translate(10, 2)"} d="M12.667 8.66634H3.33366C3.15685 8.66634 2.98728 8.5961 2.86225 8.47108C2.73723 8.34605 2.66699 8.17649 2.66699 7.99967C2.66699 7.82286 2.73723 7.65329 2.86225 7.52827C2.98728 7.40325 3.15685 7.33301 3.33366 7.33301H12.667C12.8438 7.33301 13.0134 7.40325 13.1384 7.52827C13.2634 7.65329 13.3337 7.82286 13.3337 7.99967C13.3337 8.17649 13.2634 8.34605 13.1384 8.47108C13.0134 8.5961 12.8438 8.66634 12.667 8.66634Z" fill="black"/>
            </svg>
        </svg>
    )
}

export enum CheckboxState {
    Checked,
    Unchecked,
    ChildChecked,
    NotClickable
}

interface CheckboxProps {
    checkboxType: CheckboxState,
    className: string,
    onClick: () => void
}

function CheckboxFactory(props: { checkboxType: CheckboxState, checkboxImgProps: CheckboxImgProps }) {
    switch (props.checkboxType) {
        case CheckboxState.Checked:
            return <CheckboxChecked {...props.checkboxImgProps}/>
        case CheckboxState.Unchecked:
            return <CheckboxUnchecked {...props.checkboxImgProps}/>
        case CheckboxState.ChildChecked:
            return <CheckboxChildChecked {...props.checkboxImgProps}/>
        case CheckboxState.NotClickable:
            return <CheckboxNotClickable {...props.checkboxImgProps}/>
    }
}

function Checkbox(props: CheckboxProps) {
    const imgProps: CheckboxImgProps = {
        className: props.className,
        onClick: props.onClick
    }

    return <CheckboxFactory checkboxType={props.checkboxType} checkboxImgProps={imgProps}/>
}

export default Checkbox;
