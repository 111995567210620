import './CompsTab.scss';
import React from 'react';
import CollapsibleArrow from "../CollapsibleArrow/CollapsibleArrow";
import CompsSelectedCompsGroup from '../CompsSelectedCompsGroup/CompsSelectedCompsGroup';
import {useHistory} from 'react-router-dom';
import {Routes} from "../../routes/routes";
import {useSelector} from "react-redux";
import {selectSelectedCompsGroup} from "../../comps/compsGroupSlice";

function CompsTab(props: { isExpanded: boolean, onClick: () => void }) {
    const history = useHistory();
    const comps = useSelector(selectSelectedCompsGroup);

    function handleOnClick() {
        props.onClick();
    }

    function handleOnViewInComps() {
        history.push(Routes.comps)
    }

    return (
        <div className={'page-comps-tab'}>
            <div className={'comps-tab-flyout'}>
                <div className={'clickable roboto-regular-18 comps-tab'} onClick={handleOnClick}>
                    <div className={'comps-tab-wrap'}>
                        Comps Group
                        <CollapsibleArrow isExpanded={!props.isExpanded}/>
                    </div>
                </div>
                <div className={'comps-tab-comps-group ' + (props.isExpanded ? 'expanded' : '')}>
                    {
                        props.isExpanded
                            ? <CompsSelectedCompsGroup
                                companies={comps} buttonText={'View In Comps'}
                                onClick={handleOnViewInComps}/>
                            : null
                    }
                </div>
            </div>
        </div>
    )
}

export default CompsTab;

