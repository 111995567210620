import {lineItem} from "../../../api/requestsTypes";
import React from "react";

interface OrderInfoParams {
    lineItems: lineItem[];
    dueText: string;
    total: string;
    children?: React.ReactNode;
}

export function OrderInfo(props: OrderInfoParams) {
    return (
        <div className={"order-info"}>
            {
                props?.lineItems?.map((item, i) =>
                    <>
                        <div className={"left"} key={i}>
                            <p>
                                {item.description}
                            </p>
                        </div>
                        <div className={"right"} key={i + 0.5}>
                            <p>
                                {item.amount}
                            </p>
                        </div>
                    </>
                )
            }
            <div className={'overlined'}>
                <p className={'left bold'}>{props.dueText}</p>
            </div>
            <div className={'overlined'}>
                <p className={'right bold'}>{props.total}</p>
            </div>
            {props.children}
        </div>
    )
}
