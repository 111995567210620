import {CSSTransition} from "react-transition-group";
import {AmplifyAuthenticator, AmplifyForgotPassword, AmplifySignIn, AmplifySignUp} from "@aws-amplify/ui-react";
import {AuthState} from "@aws-amplify/ui-components";
import greeting_logo from "../../img/valueanalytics.svg";
import React, {useEffect, useState} from "react";
import './AccountCreation.scss'
import {Container, Row} from "react-bootstrap";
import classNames from "classnames";
import {useDispatch, useSelector} from "react-redux";
import {
    fetchPlanOptions,
    selectIsLoadingOptions,
    selectPlanOptions,
    updatePlanOptions,
    updatePlanType
} from "../../signup/signUpSlice";
import {Action, ThunkDispatch} from "@reduxjs/toolkit";
import {RootState} from "../../store";
import PlanOption from "../PlanOptionsPage/PlanOption";
import {Loader} from "../Loader/Loader";
import {SignupForm} from "../SignupForm/SignupForm";
import {SubscriptionUpdaterModal} from "../PlanOptionsPage/SubscriptionUpdaterModal";
import {IPlanOption} from "../../api/requests";
import {Col} from "reactstrap";


function handleAuthError(message: any) {
}

interface CreateCognitoAccountProps {
    initialState: AuthState.SignUp | AuthState.SignIn
}

export function CreateCognitoAccount(props: CreateCognitoAccountProps) {

    return (
        <CSSTransition appear
                       in
                       classNames={"login-transition"}
                       timeout={1000}
        >
            <div className={'create-cognito-form'}>
                <AmplifyAuthenticator federated={{}}
                                      initialAuthState={props.initialState}
                                      usernameAlias="email"
                                      handleAuthStateChange={handleAuthError}>
                    <AmplifySignUp
                        headerText=""
                        usernameAlias="email"
                        slot="sign-up"
                        formFields={[{
                            type: "custom:fullname",
                            label: "Full Name*",
                            placeholder: "Enter your full name",
                            inputProps: {required: true},
                        },
                            {
                                type: "email",
                                label: "Email Address*",
                                placeholder: "Enter your email address",
                                inputProps: {required: true, autocomplete: "username"},
                            },
                            {
                                type: "password",
                                label: "Password*",
                                placeholder: "Enter your password",
                                inputProps: {required: true, autocomplete: "new-password"},
                            }
                        ]}
                    >
                        <div slot="header-subtitle" className={'amplify-header'}>
                            <img className={"signup-logo"} src={greeting_logo}/>
                            <br/>
                            <span className={'welcome-message'}>{"Create Account"}</span>
                        </div>
                    </AmplifySignUp>

                    <AmplifySignIn className={'login-element'}
                                   headerText=""
                                   slot="sign-in"
                                   submitButtonText={'Login'}
                                   usernameAlias={'email'}>
                        <div slot="greetings"></div>
                        <div slot="federated-buttons"/>
                        <div slot="header-subtitle" className={'amplify-header'}>
                            <img className={"signup-logo"} src={greeting_logo}/>
                            <br/>
                            <span className={'welcome-message'}>{"Welcome!"}</span>
                        </div>
                    </AmplifySignIn>

                    <AmplifyForgotPassword className={'login-element'}
                                           headerText="Password Reset"
                                           slot="forgot-password"
                                           usernameAlias="email"
                    >
                    </AmplifyForgotPassword>
                </AmplifyAuthenticator>
            </div>
        </CSSTransition>
    )
}

interface planOptionsProps {
    planOptions: IPlanOption[]
    setStep: (step: flowStep) => void
    direction: 'from-right' | 'from-left'
    setDirection: (d: direction) => void
}

function PlanOptions(props: planOptionsProps) {
    const dispatch = useDispatch<ThunkDispatch<RootState, any, Action>>();

    function toggleShowSignupForm(planId: string) {
        dispatch(updatePlanType(planId));
        dispatch(updatePlanOptions(planId))
        props.setDirection('from-right')
        props.setStep(3)
    }


    const planOptionsComponent = props.planOptions.map(option =>
        <Col lg={4} md={12} className={'pb-5'}>
            <PlanOption
                selected={option.selected_plan}
                key={option.price_id}
                option={option}
                onFormToggle={toggleShowSignupForm}/>
        </Col>
    )

    return (
        <CSSTransition appear
                       exit
                       in
                       classNames={props.direction}
                       timeout={1000}
        >
            <div className="plan-options">
                <Row>
                    {
                        planOptionsComponent
                    }
                </Row>
            </div>
        </CSSTransition>
    )
}

type flowStep = 1 | 2 | 3
type direction = 'from-left' | 'from-right'

interface AccountCreationProps {
    isLoggedIn: boolean,
    isUpgrading: boolean
}

export function AccountCreationFlow(props: AccountCreationProps) {
    const dispatch = useDispatch()
    const planOptions = useSelector(selectPlanOptions)
    const [direction, setDirection] = useState<direction>('from-right')
    const [isUpdatingSubscription, setIsUpdatingSubscription] = useState(false);
    const [step, setStep] = useState<flowStep>(1);
    const isLoading = useSelector(selectIsLoadingOptions)


    async function asyncDispatch() {
        return dispatch(fetchPlanOptions())
    }

    // if they are logged in, switch to step 2
    useEffect(() => {
        if (props.isLoggedIn) {
            asyncDispatch().then(() => {
                setStep(2)
            })

        }

    }, [props.isLoggedIn])

    function handleOnSubscriptionActivated() {
        setIsUpdatingSubscription(false);
    }

    const blur = classNames({
        'blur': isUpdatingSubscription
    });


    const createAccountClassName = classNames({
        'flow-state-text': true,
        'selected': step === 1,
        'left': true,

    })

    const selectPlanClassName = classNames({
        'flow-state-text': true,
        'selected': step === 2

    })

    const selectPaymentCLassName = classNames({
        'flow-state-text': true,
        'selected': step === 3,
        'right': true

    })

    function getFlowStepComponent() {

        if (step === 1) {
            return <CreateCognitoAccount
                initialState={props.isUpgrading ? AuthState.SignIn : AuthState.SignUp}
            />
        } else if (step === 2) {
            return <PlanOptions
                planOptions={planOptions}
                setStep={setStep}
                direction={direction}
                setDirection={setDirection}
            />
            return <p>not implemented</p>
        } else if (step == 3) {
            return <SignupForm
                onUpdatingSubscription={setIsUpdatingSubscription}
                isUpgrading={props.isUpgrading}
            />
        } else {
            return <p></p>
        }

    }

    function goBack() {
        setStep(2)
        setDirection('from-left')
    }

    return (

        <div className={'signup-style'}>
            <CSSTransition appear
                           in
                           classNames={"from-right"}
                           timeout={1000}
            >
                <Container className={'h-100 ' + blur}>
                    <Row className={'justify-content-center align-content-center pt-5'}>
                        <div className={'create-account-container'}>
                            {step === 3 ?
                                <div className={"go-back"}>
                                    <button className={"go-back-button"}
                                            onClick={goBack}>
                                        {"<"} Go Back
                                    </button>
                                </div>
                                : ""}
                            <div className={'flow-state-container'}>
                                <span className={createAccountClassName}>Account</span>
                                <span className={selectPlanClassName}>Plan</span>
                                <span className={selectPaymentCLassName}>Payment</span>
                            </div>
                            <Loader isLoading={isLoading} className={'gold'}></Loader>
                        </div>
                    </Row>
                    <Row>
                        {getFlowStepComponent()}
                    </Row>

                </Container>
            </CSSTransition>
            {
                isUpdatingSubscription
                    ? <SubscriptionUpdaterModal onSubscriptionActivated={handleOnSubscriptionActivated}
                                                isUpgrading={props.isUpgrading}
                    />
                    : null
            }
        </div>
    )


}
