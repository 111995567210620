import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {RootState} from '../store'
import {createEmptySizePremiums, createMethodology, IMethodology, ISizePremiums} from "./sizePremium"
import {getSizePremiums} from "../api/requests";
import {resetStateOnSignOut} from "../auth/signOut";


interface ISizePremiumsSlice {
    isLoadingSizePremiums: boolean
    sizePremiums: ISizePremiums
    methodology: IMethodology
}

export const fetchSizePremiums = createAsyncThunk(
    'sizePremiumsSlice/fetchSizePremiums',
    async (data, thunkAPI) => {
        return await getSizePremiums()
    }
)

const initialState: ISizePremiumsSlice = {
    isLoadingSizePremiums: false,
    sizePremiums: createEmptySizePremiums(),
    methodology: createMethodology(),
}

export const sizePremiumsSlice = createSlice({
    name: "sizePremiumsSlice",
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(fetchSizePremiums.pending, (state, action) => {
            state.isLoadingSizePremiums = true
        })
        builder.addCase(fetchSizePremiums.fulfilled, (state, action) => {
            state.sizePremiums = action.payload
            state.isLoadingSizePremiums = false
        })
        builder.addCase(fetchSizePremiums.rejected, (state, action) => {
            state.sizePremiums = createEmptySizePremiums()
            state.isLoadingSizePremiums = false
        })
        resetStateOnSignOut(builder, initialState)
    },
})

export const selectSizePremiumsSlice = (state: RootState): ISizePremiumsSlice => {
    return state.sizePremiumSlice
}


