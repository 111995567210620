import {useInvoiceLogic} from "./UseInvoiceLogic";
import {deleteManagedUser} from "../../../api/requests";
import {fetchSubMgmtData} from "../../../billing/billingSlice";
import {OrderSummary} from "./OrderSummary";
import {PrimaryButton} from "../../Button/Button";
import React from "react";
import {CancelUserProps} from "./CancelUserProps";

export function DeleteUserInvoice(props: CancelUserProps) {
    const {
        error,
        isLoading, isEnabled,
        performAction
    } = useInvoiceLogic(props)
    const user_id = props.invoice.invoice_preview_params.user_id
    const user_info = props.users.filter(user => {
        return user.id === user_id
    })[0]

    const button_text = 'Delete'
    const desc_text_1 = `Deleting ${user_info.name}'s account will not affect your monthly invoice.` +
        ` However, deleting ${user_info.name}'s account will allow` +
        ` ${user_info.name} to create an Account Manager subscription with ${user_info.email}.`
    const desc_text_2 = `Press "Delete" to delete ${user_info.name}'s account and terminate access.`
    const deleteUser = performAction(deleteUserAction)

    function deleteUserAction() {
        let payload = {
            'id': user_id
        }
        return deleteManagedUser(payload)
    }

    return (
        <OrderSummary
            errors={props.errors}
            updateError={error}
            descText1={desc_text_1}
            descText2={desc_text_2}
            actionButton={
                <PrimaryButton className={'right-inline-button'}
                               text={button_text}
                               onClick={deleteUser}
                               isEnabled={isEnabled}
                               isLoading={isLoading}/>
            }
        />
    )
}
