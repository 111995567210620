import searchFilterUpArrow from "../../img/search-filter-arrow-up.svg";
import searchFilterDownArrow from "../../img/search-filter-arrow-down.svg";
import './CollapsibleArrow.scss';
import React from "react-dom";

export function CollapsibleArrow(props: { isExpanded: boolean }) {
    return <img className='collapsible-arrow' src={props.isExpanded
        ? searchFilterUpArrow
        : searchFilterDownArrow}/>;
}

export default CollapsibleArrow;
